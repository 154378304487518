import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/images/dexatel-logo.svg';
import { DxButton } from 'components/common';
import 'assets/scss/leftNavigation.scss';
import { useLocalization } from 'hooks';
import routes from 'constants/routes';
import DxMenu from './Menu/Menu';

const LeftNavigation: React.FC = () => {
    const { t } = useLocalization();

    return (
        <aside className="left-navigation">
            <div className="logo-outer d-flex align-items-center justify-content-center mx-auto">
                <Link className="d-block" to={routes.DASHBOARD}>
                    <img src={logo} alt="Dexatel" width={146} height={26} />
                </Link>
            </div>

            <div className="left-navigation-content">
                <Link to={routes.TRY_IT_NOW}>
                    <DxButton radius="sharped" appearance="default">
                        {t('TRY_IT_NOW')}
                    </DxButton>
                </Link>
            </div>
            <DxMenu />
        </aside>
    );
};

export default memo(LeftNavigation);
