import { FC, useMemo } from 'react';
import { IConversationItem } from '../types';

interface IMessagesHeader {
    selectedConversation: IConversationItem;
    isModal: boolean;
}
export const MessagesHeader: FC<IMessagesHeader> = ({ selectedConversation, isModal = false }) => {
    const senderName = useMemo(() => {
        return (
            selectedConversation.business_name_en ||
            selectedConversation.business_name ||
            selectedConversation.sender_name
        );
    }, [selectedConversation]);

    return (
        <div className={'messages-header'}>
            <div className={'messages-sender'}>{senderName}</div>
        </div>
    );
};
