import {
    deleteCancelableRequest,
    GetawayAPIService,
    GetawayAPIQuery,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';

import { ICancelableRequest } from 'types/services/http.config';
import { TID, TQuery } from 'types/services/apiKey';
import { IHeaders } from 'types/services/account';

const getSenders = (
    query: TQuery,
    channel: string | string[] = 'sms',
    type: any = 'alphanumeric',
): ICancelableRequest => {
    const q = query ? `${query}&` : '?';
    let typeFilter = '';
    if (channel === 'sms' || channel?.includes('sms')) {
        if (typeof type === 'string') {
            typeFilter = `&filter[type]=${type}`;
        } else {
            type.forEach((item: string) => {
                typeFilter += `&filter[type]=${item}`;
            });
        }
    }
    let queryStr: string;
    if (Array.isArray(channel)) {
        queryStr = `filter[channel]=${channel[0]}&filter[channel]=${channel[1]}${typeFilter}`;
    } else {
        queryStr = `filter[channel]=${channel}${typeFilter}`;
    }
    return getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/senders${q}${queryStr}`,
    });
};

const getActiveSenders = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/senders?filter[status]=Available&filter[channel]=sms&page_size=1000',
    });
};

const getSender = (id: TID): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}`,
    });

const deleteSender = (id: TID): ICancelableRequest =>
    deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}`,
    });

const deleteBlockerNumber = (id: TID, number: string | number): ICancelableRequest =>
    deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/blockers/${number} `,
    });

const getNumbers = (type = '', country = ''): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/numbers?type=${type}&country=${country}`,
    });

const createSender = (): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/senders',
    });

const updateShortCode = (id: TID, entity = ''): ICancelableRequest => {
    let endpoint = `/senders/${id}`;
    if (entity) {
        endpoint = `${endpoint}/${entity}`;
    }
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => endpoint,
    });
};

const updateCompany = (id: TID): ICancelableRequest => updateShortCode(id, 'company');

const updateUsageInfo = (id: TID): ICancelableRequest => updateShortCode(id, 'sms_campaign');

const updateComplianceKeywordResponses = (id: TID): ICancelableRequest =>
    updateShortCode(id, 'compliance_keyword_responses');

const updateSignupOptions = (id: TID): ICancelableRequest => updateShortCode(id, 'signup_options');

const updateExampleMessages = (id: TID): ICancelableRequest => updateShortCode(id, 'example_messages');

const uploadFile = (id: TID): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/required_attachments`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

const updateWhatsappInfo = (id: TID, headers?: IHeaders | undefined): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/whatsapp`,
        headers,
    });

const whatsappPhoneVerification = (id: TID): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/phone_verifications`,
    });

const updateViberInfo = (id: TID): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/viber`,
    });

const uploadLogo = (id: TID): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/viber_attachments`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

const uploadLogoWhatsapp = (id: TID): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/whatsapp_attachments`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

const createBlockerNumber = (id: TID): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders/${id}/blockers`,
    });

const methods = {
    uploadFile,
    getSender,
    getSenders,
    deleteSender,
    getNumbers,
    createSender,
    updateShortCode,
    updateCompany,
    updateUsageInfo,
    getActiveSenders,
    updateComplianceKeywordResponses,
    updateSignupOptions,
    updateExampleMessages,
    updateWhatsappInfo,
    updateViberInfo,
    whatsappPhoneVerification,
    uploadLogo,
    uploadLogoWhatsapp,
    createBlockerNumber,
    deleteBlockerNumber,
};

export default methods;
