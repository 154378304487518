import React, { useMemo } from 'react';

import { DxIcon, DxModal } from '../../common';

import tadaIcon from 'assets/images/homepage/tada-icon.svg';
import channelsIcon from 'assets/images/homepage/channels-icon.svg';
import senderId from 'assets/images/homepage/sender-id.svg';
import apiIcon from 'assets/images/homepage/api.svg';

import 'assets/scss/welcomePopup.scss';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTES_ENUM } from '../../../constants/routes';

const WelcomePopup = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
    const additionalProps = useMemo(() => {
        return visible ? { transitionName: 'none', maskTransitionName: 'none' } : {};
    }, [visible]);
    return (
        <DxModal
            className="welcome-popup"
            width={800}
            visible={visible}
            footer={null}
            closeIcon={<DxIcon type="close_small" size="sm" />}
            onCancel={onClose}
            {...additionalProps}
        >
            <div className="px-4">
                <img src={tadaIcon} alt="Tada icon" width={76} height={63} />
                <p className="font-size-24 font-bold mt-4 mb-3">Welcome to Dexatel!</p>
                <p className="mb-3 pb-3">
                    We're thrilled to have you on board for a free trial. We sincerely appreciate your decision to
                    explore our SMS marketing platform, and we're here to assist you throughout your journey.
                </p>

                <div className="pages-box p-4">
                    <p className="mb-4">
                        Please take your time to navigate the platform, create impactful campaigns, and elevate your
                        marketing efforts. If you have questions or need support, our team is readily available to help.
                    </p>
                    <div className="d-flex justify-content-between">
                        <Link
                            to={PRIVATE_ROUTES_ENUM.TRY_IT_NOW}
                            className="pages-item d-flex align-items-center text-start ps-4 pe-3 py-3"
                        >
                            <span className="d-flex flex-shrink-0">
                                <img width={64} height={64} src={channelsIcon} alt="" />
                            </span>
                            <span className="ms-3 d-flex flex-column">
                                <span className="font-bold font-size-16 mb-1">Try It Now</span>
                                <span className="mb-0">
                                    Try out our services by sending a message to your own verified number.
                                </span>
                            </span>
                        </Link>
                        <Link
                            to={PRIVATE_ROUTES_ENUM.REQUEST_SENDER_ID}
                            className="pages-item d-flex text-start ps-4 pe-3 py-3"
                        >
                            <span className="d-flex align-items-center flex-shrink-0">
                                <img width={64} height={64} src={senderId} alt="" />
                            </span>
                            <span className="ms-3 d-flex flex-column">
                                <span className="font-bold font-size-16 mb-1">Request Sender ID</span>
                                <span className="mb-0">
                                    You can request the Sender ID to send a campaign through all channels we offer.
                                </span>
                            </span>
                        </Link>
                    </div>
                    <Link
                        to={PRIVATE_ROUTES_ENUM.API_KEYS}
                        className="pages-item w-100 d-flex align-items-center text-start ps-4 pe-3 py-3 mt-4"
                    >
                        <span className="d-flex flex-shrink-0">
                            <img width={64} height={64} src={apiIcon} alt="" />
                        </span>
                        <span className="ms-3 d-flex flex-column">
                            <span className="font-bold font-size-16 mb-1">API Configuration</span>
                            <span className="mb-0">
                                Create your first API key and start using Dexatel API in under 30 seconds.
                            </span>
                        </span>
                    </Link>
                </div>
            </div>
        </DxModal>
    );
};

export default WelcomePopup;
