import React, { FC, useEffect, useMemo, useState } from 'react';

import { MessagesHeader } from './MessagesHeader';
import { MessagesMain } from './MessagesMain';
import { DxIcon, DxModal, EmptyState } from '../../../components/common';
import conversationImg from 'assets/images/empty-states/conversation-chat.svg';

import { IConversationItem } from '../types';
import { MessagesFooter } from './MessagesFooter';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducer } from '../../../types/reducers';
import { IInboxState } from '../../../types/reducers/inbox';
import { InboxActionTypes } from '../../../actions/types';

interface IMessages {
    selectedConversation: IConversationItem | null;
    isModal: boolean;
}

export const Messages: FC<IMessages> = ({ selectedConversation, isModal }) => {
    const { modalSelectedConversation } = useSelector<IRootReducer, IInboxState>((state) => state.inbox);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const dispatch = useDispatch();
    /**
     * Show you were unassigned before clearing Selected Conversation
     */
    useEffect(() => {
        setIsInfoModalVisible(false);
        if (selectedConversation?.showPopUp) {
            if (modalSelectedConversation) {
                setIsInfoModalVisible(true);
            } else if (!modalSelectedConversation) {
                setIsInfoModalVisible(true);
            }
        }
    }, [selectedConversation, modalSelectedConversation]);

    const infoModalMessages = useMemo(() => {
        if (selectedConversation) {
            switch (selectedConversation.showPopUp) {
                case 'UPDATED_ASSIGNEE':
                    return {
                        title: 'Conversation unassigned',
                        description: 'You can no longer read this conversation.',
                    };
                case 'DELETED':
                    return {
                        title: 'Conversation unavailable',
                        description: 'You cannot access this conversation.',
                    };
            }
        }
        return {
            title: '',
            description: '',
        };
    }, [selectedConversation?.showPopUp]);

    useEffect(() => {
        if (isInfoModalVisible) {
            document.querySelector('.dx-wrapper')?.classList.add('blur-effect');
        }
        return () => {
            document.querySelector('.dx-wrapper')?.classList.remove('blur-effect');
        };
    }, [isInfoModalVisible]);

    if (!selectedConversation) {
        return (
            <EmptyState image={conversationImg} title={'Once a conversation is selected, it will be displayed here.'} />
        );
    }

    return (
        <div>
            <MessagesHeader selectedConversation={selectedConversation} isModal={isModal} />
            <MessagesMain selectedConversation={selectedConversation} key={selectedConversation.id} isModal={isModal} />
            <MessagesFooter
                selectedConversation={selectedConversation}
                isModal={isModal}
                isInfoModalVisible={isInfoModalVisible}
            />
            <DxModal
                className="with-custom-position"
                centered={true}
                visible={isInfoModalVisible}
                icon={<DxIcon appearance={'danger'} type="info_circle_outline" size={'lg'} />}
                title={infoModalMessages.title}
                description={infoModalMessages.description}
                confirmText={'Acknowledge'}
                onCancel={() => {
                    if (isModal) {
                        dispatch({ type: InboxActionTypes.CLEAR_MODAL_SELECTED_CONVERSATION });
                    } else {
                        dispatch({ type: InboxActionTypes.CLEAR_SELECTED_CONVERSATION });
                    }
                }}
                onOk={() => {
                    if (isModal) {
                        dispatch({ type: InboxActionTypes.CLEAR_MODAL_SELECTED_CONVERSATION });
                    } else {
                        dispatch({ type: InboxActionTypes.CLEAR_SELECTED_CONVERSATION });
                    }
                }}
            />
        </div>
    );
};
