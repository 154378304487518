import { ContactsActionTypes as Types } from './types';

const editContact = (editData: any) => {
    return {
        type: Types.EDIT,
        payload: editData,
    };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    editContact,
};
