import {
    deleteCancelableRequest,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';

import { ICancelableRequest } from 'types/services/http.config';
import { USER_STATUSES } from '../../constants/common';

const UsersHttpService = {
    getUsers: (): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users?filter[statuses]=${USER_STATUSES.PHONE_VERIFIED}`,
        });
    },
    query: (query: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/self/invitations${query}`,
        });
    },
    getSingle: (id: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/${id}`,
        });
    },
    create: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self/invitations',
        });
    },
    update: (id: string): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/${id}`,
        });
    },
    block: (email: string): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/self/subusers/${email}`,
        });
    },
    delete: (email: string): ICancelableRequest => {
        return deleteCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/self/subusers/${email}`,
        });
    },
};

export default UsersHttpService;
