import Axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { isNil, isObject, noop } from 'lodash';
import queryString from 'query-string';

import { IRequestObject, ICancelableRequestParam, TEndpointPath, ICancelableRequest } from 'types/services/http.config';
import { PaginationDefaultOptions } from 'constants/common';
import { AuthAction } from 'actions';
import store from 'store';

const axiosConfig = {
    baseURL: process.env.REACT_APP_GETAWAY_API_URL,
    timeout: 30000,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
    },
};
const GetawayAPIService: AxiosInstance = Axios.create(axiosConfig);
const GetawayAPIQuery: AxiosInstance = Axios.create(axiosConfig);
const ExternalAPIService: AxiosInstance = Axios.create();

const GetawayBLOBAPIService: AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_GETAWAY_API_URL,
    timeout: 30000,
    withCredentials: false,
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/json',
    },
});

const setRequestHeadersInterceptor = (request: any) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !request.headers.Authorization) {
        request.headers.Authorization = 'Bearer ' + accessToken;
    }
    return request;
};

const setRequestHeadersInterceptorErrHandler = (error: AxiosError) => {
    return Promise.reject(error);
};

const requestDefaultPageSizeInterceptor = (request: any) => {
    if (request.method === 'get') {
        const { pageSize, pageSizeOptions } = PaginationDefaultOptions;
        const [requestOrigin, requestQuery = ''] = request.url.split('?');
        const query = queryString.parse(requestQuery);

        if (
            query.page_size !== '1000' &&
            (isNil(query.page_size) || pageSizeOptions?.indexOf(query.page_size as string) === -1)
        ) {
            query.page_size = `${pageSize}`;
        }

        request.url = `${requestOrigin}?${queryString.stringify(query, { encode: false })}`;
    }
    return request;
};

const responseInterceptor = (response: AxiosResponse) => {
    return response;
};

const responseInterceptorErrHandler = (error: any) => {
    // Unauthorized 401
    // Forbidden 403
    // Conflict 409

    try {
        const { status } = error.response;
        if (status === 401) {
            const { pathname, search } = window.location;
            localStorage.removeItem('accessToken');
            store.dispatch(AuthAction.doLogout(`${pathname}${search}`));
        }

        if (status === 400 || status === 401 || status === 404 || status === 409 || status === 429) {
            const { data } = error.response;

            return Promise.reject({ ...data, status });
        }
    } catch {
        // TODO: should redirect to the no internet page.
        // console.log('Network error');
    }

    return Promise.reject(error);
};

const responseDTOParserInterceptorErrHandler = (error: object) => {
    // need handle error case
    return Promise.reject(error);
};

const responseDTOParserInterceptor = (response: any) => {
    return response.data;
};

// Request interceptors ===========================================
GetawayAPIService.interceptors.request.use(setRequestHeadersInterceptor, setRequestHeadersInterceptorErrHandler);
GetawayAPIQuery.interceptors.request.use(setRequestHeadersInterceptor, setRequestHeadersInterceptorErrHandler);

// Response interceptors ===========================================
GetawayAPIService.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
GetawayAPIService.interceptors.response.use(responseDTOParserInterceptor, responseDTOParserInterceptorErrHandler);

GetawayAPIQuery.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
GetawayAPIQuery.interceptors.response.use(responseDTOParserInterceptor, responseDTOParserInterceptorErrHandler);

GetawayAPIQuery.interceptors.request.use(requestDefaultPageSizeInterceptor);

GetawayBLOBAPIService.interceptors.request.use(setRequestHeadersInterceptor, setRequestHeadersInterceptorErrHandler);
GetawayBLOBAPIService.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);

const generateRequestObj = (
    requestService: object,
    endpointPath: TEndpointPath,
    defaultParams: any,
): IRequestObject => {
    const cancelTokenSource: any = Axios.CancelToken.source();
    if (!isObject(requestService)) console.log("requestService isn't Object");
    if (!isObject(endpointPath)) console.log("endpointPath isn't String");
    if (!isObject(defaultParams)) defaultParams = {};

    return {
        cancelTokenSource,
        request: noop,
    };
};

const getCancelableRequest = ({
    requestService,
    endpointPath,
    defaultParams,
    headers,
}: ICancelableRequestParam): ICancelableRequest => {
    let { request, cancelTokenSource }: IRequestObject = generateRequestObj(
        requestService,
        endpointPath,
        defaultParams,
    );
    const { cancel, token } = cancelTokenSource;

    request = (params = {}) => {
        const encodedParams: URLSearchParams = new URLSearchParams();
        const url = new URL(window.location.origin + endpointPath({ ...defaultParams, ...params }));
        if (url.search.length > 1) {
            url.searchParams.forEach((value, name) => {
                encodedParams.append(name, value);
            });
        }
        const haveSearch = url.search.includes('[name]');
        return requestService.get(haveSearch ? url.pathname : endpointPath({ ...defaultParams, ...params }), {
            cancelToken: token,
            headers,
            params: haveSearch ? encodedParams : undefined,
        });
    };

    return {
        cancel,
        request,
    };
};

const postCancelableRequest = ({
    requestService,
    endpointPath,
    defaultParams,
    headers,
    timeout,
}: ICancelableRequestParam): ICancelableRequest => {
    let { request, cancelTokenSource } = generateRequestObj(requestService, endpointPath, defaultParams);
    const { cancel, token } = cancelTokenSource;

    request = (requestBody, params = {}) =>
        requestService.post(endpointPath({ ...defaultParams, ...params }), requestBody, {
            cancelToken: token,
            headers,
            timeout,
        });

    return {
        cancel,
        request,
    };
};

const putCancelableRequest = ({
    requestService,
    endpointPath,
    defaultParams,
    headers,
}: ICancelableRequestParam): ICancelableRequest => {
    let { request, cancelTokenSource } = generateRequestObj(requestService, endpointPath, defaultParams);
    const { cancel, token } = cancelTokenSource;

    request = (requestBody, params = {}) =>
        requestService.put(
            endpointPath({ ...defaultParams, ...params }),
            requestBody === undefined ? {} : requestBody,
            {
                cancelToken: token,
                headers,
            },
        );

    return {
        cancel,
        request,
    };
};

const deleteCancelableRequest = ({
    requestService,
    endpointPath,
    defaultParams,
    headers,
}: ICancelableRequestParam): ICancelableRequest => {
    let { request, cancelTokenSource } = generateRequestObj(requestService, endpointPath, defaultParams);
    const { cancel, token } = cancelTokenSource;

    request = (params = {}) =>
        requestService.delete(endpointPath({ ...defaultParams, ...params }), {
            cancelToken: token,
            headers,
        });

    return {
        cancel,
        request,
    };
};

export {
    GetawayAPIQuery,
    GetawayAPIService,
    ExternalAPIService,
    GetawayBLOBAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
    deleteCancelableRequest,
};
