export const InsightsPreferences = {
    options: [
        { value: 'channel', label: 'Channel', selected: true },
        { value: 'priority', label: 'Source', selected: true },
        { value: 'from', label: 'Sender', selected: true },
        { value: 'to', label: 'Phone', selected: true },
        { value: 'country', label: 'Country', selected: true },
        { value: 'text', label: 'Message', selected: true },
        { value: 'segment_count', label: 'Segments', selected: false },
        { value: 'price', label: 'Price', selected: true },
        { value: 'direction', label: 'Type', selected: false },
        { value: 'create_date', label: 'Date', selected: true },
        { value: 'status', label: 'Status', selected: true },
        { value: 'verification_status', label: 'Code Status', selected: true },
        { value: 'wa_category', label: 'WhatsApp Category', selected: false },
    ],
    total: 13,
    count: 10,
    perPage: 10,
};
