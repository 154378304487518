import {
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
    GetawayBLOBAPIService,
} from './http.config';
// Import Interfaces
import { ICancelableRequest } from 'types/services/http.config';
// Import Interfaces
import { IHeaders } from 'types/services/account';
import { TLevel, THash } from 'types/services/auth';

const AccountHttpService = {
    getInfo: (level: TLevel): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/accounts/self',
            headers: { 'D-Account-Info-Level': level },
        });
    },
    updateInfo: (headers: IHeaders | undefined = {}): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/accounts/self',
            headers,
        });
    },
    getUserInvitationIsValid: (hash: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/self/invitations/${hash}`,
        });
    },
    getUser: (): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self',
        });
    },
    disConnectGoogle: (): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self/social_networks/google',
        });
    },
    connectGoogle: (hash: THash): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self/social_networks',
            headers: { 'D-social-network-token': hash },
        });
    },
    updateUser: (headers: IHeaders | undefined = {}): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self',
            headers,
        });
    },
    phoneVerification: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/phone_verifications',
        });
    },
    impersonate: (id: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/auth_sessions/${id}`,
        });
    },
    exportRates: (query: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayBLOBAPIService,
            endpointPath: () => `/export_rates${query}`,
        });
    },
    getCustomRates: (search: any): ICancelableRequest => {
        const q = search ? `${search}&` : '?';
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/rates${q}filter[channel]=sms&rate_mode=ACCOUNT`,
        });
    },
    setUserPreference: (): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/users/self`,
        });
    },
};

export default AccountHttpService;
