import { ICancelableRequest } from '../../types/services/http.config';
import { GetawayAPIService, getCancelableRequest, postCancelableRequest } from './http.config';

const bindAccount = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/pipedrive-oauth/token',
    });
};

const getJWT = (companyId: string, query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/pipedrive-companies/${companyId}${query}`,
    });
};

const getAccount = (jwt: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/accounts/self',
        headers: { 'D-Account-Info-Level': 'long', Authorization: `Bearer ${jwt}` },
    });
};

const pipedriveSenders = (jwt: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/senders?filter[status]=Available&filter[channel]=sms&page_size=1000',
        headers: { Authorization: `Bearer ${jwt}` },
    });
};

const pipedriveSendSMS = (jwt: string): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/pipedrive-messages`,
        headers: { Authorization: `Bearer ${jwt}` },
    });
};

const methods = { bindAccount, getJWT, pipedriveSenders, pipedriveSendSMS, getAccount };
export default methods;
