import { useCallback, useMemo } from 'react';
import { isNil } from 'lodash';

import { IPaginationOptions, IPaginationQuery, IPaginationResponse } from 'types/hooks/usePagination';
import 'assets/scss/dxPagination.scss';
import { useQuery } from './index';

function usePagination(responseOptions: IPaginationResponse, pagePropName = 'page', pageSizePropName = 'page_size') {
    const { updateQuery } = useQuery();

    const PaginationDefaultOptions: IPaginationOptions = {
        pageSizeOptions: ['10', '20', '50', '100'],
        hideOnSinglePage: false,
        showSizeChanger: true,
        // showQuickJumper: true,
        pageSize: 10,
        current: 1,
        total: 0,
    };

    let current = PaginationDefaultOptions.current;
    let pageSize = PaginationDefaultOptions.pageSize;
    let total = PaginationDefaultOptions.total;

    if (!isNil(responseOptions)) {
        current = isNil(responseOptions.number) ? current : responseOptions.number;
        pageSize = isNil(responseOptions.size) ? pageSize : responseOptions.size;
        total = isNil(responseOptions.total) ? total : responseOptions.total;
    }

    const options = useMemo(
        () => ({
            pageSize,
            current,
            total,
        }),
        [pageSize, current, total],
    );

    const optionsResult = useMemo(() => Object.assign(PaginationDefaultOptions, options), [options]);

    const onChange = useCallback(
        (value: number) => {
            const query: IPaginationQuery = { [pagePropName]: value };
            updateQuery(query, false);
        },
        [updateQuery],
    );

    const onShowSizeChange = useCallback(
        (current: number, value: number) => {
            const query: IPaginationQuery = { [pageSizePropName]: value, [pagePropName]: current };
            updateQuery(query, false);
        },
        [updateQuery],
    );

    return {
        ...optionsResult,
        onShowSizeChange,
        onChange,
    };
}

export default usePagination;
