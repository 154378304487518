import React, { FC, memo, useEffect, useState } from 'react';
import { Alert } from 'antd';

import { IAlertProps } from 'types/components';
import DxIcon from '../Icon';
import { getIconType } from './utils';

import 'assets/scss/dxAlert.scss';

const DxAlert: FC<IAlertProps> = ({
    isStatic = true,
    delay = 4000,
    message,
    appearance = 'info',
    showIcon = false,
    closable = false,
    description,
    closeText,
    style = {},
    ...restProps
}) => {
    const [isVisible, setVisibleState] = useState<boolean>(true);

    const iconType: string = getIconType(appearance, description);

    useEffect(() => {
        if (!isStatic) {
            setTimeout(() => {
                setVisibleState(!isVisible);
            }, delay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={`dx-alert alert-${appearance}`} style={style}>
            <Alert
                message={message}
                showIcon={showIcon}
                closable={closable}
                description={description}
                icon={<DxIcon type={iconType} />}
                closeText={closable && <DxIcon type="close_small" />}
                {...restProps}
            />
        </div>
    );
};

export default memo(DxAlert);
