export const PUBLIC_ROUTES_ENUM = {
    SIGN_IN: '/sign-in/',
    SIGN_UP: '/sign-up/',
    EMAIL_VERIFICATION: '/email-verification',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:hash',
    PIPEDRIVE: '/pipedrive',
    PHONE_VERIFICATION: '/phone-verification',
    ACCOUNT_VERIFICATION: '/verification',
    INVITATIONS: '/invitations',
    // TEST_COMPONENTS: '/test-components',
};
export const PRIVATE_ROUTES_ENUM = {
    // Main page
    DASHBOARD: '/',
    WELCOME_DASHBOARD: '/welcome',
    // Try It Now
    TRY_IT_NOW: '/try-it-now',
    TRY_IT_NOW_SMS: '/try-it-now/sms',
    TRY_IT_NOW_VIBER: '/try-it-now/viber',
    TRY_IT_NOW_WHATSAPP: '/try-it-now/whatsapp',
    // SMS
    SMS: '/sms',
    SMS_MY_CAMPAIGNS: '/sms/my-campaigns',
    SMS_CREATE_CAMPAIGN: '/sms/create-campaign',
    // WhatsApp
    WHATSAPP: '/whatsapp',
    // WhatsApp Senders
    WHATSAPP_GET_ACCESS: '/whatsapp/get-access',
    WHATSAPP_MY_SENDERS: '/whatsapp/my-senders',
    WHATSAPP_BUSINESS_DETAILS: '/whatsapp/business-details',
    // WhatsApp Templates
    WHATSAPP_MY_TEMPLATES: '/whatsapp/my-templates',
    CREATE_WHATSAPP_TEMPLATE: '/whatsapp/create-template',
    // WhatsApp Campaigns
    WHATSAPP_MY_CAMPAIGNS: '/whatsapp/my-campaigns',
    WHATSAPP_CREATE_CAMPAIGN: '/whatsapp/create-campaign',
    // Viber
    VIBER: '/viber',
    // Viber Senders
    VIBER_GET_ACCESS: '/viber/get-access',
    VIBER_MY_SENDERS: '/viber/my-senders',
    VIBER_BUSINESS_DETAILS: '/viber/business-details',
    // Viber Campaigns
    VIBER_MY_CAMPAIGNS: '/viber/my-campaigns',
    VIBER_CREATE_CAMPAIGN: '/viber/create-campaign',
    // Add Channel
    ADD_CHANNEL: '/add-channel',
    // Senders
    SENDERS: '/senders',
    // Sender IDs
    MY_SENDERS: '/senders/my-senders',
    REQUEST_SENDER_ID: '/senders/request-sender-id',
    // Sender Numbers
    MY_NUMBERS: '/senders/my-numbers',
    BUY_NUMBER: '/senders/buy-number',
    // Short Codes
    MY_SHORT_CODES: '/senders/my-short-codes',
    REQUEST_SHORT_CODE: '/senders/request-short-code',
    REQUEST_SHORT_CODE_EDIT: '/senders/request-short-code/:id',
    // Templates
    LIBRARY: '/library',
    CREATE_TEMPLATE: '/library/create',
    EDIT_SMS_TEMPLATE: '/library/edit/:id',
    EDIT_VIBER_TEMPLATE: '/library/viber-edit/:id',
    // Contacts
    CONTACTS: '/contacts',
    AUDIENCE: '/contacts/my-audiences',
    CREATE_AUDIENCE: '/contacts/create-audience',
    RENAME_AUDIENCE: '/contacts/rename-audience/:id',
    ADD_CONTACT: '/contacts/add-contact/:id',
    STOP_LIST: '/contacts/my-stop-list',
    STOP_ADD_CONTACT: '/contacts/stop-list/add-contact',
    // Insights
    INSIGHTS: '/insights',
    // Link Management
    LINK_MANAGEMENT: '/link-management',
    // APIs
    API_KEYS: '/apis',
    CREATE_API: '/apis/create',
    EDIT_API: '/apis/edit/:id',
    // Messages
    RESPONSES: '/responses',
    // Settings
    SETTINGS: '/settings',
    MY_USER: '/settings/user',
    PROFILE: '/settings/account',
    ALERTS: '/settings/alerts',
    USERS: '/settings/users',
    ADD_USER: '/settings/users/create',
    EDIT_USER: '/settings/users/:id',
    SECURITY: '/settings/security',
    COUNTRY_BLACKLIST: '/settings/country-blacklist',
    SMS_LIMITS: '/settings/sms-limits',
    ADD_LIMIT: '/settings/sms-limits/create',
    EDIT_LIMIT: '/settings/sms-limits/:id',
    // Subaccounts
    SUBACCOUNTS: '/subaccounts',
    // Billing
    BILLING: '/billing',
    PAYMENTS: '/billing/payments',
    INVOICES: '/billing/invoices',
    AUTO_TOP_UP: '/billing/auto-top-up',
    RATES: '/billing/rates',
    // Add Funds
    ADD_FUNDS: '/add-funds',
    ADD_FUNDS_THANK_YOU: '/add-funds/thank-you',
    ADD_FUNDS_FAILED: '/add-funds/failed',
    // Verification
    PHONE_VERIFICATION: '/change-phone',
    LOGOUT: '/logout',
    // TEST_COMPONENTS: '/test-components',
    // ACCOUNT_VERIFICATION: '/account-verification',
    // Inbox
    INBOX: '/inbox',
    INBOX_ASSIGNED_TO_ME: '/inbox/assigned-to-me',
    INBOX_ASSIGNED_TO_OTHERS: '/inbox/assigned-to-others',
    INBOX_UNASSIGNED: '/inbox/unassigned',
    // PipeDrive
    PIPEDRIVE: '/pipedrive',
    // Carrier Lookup
    CARRIER_LOOKUP: '/tools',
    SINGLE_LOOKUP: '/tools/carrier-single',
    BULK_LOOKUP: '/tools/carrier-bulk',
    LOOKUP_HISTORY: '/tools/carrier-history',
};

export const REDIRECTION_ROUTES_ENUM: { [key: string]: string } = {
    '/login': PUBLIC_ROUTES_ENUM.SIGN_IN,
    '/register': PUBLIC_ROUTES_ENUM.SIGN_UP,
};

export const PRIVATE_ROUTES_VALUES = Object.values(PRIVATE_ROUTES_ENUM);
export const PUBLIC_ROUTES_VALUES = Object.values(PUBLIC_ROUTES_ENUM);
export const REDIRECTION_ROUTES_KEYS = Object.keys(REDIRECTION_ROUTES_ENUM);

const ROUTES_ENUM = { ...PUBLIC_ROUTES_ENUM, ...PRIVATE_ROUTES_ENUM };

export default ROUTES_ENUM;
