import React, { FC, memo, useEffect, useState } from 'react';
import { Progress } from 'antd';
import { getColor } from 'services/utils/helper';

const Loading: FC = () => {
    const [percent, setPercent] = useState<number>(0);
    const [incrementPercent, setIncrementPercent] = useState<any>(false);

    useEffect(() => {
        if (percent >= 90) {
            clearInterval(incrementPercent);
            setIncrementPercent(null);
            return;
        }

        setIncrementPercent(
            setInterval(() => {
                setPercent(percent + 10);
            }, 500),
        );

        return clearInterval(incrementPercent);
    }, [percent]);

    return <Progress type="circle" strokeColor={getColor('--primary-main')} percent={percent} width={80} />;
};

export default memo(Loading);
