import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { useLocalization } from 'hooks';
import { IRecommendedChannelProps } from 'types/components';
import sms from 'assets/images/homepage/sms.svg';
import viber from 'assets/images/homepage/viber.svg';
import whatsapp from 'assets/images/homepage/whatsapp.svg';
import 'assets/scss/recommendedChannel.scss';
import { DxCard } from 'components/common';
import { getCountryNameByCode } from 'services/utils/helper';
import { RecommendedChannels } from 'pages/Dashboard/config';
import { isNil } from 'lodash';
import { CHANNELS } from 'constants/common';
import { IChannel } from 'types/global';

const RecommendedChannel: FC<IRecommendedChannelProps> = ({ channel, country }) => {
    const { t } = useLocalization();

    const imgPaths = {
        SMS: sms,
        VIBER: viber,
        WHATSAPP: whatsapp,
    };

    const labels = {
        SMS: t('SMS'),
        VIBER: t('VIBER'),
        WHATSAPP: t('WHATSAPP'),
    };

    let recommended = RecommendedChannels[channel]?.ALL;

    if (!isNil(country) && !isNil(RecommendedChannels[channel]?.[country?.toLowerCase()])) {
        recommended = RecommendedChannels[channel]?.[country?.toLowerCase()];
    }

    return (
        <DxCard withShadow={true}>
            <h2 className="homepage-card-title">
                {t('OTHER_RECOMMENDED_CHANNELS')}
                <strong> {getCountryNameByCode(country?.toLowerCase())}</strong>
            </h2>
            {recommended?.map((recommendedChannel, index) => (
                <div
                    key={index}
                    className={classNames('channel', {
                        sms: recommendedChannel === CHANNELS.SMS,
                        viber: recommendedChannel === CHANNELS.VIBER,
                        whatsapp: recommendedChannel === CHANNELS.WHATSAPP,
                    })}
                >
                    <img
                        src={imgPaths[recommendedChannel as IChannel]}
                        alt={recommendedChannel}
                        height={32}
                        width={32}
                    />
                    <p className="channel-title">{labels[recommendedChannel as IChannel]}</p>
                </div>
            ))}
        </DxCard>
    );
};

export default memo(RecommendedChannel);
