import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { IAuthState, IRootReducer } from 'types/reducers/auth';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { DxLoading } from 'components';

const AppRoutes = () => {
    const auth = useSelector<IRootReducer, IAuthState>((state) => state.auth);
    // show loading while checking auth state
    if (!auth.ready) {
        return (
            <div className={'main-loading'}>
                <DxLoading />
            </div>
        );
    }

    return (
        <Suspense
            fallback={
                <div className={'main-loading'}>
                    <DxLoading />
                </div>
            }
        >
            {auth.isLoggedIn ? <PrivateRoutes /> : <PublicRoutes prevRoute={auth.prevRoute} />}
        </Suspense>
    );
};

export default AppRoutes;
