import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'dexatel-auth';
// @ts-ignore
import TagManager from 'react-gtm-module';

import { AccountHttpService, TransactionsHttpService } from 'services/http';
import { IAccountState, IAuthState, IRootReducer } from 'types/reducers';
import { AuthAction, AccountAction, BalanceAction } from 'actions';
import { PUBLIC_ROUTES_ENUM, PRIVATE_ROUTES_ENUM } from 'constants/routes';
import { IBalanceState } from 'types/reducers/balance';
import { useRequest, useMount } from 'hooks';
import { isKnownRoute } from '../../services/utils/routeHelper';
import { Helmet } from 'react-helmet';
import { AuthActionTypes } from '../../actions/types';
import { USER_STATUSES } from '../../constants/common';
import 'assets/scss/pages/page-status.scss';
import 'assets/scss/bootstrap-utilities.min.css';
import queryString from 'query-string';
import { DxShowNotification } from '../common';
import jwt_decode from 'jwt-decode';

const tagManagerArgs = {
    gtmId: 'GTM-TZ8LB3V',
};

const App: React.FC = (props) => {
    const auth = useSelector<IRootReducer, IAuthState>((state) => state.auth);
    const { currency, permissions, accountId, username, fullName } = useSelector<IRootReducer, IAccountState>(
        (state) => state.account,
    );
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const queryParams = queryString.parse(search);
    const { doGetRequest } = useRequest();
    const dispatch = useDispatch();
    const prevRoute = useRef<null | string>(null);
    const [retry, setRetry] = useState(false);
    /**
     * Permissions set
     */
    const canView = useMemo(() => {
        return permissions.PAYMENTS?.canView;
    }, [permissions]);

    /**
     * Auth Package
     */
    const { getUserAndAccount, signOut } = useAuth();

    const account = useRef({
        impersonate: (sessionId: string) => AccountHttpService.impersonate(sessionId),
    }).current;

    const transactions = useRef({
        getBalance: (accountId: string) => TransactionsHttpService.getBalance(accountId),
    }).current;

    const init = async () => {
        try {
            let impersonated = false;
            if (queryParams.session_id) {
                const requestFn = account.impersonate((queryParams.session_id as string) || '');
                await doGetRequest(requestFn.request, {
                    successCallback: (data) => {
                        const mainToken: string | null = localStorage.getItem('accessToken');
                        localStorage.setItem('accessTokenMain', mainToken || '');
                        localStorage.setItem('accessToken', data.token);
                        if (window.heap) {
                            impersonated = true;
                        }
                    },
                    errorCallback: (error) => {
                        DxShowNotification('error', 'Session is no valid anymore');
                        TagManager.initialize(tagManagerArgs);
                        if (error?.response?.status !== 429) {
                            signOut();
                            dispatch(AuthAction.doLogout(''));
                        } else {
                            setRetry(true);
                        }
                    },
                });
            }
            /**
             * Skip initialization from iframe
             */
            if (window.location !== window.parent.location) {
                dispatch({ type: AuthActionTypes.DO_IFRAME_LOGIN });
                return;
            }
            if (pathname === PRIVATE_ROUTES_ENUM.LOGOUT) {
                signOut();
                dispatch(AuthAction.doLogout(''));
            } else {
                const token = localStorage.getItem('accessToken');
                if (token) {
                    const { user, account } = await getUserAndAccount();
                    /**
                     * Get Account Custom Rates
                     */
                    let hasCustomRates = false;
                    if ((user.permissions.INVOICES && 1) === 1) {
                        const requestFn = AccountHttpService.getCustomRates('');
                        const customRates: any = await doGetRequest(requestFn.request, {
                            successCallback: (response: any) => {
                                // console.log('response', response);
                            },
                            errorCallback: (error) => {
                                // console.log('error', error);
                            },
                        });
                        hasCustomRates = Boolean(customRates.data.length);
                    }
                    if (user.status === USER_STATUSES.NEW) {
                        signOut();
                        navigate(PUBLIC_ROUTES_ENUM.EMAIL_VERIFICATION, { state: { email: user.username } });
                        return;
                    }
                    /**
                     * Call GTM with userID
                     */
                    const userId = user.id;
                    TagManager.initialize({ ...tagManagerArgs, dataLayer: { userId } });
                    if (window.heap) {
                        window.heap.identify(
                            `${account.company_name} (${impersonated ? 'Impersonated' : user.full_name})`,
                        );
                        window.heap.addUserProperties({
                            email: user.username,
                            cpass_user_id: user.id,
                            account_id: account.id,
                        });
                    }
                    const status = [USER_STATUSES.NEW, USER_STATUSES.EMAIL_VERIFIED].includes(user.status)
                        ? user.status
                        : account.status;
                    const info = {
                        ...user,
                        ...account,
                        status,
                        userId,
                        hasCustomRates,
                    };
                    dispatch(AccountAction.setInfo(info));
                    dispatch(AuthAction.doLogin());
                } else {
                    let returnUrl = '';
                    /**
                     * Call GTM without userID
                     */
                    TagManager.initialize(tagManagerArgs);
                    if (isKnownRoute(pathname, true)) {
                        returnUrl = `${pathname}${search}`;
                    }
                    signOut();
                    dispatch(AuthAction.doLogout(returnUrl));
                }
            }
        } catch (error: any) {
            /**
             * Call GTM without userID
             */
            TagManager.initialize(tagManagerArgs);
            let returnUrl = '';
            if (isKnownRoute(pathname, true)) {
                returnUrl = `${pathname}${search}`;
            }
            if (error?.response?.status !== 429) {
                signOut();
                dispatch(AuthAction.doLogout(returnUrl));
            } else {
                setRetry(true);
            }
        }
    };

    // Get balance info
    const getBalanceInfo = (currency: string, requestFn: any) => {
        if (isKnownRoute(pathname, false)) {
            return;
        }
        doGetRequest(requestFn.request, {
            successCallback: (balance: IBalanceState) => {
                if (balance.currency !== currency && currency) {
                    balance.currency = currency;
                }
                dispatch(BalanceAction.setInfo(balance));
            },
        });
    };

    useEffect(() => {
        const requestFn = transactions.getBalance(accountId || '');
        if (auth.isLoggedIn && canView) {
            getBalanceInfo(currency, requestFn);
        }
        return () => {
            requestFn.cancel();
        };
    }, [pathname, auth.isLoggedIn]);

    useEffect(() => {
        if (prevRoute.current && !sessionStorage.getItem('internalRoute')) {
            sessionStorage.setItem('internalRoute', 'TRUE');
        }
        prevRoute.current = pathname;
    }, [pathname]);

    useMount(() => {
        init();
    });

    /**
     * Clarity part
     */
    const [clarityInitialized, setClarityInitialized] = useState(false);
    useEffect(() => {
        if (clarityInitialized || isKnownRoute(pathname, false)) {
            return;
        }
        let intervalId: NodeJS.Timeout;
        if (username) {
            intervalId = setInterval(() => {
                if (window.clarity) {
                    window.clarity('identify', username, 'dashboard-session', pathname, username);
                    setClarityInitialized(true);
                }
            }, 2000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [clarityInitialized, pathname, username]);

    return (
        <>
            <Helmet>
                <title>Dexatel Dashboard - Your All-in-One Communication Solution</title>
                <meta
                    name="description"
                    content="Check out Dexatel's dashboard for real-time insights to monitor your communication channels efficiently. Sign up to experience the power of communication."
                />
            </Helmet>
            {retry ? (
                <div className="page-status">
                    <div className="page-status-content">
                        <h1 className="page-status-title">Limit exceeded</h1>
                        <p className="page-status-desc">You have exceeded requests limit per second, try later.</p>
                    </div>
                </div>
            ) : (
                <div className={'dx-wrapper'}>{props.children}</div>
            )}
        </>
    );
};

export default App;
