import React, { FC, memo } from 'react';
import { Button } from 'antd';

import { IButtonProps } from 'types/components';
import { DxTooltip } from '../index';
import { BTN_APPEARANCE } from './constants';

import 'assets/scss/dxButton.scss';

const DxButton: FC<IButtonProps> = ({
    children,
    size,
    appearance = BTN_APPEARANCE.SECONDARY,
    radius = 'sharped',
    disabled,
    tooltipMessage,
    customClassName,
    placement = 'left',
    ...restProps
}) => {
    if (disabled && tooltipMessage) {
        return (
            <DxTooltip message={true} title={tooltipMessage} placement={placement}>
                <div
                    // style={{ pointerEvents: 'none' }}
                    className={`dx-button ${appearance} ${radius} ${size ? size : ''}`}
                >
                    <Button disabled={disabled} {...restProps}>
                        {children}
                    </Button>
                </div>
            </DxTooltip>
        );
    }
    return (
        <div className={`dx-button ${customClassName ? customClassName : ''} ${appearance} ${radius} ${size}`}>
            <Button disabled={disabled} {...restProps}>
                {children}
            </Button>
        </div>
    );
};

export default memo(DxButton);
