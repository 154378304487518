import {
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';
import { ICancelableRequest } from 'types/services/http.config';
import { TQuery } from 'types/services/apiKey';

function getCookie(name: any) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        // @ts-ignore
        return parts.pop().split(';').shift();
    }
}

const TransactionsHttpService = {
    createPaymentIntent: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => 'payment_intents',
        });
    },
    createPayment: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/payments',
            headers: {
                'GA-CID': getCookie('_ga'),
                'GA-SESSION-ID': getCookie('_ga_9Z3PJM1M8D'),
            },
        });
    },
    // NEW
    getBalance: (accountId: string | undefined): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/balances/${accountId}`,
        });
    },
    // NEW
    updateTopUp: (accountId: string): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/balances/${accountId}`,
        });
    },
    // NEW TODO
    getPayments: (query: TQuery): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIQuery,
            endpointPath: () => `/payments/${query}`,
        });
    },
    // NEW
    getCreditAlerts: (accountId: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/alerts/${accountId}`,
        });
    },
    // NEW
    createCreditAlerts: (accountId: string): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/alerts/${accountId}`,
        });
    },
    // +
    downloadInvoice: (id: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/transaction/get/${id}`,
        });
    },
};

export default TransactionsHttpService;
