import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ILanguageCode, ITranslationKey } from 'types/localization';

function useLocalization() {
    const { t: translate, i18n } = useTranslation();

    const t = useCallback(
        (key: keyof ITranslationKey, options?: object) => {
            return i18n.exists(key) ? translate(key, options) : key;
        },
        [translate, i18n],
    );

    const changeLanguage = useCallback(
        async (lang: ILanguageCode) => {
            await i18n.changeLanguage(lang);
        },
        [i18n],
    );

    return {
        changeLanguage,
        t,
    };
}

export default useLocalization;
