import { ICancelableRequest } from '../../types/services/http.config';
import { deleteCancelableRequest, GetawayAPIService, getCancelableRequest, putCancelableRequest } from './http.config';

const getConversationsList = (query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${query}`,
    });
};

const getConversationsByToken = (pageToken: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${pageToken}`,
    });
};

export const getConversationMessages = (conversationId: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${conversationId}/messages`,
    });
};

export const getConversationMessagesByToken = (conversationId: string, pageToken: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${conversationId}/messages/${pageToken}`,
    });
};

export const updateConversation = (conversationId: string): ICancelableRequest => {
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${conversationId}`,
    });
};

export const deleteConversation = (conversationId: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${conversationId}`,
    });
};

export const getConversationAssignments = (conversationId: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/conversations/${conversationId}/assignments`,
    });
};

const methods = {
    getConversationsList,
    getConversationsByToken,
    getConversationMessages,
    getConversationMessagesByToken,
    updateConversation,
    deleteConversation,
    getConversationAssignments,
};

export default methods;
