import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useAPIQuery(
    customSearch: string | null = null,
    excludedParams: string[] = ['page', 'page_size'],
    redundantParams: string[] = [],
) {
    const { search } = useLocation();

    return useMemo(() => {
        if (!search && !customSearch) {
            return search;
        }
        if (customSearch === '') {
            return '?';
        }
        const queryParams = customSearch === null ? search.slice(1).split('&') : customSearch.split('&');
        const excludeRedundant = queryParams.filter((query) => {
            const [name] = query.split('=');
            return !redundantParams.includes(name);
        });
        const filtered = excludeRedundant.map((query) => {
            const [name, value] = query.split('=');
            return excludedParams.includes(name)
                ? query
                : value.includes(',')
                ? value
                      .split(',')
                      .map((singleValue) => `filter[${name}]=${singleValue}`)
                      .join('&')
                : `filter[${name}]=${value}`;
        });
        return `?${filtered.join('&')}`;
    }, [search, customSearch, excludedParams]);
}

export default useAPIQuery;
