import React, { lazy, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes, Navigate } from 'react-router-dom';

import PrivateBlankLayout from 'components/Layout/PrivateBlankLayout';
import { PRIVATE_ROUTES_ENUM } from 'constants/routes';
import { IRouteElement } from './types';
import LazyComponent from 'components/LazyComponent';

// RBAC FILTERING
import { IRootReducer } from '../types/reducers';
import { PERMISSION_KEYS, PERMISSIONS_OBJ } from '../services/utils/helper';
// Inbox Events
import { useSSEEvents } from '../hooks/useSSEEvents';
import { Messages } from '../pages/Inbox/Messages';
import { IConversationItem } from '../pages/Inbox/types';
import { InboxActionTypes } from '../actions/types';
import { DxWrapperModal } from '../components/common';
import { COMPANY_STATUSES } from '../constants/common';

const PrivateLayout = lazy(() => import('components/Layout/PrivateLayout'));
// Dashboard
const Dashboard = lazy(() => import('pages/Dashboard'));
// Try It Now
const TryItNow = lazy(() => import('pages/TryItNow'));
const TryItNowHome = lazy(() => import('pages/TryItNow/Home'));
const TryItNowSMS = lazy(() => import('pages/TryItNow/SendSMS'));
const TryItNowViber = lazy(() => import('pages/TryItNow/Viber'));
const TryItNowWhatsapp = lazy(() => import('pages/TryItNow/Whatsapp'));
// SMS
const SMSCampaign = lazy(() => import('pages/SMSCampaign'));
const MyCampaigns = lazy(() => import('pages/SMSCampaign/MyCampaigns'));
const CreateCampaign = lazy(() => import('pages/SMSCampaign/CreateCampaign'));
// WhatsApp
const Whatsapp = lazy(() => import('pages/WhatsApp'));
const WhatsappGetAccess = lazy(() => import('pages/WhatsApp/GetAccess'));
const WhatsappBusinessDetails = lazy(() => import('pages/WhatsApp/BusinessDetails'));
const WhatsappTemplates = lazy(() => import('pages/WhatsApp/Templates/MyTemplates'));
const CreateWhatsappTemplate = lazy(() => import('pages/WhatsApp/Templates/Create'));
const WhatsappMySenders = lazy(() => import('pages/WhatsApp/MySenders'));
const WhatsappMyCampaigns = lazy(() => import('pages/WhatsApp/Campaign/MyCampaigns'));
const WhatsappCreateCampaign = lazy(() => import('pages/WhatsApp/Campaign/CreateCampaign'));
// Viber
const Viber = lazy(() => import('pages/Viber'));
const ViberGetAccess = lazy(() => import('pages/Viber/GetAccess'));
const ViberMySenders = lazy(() => import('pages/Viber/MySenders'));
const ViberMyCampaigns = lazy(() => import('pages/Viber/ViberCampaign/MyCampaigns'));
const ViberCreateCampaign = lazy(() => import('pages/Viber/ViberCampaign/CreateCampaign'));
const ViberBusinessDetails = lazy(() => import('pages/Viber/BusinessDetails'));
// Add Channel
const AddChannel = lazy(() => import('pages/AddChannel'));
// Senders
const Senders = lazy(() => import('pages/Senders'));
const MySenders = lazy(() => import('pages/Senders/MySenders'));
const MyNumbers = lazy(() => import('pages/Senders/MyNumbers'));
const MyShortCodes = lazy(() => import('pages/Senders/MyShortCodes'));
const RequestSenderID = lazy(() => import('pages/Senders/RequestSenderID'));
const RequestShortCode = lazy(() => import('pages/Senders/RequestShortCode'));
const BuyNumber = lazy(() => import('pages/Senders/BuyNumber'));
// Library
const Library = lazy(() => import('pages/Library'));
const Templates = lazy(() => import('pages/Library/Templates'));
const CreateTemplate = lazy(() => import('pages/Library/CreateTemplate'));
const RenameTemplate = lazy(() => import('pages/Library/CreateTemplate/SmsTemplate'));
const RenameViberTemplate = lazy(() => import('pages/Library/CreateTemplate/ViberTemplate'));
// Contacts
const Contacts = lazy(() => import('pages/Contacts'));
const MyAudiences = lazy(() => import('pages/Contacts/MyAudiences'));
const CreateAudience = lazy(() => import('pages/Contacts/CreateAudience'));
const RenameAudience = lazy(() => import('pages/Contacts/RenameAudience'));
const AddContact = lazy(() => import('pages/Contacts/AddContact'));
const StopList = lazy(() => import('pages/Contacts/StopList'));
const StopAddContact = lazy(() => import('pages/Contacts/StopList/AddContact'));
// Insights
const Insights = lazy(() => import('pages/Insights'));
const InsightsContent = lazy(() => import('pages/Insights/Content'));
// Link Management
const LinkManagement = lazy(() => import('pages/LinkManagement'));
// APIs
const Apis = lazy(() => import('pages/Apis'));
const ApiKeys = lazy(() => import('pages/Apis/Keys'));
const CreateApiKey = lazy(() => import('pages/Apis/Keys/Create'));
// Inbox
const InboxWrapper = lazy(() => import('pages/Inbox'));
const Inbox = lazy(() => import('pages/Inbox/Inbox'));
// Settings
const Settings = lazy(() => import('pages/Settings'));
const MyUser = lazy(() => import('pages/Settings/MyUser'));
const Profile = lazy(() => import('pages/Settings/Profile'));
const Security = lazy(() => import('pages/Settings/Security'));
const CountryBlackList = lazy(() => import('pages/Settings/CountryBlackList'));
const SMSLimits = lazy(() => import('pages/Settings/SMSLimits'));
const Alerts = lazy(() => import('pages/Settings/Alerts'));
const Users = lazy(() => import('pages/Settings/Users'));
const AddUserFixedLayout = lazy(() => import('pages/Settings/Users/AddUser'));
const AddLimitFixedLayout = lazy(() => import('pages/Settings/SMSLimits/AddLimit'));
// Subaccounts
const Subaccounts = lazy(() => import('pages/Subaccounts'));
// Billing
const Billing = lazy(() => import('pages/Billing'));
const Payments = lazy(() => import('pages/Billing/Payments'));
const Invoices = lazy(() => import('pages/Billing/Invoices'));
const AutoTopUp = lazy(() => import('pages/Billing/AutoTopUp'));
const Rates = lazy(() => import('pages/Billing/Rates'));
// Add Funds
const AddFunds = lazy(() => import('pages/AddFunds'));
const AddFundsThankYou = lazy(() => import('pages/AddFunds/ThankYou'));
const AddFundsFailed = lazy(() => import('pages/AddFunds/Failed'));
// Account Verification
const PhoneVerification = lazy(() => import('pages/PhoneVerification'));
// Carrier Lookup
const CarrierLookupWrapper = lazy(() => import('pages/CarrierLookup'));
const SingleLookup = lazy(() => import('pages/CarrierLookup/SingleLookup'));
const BulkLookup = lazy(() => import('pages/CarrierLookup/BulkLookup'));
const LookupHistory = lazy(() => import('pages/CarrierLookup/LookupHistory'));
// Not Found
const PageNotFound = lazy(() => import('pages/PageStatuses/PageNotFound'));

const {
    WELCOME_DASHBOARD,
    TRY_IT_NOW,
    TRY_IT_NOW_SMS,
    TRY_IT_NOW_VIBER,
    TRY_IT_NOW_WHATSAPP,
    SMS,
    SMS_MY_CAMPAIGNS,
    SMS_CREATE_CAMPAIGN,
    WHATSAPP,
    WHATSAPP_GET_ACCESS,
    WHATSAPP_MY_SENDERS,
    WHATSAPP_BUSINESS_DETAILS,
    WHATSAPP_MY_TEMPLATES,
    CREATE_WHATSAPP_TEMPLATE,
    WHATSAPP_MY_CAMPAIGNS,
    WHATSAPP_CREATE_CAMPAIGN,
    VIBER,
    VIBER_GET_ACCESS,
    VIBER_MY_SENDERS,
    VIBER_BUSINESS_DETAILS,
    VIBER_MY_CAMPAIGNS,
    VIBER_CREATE_CAMPAIGN,
    ADD_CHANNEL,
    SENDERS,
    MY_SENDERS,
    REQUEST_SENDER_ID,
    MY_NUMBERS,
    BUY_NUMBER,
    MY_SHORT_CODES,
    REQUEST_SHORT_CODE,
    REQUEST_SHORT_CODE_EDIT,
    LIBRARY,
    CREATE_TEMPLATE,
    EDIT_SMS_TEMPLATE,
    EDIT_VIBER_TEMPLATE,
    CONTACTS,
    AUDIENCE,
    CREATE_AUDIENCE,
    RENAME_AUDIENCE,
    ADD_CONTACT,
    STOP_LIST,
    STOP_ADD_CONTACT,
    INSIGHTS,
    LINK_MANAGEMENT,
    API_KEYS,
    CREATE_API,
    EDIT_API,
    SETTINGS,
    MY_USER,
    PROFILE,
    ALERTS,
    USERS,
    ADD_USER,
    EDIT_USER,
    SECURITY,
    COUNTRY_BLACKLIST,
    SMS_LIMITS,
    ADD_LIMIT,
    EDIT_LIMIT,
    SUBACCOUNTS,
    BILLING,
    PAYMENTS,
    INVOICES,
    AUTO_TOP_UP,
    RATES,
    PHONE_VERIFICATION,
    ADD_FUNDS,
    ADD_FUNDS_THANK_YOU,
    ADD_FUNDS_FAILED,
    INBOX,
    INBOX_ASSIGNED_TO_ME,
    INBOX_ASSIGNED_TO_OTHERS,
    INBOX_UNASSIGNED,
    CARRIER_LOOKUP,
    SINGLE_LOOKUP,
    BULK_LOOKUP,
    LOOKUP_HISTORY,
} = PRIVATE_ROUTES_ENUM;

export const privateRoutes: IRouteElement[] = [
    {
        element: <LazyComponent view={PrivateLayout} />,
        children: [
            // Main page
            {
                index: true,
                element: <Dashboard />,
            },
            {
                path: WELCOME_DASHBOARD,
                element: <Dashboard />,
            },
            // Try It Now
            {
                path: TRY_IT_NOW,
                element: <TryItNow />,
                children: [
                    {
                        index: true,
                        element: <TryItNowHome />,
                    },
                    {
                        path: TRY_IT_NOW_SMS,
                        element: <TryItNowSMS />,
                        rule: 'MESSAGES',
                        mode: 'canAdd',
                    },
                    {
                        path: TRY_IT_NOW_VIBER,
                        element: <TryItNowViber />,
                    },
                    {
                        path: TRY_IT_NOW_WHATSAPP,
                        element: <TryItNowWhatsapp />,
                    },
                ],
            },
            // SMS
            {
                path: SMS,
                element: <LazyComponent view={SMSCampaign} />,
                rule: 'CAMPAIGNS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={SMS_MY_CAMPAIGNS} />,
                    },
                    // SMS --> My Campaigns
                    {
                        path: SMS_MY_CAMPAIGNS,
                        element: <MyCampaigns />,
                        rule: 'CAMPAIGNS',
                        mode: 'canView',
                    },
                    // SMS --> Create Campaign
                    {
                        path: SMS_CREATE_CAMPAIGN,
                        element: <CreateCampaign />,
                        rule: 'CAMPAIGNS',
                        mode: 'canAdd',
                    },
                ],
            },
            // WhatsApp Senders
            {
                path: WHATSAPP,
                element: <LazyComponent view={Whatsapp} />,
                rule: 'SENDERS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={WHATSAPP_GET_ACCESS} />,
                    },
                    // WhatsApp Senders --> Get Access
                    {
                        path: WHATSAPP_GET_ACCESS,
                        element: <WhatsappGetAccess />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    // WhatsApp Senders --> My Senders
                    {
                        path: WHATSAPP_MY_SENDERS,
                        element: <WhatsappMySenders />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    // WhatsApp Senders --> Add Sender
                    {
                        path: WHATSAPP_BUSINESS_DETAILS,
                        element: <WhatsappBusinessDetails />,
                        rule: 'SENDERS',
                        mode: 'canAdd',
                    },
                ],
            },
            // WhatsApp Templates
            {
                path: WHATSAPP,
                element: <LazyComponent view={Whatsapp} />,
                rule: 'TEMPLATES',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={WHATSAPP_MY_TEMPLATES} />,
                    },
                    // WhatsApp Templates --> My Templates
                    {
                        path: WHATSAPP_MY_TEMPLATES,
                        element: <WhatsappTemplates />,
                        rule: 'TEMPLATES',
                        mode: 'canView',
                    },
                    // WhatsApp Templates --> Create Template
                    {
                        path: CREATE_WHATSAPP_TEMPLATE,
                        element: <CreateWhatsappTemplate />,
                        rule: 'TEMPLATES',
                        mode: 'canAdd',
                    },
                ],
            },
            // WhatsApp Campaigns
            {
                path: WHATSAPP,
                element: <LazyComponent view={Whatsapp} />,
                rule: 'CAMPAIGNS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={WHATSAPP_MY_CAMPAIGNS} />,
                    },
                    // WhatsApp Campaigns --> My Campaigns
                    {
                        path: WHATSAPP_MY_CAMPAIGNS,
                        element: <WhatsappMyCampaigns />,
                        rule: 'CAMPAIGNS',
                        mode: 'canView',
                    },
                    // WhatsApp Campaigns --> Create Campaign
                    {
                        path: WHATSAPP_CREATE_CAMPAIGN,
                        element: <WhatsappCreateCampaign />,
                        rule: 'CAMPAIGNS',
                        mode: 'canAdd',
                    },
                ],
            },
            // Viber Senders
            {
                path: VIBER,
                element: <LazyComponent view={Viber} />,
                rule: 'SENDERS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={VIBER_GET_ACCESS} />,
                    },
                    {
                        path: VIBER_GET_ACCESS,
                        element: <ViberGetAccess />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    {
                        path: VIBER_MY_SENDERS,
                        element: <ViberMySenders />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    {
                        path: VIBER_BUSINESS_DETAILS,
                        element: <ViberBusinessDetails />,
                        rule: 'SENDERS',
                        mode: 'canAdd',
                    },
                ],
            },
            // Viber Campaigns
            {
                path: VIBER,
                element: <LazyComponent view={Viber} />,
                rule: 'CAMPAIGNS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={VIBER_MY_CAMPAIGNS} />,
                    },
                    {
                        path: VIBER_MY_CAMPAIGNS,
                        element: <ViberMyCampaigns />,
                        rule: 'CAMPAIGNS',
                        mode: 'canView',
                    },
                    {
                        path: VIBER_CREATE_CAMPAIGN,
                        element: <ViberCreateCampaign />,
                        rule: 'CAMPAIGNS',
                        mode: 'canAdd',
                    },
                ],
            },
            // Add Channel
            {
                path: ADD_CHANNEL,
                element: <AddChannel />,
                rule: 'CHANNELS',
                mode: 'canAdd',
            },
            // Senders
            {
                path: SENDERS,
                element: <LazyComponent view={Senders} />,
                rule: 'SENDERS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={MY_SENDERS} />,
                    },
                    // Sender IDs
                    {
                        path: MY_SENDERS,
                        element: <MySenders />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    {
                        path: REQUEST_SENDER_ID,
                        element: <RequestSenderID />,
                        rule: 'SENDERS',
                        mode: 'canAdd',
                    },
                    // Sender Numbers
                    {
                        path: MY_NUMBERS,
                        element: <MyNumbers />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    {
                        path: BUY_NUMBER,
                        element: <BuyNumber />,
                        rule: 'NUMBERS',
                        mode: 'canView',
                    },
                    // Short Codes
                    {
                        path: MY_SHORT_CODES,
                        element: <MyShortCodes />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    {
                        path: REQUEST_SHORT_CODE,
                        element: <RequestShortCode />,
                        rule: 'SENDERS',
                        mode: 'canAdd',
                    },
                    {
                        path: REQUEST_SHORT_CODE_EDIT,
                        element: <RequestShortCode />,
                        rule: 'SENDERS',
                        mode: 'canEdit',
                    },
                ],
            },
            // Templates
            {
                path: LIBRARY,
                element: <LazyComponent view={Library} />,
                rule: 'TEMPLATES',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Templates />,
                        rule: 'TEMPLATES',
                        mode: 'canView',
                    },
                    {
                        path: CREATE_TEMPLATE,
                        element: <CreateTemplate />,
                        rule: 'TEMPLATES',
                        mode: 'canAdd',
                    },
                    {
                        path: EDIT_SMS_TEMPLATE,
                        element: <RenameTemplate />,
                        rule: 'TEMPLATES',
                        mode: 'canEdit',
                    },
                    {
                        path: EDIT_VIBER_TEMPLATE,
                        element: <RenameViberTemplate />,
                        rule: 'TEMPLATES',
                        mode: 'canEdit',
                    },
                ],
            },
            // Contacts Audience
            {
                path: CONTACTS,
                element: <LazyComponent view={Contacts} />,
                rule: 'AUDIENCES',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={AUDIENCE} />,
                    },
                    {
                        path: AUDIENCE,
                        element: <MyAudiences />,
                        rule: 'AUDIENCES',
                        mode: 'canView',
                    },
                    {
                        path: CREATE_AUDIENCE,
                        element: <CreateAudience />,
                        rule: 'AUDIENCES',
                        mode: 'canAdd',
                    },
                    {
                        path: RENAME_AUDIENCE,
                        element: <RenameAudience />,
                        rule: 'AUDIENCES',
                        mode: 'canEdit',
                    },
                    {
                        path: ADD_CONTACT,
                        element: <AddContact />,
                        rule: 'AUDIENCES',
                        mode: 'canEdit',
                    },
                ],
            },
            // Contacts Stop list
            {
                path: CONTACTS,
                element: <LazyComponent view={Contacts} />,
                rule: 'SENDERS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={STOP_LIST} />,
                    },
                    {
                        path: STOP_LIST,
                        element: <LazyComponent view={StopList} />,
                        rule: 'SENDERS',
                        mode: 'canView',
                    },
                    {
                        path: STOP_ADD_CONTACT,
                        element: <LazyComponent view={StopAddContact} />,
                        rule: 'SENDERS',
                        mode: 'canEdit',
                    },
                ],
            },
            // Insights
            {
                path: INSIGHTS,
                element: <LazyComponent view={Insights} />,
                rule: 'MESSAGES',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <InsightsContent />,
                        rule: 'MESSAGES',
                        mode: 'canView',
                    },
                ],
            },
            // Link Management
            {
                path: LINK_MANAGEMENT,
                element: <LazyComponent view={LinkManagement} />,
                rule: 'SHORT_URLS',
                mode: 'canView',
            },
            // APIs
            {
                path: API_KEYS,
                element: <LazyComponent view={Apis} />,
                rule: 'KEYS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <ApiKeys />,
                        rule: 'KEYS',
                        mode: 'canView',
                    },
                    {
                        path: CREATE_API,
                        element: <CreateApiKey />,
                        rule: 'KEYS',
                        mode: 'canAdd',
                    },
                    {
                        path: EDIT_API,
                        element: <CreateApiKey />,
                        rule: 'KEYS',
                        mode: 'canEdit',
                    },
                ],
            },
            // INBOX
            {
                path: INBOX,
                element: <LazyComponent view={InboxWrapper} />,
                rule: 'MESSAGES',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={INBOX_ASSIGNED_TO_ME} />,
                    },
                    {
                        path: INBOX_ASSIGNED_TO_ME,
                        element: <Inbox assignee={'self'} key={'self'} />,
                        rule: 'MESSAGES',
                        mode: 'canView',
                    },
                ],
            },
            // Carrier Lookup
            {
                path: CARRIER_LOOKUP,
                element: <LazyComponent view={CarrierLookupWrapper} />,
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={SINGLE_LOOKUP} />,
                    },
                    {
                        path: SINGLE_LOOKUP,
                        element: <SingleLookup />,
                    },
                    {
                        path: BULK_LOOKUP,
                        element: <BulkLookup />,
                    },
                    {
                        path: LOOKUP_HISTORY,
                        element: <LookupHistory />,
                    },
                ],
            },
            {
                path: INBOX,
                element: <LazyComponent view={InboxWrapper} />,
                rule: 'CONVERSATIONS',
                mode: 'canView',
                children: [
                    {
                        index: true,
                        element: <Navigate replace={true} to={INBOX_ASSIGNED_TO_ME} />,
                    },
                    {
                        path: INBOX_ASSIGNED_TO_ME,
                        element: <Inbox assignee={'self'} key={'self'} />,
                        rule: 'CONVERSATIONS',
                        mode: 'canView',
                    },
                    {
                        path: INBOX_ASSIGNED_TO_OTHERS,
                        element: <Inbox assignee={'others'} key={'others'} />,
                        rule: 'CONVERSATIONS',
                        mode: 'canAdd',
                    },
                    {
                        path: INBOX_UNASSIGNED,
                        element: <Inbox assignee={'none'} key={'none'} />,
                        rule: 'CONVERSATIONS',
                        mode: 'canView',
                    },
                ],
            },
            // Settings
            {
                path: SETTINGS,
                element: <LazyComponent view={Settings} />,
                children: [
                    {
                        path: MY_USER,
                        element: <MyUser />,
                    },
                    {
                        path: PROFILE,
                        element: <Profile />,
                        rule: 'ACCOUNTS',
                        mode: 'canView',
                    },
                    {
                        path: ALERTS,
                        element: <Alerts />,
                        rule: 'ALERTS',
                        mode: 'canView',
                    },
                    {
                        path: USERS,
                        element: <Users />,
                        rule: 'USERS',
                        mode: 'canAdd',
                    },
                    {
                        path: SECURITY,
                        element: <Security />,
                    },
                    {
                        path: COUNTRY_BLACKLIST,
                        element: <CountryBlackList />,
                        rule: 'ACCOUNTS',
                        mode: 'canView',
                    },
                    {
                        path: SMS_LIMITS,
                        element: <SMSLimits />,
                        rule: 'SMS_LIMITS',
                        mode: 'canView',
                    },
                ],
            },
            // Settings --> Add User
            {
                path: ADD_USER,
                element: <AddUserFixedLayout />,
                rule: 'USERS',
                mode: 'canAdd',
            },
            {
                path: EDIT_USER,
                element: <AddUserFixedLayout />,
                rule: 'USERS',
                mode: 'canEdit',
            },
            // Settings --> Add Limit
            {
                path: ADD_LIMIT,
                element: <AddLimitFixedLayout />,
                rule: 'SMS_LIMITS',
                mode: 'canEdit',
            },
            {
                path: EDIT_LIMIT,
                element: <AddLimitFixedLayout />,
                rule: 'SMS_LIMITS',
                mode: 'canEdit',
            },
            // SUBACCOUNTS
            {
                path: SUBACCOUNTS,
                element: <LazyComponent view={Subaccounts} />,
                rule: 'ACCOUNTS',
                mode: 'canView',
            },
            // Billing
            {
                path: BILLING,
                element: <LazyComponent view={Billing} />,
                children: [
                    {
                        path: PAYMENTS,
                        element: <Payments />,
                        rule: 'PAYMENTS',
                        mode: 'canView',
                    },
                    {
                        path: INVOICES,
                        element: <Invoices />,
                        rule: 'INVOICES',
                        mode: 'canView',
                    },
                    {
                        path: AUTO_TOP_UP,
                        element: <AutoTopUp />,
                        rule: 'PAYMENTS',
                        mode: 'canView',
                    },
                    {
                        path: RATES,
                        element: <Rates />,
                        rule: 'PAYMENTS',
                        mode: 'canView',
                    },
                ],
            },
            // Add Funds
            {
                path: ADD_FUNDS,
                element: <AddFunds />,
                rule: 'PAYMENTS',
                mode: 'canAdd',
            },
            {
                path: ADD_FUNDS_THANK_YOU,
                element: <AddFundsThankYou />,
                rule: 'PAYMENTS',
                mode: 'canAdd',
            },
            {
                path: ADD_FUNDS_FAILED,
                element: <AddFundsFailed />,
                rule: 'PAYMENTS',
                mode: 'canAdd',
            },
        ],
    },
    {
        element: <PrivateBlankLayout />,
        children: [
            // Account Verification
            {
                path: PHONE_VERIFICATION,
                element: <PhoneVerification />,
            },
        ],
    },
    // Not Found
    {
        path: '*',
        element: <PageNotFound />,
    },
];

const PrivateRoutes = () => {
    /**
     * Following our structure of Routes we have 3 main routes (PARENT)
     * 1) PrivateLayout
     * 2) PrivateBlankLayout
     * 3) NotFound page
     * This main routes do not have any rules applied to them, later we go through their children routes (CHILDREN)IF any
     * Based on their rule, we either add CHILDREN route or ignore it with all of their children (GRAND_CHILDREN) routes as well.
     * For each GRAND_CHILDREN route we either include them or ignore based on rule.
     *
     * P.S. If account is not verified yet, any route that requires 'canAdd' or 'canEdit' rule will be ignored
     * Exceptions: Add Channel and Users
     */
    const permissions = useSelector<IRootReducer, PERMISSIONS_OBJ>((state) => state.account.permissions);
    const status = useSelector<IRootReducer, string>((state) => state.account.status);
    const accountType = useSelector<IRootReducer, string>((state) => state.account.accountType);
    const modalSelectedConversation = useSelector<IRootReducer, IConversationItem | null>(
        (state) => state.inbox.modalSelectedConversation,
    );

    const dispatch = useDispatch();
    /**
     * Server-sent events listener
     */
    useSSEEvents();
    const privateRoutesBasedOnRole = useMemo(() => {
        const newRoutes: any = [];
        for (const route of privateRoutes) {
            const newRoute: any = { ...route };
            newRoutes.push({ ...newRoute, children: [] });
            if (route.children) {
                for (const subRoute of route.children) {
                    if (subRoute.rule) {
                        const subAccountDisabled = accountType === 'SUB_ACCOUNT' && subRoute.path === SUBACCOUNTS;
                        const permissionMode: 'canView' | 'canEdit' | 'canDelete' | 'canAdd' = subRoute.mode;
                        let hasPermission: boolean = permissions[subRoute.rule as PERMISSION_KEYS]![permissionMode];
                        if (
                            (permissionMode === 'canAdd' || permissionMode === 'canEdit') &&
                            status !== COMPANY_STATUSES.VERIFIED
                        ) {
                            // ADD_CHANNEL route can be viewed by UNVERIFIED user
                            hasPermission = subRoute.path === ADD_CHANNEL;
                        }
                        if (hasPermission && !subAccountDisabled) {
                            if (subRoute.children) {
                                newRoutes.at(-1).children.push({ ...subRoute, children: [] });
                            } else {
                                newRoutes.at(-1).children.push(subRoute);
                            }
                        } else {
                            continue;
                        }
                    } else {
                        if (subRoute.children) {
                            newRoutes.at(-1).children.push({ ...subRoute, children: [] });
                        } else {
                            newRoutes.at(-1).children.push(subRoute);
                        }
                    }
                    if (subRoute.children) {
                        for (const subSubRoute of subRoute.children) {
                            if (subSubRoute.rule) {
                                const permissionMode: 'canView' | 'canEdit' | 'canDelete' | 'canAdd' = subSubRoute.mode;
                                let hasPermission: boolean =
                                    permissions[subSubRoute.rule as PERMISSION_KEYS]![permissionMode];
                                if (
                                    (permissionMode === 'canAdd' || permissionMode === 'canEdit') &&
                                    status !== COMPANY_STATUSES.VERIFIED
                                ) {
                                    // USERS and ASSIGNED OTHERS route can be viewed by UNVERIFIED user
                                    hasPermission =
                                        subSubRoute.path === USERS || subSubRoute.path === INBOX_ASSIGNED_TO_OTHERS;
                                }
                                if (hasPermission) {
                                    newRoutes.at(-1).children.at(-1).children.push(subSubRoute);
                                }
                            } else {
                                newRoutes.at(-1).children.at(-1).children.push(subSubRoute);
                            }
                        }
                    }
                }
            }
        }
        return newRoutes;
    }, [permissions]);

    const routes = useRoutes(privateRoutesBasedOnRole);
    const modalTitle = useMemo(() => {
        return (
            <div className={'modal-header-container'}>
                Quick Reply{' '}
                {(modalSelectedConversation?.unread_count || 0) > 0 && (
                    <div className={'modal-header-notification-count'}>{modalSelectedConversation?.unread_count}</div>
                )}
            </div>
        );
    }, [modalSelectedConversation]);
    return (
        <>
            {routes}
            {permissions.MESSAGES?.canView && (
                <DxWrapperModal
                    footer={null}
                    title={modalTitle}
                    visible={!!modalSelectedConversation?.id}
                    className={`notification-modal ${modalSelectedConversation?.showPopUp ? 'messages-blur' : ''}`}
                    onCancel={() => {
                        dispatch({ type: InboxActionTypes.CLEAR_MODAL_SELECTED_CONVERSATION });
                    }}
                    destroyOnClose={true}
                >
                    <div className={'messages-wrapper'}>
                        <Messages selectedConversation={modalSelectedConversation} isModal={true} />
                    </div>
                </DxWrapperModal>
            )}
        </>
    );
};

export default PrivateRoutes;
