import {
    deleteCancelableRequest,
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
} from './http.config';
import { ICancelableRequest } from 'types/services/http.config';

const getShortUrl = (query: string = ''): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/short_urls${query}`,
    });
};

const getShortUrlStatistics = (query: string = ''): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/short_urls/stat${query}`,
    });
};

const createShortUrl = (query: string = ''): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/short_urls`,
    });
};

const deleteUrl = (id: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/short_urls/${id}`,
    });
};

const methods = {
    getShortUrl,
    createShortUrl,
    deleteUrl,
    getShortUrlStatistics,
};

export default methods;
