import React, { FC, memo } from 'react';

import { IDxCardProps } from 'types/components';

import 'assets/scss/dxCard.scss';

const DxCard: FC<IDxCardProps> = ({ children, withShadow = false, className, ...restProps }) => (
    <div className={`dx-card ${withShadow && 'dx-with-shadow'} ${className ? className : ''}`} {...restProps}>
        {children}
    </div>
);

export default memo(DxCard);
