import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Menu } from 'antd';

import { IChannelsList } from 'types/components/menu';
import { IAccountState, IRootReducer } from 'types/reducers';
import { isSelected } from 'services/utils/helper';
import { useLocalization } from 'hooks';
import { DxIcon } from 'components';

const { SubMenu } = Menu;

interface ILeftNavMenuProps {
    openItems: any;
    list: IChannelsList[];
}

const LeftNavChannels: FC<ILeftNavMenuProps> = ({ openItems, list }) => {
    const { t } = useLocalization();
    const { channels, permissions } = useSelector<IRootReducer, IAccountState>((state) => state.account);

    const whatsAppChannelEnabled = useMemo(() => {
        return permissions.SENDERS?.canView || permissions.TEMPLATES?.canView || permissions.CAMPAIGNS?.canView;
    }, [permissions]);

    const viberChannelEnabled = useMemo(() => {
        return permissions.SENDERS?.canView || permissions.CAMPAIGNS?.canView;
    }, [permissions]);

    const openItemsModified = useMemo(() => {
        const modifiedItems: string[] = [];
        openItems.forEach((item: string) => {
            modifiedItems.push(item);
            if (item === '/viber/my-senders') {
                modifiedItems.push('/viber/get-access');
            }
            if (item === '/whatsapp/my-senders') {
                modifiedItems.push('/whatsapp/get-access');
            }
        });
        return modifiedItems;
    }, [openItems]);

    return (
        <>
            <Menu
                className={`dx-menu`}
                mode="inline"
                defaultOpenKeys={openItemsModified}
                selectedKeys={openItemsModified}
            >
                {list.map(
                    ({ channel, label, link, icon, subMenu, viewRule }) =>
                        isSelected(channel, channels) &&
                        (isNil(subMenu) ? (
                            <Menu.Item
                                className={`dx-menu-item ${permissions[viewRule]!.canView ? '' : 'custom-disable'}`}
                                key={link}
                            >
                                {permissions[viewRule]!.canView ? (
                                    <Link className="dx-menu-link" to={link}>
                                        <DxIcon type={icon} />
                                        <span className="dx-menu-link-text">{t(label)}</span>
                                    </Link>
                                ) : (
                                    <span className="dx-menu-link">
                                        <DxIcon type={icon} />
                                        <span className="dx-menu-link-text">{t(label)}</span>
                                    </span>
                                )}
                            </Menu.Item>
                        ) : (
                            <SubMenu
                                key={link}
                                className={`dx-sub-menu-outer ${
                                    ((label === 'WHATSAPP' && !whatsAppChannelEnabled) ||
                                        (label === 'VIBER' && !viberChannelEnabled)) &&
                                    'not-ready'
                                }`}
                                icon={<DxIcon type={icon} />}
                                title={t(label)}
                            >
                                <Menu.ItemGroup key={link} className="dx-sub-menu">
                                    {subMenu &&
                                        subMenu.map(({ label: subLabel, link: subLink, viewRule: subRule }) => (
                                            <Menu.Item className="dx-sub-menu-item" key={subLink}>
                                                {permissions[subRule]!.canView ? (
                                                    <Link className="dx-sub-menu-link" to={subLink}>
                                                        {t(subLabel)}
                                                    </Link>
                                                ) : (
                                                    <span className="dx-sub-menu-link custom-disable">
                                                        {t(subLabel)}
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        ))}
                                </Menu.ItemGroup>
                            </SubMenu>
                        )),
                )}
            </Menu>
        </>
    );
};

export default LeftNavChannels;
