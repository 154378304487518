import { getBalanceLabel, getCountryNameByCode } from 'services/utils/helper';
import { DateTimezone } from 'components/common';
import { isNil } from 'lodash';

export const CurrencyRenderer = (value: any, currency: any) => {
    if (isNil(value) || isNaN(parseFloat(value))) {
        return value;
    }
    return getBalanceLabel(value, currency);
};

export const DateRenderer = (value: string) => {
    if (isNil(value)) {
        return <></>;
    }
    return <DateTimezone value={value} />;
};

export const CountryRenderer = (value: string, item: any = {}) => {
    if (item.withCompany && typeof item.id === 'undefined') {
        return item.company_name;
    }
    return getCountryNameByCode(value.toLowerCase());
};

export const PeriodRenderer = (value: string, item: any) => {
    return (
        <span>
            <DateTimezone value={item.period_start} /> - <DateTimezone value={item.period_end} />
        </span>
    );
};

const renderers = { CurrencyRenderer, DateRenderer, CountryRenderer };

export default renderers;
