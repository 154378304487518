// Import Interfaces
import { ICreateColumnProps, IBooleanObject, ISortMethods } from 'types/components/dataTable';
import { ITableKeys } from 'types/localization';

function uppercase(key: string) {
    return `TABLE_KEY_${key.toUpperCase()}`;
}

const defaultRenderer = (field: any) => field;

const typeSorting: ISortMethods = {
    number: (key: string) => (a: any, b: any) => a[key] - b[key],
    string: (key: string) => (a: any, b: any) => a[key].localeCompare(b[key]),
    date: (key: string) => (a: any, b: any) => a[key] - b[key],
};

export function createColumns({
    displayPattern = uppercase,
    columnsOrder = [],
    excludedKeys = [],
    includedKeys = [],
    keyRenderers = {},
    keyFilters = {},
    model,
    sticky,
    columnsWidth = {},
    t,
}: ICreateColumnProps) {
    const blackList: IBooleanObject = excludedKeys.reduce((prev, current) => ({ ...prev, [current]: true }), {});
    const whiteList: IBooleanObject = includedKeys.reduce((prev, current) => ({ ...prev, [current]: true }), {});

    const keys = columnsOrder.length
        ? columnsOrder
        : Object.keys(model).filter((key) => (includedKeys.length ? whiteList[key] : !blackList[key]));

    return keys.map((key: string) => {
        const dummyColumn = {
            title: t(displayPattern(key) as keyof ITableKeys),
            render: keyRenderers[key] || defaultRenderer,
            dataIndex: key,
            key,
            width: sticky ? columnsWidth[key] || 110 : columnsWidth[key] || null,
            fixed: key === sticky,
        };

        if (keyFilters[key]) {
            const type: string = model[key] instanceof Date ? 'date' : typeof model[key];
            const sorterForKey = typeSorting[type] || typeSorting.string;
            const sortProps = {
                sorter: sorterForKey(key),
            };
            return Object.assign(dummyColumn, sortProps);
        }

        return dummyColumn;
    });
}
