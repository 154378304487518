import {
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
    putCancelableRequest,
} from './http.config';

import { ICancelableRequest } from 'types/services/http.config';
import { TQuery } from 'types/services/apiKey';

const getAudiences = (query: TQuery): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/audiences${query}`,
    });

const getAudience = (audienceId: any): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${audienceId}`,
    });

const createAudience = (singleContact: boolean = false): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        timeout: 60000,
        endpointPath: () => `/${singleContact ? 'audiences' : 'audience_imports'}`,
    });

const deleteAudience = (audienceId: any): ICancelableRequest =>
    deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${audienceId}`,
    });

const updateAudience = (audienceId: any): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${audienceId}`,
    });

const createContact = (audienceId: any): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${audienceId}/contacts`,
    });

const updateContact = (audienceId: any, contactId: any): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${audienceId}/contacts/${contactId}`,
    });

const deleteContact = (audienceId: any, contactId: any): ICancelableRequest =>
    deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${audienceId}/contacts/${contactId}`,
    });

const getContacts = (audienceId: any, query: TQuery): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/audiences/${audienceId}/contacts${query}`,
    });

const isUnique = (name: string): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/audiences/${name}`,
    });

const methods = {
    getAudiences,
    getAudience,
    createAudience,
    deleteAudience,
    updateAudience,
    createContact,
    deleteContact,
    getContacts,
    isUnique,
    updateContact,
};

export default methods;
