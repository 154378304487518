import { useState } from 'react';

function useDebounce(callback: (args: any) => any, delay: number) {
    const [timer, setTimer] = useState<any>(null);
    return (...args: any) => {
        clearTimeout(timer);
        // @ts-ignore
        const temp = setTimeout(() => callback(...args), delay);
        setTimer(temp);
    };
}
export default useDebounce;