import React, { useEffect, useRef } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useDispatch } from 'react-redux';
import { InboxActionTypes, SMSViolationTypes } from '../actions/types';
import { DxShowNotification } from '../components';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTES_ENUM } from '../constants/routes';

export const SSE_EVENT_TYPES = {
    CONNECTION_ESTABLISHED: 'CONNECTION_OK',
    FORCE_LOGOUT: 'FORCE_LOGOUT',
    BADGES: 'BADGES',
    CONVERSATION: 'CONVERSATION',
    MESSAGE: 'MESSAGE',
    MESSAGE_STATUS: 'MESSAGE_STATUS',
    MESSAGE_VIOLATION: 'MESSAGE_VIOLATION',
    VIOLATION_EXPIRATION: 'VIOLATION_EXPIRATION',
};

export const useSSEEvents = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sessionObject: { 'X-Session-ID'?: string } = {};
    let lastEventQuery = '';
    const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            await fetchEventSource(`${process.env.REACT_APP_GETAWAY_API_URL}/events${lastEventQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                    ...sessionObject,
                },
                openWhenHidden: true,
                async onopen(res) {
                    if (res.status >= 400 && res.status < 500 && res.status !== 429) {
                        // Connection failed, will retry.
                    }
                },
                signal: ctrlAbort.current?.signal,
                onmessage(event) {
                    const parsedData = JSON.parse(event.data);
                    // console.log('Event data', parsedData.data);
                    if (parsedData?.data?.event_type) {
                        switch (parsedData.data.event_type) {
                            case SSE_EVENT_TYPES.CONNECTION_ESTABLISHED:
                                lastEventQuery = '?last_event_time=' + parsedData.timestamp;
                                sessionObject['X-Session-ID'] = parsedData.data.session_id;
                                break;
                            case SSE_EVENT_TYPES.FORCE_LOGOUT:
                                localStorage.clear();
                                window.location.reload();
                                break;
                            // INBOX
                            case SSE_EVENT_TYPES.BADGES:
                                lastEventQuery = '?last_event_time=' + parsedData.timestamp;
                                dispatch({ type: InboxActionTypes.SET_BADGES, payload: parsedData.data });
                                break;
                            case SSE_EVENT_TYPES.CONVERSATION:
                                lastEventQuery = '?last_event_time=' + parsedData.timestamp;
                                if (window.PUSH_EVENTS_IN_QUEUE) {
                                    dispatch({ type: InboxActionTypes.PUSH_IN_QUEUE, payload: parsedData.data });
                                } else {
                                    dispatch({
                                        type: InboxActionTypes.UPDATE_CONVERSATION,
                                        payload: parsedData.data,
                                    });
                                }
                                break;
                            case SSE_EVENT_TYPES.MESSAGE:
                                lastEventQuery = '?last_event_time=' + parsedData.timestamp;
                                if (window.PUSH_EVENTS_IN_QUEUE) {
                                    dispatch({ type: InboxActionTypes.PUSH_IN_QUEUE, payload: parsedData.data });
                                } else {
                                    dispatch({ type: InboxActionTypes.UPDATE_MESSAGES, payload: parsedData.data });
                                }
                                break;
                            case SSE_EVENT_TYPES.MESSAGE_STATUS:
                                lastEventQuery = '?last_event_time=' + parsedData.timestamp;
                                if (window.PUSH_EVENTS_IN_QUEUE) {
                                    dispatch({ type: InboxActionTypes.PUSH_IN_QUEUE, payload: parsedData.data });
                                } else {
                                    dispatch({
                                        type: InboxActionTypes.UPDATE_MESSAGE_STATUS,
                                        payload: parsedData.data,
                                    });
                                }
                                break;
                            case SSE_EVENT_TYPES.MESSAGE_VIOLATION:
                                DxShowNotification(
                                    'error',
                                    <span
                                        onClick={() => {
                                            navigate(PRIVATE_ROUTES_ENUM.SMS_LIMITS);
                                        }}
                                    >{`Threshold reached! You can adjust your rule settings accordingly.`}</span>,
                                    null,
                                    20,
                                );
                                dispatch({ type: SMSViolationTypes.ADD_VIOLATION, payload: parsedData.data });
                                break;
                            default:
                            // console.log('case to handle', parsedData.data);
                        }
                    }
                },
                onclose() {
                    console.log('Connection closed by the server');
                },
                onerror(err) {
                    throw err;
                },
            });
        } catch (error: any) {
            setTimeout(() => {
                fetchData();
            }, 2000);
        }
    };

    const ctrlAbort = useRef<AbortController | undefined>();

    useEffect(() => {
        ctrlAbort.current = new AbortController();
        fetchData();
        return () => {
            ctrlAbort.current?.abort();
        };
    }, []);
};
