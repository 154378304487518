import {
    deleteCancelableRequest,
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';
import { ICancelableRequest } from 'types/services/http.config';
import { TID, TQuery } from '../../types/services/apiKey';

const getSmsCampaigns = (query: TQuery, channel = 'sms'): ICancelableRequest => {
    const q = query ? `${query}&` : '?';
    return getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/campaigns${q}filter[channel]=${channel}`,
    });
};

const getCampaignByName = (name: string, channel = 'sms'): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/campaigns/${name}?channel=${channel}`,
    });
};

const getCampaign = (id: string, channel = 'sms'): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/campaigns/${id}`,
    });
};

const createSmsCampaign = (): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/campaigns',
    });

const updateSmsCampaign = (id: any): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/campaigns/${id}`,
    });

const importAudience = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        timeout: 60000,
        endpointPath: () => `/campaign_imports`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const deleteSmsCampaign = (id: TID): ICancelableRequest =>
    deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/campaigns/${id}`,
    });

const getContacts = (search: any) =>
    getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/stop_list${search}`,
    });

const cancelSmsCampaign = (id: TID): ICancelableRequest =>
    putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/campaigns/${id}`,
    });

const methods = {
    getSmsCampaigns,
    getCampaignByName,
    createSmsCampaign,
    importAudience,
    updateSmsCampaign,
    deleteSmsCampaign,
    cancelSmsCampaign,
    getContacts,
    getCampaign,
};

export default methods;
