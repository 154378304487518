import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IDxFixedLayout } from 'types/components';
import { DxIcon, DxSteps, DxButton } from 'components/common';
import { PRIVATE_ROUTES_ENUM } from 'constants/routes';

import 'assets/scss/dxFixedLayout.scss';
import { Col, Row } from 'antd';

const DxFixedLayout: FC<IDxFixedLayout> = ({
    children,
    title,
    steps,
    currentStep = 0,
    translate = true,
    path,
    closable = true,
    customAction,
    customClassName,
    buttonsActive,
    buttonsLoading,
    onPrev,
    onNext,
    footerAdditionalActions = [],
    showOnStep = true,
    showNext = true,
    lastStepText,
}) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (onNext) {
            document.querySelector('body')?.classList.add('with-footer');
        }

        return () => document.querySelector('body')?.classList.remove('with-footer');
    }, []);
    const goBack = () => {
        if (customAction) {
            customAction();
            return;
        }
        if (path) {
            navigate(path);
            return;
        }
        if (sessionStorage.getItem('internalRoute')) {
            navigate(-1);
        } else {
            navigate(PRIVATE_ROUTES_ENUM.DASHBOARD);
        }
    };

    const handleStepClick = (step: number) => {
        if (step < currentStep && onPrev) {
            onPrev(step);
        }
    };

    return (
        <div className={`dx-fixed-layout ${customClassName ? customClassName : ''}`}>
            <div className="dx-fixed-layout-header">
                <h2 className="dx-fixed-layout-main-title">{title}</h2>
                {steps && (
                    <DxSteps
                        list={steps}
                        current={currentStep}
                        handleStepClick={handleStepClick}
                        translate={translate}
                    />
                )}
                {closable && (
                    <button type="button" onClick={goBack} className="dx-fixed-layout-close-icon">
                        <DxIcon size="md" type="close_big" />
                    </button>
                )}
            </div>
            <div className="dx-fixed-layout-content">{children}</div>
            {showOnStep && onNext && (
                <div className={'dx-fixed-layout-footer'}>
                    {currentStep > 0 && onPrev && (
                        <DxButton
                            appearance={'default'}
                            loading={buttonsLoading}
                            disabled={!buttonsActive?.prev}
                            onClick={() => onPrev(currentStep - 1)}
                        >
                            <DxIcon type={'short_left'} />
                            Back
                        </DxButton>
                    )}
                    <div className={'dx-fixed-layout-footer-right'}>
                        {showNext && (
                            <>
                                <Row gutter={16}>
                                    {footerAdditionalActions.map((action) => {
                                        return (
                                            <Col key={action.actionName}>
                                                <DxButton
                                                    appearance={'default'}
                                                    loading={buttonsLoading}
                                                    disabled={action.disabled}
                                                    onClick={action.actionClick}
                                                >
                                                    {action.actionIcon && <DxIcon type={action.actionIcon} />}
                                                    {action.actionName}
                                                </DxButton>
                                            </Col>
                                        );
                                    })}
                                    <Col>
                                        <DxButton
                                            appearance={'primary'}
                                            disabled={!buttonsActive?.next}
                                            loading={buttonsLoading}
                                            onClick={onNext}
                                        >
                                            {currentStep === steps?.length - 1 || !steps ? (
                                                <>
                                                    {lastStepText}{' '}
                                                    <span className={'right-icon'}>
                                                        <DxIcon type={'short_right'} />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    Next{' '}
                                                    <span className={'right-icon'}>
                                                        <DxIcon type={'short_right'} />
                                                    </span>
                                                </>
                                            )}
                                        </DxButton>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DxFixedLayout;
