import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { pickBy } from 'lodash';

import { IPaginationQuery } from 'types/hooks/usePagination';
import { IFilterQuery } from 'types/hooks/useFilter';

type IUrlQuery = IFilterQuery | IPaginationQuery | '';

function useQuery() {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const urlProps = useMemo(() => {
        const urlParams = new URLSearchParams(search);
        return Object.fromEntries(urlParams);
    }, [search]);

    const updateQuery = useCallback(
        (query: IUrlQuery, resetPage: boolean = true) => {
            const updatedParams = Object.assign(urlProps, query);
            resetPage && delete updatedParams.page;
            const _search = createSearchParams(updatedParams).toString();
            navigate({
                search: _search,
                pathname,
            });
        },
        [urlProps, navigate, pathname],
    );
    const updateFilter = useCallback(
        (query: IFilterQuery, resetPage: boolean = true) => {
            const countriesStr = query.countries?.join(',');
            const statusesStr = query.statuses?.join(',');
            const userStatusesStr = query.user_statuses?.join(',');
            const senderIdsStr = query.sender_ids?.join(',');
            const campaignIdsStr = query.campaign_ids?.join(',');
            const positionsStr = query.positions?.join(',');
            const typeStr = query.type?.join(',');
            const waCategoriesStr = query.wa_categories?.join(',');
            const countryQuery = countriesStr ? { countries: countriesStr } : {};
            const statusQuery = statusesStr ? { statuses: statusesStr } : {};
            const userStatusQuery = userStatusesStr ? { user_statuses: userStatusesStr } : {};
            const senderIdsQuery = senderIdsStr ? { sender_ids: senderIdsStr } : {};
            const campaignIdsQuery = campaignIdsStr ? { campaign_ids: campaignIdsStr } : {};
            const positionsQuery = positionsStr ? { positions: positionsStr } : {};
            const typeQuery = query.type ? { type: typeStr } : {};
            const waCategoriesQuery = query.wa_categories ? { wa_categories: waCategoriesStr } : {};
            const updatedParams = Object.assign(
                urlProps,
                query,
                statusQuery,
                userStatusQuery,
                countryQuery,
                senderIdsQuery,
                campaignIdsQuery,
                positionsQuery,
                typeQuery,
                waCategoriesQuery,
            );
            const cleanQuery = pickBy(updatedParams);
            resetPage && delete cleanQuery.page;
            if (cleanQuery.name) {
                cleanQuery.name = encodeURIComponent(cleanQuery.name);
            }
            if (cleanQuery.search) {
                cleanQuery.search = encodeURIComponent(cleanQuery.search);
            }
            if (cleanQuery.to_prefix) {
                cleanQuery.to_prefix = encodeURIComponent(cleanQuery.to_prefix);
            }
            const _search = decodeURIComponent(createSearchParams(cleanQuery).toString());
            navigate({
                search: _search,
                pathname,
            });
        },
        [urlProps, navigate, pathname],
    );

    const removeFilter = useCallback(
        (key: string, resetPage: boolean = true) => {
            delete urlProps[key];
            resetPage && delete urlProps.page;
            if (urlProps.name) {
                urlProps.name = encodeURIComponent(urlProps.name);
            }
            if (urlProps.search) {
                urlProps.search = encodeURIComponent(urlProps.search);
            }
            if (urlProps.to_prefix) {
                urlProps.to_prefix = encodeURIComponent(urlProps.to_prefix);
            }
            const _search = decodeURIComponent(createSearchParams(urlProps).toString());
            navigate({
                search: _search,
                pathname,
            });
        },
        [urlProps, navigate, pathname],
    );

    return {
        updateFilter,
        removeFilter,
        updateQuery,
        urlProps,
    };
}
export default useQuery;
