import { NOTIFICATION_TYPES } from './constants';

const { INFO, SUCCESS, WARNING, ERROR } = NOTIFICATION_TYPES;

const getNotificationData = (type = INFO) => {
    switch (type) {
        case INFO:
            return ['info-notification', 'info_circle_outline'];
        case SUCCESS:
            return ['success-notification', 'circle_check_outline'];
        case WARNING:
            return ['warning-notification', 'error_outline'];
        case ERROR:
            return ['error-notification', 'off_outline_close'];
        default:
            return ['info-notification', 'info_circle_outline'];
    }
};

export { getNotificationData };
