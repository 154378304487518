import {
    deleteCancelableRequest,
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
} from './http.config';

import { ICancelableRequest } from 'types/services/http.config';
import { TQuery } from '../../types/services/apiKey';

const getSenders = (channels: string[] = []): ICancelableRequest => {
    const filters = channels.join(',');
    const queryStr = filters ? `&filter[channels]=${filters}` : '';
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/senders?page_size=300&filter[status]=Available${queryStr}`,
    });
};

const createMessage = (): ICancelableRequest =>
    postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/messages',
    });

const getMessages = (query: TQuery): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/responses${query}`,
    });

const getAllMessages = (id: number, query: string): ICancelableRequest =>
    getCancelableRequest({
        requestService: GetawayAPIQuery,
        endpointPath: () => `/responses/${id}${query}`,
    });

const deleteMessage = (messageId: any): ICancelableRequest =>
    deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/responses/${messageId}`,
    });

const methods = {
    getSenders,
    createMessage,
    getMessages,
    getAllMessages,
    deleteMessage,
};

export default methods;
