import React, { memo } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import iconSet from 'pages/TestComponents/icons/selection.json';
import IcomoonReact from 'icomoon-react';
import { IDxIcon } from 'types/components';

import 'assets/scss/dxIcon.scss';
import { DxTooltip } from '../index';

const DxIcon: React.FC<IDxIcon> = ({
    type,
    appearance,
    size = 'sm',
    onClick,
    dataAttribute,
    disabled = false,
    tooltipMessage,
    tooltipPlacement,
}) => {
    const onClickProp = isNil(onClick) ? {} : { onClick };

    const composedClassNames = classNames(`dx-icon icon-${type}`, {
        [`icon-${appearance}`]: !isNil(appearance),
        [`icon-${size}`]: !isNil(size),
        [`icon-disabled`]: disabled,
        clickable: !isNil(onClick),
    });
    if (disabled && tooltipMessage) {
        return (
            <DxTooltip message={true} title={tooltipMessage} placement={tooltipPlacement || 'left'}>
                <span className={'icon-outer'}>
                    <IcomoonReact
                        className={composedClassNames}
                        icon={type}
                        {...onClickProp}
                        {...dataAttribute}
                        iconSet={iconSet}
                    />
                </span>
            </DxTooltip>
        );
    }
    return (
        <IcomoonReact
            className={composedClassNames}
            icon={type}
            {...onClickProp}
            {...dataAttribute}
            iconSet={iconSet}
        />
    );
};

DxIcon.defaultProps = {
    dataAttribute: {},
};

export default memo(DxIcon);
