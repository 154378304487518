// Auth Action Types
export const AuthActionTypes = {
    DO_LOGIN: 'DO_LOGIN',
    DO_LOGOUT: 'DO_LOGOUT',
    DO_IFRAME_LOGIN: 'DO_IFRAME_LOGIN',
    SET_PHONE_STEP: 'SET_PHONE_STEP,',
    REMOVE_FIRST_LOGIN: 'REMOVE_FIRST_LOGIN',
};

// Account Action Types
export const AccountActionTypes = {
    SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
    UPDATE_INFO: 'UPDATE_ACCOUNT_INFO',
    SET_ACCOUNT_SENDERS: 'SET_ACCOUNT_SENDERS',
    SET_VALID_INV: 'SET_VALID_INV',
    TOGGLE_VERIFY: 'TOGGLE_VERIFY',
    UPDATE_PREFERENCES: 'UPDATE_PREFERENCES',
};

// Template Action Types
export const TemplateActionTypes = {
    INIT: 'TEMPLATE_INIT',
    CREATE: 'TEMPLATE_CREATE',
    EDIT: 'TEMPLATE_EDIT',
    DELETE: 'TEMPLATE_DELETE',
};

// Whatsapp Template Action Types
export const WhatsappTemplateActionTypes = {
    INIT: 'WHATSAPP_TEMPLATE_INIT',
    CREATE: 'WHATSAPP_TEMPLATE_CREATE',
    EDIT: 'WHATSAPP_TEMPLATE_EDIT',
    DELETE: 'WHATSAPP_TEMPLATE_DELETE',
};

// Api Keys page
export const ApiKeyActionTypes = {
    INIT: 'API_INIT',
    CREATE: 'API_CREATE',
    EDIT: 'API_EDIT',
    DELETE: 'API_DELETE',
};

// Payment Action Types
export const PaymentActionTypes = {
    INIT: 'TEMPLATE_INIT',
};

// Balance Action Types
export const BalanceActionTypes = {
    BALANCE: 'BALANCE',
};

// Senders page
export const SendersActionTypes = {
    INIT: 'SENDERS_INIT',
    DELETE: 'SENDERS_DELETE',
};

// Whatsapp Senders page
export const WhatsappSendersActionTypes = {
    INIT: 'WHATSAPP_SENDERS_INIT',
    DELETE: 'WHATSAPP_SENDERS_DELETE',
};

// Viber Senders page
export const ViberSendersActionTypes = {
    INIT: 'VIBER_SENDERS_INIT',
    DELETE: 'VIBER_SENDERS_DELETE',
};

// Shortcodes page
export const ShortcodesActionTypes = {
    INIT: 'SHORTCODES_INIT',
    DELETE: 'SHORTCODES_DELETE',
};

// Numbers page
export const NumbersActionTypes = {
    INIT: 'NUMBERS_INIT',
    DELETE: 'NUMBERS_DELETE',
};

// Contacts page
export const AudiencesActionTypes = {
    INIT: 'AUDIENCES_INIT',
    RE_FETCH: 'AUDIENCES_RE_FETCH',
};

export const ContactsActionTypes = {
    INIT: 'CONTACTS_INIT',
    EDIT: 'EDIT',
};

// SMS Campaign
export const SMSCampaignActionTypes = {
    INIT: 'SMS_CAMPAIGN_INIT',
    DELETE: 'SMS_CAMPAIGN_DELETE',
    UPDATE_LIST: 'UPDATE_LIST',
};

// Viber Campaign
export const ViberCampaignActionTypes = {
    INIT: 'VIBER_CAMPAIGN_INIT',
    DELETE: 'VIBER_CAMPAIGN_DELETE',
    UPDATE_LIST: 'UPDATE_LIST',
};

// Whatsapp Campaign
export const WhatsappCampaignActionTypes = {
    INIT: 'WHATSAPP_CAMPAIGN_INIT',
    DELETE: 'WHATSAPP_CAMPAIGN_DELETE',
    UPDATE_LIST: 'UPDATE_LIST',
};

// Responses page
export const ResponsesActionTypes = {
    INIT: 'RESPONSES_INIT',
    DELETE: 'RESPONSES_DELETE',
};

// SMS Limits page
export const SMSViolationTypes = {
    ADD_VIOLATION: 'ADD_VIOLATION',
    REMOVE_VIOLATION: 'REMOVE_VIOLATION',
};

export const InvoicesActionTypes = {
    INIT: 'INVOICES_INIT',
};

export const PendingAudiencesActionTypes = {
    INIT: 'PENDING_AUDIENCES_INIT',
    ADD: 'PENDING_AUDIENCES_ADD',
    REMOVE: 'PENDING_AUDIENCES_REMOVE',
};

export const InboxActionTypes = {
    SET_BADGES: 'SET_BADGES',
    SET_USERS: 'SET_USERS',
    SET_SENDERS: 'SET_SENDERS',
    SET_CONVERSATIONS: 'SET_CONVERSATIONS',
    SELECT_CONVERSATION: 'SELECT_CONVERSATION',
    CLEAR_SELECTED_CONVERSATION: 'CLEAR_SELECTED_CONVERSATION',
    CLEAR_CONVERSATIONS: 'CLEAR_CONVERSATIONS',
    SET_ASSIGNMENT_HISTORY: 'SET_ASSIGNMENT_HISTORY',
    SET_MESSAGES: 'SET_MESSAGES',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',
    SET_MESSAGES_READ: 'SET_MESSAGES_READ',
    SET_UNREAD_CONVERSATIONS: 'SET_UNREAD_CONVERSATIONS',
    UNREAD_OPEN: 'UNREAD_OPEN',
    // MODAL
    SET_MODAL_MESSAGES: 'SET_MODAL_MESSAGES',
    CLEAR_MODAL_MESSAGES: 'CLEAR_MODAL_MESSAGES',
    SET_MODAL_MESSAGES_READ: 'SET_MODAL_MESSAGES_READ',
    SELECT_MODAL_CONVERSATION: 'SELECT_MODAL_CONVERSATION',
    SET_MODAL_ASSIGNMENT_HISTORY: 'SET_MODAL_ASSIGNMENT_HISTORY',
    CLEAR_MODAL_SELECTED_CONVERSATION: 'CLEAR_MODAL_SELECTED_CONVERSATION',
    // EVENTS
    PUSH_IN_QUEUE: 'PUSH_IN_QUEUE',
    CLEAR_QUEUE: 'CLEAR_QUEUE',
    UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
    UPDATE_MESSAGES: 'UPDATE_MESSAGES',
    UPDATE_MESSAGE_STATUS: 'UPDATE_MESSAGE_STATUS',
    NEW_MESSAGE: 'NEW_MESSAGE',
};
