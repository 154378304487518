import {
    deleteCancelableRequest,
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';
import { ICancelableRequest } from 'types/services/http.config';

const InsightsHttpService = {
    logs: (query: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIQuery,
            endpointPath: () => `/messages${query}`,
        });
    },
    charts: (query: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIQuery,
            endpointPath: () => `/charts${query}`,
        });
    },
    senders: (channel?: string): ICancelableRequest => {
        const q = channel ? `&filter[channel]=${channel}` : '';
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/senders?page_size=1000&filter[status]=available${q}`,
        });
    },
    campaigns: (channel?: string): ICancelableRequest => {
        const q = channel ? `&filter[channel]=${channel}` : '';
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/campaigns?page_size=1000${q}`,
        });
    },
    getExports: (name: string, page: string, page_size: string): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () =>
                `/logs?page=${page || 1}&page_size=${page_size || 10}${name ? `&filter[name]=${name}` : ''}`,
        });
    },
    createExport: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/logs`,
        });
    },
    deleteExport: (id: string): ICancelableRequest => {
        return deleteCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/logs/${id}`,
        });
    },
    cancelExport: (id: string): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/logs/${id}`,
        });
    },
};

export default InsightsHttpService;
