import i18n from 'i18next';

import TRANSLATIONS_EN from 'assets/resources/en';

const resources = {
    en: {
        translation: TRANSLATIONS_EN
    }
};

i18n.init({
    lng: 'en',
    resources,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
