import {
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
    deleteCancelableRequest,
} from './http.config';

import { ICancelableRequest } from 'types/services/http.config';

const createInvitation = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/accounts/self/invitations`,
    });
};

const createSubaccount = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/accounts/self/subusers`,
    });
};

const getList = (query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/accounts/self/invitations${query}`,
    });
};

const getInvitationIsValid = (hash: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/accounts/self/invitations/${hash}`,
    });
};

const deleteRow = (email: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/accounts/self/subaccounts/${email}`,
    });
};

const updateRow = (email: string): ICancelableRequest => {
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/accounts/self/subaccounts/${email}`,
    });
};

const getTokenSub = (id: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/users/${id}/token`,
    });
};

const methods = {
    createInvitation,
    getList,
    deleteRow,
    updateRow,
    getTokenSub,
    createSubaccount,
    getInvitationIsValid,
};

export default methods;
