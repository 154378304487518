import React, { memo, FC } from 'react';
import { IOrderedList } from 'types/components';
import { useLocalization } from 'hooks';
import 'assets/scss/orderedList.scss';

const OrderedList: FC<IOrderedList> = ({ data }) => {
    const { t } = useLocalization();
    return (
        <ul className="ordered-list">
            {data.map((item, index) => (
                <li className="ordered-list-item" key={index}>
                    {t(item)}
                </li>
            ))}
        </ul>
    );
};

export default memo(OrderedList);
