import React from 'react';
import { Outlet } from 'react-router-dom';

import 'assets/scss/publicLayout.scss';
import capterraLogo from 'assets/images/logos/capterra.svg';
import getAppLogo from 'assets/images/logos/get-app.svg';
import trustPilotLogo from 'assets/images/logos/trust-pilot.svg';
import { AuthSlider } from 'components';

const PublicLayout = () => {
    const date = new Date();
    return (
        <div className="public-layout d-flex align-items-center h-100">
            <div className="public-left-container justify-content-center d-flex flex-column align-items-center px-5">
                <div className="h-100 d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="scrollable-box d-flex align-items-start">
                        <AuthSlider />
                        <div className="partners-box d-flex justify-content-center align-items-center">
                            <img src={capterraLogo} width={100} height={22} alt="Capterra" />
                            <img src={getAppLogo} width={102} height={18} alt="Get App" />
                            <img src={trustPilotLogo} width={99} height={24} alt="Trust Pilot" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="public-right-container position-relative d-flex align-items-center justify-content-center px-md-5 px-4">
                <div className="h-100 d-flex flex-column justify-content-center w-100">
                    <div className="scrollable-box d-flex align-items-start">
                        <div className="container">
                            <Outlet />
                        </div>
                        <div className="public-footer pb-4 px-3 mx-3 d-flex justify-content-between position-absolute">
                            <p className="font-size-14 mb-0">
                                &copy; {date.getFullYear()} Dexatel. All Rights Reserved.
                            </p>
                            <div className="d-flex gap-4">
                                <a
                                    target="_blank"
                                    className="footer-link font-size-14"
                                    href="https://dexatel.com/privacy-policy/"
                                >
                                    Privacy Policy
                                </a>
                                <a
                                    target="_blank"
                                    className="footer-link font-size-14"
                                    href="https://dexatel.com/terms-of-service/"
                                >
                                    Terms of Service
                                </a>
                                <a
                                    target="_blank"
                                    className="footer-link font-size-14"
                                    href="https://dexatel.com/anti-spam-policy/"
                                >
                                    Anti-Spam Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicLayout;
