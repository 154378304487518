import React, { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { DxIcon, DxTooltip, InputHint } from 'components';
import { IDxInputProps } from 'types/components';

import 'assets/scss/dxInput.scss';

const renderFunction =
    ({ label, tooltipPlacement, helperText, errorText, tooltipInfo, customClassName, ...restProps }: any) =>
    ({ field, fieldState: { error } }: any) => {
        const controlledId = uuidv4();
        return (
            <div>
                {!isNil(label) && (
                    <label htmlFor={controlledId} className="dx-label">
                        {label}
                        {!isNil(tooltipInfo) && (
                            <DxTooltip placement={tooltipPlacement} title={tooltipInfo}>
                                <div className="dx-icon-outer">
                                    <DxIcon size="sm" type="info_circle" />
                                </div>
                            </DxTooltip>
                        )}
                    </label>
                )}
                <Input
                    {...field}
                    {...(!isNil(label) ? { id: controlledId } : {})}
                    className={`dx-input ${!isNil(error) && 'error'} ${customClassName ? customClassName : ''} `}
                    {...restProps}
                />

                <InputHint error={error} helperText={helperText} />
            </div>
        );
    };

const DxInput: FC<IDxInputProps> = ({
    fieldName = '',
    label,
    tooltipPlacement,
    helperText,
    errorText,
    tooltipInfo,
    ...restProps
}) => (
    <Controller
        name={fieldName}
        render={renderFunction({ label, tooltipPlacement, helperText, errorText, tooltipInfo, ...restProps })}
    />
);

export default memo(DxInput);
