import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useLocalization } from 'hooks';

import { DxIcon } from 'components';
import { IMenuList } from 'types/components/menu';
import { isNil } from 'lodash';

import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { IAccountState, IRootReducer } from '../../../types/reducers';
import { IInboxState } from '../../../types/reducers/inbox';

const { SubMenu } = Menu;

interface ILeftNavMenuProps {
    openItems: any;
    list: IMenuList[];
}
const LeftNavMenu: FC<ILeftNavMenuProps> = ({ openItems, list }) => {
    const { t } = useLocalization();
    const { permissions } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    const { badges } = useSelector<IRootReducer, IInboxState>((state) => state.inbox);

    const sendersEnabled = useMemo(() => {
        return permissions.SENDERS?.canView;
    }, [permissions]);

    const contactsEnabled = useMemo(() => {
        return permissions.AUDIENCES?.canView || permissions.SENDERS?.canView;
    }, [permissions]);

    const inboxEnabled = useMemo(() => {
        return permissions.CONVERSATIONS?.canView || permissions.MESSAGES?.canView;
    }, [permissions]);

    const badgesCount = useMemo<any>(() => {
        const self = Object.values(badges.self).reduce((total, val) => (val > 0 ? total + 1 : total), 0);
        const others = Object.values(badges.others).reduce((total, val) => (val > 0 ? total + 1 : total), 0);
        const none = Object.values(badges.none).reduce((total, val) => (val > 0 ? total + 1 : total), 0);
        const selfTotal = Object.values(badges.self).length;
        const othersTotal = Object.values(badges.others).length;
        const noneTotal = Object.values(badges.none).length;
        return {
            INBOX_ASSIGNED_TO_ME: { total: selfTotal >= 100 ? '99+' : selfTotal, unread: self },
            INBOX_ASSIGNED_TO_OTHERS: { total: othersTotal >= 100 ? '99+' : othersTotal, unread: others },
            INBOX_UNASSIGNED: { total: noneTotal >= 100 ? '99+' : noneTotal, unread: none },
            INBOX: {
                total: selfTotal + othersTotal + noneTotal > 100 ? '99+' : selfTotal + othersTotal + noneTotal,
                unread: self + others + none,
            },
        };
    }, [badges]);

    return (
        <Menu className="dx-menu" mode="inline" defaultOpenKeys={openItems} selectedKeys={openItems}>
            {list.map(({ withSpace, label, link, icon, subMenu, viewRule }) =>
                isNil(subMenu) ? (
                    <Menu.Item
                        className={`dx-menu-item ${withSpace && 'with-space'} ${
                            permissions[viewRule]!.canView ? '' : 'custom-disable'
                        }`}
                        key={link}
                    >
                        {permissions[viewRule]!.canView ? (
                            <Link className="dx-menu-link" to={link}>
                                <DxIcon type={icon} />
                                <span className="dx-menu-link-text">{t(label)}</span>
                            </Link>
                        ) : (
                            <span className="dx-menu-link">
                                <DxIcon type={icon} />
                                <span className="dx-menu-link-text">{t(label)}</span>
                            </span>
                        )}
                    </Menu.Item>
                ) : (
                    <SubMenu
                        key={link}
                        className={`dx-sub-menu-outer ${
                            ((label === 'SENDERS' && !sendersEnabled) ||
                                (label === 'CONTACTS' && !contactsEnabled) ||
                                (label === 'INBOX' && !inboxEnabled)) &&
                            'not-ready'
                        }`}
                        icon={<DxIcon type={icon} />}
                        title={
                            <div className={'dx-menu-item-inner'}>
                                <div className={'dx-item-text'}>
                                    {t(label) + (badgesCount[label] ? ' (' + badgesCount[label]?.total + ')' : '')}
                                </div>
                                {badgesCount[label]?.unread ? (
                                    <div className={'dx-item-suffix'}>{badgesCount[label].unread}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                        }
                    >
                        <Menu.ItemGroup key={link} className="dx-sub-menu">
                            {subMenu &&
                                subMenu.map(({ label: subLabel, link: subLink, viewRule: subRule }) => {
                                    let viewRule: 'canView' | 'canAdd' = 'canView';
                                    if (subLabel === 'INBOX_ASSIGNED_TO_OTHERS') {
                                        viewRule = 'canAdd';
                                    }
                                    return (
                                        <Menu.Item className="dx-sub-menu-item" key={subLink}>
                                            {permissions[subRule]![viewRule] ? (
                                                <Link className="dx-sub-menu-link" to={subLink}>
                                                    <div className={'dx-menu-item-inner'}>
                                                        <div className={'dx-item-text'}>
                                                            {t(subLabel) +
                                                                (badgesCount[subLabel]
                                                                    ? ' (' + badgesCount[subLabel]?.total + ')'
                                                                    : '')}
                                                        </div>
                                                        {badgesCount[subLabel]?.unread ? (
                                                            <div className={'dx-item-suffix right-0'}>
                                                                {badgesCount[subLabel].unread}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </Link>
                                            ) : (
                                                <span className="dx-sub-menu-link custom-disable">{t(subLabel)}</span>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                        </Menu.ItemGroup>
                    </SubMenu>
                ),
            )}
        </Menu>
    );
};

export default LeftNavMenu;
