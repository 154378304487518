import { AccountActionTypes as Types } from './types';

const setInfo = (data: any) => {
    return {
        type: Types.SET_ACCOUNT_INFO,
        payload: data,
    };
};

const setSenders = (data: { name: string; id: string }[]) => {
    return {
        type: Types.SET_ACCOUNT_SENDERS,
        payload: data,
    };
};

export default {
    setInfo,
    setSenders,
};
