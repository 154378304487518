import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { DxAuthProvider } from 'dexatel-auth';

import { App } from 'components';
import Routes from 'routes';
import store from './store';
import i18n from './i18n';

import 'assets/scss/vars.scss';
import './index.scss';
import 'assets/scss/media.scss';

window.PUSH_EVENTS_IN_QUEUE = 0;

render(
    <React.StrictMode>
        <DxAuthProvider baseURL={process.env.REACT_APP_GETAWAY_API_URL!}>
            <Provider store={store}>
                <BrowserRouter>
                    <I18nextProvider i18n={i18n}>
                        <App>
                            <Routes />
                        </App>
                    </I18nextProvider>
                </BrowserRouter>
            </Provider>
        </DxAuthProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
