import { combineReducers } from 'redux';
// Import reducers
import auth from './auth.reducer';
import apis from './apis.reducer';
import account from './account.reducer';
import balance from './balance.reducer';
import senders from './senders.reducer';
import numbers from './numbers.reducer';
import payments from './payments.reducer';
import contacts from './contacts.reducer';
import templates from './templates.reducer';
import audiences from './audiences.reducer';
import responses from './responses.reducer';
import shortcodes from './shortcodes.reducer';
import smsCampaigns from './smsCampaigns.reducer';
import viberSenders from './viberSenders.reducer';
import viberCampaigns from './viberCampaigns.reducer';
import whatsappCampaigns from './whatsappCampaigns.reducer';
import whatsappSenders from './whatsappSenders.reducer';
import whatsappTemplates from './whatsappTemplates.reducer';
import invoices from './invoices.reducer';
import pendingAudiences from './pendingAudiences.reducer';
import inbox from './inbox.reducer';
import violation from './newViolation.reducer';

export default combineReducers({
    auth,
    apis,
    account,
    balance,
    senders,
    payments,
    numbers,
    contacts,
    templates,
    audiences,
    responses,
    shortcodes,
    smsCampaigns,
    viberSenders,
    viberCampaigns,
    whatsappSenders,
    whatsappCampaigns,
    whatsappTemplates,
    invoices,
    pendingAudiences,
    inbox,
    violation,
});
