import { Modal } from 'antd';
import { IDxWrapperModal } from '../../../types/components';
import React, { FC } from 'react';
import 'assets/scss/dxModal.scss';
import { DxIcon } from '../index';

const DxWrapperModal: FC<IDxWrapperModal> = ({ children, className, ...restProps }) => {
    return (
        <Modal
            maskStyle={{ zIndex: 1031 }}
            centered={true}
            closeIcon={<DxIcon type="close_big" size="md" />}
            wrapClassName="dx-modal-wrap"
            className={`dx-wrapper-modal ${className}`}
            {...restProps}
        >
            {children}
        </Modal>
    );
};

export default DxWrapperModal;
