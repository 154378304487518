import {
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
    putCancelableRequest,
} from './http.config';

import { ICancelableRequest } from 'types/services/http.config';

const getCardAddSecret = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/setup_intents`,
    });
};

const getClientCards = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/payment_methods`,
    });
};

const getPaymentMethods = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/transaction/stripe-settings`,
    });
};

const createCardRequest = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `payment_methods`,
    });
};

const removePaymentMethodRequest = (id: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `payment_methods/${id}`,
    });
};

const setDefaultPaymentMethodRequest = (id: string): ICancelableRequest => {
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `payment_methods/${id}`,
    });
};

const updatePaymentMethodSettingsRequest = (): ICancelableRequest => {
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/transaction/stripe-settings`,
    });
};

const methods = {
    getCardAddSecret,
    createCardRequest,
    getClientCards,
    removePaymentMethodRequest,
    setDefaultPaymentMethodRequest,
    updatePaymentMethodSettingsRequest,
    getPaymentMethods,
};

export default methods;
