import SignIn1 from 'assets/images/auth/sign_in_1.webp';

import ResetPassword1 from 'assets/images/auth/reset_password_1.webp';

import Email1 from 'assets/images/auth/email_1.webp';

import SignUp1 from 'assets/images/auth/sign_up_1.webp';
import SignUp2 from 'assets/images/auth/sign_up_2.webp';
import SignUp3 from 'assets/images/auth/sign_up_3.webp';
import SignUp4 from 'assets/images/auth/sign_up_4.webp';

import Code1 from 'assets/images/auth/code_1.webp';
import Phone1 from 'assets/images/auth/phone_1.webp';

export const AuthSliderSignUpData = [
    {
        title: 'One Platform for',
        subTitle: 'All Business Interactions',
        imgSrc: SignUp1,
        desc: 'Easily manage incoming and outgoing messages from all channels in real time within a single omnichannel inbox.',
    },
    {
        title: 'One Platform for',
        subTitle: 'All Business Interactions',
        imgSrc: SignUp2,
        desc: 'Shorten your URLs and track click-through rates to see the efficiency of your marketing strategy.',
    },
    {
        title: 'One Platform for',
        subTitle: 'All Business Interactions',
        imgSrc: SignUp3,
        desc: 'Import and segment your audience to target the right customers with your personalized campaigns.',
    },
    {
        title: 'One Platform for',
        subTitle: 'All Business Interactions',
        imgSrc: SignUp4,
        desc: 'Analyze the insights of your campaigns to see their performance and make necessary tweaks to your strategy.',
    },
];

export const AuthSliderCodeData = [
    {
        title: 'Verify Your Account',
        subTitle: 'to Use the Dexatel Platform',
        imgSrc: Code1,
        desc: 'Take the first step to verify your account so you gain access to our comprehensive platform for managing all your business interactions.',
    },
];

export const AuthSliderPhoneData = [
    {
        title: 'Verify Your Account',
        subTitle: 'with a Valid Phone Number',
        imgSrc: Phone1,
        desc: 'Please enter a valid phone number for verification. This step is essential to gain access to our comprehensive platform for managing all your business interactions.',
    },
];

export const AuthSliderData = [
    {
        title: 'Sign in to Start',
        subTitle: 'Connecting With Your Audience',
        imgSrc: SignIn1,
        desc: "Sign in to your account to take advantage of Dexatel's omnichannel communication features.",
    },
];

export const AuthSliderResetData = [
    {
        title: 'Reset Your Password',
        subTitle: 'to Continue Messaging',
        imgSrc: ResetPassword1,
        desc: 'Follow the steps to reset your password and pick up where you left off on your omnichannel messaging journey with Dexatel.',
    },
];

export const AuthSliderEmailData = [
    {
        title: 'Check Your Email',
        subTitle: 'to Continue the Recovery Process',
        imgSrc: Email1,
        desc: 'Head over to your email inbox and open the email from Dexatel to change your password, recover your account, and resume your omnichannel communications.',
    },
];

export const AuthSliderEmailRegistrationData = [
    {
        title: 'Complete the Verification Process',
        subTitle: 'to Create Your Account',
        imgSrc: Email1,
        desc: 'Open your email inbox and verify your account by clicking on the link to start using the Dexatel platform.',
    },
];
