import { ICancelableRequest } from '../../types/services/http.config';
import {
    deleteCancelableRequest,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';

const getLimits = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/limits`,
    });
};

const getLimitSingle = (id: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/limits/${id}`,
    });
};

const createLimit = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/limits`,
    });
};

const editLimit = (id: string): ICancelableRequest => {
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/limits/${id}`,
    });
};

const deleteLimit = (id: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/limits/${id}`,
    });
};

const getViolations = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => '/violations',
    });
};

const methods = { getLimits, getLimitSingle, createLimit, editLimit, deleteLimit, getViolations };

export default methods;
