import React, { FC, memo } from 'react';
import { isNil } from 'lodash';

interface IInputHintProps {
    error?: any;
    helperText?: string | undefined;
}

const InputHint: FC<IInputHintProps> = ({ error, helperText }) => {
    if (!isNil(error)) {
        return <p className="dx-error-text">{error.message}</p>;
    }

    if (!isNil(helperText)) {
        return <p className="dx-helper-text">{helperText}</p>;
    }

    return null;
};

export default memo(InputHint);
