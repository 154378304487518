import React, { FC, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, ValidationMode } from 'react-hook-form';

import { MessagesTemplates } from './MessagesTemplates';
import { IConversationItem } from '../types';
import { DxAlert, DxButton, DxForm, DxIcon, DxPopover, DxShowNotification, DxTextarea } from '../../../components';
import { TEMPLATE_SYMBOL_COUNT } from '../../../constants/common';
import { useSelector } from 'react-redux';
import { IAccountState, IRootReducer } from '../../../types/reducers';
import { makePermissionRequiredMessage } from '../../../services/utils/helper';
import { useRequest } from '../../../hooks';
import { ResponsesHttpService } from '../../../services/http';
import { IErrors } from '../../../types/validation';
import { IInboxState } from '../../../types/reducers/inbox';
import { MessagesMedia } from './MessagesMedia';

interface IMessagesFooter {
    selectedConversation: IConversationItem;
    isModal: boolean;
    isInfoModalVisible: boolean;
}

interface IFormInputs {
    text: string;
}

const schema = yup.object({
    text: yup.string().required().max(1000).label('Message'),
});

export const formProps = {
    mode: 'onChange' as keyof ValidationMode,
    resolver: yupResolver(schema),
    revalidateMode: 'onSubmit',
};

export const MessagesFooter: FC<IMessagesFooter> = ({ selectedConversation, isModal = false, isInfoModalVisible }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMediaModalVisible, setIsMediaModalVisible] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(true);
    useEffect(() => {
        setIsAlertVisible(true);
    }, [selectedConversation.id]);
    const [isMediaVisible, setIsMediaVisible] = useState(false);
    const [type, setType] = useState('text');
    const toggleModal = () => {
        setIsModalVisible((prev) => !prev);
    };

    useEffect(() => {
        if (isInfoModalVisible) {
            setIsAlertVisible(false);
        }
    }, [isInfoModalVisible]);

    const toggleMediaModal = (type: string) => {
        setType(type);
        setIsMediaModalVisible((prev) => !prev);
    };

    /**
     * Permissions set
     */
    const { permissions } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    const canAdd = useMemo(() => {
        return permissions.MESSAGES?.canAdd;
    }, [permissions]);

    const {
        messages: { isViberLimitActive: isViberLimitActiveInbox, isActiveConversation: isActiveConversationInbox },
        modalMessages: { isViberLimitActive: isViberLimitActiveModal, isActiveConversation: isActiveConversationModal },
    } = useSelector<IRootReducer, IInboxState>((state) => state.inbox);

    const isViberLimitActive = useMemo(() => {
        return isModal ? isViberLimitActiveModal : isViberLimitActiveInbox;
    }, [isViberLimitActiveInbox, isViberLimitActiveModal]);
    const isActiveConversation = useMemo(() => {
        return isModal ? isActiveConversationModal : isActiveConversationInbox;
    }, [isActiveConversationInbox, isActiveConversationModal]);

    useEffect(() => {
        setValue('text', '');
    }, [selectedConversation.id]);

    const sessionState = useMemo(() => {
        return {
            inSession:
                selectedConversation.channel === 'WHATSAPP' || selectedConversation.channel === 'VIBER'
                    ? isActiveConversation
                    : true,
            inLimit: selectedConversation.channel === 'VIBER' ? isViberLimitActive : true,
        };
    }, [selectedConversation, isViberLimitActive, isActiveConversation]);

    useEffect(() => {
        if (selectedConversation.channel === 'WHATSAPP' && !sessionState.inSession) {
            setValue('text', '');
        }
    }, [sessionState]);

    const { doPostRequest } = useRequest();
    const [sending, setSending] = useState(false);
    const methods = useForm<IFormInputs>(formProps);
    const {
        setValue,
        handleSubmit,
        formState: { isValid },
    } = methods;

    const onSubmit = (data: IFormInputs) => {
        if (window.PUSH_EVENTS_IN_QUEUE !== 0) {
            return;
        }
        setSending(true);
        const typeData: { type?: string } = {};
        if (selectedConversation.channel === 'VIBER') {
            typeData.type = 'text';
        }
        const textData: { text?: string } = {};
        if (data.text) {
            textData.text = data.text;
        }
        doPostRequest(ResponsesHttpService.createMessage().request, {
            requestBody: {
                data: {
                    from: selectedConversation.sender_name,
                    to: [selectedConversation.number],
                    channel: selectedConversation.channel,
                    ...textData,
                    ...typeData,
                },
            },
            successCallback: (res) => {
                setSending(false);
                setValue('text', '');
            },
            errorCallback: (data) => {
                const { errors } = data as IErrors;
                errors?.map((error) => {
                    DxShowNotification('error', error?.message);
                });
                setSending(false);
            },
        });
    };

    const handleKeyPress = (e: any) => {
        if (e.charCode === 13 && !e.shiftKey && !sending) {
            handleSubmit(onSubmit)();
            e.preventDefault();
        }
    };

    return (
        <div className={'messages-footer'}>
            <div className={'messages-footer-inner'}>
                <DxForm methods={methods} onFinish={handleSubmit(onSubmit)}>
                    <div className={`message-reply-box`}>
                        <div
                            className={`footer-actions-box  ${
                                !canAdd || (!sessionState.inSession && selectedConversation.channel === 'WHATSAPP')
                                    ? 'disabled'
                                    : ''
                            }`}
                        >
                            <div className="footer-actions">
                                {['whatsapp', 'viber'].includes(selectedConversation.channel.toLowerCase()) && (
                                    <DxPopover
                                        placement="topLeft"
                                        overlayClassName={'footer-file-popover'}
                                        visible={isMediaVisible}
                                        onVisibleChange={() => setIsMediaVisible((prev) => !prev)}
                                        content={
                                            <div className="footer-actions-media">
                                                <div
                                                    onClick={() => {
                                                        setIsMediaVisible(false);
                                                        toggleMediaModal('image');
                                                    }}
                                                    className="footer-filter-type"
                                                >
                                                    <DxIcon type={'image-filled'} />
                                                    Image
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setIsMediaVisible(false);
                                                        toggleMediaModal('video');
                                                    }}
                                                    className="footer-filter-type"
                                                >
                                                    <DxIcon type={'video-filled'} />
                                                    Video
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setIsMediaVisible(false);
                                                        toggleMediaModal('document');
                                                    }}
                                                    className="footer-filter-type"
                                                >
                                                    <DxIcon type={'document_filled'} />
                                                    Document
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div
                                            className={`footer-attach-file ${
                                                (!sessionState.inSession &&
                                                    selectedConversation.channel === 'WHATSAPP') ||
                                                !canAdd
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                        >
                                            <div
                                                className={`footer-attach-file-inner ${
                                                    isMediaVisible ? 'selected' : ''
                                                }`}
                                            >
                                                <DxIcon type="attachment" /> Attach Files
                                            </div>
                                        </div>
                                    </DxPopover>
                                )}
                                <div
                                    onClick={toggleModal}
                                    className={`footer-template-btn ${isModalVisible ? 'selected' : ''} ${
                                        !canAdd ? 'disabled' : ''
                                    }`}
                                >
                                    <DxIcon type="message_plus_alt" /> Reply from Template
                                </div>
                            </div>
                            {!canAdd && sessionState.inSession && sessionState.inLimit && (
                                <DxPopover
                                    placement="topRight"
                                    overlayClassName={`inbox-alert ${isModal ? 'modal' : ''}`}
                                    visible={isAlertVisible}
                                    destroyTooltipOnHide={true}
                                    content={
                                        <DxAlert
                                            message={'You need Add Message permission.'}
                                            appearance="warning"
                                            onClose={() => setIsAlertVisible(false)}
                                            closable={true}
                                        />
                                    }
                                    onVisibleChange={() => setIsAlertVisible((prev) => !prev)}
                                >
                                    <DxIcon size="md" appearance={'warning'} type={'error'} />
                                </DxPopover>
                            )}
                            {!sessionState.inLimit && sessionState.inSession && (
                                <DxPopover
                                    placement="topRight"
                                    overlayClassName={`inbox-alert ${isModal ? 'modal' : ''}`}
                                    visible={isAlertVisible}
                                    destroyTooltipOnHide={true}
                                    content={
                                        <DxAlert
                                            message={
                                                'The session window is not active. You can send only promotional or transactional messages.'
                                            }
                                            appearance="warning"
                                            onClose={() => setIsAlertVisible(false)}
                                            closable={true}
                                        />
                                    }
                                    onVisibleChange={() => setIsAlertVisible((prev) => !prev)}
                                >
                                    <DxIcon size="md" appearance={'warning'} type={'error'} />
                                </DxPopover>
                            )}
                            {!sessionState.inSession && (
                                <DxPopover
                                    placement="topRight"
                                    overlayClassName={`inbox-alert ${isModal ? 'modal' : ''}`}
                                    visible={isAlertVisible}
                                    destroyTooltipOnHide={true}
                                    content={
                                        <DxAlert
                                            message={
                                                selectedConversation.channel === 'VIBER'
                                                    ? 'The session window is closed. You can send only promotional or transactional messages.'
                                                    : 'The session window is closed. You can send only template messages.'
                                            }
                                            appearance="warning"
                                            onClose={() => setIsAlertVisible(false)}
                                            closable={true}
                                        />
                                    }
                                    onVisibleChange={() => setIsAlertVisible((prev) => !prev)}
                                >
                                    <DxIcon size="md" appearance={'warning'} type="error" />
                                </DxPopover>
                            )}
                        </div>
                        <DxButton
                            htmlType="submit"
                            appearance="primary"
                            radius="sharped"
                            disabled={
                                !isValid ||
                                !canAdd ||
                                (!sessionState.inSession && selectedConversation.channel === 'WHATSAPP')
                            }
                            loading={sending}
                        >
                            {!sending && <DxIcon type="send" size="sm" />}
                        </DxButton>

                        <DxTextarea
                            disabled={
                                !canAdd || (!sessionState.inSession && selectedConversation.channel === 'WHATSAPP')
                            }
                            fieldName="text"
                            placeholder="Reply..."
                            showCounter={true}
                            maxLength={TEMPLATE_SYMBOL_COUNT}
                            showSmsCount={selectedConversation.channel === 'SMS'}
                            simpleCounter={true}
                            onKeyPress={handleKeyPress}
                            hideError={true}
                        />
                    </div>

                    <MessagesTemplates
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        channel={selectedConversation.channel.toLowerCase()}
                        senderCode={selectedConversation.sender_name}
                        selectedConversation={selectedConversation}
                    />
                    <MessagesMedia
                        isModalVisible={isMediaModalVisible}
                        setIsModalVisible={setIsMediaModalVisible}
                        channel={selectedConversation.channel.toLowerCase()}
                        type={type}
                        selectedConversation={selectedConversation}
                    />
                </DxForm>
            </div>
        </div>
    );
};
