import React, { FC, memo } from 'react';
import { Modal } from 'antd';
import { isNil } from 'lodash';

import { IDxConfirmModalProps } from 'types/components';
import { overflowBackgroundColor } from './config';
import { DxButton, DxIcon } from '../index';

import 'assets/scss/dxModal.scss';

const DxConfirmModal: FC<IDxConfirmModalProps> = ({
    customAlignment = false,
    visible = false,
    title,
    description,
    textAlignment = 'center',
    btnAlignment = 'center',
    icon,
    confirmText,
    cancelText = 'Cancel',
    cancelAppearance = 'default',
    confirmAppearance = 'primary',
    onOk,
    onCancel,
    children,
    onLoading = false,
    disableOkBtn = false,
    customClassName = '',
    ...restProps
}) => (
    <Modal
        className={`dx-modal${customAlignment ? ' custom-alignment' : ''}${
            customClassName ? ' ' + customClassName : ''
        }`}
        closeIcon={<DxIcon type="close_small" />}
        visible={visible}
        wrapClassName="dx-modal-wrap"
        maskStyle={{
            backgroundColor: overflowBackgroundColor,
        }}
        onCancel={onCancel}
        footer={[
            <div key="control" className={`dx-btn-outer ${btnAlignment}`}>
                <DxButton appearance={cancelAppearance} key="back" onClick={onCancel}>
                    {cancelText}
                </DxButton>

                {onOk && (
                    <DxButton
                        disabled={disableOkBtn}
                        appearance={confirmAppearance}
                        key="submit"
                        onClick={onOk}
                        loading={onLoading}
                    >
                        {confirmText}
                    </DxButton>
                )}
            </div>,
        ]}
        {...restProps}
    >
        <div className={`dx-modal-content ${textAlignment}`}>
            {!isNil(icon) && <div className="mb-4">{icon}</div>}
            {!isNil(title) && <p className="dx-modal-title">{title}</p>}
            {!isNil(description) && <p className="dx-modal-description">{description}</p>}
            {children}
        </div>
    </Modal>
);

export default memo(DxConfirmModal);
