import {
    GetawayAPIQuery,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
    deleteCancelableRequest,
} from './http.config';
import { ICancelableRequest } from 'types/services/http.config';
import { TID, TQuery } from 'types/services/apiKey';

const ApiKeysHttpService = {
    query: (query: TQuery): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIQuery,
            endpointPath: () => `/keys${query}`,
        });
    },
    create: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/keys',
        });
    },
    get: (id: TID): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/keys/${id}`,
        });
    },
    edit: (id: TID): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/keys/${id}`,
        });
    },
    delete: (id: TID): ICancelableRequest => {
        return deleteCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/keys/${id}`,
        });
    },
};

export default ApiKeysHttpService;
