import React, { FC, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import PublicLayout from 'components/Layout/PublicLayout';
import { PUBLIC_ROUTES_ENUM } from 'constants/routes';
import { IRouteElement } from './types';

// AUTH PAGES
const SignIn = lazy(() => import('pages/Auth/SignIn'));
const SignUp = lazy(() => import('pages/Auth/SignUp'));
const EmailVerification = lazy(() => import('pages/Auth/EmailVerification'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'));
const AccountVerification = lazy(() => import('pages/Auth/AccountVerification'));
const PhoneVerificationForm = lazy(() => import('pages/PhoneVerification/PhoneVerificationForm'));
const Invitations = lazy(() => import('pages/Auth/Invitations'));

// PIPEDRIVE
const PipeDrive = lazy(() => import('pages/Pipedrive'));
// NOT FOUND
const PageNotFound = lazy(() => import('pages/PageStatuses/PageNotFound'));

const {
    EMAIL_VERIFICATION,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SIGN_IN,
    SIGN_UP,
    PIPEDRIVE,
    PHONE_VERIFICATION,
    ACCOUNT_VERIFICATION,
    INVITATIONS,
} = PUBLIC_ROUTES_ENUM;

const publicRoutes: IRouteElement[] = [
    {
        element: <PublicLayout />,
        children: [
            {
                path: SIGN_IN,
                element: <SignIn />,
            },
            {
                path: SIGN_UP,
                element: <SignUp />,
            },
            {
                path: `${EMAIL_VERIFICATION}/*`,
                element: <EmailVerification />,
            },
            {
                path: ACCOUNT_VERIFICATION,
                element: <AccountVerification />,
            },
            {
                path: PHONE_VERIFICATION,
                element: <PhoneVerificationForm />,
            },
            {
                path: FORGOT_PASSWORD,
                element: <ForgotPassword />,
            },
            {
                path: RESET_PASSWORD,
                element: <ResetPassword />,
            },
            {
                path: INVITATIONS,
                element: <Invitations />,
            },
        ],
    },
    {
        path: PIPEDRIVE,
        element: <PipeDrive />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
];

const PublicRoutes: FC<{ prevRoute?: string }> = ({ prevRoute }) => {
    const signInUrl = prevRoute ? `${PUBLIC_ROUTES_ENUM.SIGN_IN}?returnUrl=${prevRoute}` : PUBLIC_ROUTES_ENUM.SIGN_IN;

    publicRoutes[0].children = [
        {
            index: true,
            element: <Navigate replace={true} to={signInUrl} />,
        },
        ...publicRoutes[0].children,
    ];

    const routes = useRoutes(publicRoutes);
    return <>{routes}</>;
};

export default PublicRoutes;
