import { TActionCreator } from '../types/actions/types';
import { AccountActionTypes, InboxActionTypes } from '../actions/types';
import { IInboxState } from '../types/reducers/inbox';
import {
    clearEventsQueue,
    handleConversationEvent,
    handleMessageEvent,
    handleMessageEventInConversation,
    handleStatusEvent,
    handleUnreadConversationEvent,
    injectAssignmentHistory,
} from '../pages/Inbox/helpers';

export const initialState: IInboxState = {
    users: [],
    senders: [],
    conversations: {
        data: [],
        pagination: { total: 0, number: 1, size: 10 },
        assignee: '',
        filters: { search: '', channels: ['sms', 'whatsapp', 'viber'], senderId: 'All Senders' },
    },
    messages: {
        data: [],
        pagination: { total: 0, number: 1, size: 10 },
        allRead: '',
        isActiveConversation: true,
        isViberLimitActive: true,
    },
    assignmentsHistory: [],
    lastRead: {
        found: false,
        id: '',
    },
    selectedConversation: null,
    badges: {
        self: {},
        others: {},
        none: {},
    },
    utils: {
        timezone: '',
        userId: '',
        full_name: '',
        canView: { self: false, none: false, others: false },
    },
    eventsQueue: [],
    // MODAL
    unreadConversations: {
        isOpen: false,
        data: [],
        pagination: { total: 0, number: 1, size: 10 },
    },
    modalMessages: {
        data: [],
        pagination: { total: 0, number: 1, size: 10 },
        allRead: '',
        isActiveConversation: true,
        isViberLimitActive: true,
    },
    modalAssignmentsHistory: [],
    modalSelectedConversation: null,
    modalLastRead: {
        found: false,
        id: '',
    },
};

export default function inboxReducer(state: IInboxState = initialState, action: TActionCreator) {
    switch (action.type) {
        /**
         * General
         */
        case InboxActionTypes.SET_BADGES:
            return {
                ...state,
                badges: action.payload,
            };
        case InboxActionTypes.SET_USERS:
            const usersArray = action.payload
                .filter((user: any) => {
                    return user.permissions.MESSAGES > 0;
                })
                .map((user: any) => {
                    const fullNameSplit = user.full_name.split(' ');
                    return {
                        full_name: user.full_name,
                        id: user.id,
                        email: user.username,
                        initials:
                            fullNameSplit[0][0]?.toUpperCase() + fullNameSplit[1]
                                ? fullNameSplit[1][0]?.toUpperCase()
                                : '',
                    };
                });
            const utilsFullNameSplit = state.utils.full_name.split(' ');
            usersArray.unshift(
                {
                    full_name: state.utils.full_name,
                    id: state.utils.userId,
                    email: '',
                    initials:
                        utilsFullNameSplit[0][0]?.toUpperCase() + utilsFullNameSplit[1]
                            ? utilsFullNameSplit[1][0]?.toUpperCase()
                            : '',
                },
                {
                    full_name: 'Unassigned',
                    id: 'unassigned',
                    email: '',
                    initials: 'U',
                },
            );
            return {
                ...state,
                users: usersArray,
            };
        case InboxActionTypes.SET_SENDERS:
            const sendersArray = action.payload.map((item: any) => {
                return {
                    label: item?.whatsapp?.business_name || item?.viber?.business_name_en || item?.name,
                    value: item.id,
                    channel: item.channel,
                };
            });
            sendersArray.unshift({
                label: 'All Senders',
                value: 'All Senders',
                channel: 'sms',
            });
            return {
                ...state,
                senders: sendersArray,
            };
        case AccountActionTypes.SET_ACCOUNT_INFO:
            const utilsInfo = {
                timezone: action.payload.timezone || state.utils.timezone,
                userId: action.payload.userId || state.utils.userId,
                full_name: action.payload.full_name || state.utils.full_name,
                canView: {
                    self: action.payload.permissions?.MESSAGES.canView || state.utils.canView.self,
                    none: action.payload.permissions?.CONVERSATIONS.canView || state.utils.canView.self,
                    others: action.payload.permissions?.CONVERSATIONS.canAdd || state.utils.canView.self,
                },
            };
            return {
                ...state,
                utils: utilsInfo,
            };
        /**
         * Inbox
         */
        case InboxActionTypes.SET_CONVERSATIONS:
            const inboxConversations = {
                data: action.payload.init ? action.payload.data : [...state.conversations.data, ...action.payload.data],
                pagination: action.payload.pagination,
                assignee: action.payload.assignee,
                filters: action.payload.filters,
            };
            const conversationsEventData = clearEventsQueue(
                // General
                state.eventsQueue,
                state.badges,
                state.utils,
                // Inbox
                inboxConversations,
                state.selectedConversation,
                state.messages,
                state.lastRead,
                // Modal
                state.unreadConversations,
                state.modalSelectedConversation,
                state.modalMessages,
                state.modalLastRead,
            );
            return {
                ...state,
                badges: conversationsEventData.badges,
                conversations: conversationsEventData.conversations,
                selectedConversation: conversationsEventData.selectedConversation,
                messages: conversationsEventData.messages,
                lastRead: conversationsEventData.lastRead,
                unreadConversations: conversationsEventData.unreadConversations,
                modalSelectedConversation: conversationsEventData.modalSelectedConversation,
                modalMessages: conversationsEventData.modalMessages,
                modalLastRead: conversationsEventData.modalLastRead,
                eventsQueue: window.PUSH_EVENTS_IN_QUEUE === 0 ? initialState.eventsQueue : state.eventsQueue,
            };
        case InboxActionTypes.SELECT_CONVERSATION:
            return {
                ...state,
                selectedConversation: action.payload,
            };
        case InboxActionTypes.SET_MESSAGES:
            const { lastRead: inboxLastRead, ...inboxMessages } = action.payload;
            const messagesEventData = clearEventsQueue(
                // General
                state.eventsQueue,
                state.badges,
                state.utils,
                // Inbox
                state.conversations,
                state.selectedConversation,
                inboxMessages,
                inboxLastRead,
                // Modal
                state.unreadConversations,
                state.modalSelectedConversation,
                state.modalMessages,
                state.modalLastRead,
            );
            return {
                ...state,
                badges: messagesEventData.badges,
                conversations: messagesEventData.conversations,
                selectedConversation: messagesEventData.selectedConversation,
                messages: {
                    ...state.messages,
                    ...injectAssignmentHistory(inboxMessages, state.assignmentsHistory, state.utils.timezone),
                },
                lastRead: messagesEventData.lastRead,
                unreadConversations: messagesEventData.unreadConversations,
                modalSelectedConversation: messagesEventData.modalSelectedConversation,
                modalMessages: messagesEventData.modalMessages,
                modalLastRead: messagesEventData.modalLastRead,
                eventsQueue: window.PUSH_EVENTS_IN_QUEUE === 0 ? initialState.eventsQueue : state.eventsQueue,
            };
        case InboxActionTypes.SET_ASSIGNMENT_HISTORY:
            return {
                ...state,
                assignmentsHistory: action.payload,
            };
        case InboxActionTypes.SET_MESSAGES_READ:
            return {
                ...state,
                messages: { ...state.messages, allRead: action.payload },
            };
        case InboxActionTypes.CLEAR_CONVERSATIONS:
            return {
                ...state,
                users: initialState.users,
                conversations: initialState.conversations,
                selectedConversation: initialState.selectedConversation,
            };
        case InboxActionTypes.CLEAR_SELECTED_CONVERSATION:
            return {
                ...state,
                selectedConversation: null,
                assignmentsHistory: initialState.assignmentsHistory,
            };
        case InboxActionTypes.CLEAR_MESSAGES:
            return {
                ...state,
                messages: initialState.messages,
                assignmentsHistory: [],
                lastRead: initialState.lastRead,
            };
        /**
         * Modal
         */
        case InboxActionTypes.UNREAD_OPEN:
            return {
                ...state,
                unreadConversations: { ...initialState.unreadConversations, isOpen: action.payload },
            };
        case InboxActionTypes.SET_UNREAD_CONVERSATIONS:
            const modalConversations = {
                data: action.payload.init
                    ? action.payload.data
                    : [...state.unreadConversations.data, ...action.payload.data],
                pagination: action.payload.pagination,
                isOpen: state.unreadConversations.isOpen,
            };
            const modalConversationsEventData = clearEventsQueue(
                // General
                state.eventsQueue,
                state.badges,
                state.utils,
                // Inbox
                state.conversations,
                state.selectedConversation,
                state.messages,
                state.lastRead,
                // Modal
                modalConversations,
                state.modalSelectedConversation,
                state.modalMessages,
                state.modalLastRead,
            );
            return {
                ...state,
                badges: modalConversationsEventData.badges,
                conversations: modalConversationsEventData.conversations,
                selectedConversation: modalConversationsEventData.selectedConversation,
                messages: modalConversationsEventData.messages,
                lastRead: modalConversationsEventData.lastRead,
                unreadConversations: modalConversationsEventData.unreadConversations,
                modalSelectedConversation: modalConversationsEventData.modalSelectedConversation,
                modalMessages: modalConversationsEventData.modalMessages,
                modalLastRead: modalConversationsEventData.modalLastRead,
                eventsQueue: window.PUSH_EVENTS_IN_QUEUE === 0 ? initialState.eventsQueue : state.eventsQueue,
            };
        case InboxActionTypes.SELECT_MODAL_CONVERSATION:
            return {
                ...state,
                modalSelectedConversation: action.payload,
                unreadConversations: { ...state.unreadConversations, isOpen: false },
            };
        case InboxActionTypes.SET_MODAL_MESSAGES:
            const { lastRead: modalLastRead, ...modalMessages } = action.payload;
            const modalMessagesEventData = clearEventsQueue(
                // General
                state.eventsQueue,
                state.badges,
                state.utils,
                // Inbox
                state.conversations,
                state.selectedConversation,
                state.messages,
                state.lastRead,
                // Modal
                state.unreadConversations,
                state.modalSelectedConversation,
                modalMessages,
                modalLastRead,
            );
            return {
                ...state,
                badges: modalMessagesEventData.badges,
                conversations: modalMessagesEventData.conversations,
                selectedConversation: modalMessagesEventData.selectedConversation,
                messages: modalMessagesEventData.messages,
                lastRead: modalMessagesEventData.lastRead,
                unreadConversations: modalMessagesEventData.unreadConversations,
                modalSelectedConversation: modalMessagesEventData.modalSelectedConversation,
                modalMessages: {
                    ...state.modalMessages,
                    ...injectAssignmentHistory(
                        modalMessagesEventData.modalMessages,
                        state.modalAssignmentsHistory,
                        state.utils.timezone,
                    ),
                },
                modalLastRead: modalMessagesEventData.modalLastRead,
                eventsQueue: window.PUSH_EVENTS_IN_QUEUE === 0 ? initialState.eventsQueue : state.eventsQueue,
            };
        case InboxActionTypes.SET_MODAL_ASSIGNMENT_HISTORY:
            return {
                ...state,
                modalAssignmentsHistory: action.payload,
            };
        case InboxActionTypes.SET_MODAL_MESSAGES_READ:
            return {
                ...state,
                modalMessages: { ...state.modalMessages, allRead: action.payload },
            };
        case InboxActionTypes.CLEAR_MODAL_SELECTED_CONVERSATION:
            return {
                ...state,
                modalSelectedConversation: null,
                modalAssignmentsHistory: initialState.modalAssignmentsHistory,
            };
        case InboxActionTypes.CLEAR_MODAL_MESSAGES:
            return {
                ...state,
                modalMessages: initialState.modalMessages,
                modalAssignmentsHistory: [],
                modalLastRead: initialState.modalLastRead,
            };
        /**
         * Events
         */
        case InboxActionTypes.UPDATE_CONVERSATION:
            const { badges: newBadgesConversation, conversations: newConversationsConversation } =
                handleConversationEvent(state.badges, state.conversations, state.utils, action.payload);
            const { unreadConversations: newUnreadConversationsConversation } = handleUnreadConversationEvent(
                state.unreadConversations,
                newBadgesConversation,
                state.utils.userId,
                action.payload,
            );
            const {
                selectedConversation: newSelectedConversation,
                messages: newMessages,
                modalSelectedConversation: newModalSelectedConversation,
                modalMessages: newModalMessages,
            } = handleMessageEventInConversation(
                state.selectedConversation,
                state.messages,
                state.modalSelectedConversation,
                state.modalMessages,
                state.utils,
                action.payload,
            );
            return {
                ...state,
                badges: newBadgesConversation,
                conversations: newConversationsConversation,
                selectedConversation: newSelectedConversation,
                messages: newMessages,
                unreadConversations: newUnreadConversationsConversation,
                modalSelectedConversation: newModalSelectedConversation,
                modalMessages: newModalMessages,
            };
        case InboxActionTypes.UPDATE_MESSAGES:
            const { badges: newBadgesMessage, conversations: newConversationsMessage } = handleConversationEvent(
                state.badges,
                state.conversations,
                state.utils,
                action.payload,
            );
            const { unreadConversations: newUnreadConversationsMessage } = handleUnreadConversationEvent(
                state.unreadConversations,
                newBadgesMessage,
                state.utils.userId,
                action.payload,
            );
            const {
                messages: newMessagesMessage,
                lastRead: newLastReadMessage,
                selectedConversation: newSelectedConversationMessage,
            } = handleMessageEvent(
                state.messages,
                state.selectedConversation,
                state.lastRead,
                state.utils.timezone,
                action.payload,
            );
            const {
                messages: newModalMessagesMessage,
                lastRead: newModalLastReadMessage,
                selectedConversation: newModalSelectedConversationMessage,
            } = handleMessageEvent(
                state.modalMessages,
                state.modalSelectedConversation,
                state.modalLastRead,
                state.utils.timezone,
                action.payload,
            );
            return {
                ...state,
                badges: newBadgesMessage,
                conversations: newConversationsMessage,
                selectedConversation: newSelectedConversationMessage,
                messages: newMessagesMessage,
                lastRead: newLastReadMessage,
                unreadConversations: newUnreadConversationsMessage,
                modalSelectedConversation: newModalSelectedConversationMessage,
                modalMessages: newModalMessagesMessage,
                modalLastRead: newModalLastReadMessage,
            };
        case InboxActionTypes.UPDATE_MESSAGE_STATUS:
            const { messages: newMessagesStatus } = handleStatusEvent(
                state.messages,
                state.selectedConversation?.id,
                action.payload,
            );
            const { messages: newModalMessagesStatus } = handleStatusEvent(
                state.modalMessages,
                state.modalSelectedConversation?.id,
                action.payload,
            );
            return {
                ...state,
                messages: newMessagesStatus,
                modalMessages: newModalMessagesStatus,
            };
        // EVENTS QUEUE
        case InboxActionTypes.PUSH_IN_QUEUE:
            return {
                ...state,
                eventsQueue: [action.payload, ...state.eventsQueue],
            };
        case InboxActionTypes.CLEAR_QUEUE:
            // TODO should we handle events event if error occurs
            return {
                ...state,
                eventsQueue: initialState.eventsQueue,
            };
        default:
            return state;
    }
}
