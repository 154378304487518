import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMode } from 'react-hook-form';

const schema = yup.object({
    text: yup.string().when('media.type', {
        is: (type: string) => type === 'text',
        then: yup.string().trim().required().max(1000).label('Message'),
        otherwise: yup.string().max(1000).label('Message'),
    }),
    media: yup
        .object()
        .shape(
            {
                type: yup.string().required(),
                filename: yup.string().when('type', {
                    is: 'document',
                    then: yup
                        .string()
                        .max(25)
                        .required()
                        .matches(/^[A-Za-z0-9_]+$/, 'Only letters, numbers, and (_) are allowed')
                        .label('Document Name'),
                }),
                file: yup.object().when(['type', 'url'], {
                    is: (checker: string, url: string) => checker === 'text' || url,
                    then: yup.object(),
                    otherwise: yup
                        .object()
                        .required()
                        .test('file', 'File is a required field', (value) => {
                            return value?.media_id;
                        }),
                }),
                url: yup
                    .string()
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker !== 'text' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(/^https/, `URL should start with 'https'`)
                            .max(1000)
                            .label('Link'),
                    })
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker === 'document' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(
                                /\.(txt|doc|docx|dot|dotx|rtf|odt|odf|fodt|info|pdf|xps|pdax|eps|xls|xlsx|xltx|xlsm|csv|ods|fods)$/i,
                                'File extension can be only: .txt, .doc, .docx, .dot, .dotx, .rtf, .odt, .odf, .fodt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .xltx, .xlsm, .csv, .ods, .fods. maximum size 200 MB ',
                            )
                            .label('Link'),
                    })
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker === 'video' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(/\.(3gpp|m4v|mov|mp4)$/i, 'File extension can be only: .3gpp, .m4v, .mov, .mp4.')
                            .label('Link'),
                    })
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker === 'image' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(
                                /\.(jpg|jpeg|png|bmp|gif|svg|webp)$/i,
                                'File extension can be only: .bmp, .gif, .jpg, .jpeg, .png, .svg, .webp. maximum size 24 MB ',
                            )
                            .label('Link'),
                    }),
            },
            [['url', 'file']],
        )
        .test('media', 'A file attachment is required', (value) => {
            return value.type !== 'text' ? !!value.file || !!value.url : true;
        }),
    button_enabled: yup.boolean(),
    button_text: yup.string().when('button_enabled', {
        is: (buttonEnabled: boolean) => {
            return buttonEnabled;
        },
        then: yup.string().required().max(20).label('Button text'),
        otherwise: yup.string().max(20).label('Button text'),
    }),
    button_value: yup.string().when('button_enabled', {
        is: (buttonEnabled: boolean) => {
            return buttonEnabled;
        },
        then: yup.string().required().max(1000).label('Button Action URL'),
        otherwise: yup.string().max(1000).label('Button Action URL'),
    }),
});

const formProps = {
    mode: 'onChange' as keyof ValidationMode,
    resolver: yupResolver(schema),
    revalidateMode: 'onChange',
    defaultValues: {
        text: '',
        media: {
            type: 'text',
        },
        button_enabled: false,
    },
};

export { formProps };
