const ICON_TYPES = {
    INFO_CIRCLE: 'info_circle',
    INFO_CIRCLE_OUTLINE: 'info_circle_outline',
    CIRCLE_CHECK: 'circle_check',
    CIRCLE_CHECK_OUTLINE: 'circle_check_outline',
    ERROR: 'error',
    ERROR_OUTLINE: 'error_outline',
    OFF_CLOSE: 'off_close',
    OFF_CLOSE_OUTLINE: 'off_outline_close',
};

export { ICON_TYPES };
