import React, { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import { Empty, Select } from 'antd';

import { IDxDropdownProps } from 'types/components';
import { DxIcon, DxLoading, InputHint } from 'components/common';

import 'assets/scss/dxDropdown.scss';
import DxLink from '../Link/Link';

const renderFunction =
    ({ title, helperText, linkTo, linkText, customClassName, dataFetching, ...restProps }: any) =>
    ({ field, fieldState: { error } }: any) =>
        (
            <div className="dx-dropdown">
                {!isNil(title) && !isNil(linkText) && (
                    <span className="dx-dropdown-title-box">
                        <span className="dx-dropdown-title">{title}</span>
                        <DxLink appearance="link" to={linkTo}>
                            {linkText}
                        </DxLink>
                    </span>
                )}

                {!isNil(title) && isNil(linkText) && <span className="dx-dropdown-title">{title}</span>}
                <Select
                    filterOption={(input, { props: { label, value } }: any) =>
                        label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        (typeof value === 'string' && value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }
                    suffixIcon={<DxIcon type="chevron_down" size="md" />}
                    menuItemSelectedIcon={<DxIcon size="sm" type="check_big" />}
                    dropdownClassName={`dx-content ${customClassName || ''}`}
                    className="dx-select"
                    notFoundContent={
                        dataFetching ? <DxLoading size={32} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    {...field}
                    {...restProps}
                    value={field.value || undefined}
                />
                <InputHint error={error} helperText={helperText} />
            </div>
        );

const DxDropdown: FC<IDxDropdownProps> = ({ title, fieldName = '', ...restProps }) => {
    return <Controller name={fieldName} render={renderFunction({ title, ...restProps })} />;
};

export default memo(DxDropdown);
