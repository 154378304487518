import docImg from 'assets/images/left-navigation/documentation.svg';
import { IChannelsList, IMenuList } from 'types/components';
import { PRIVATE_ROUTES_ENUM } from '../../../constants/routes';

const channels: IChannelsList[] = [
    {
        channel: 'SMS',
        label: 'SMS',
        icon: 'sms',
        link: PRIVATE_ROUTES_ENUM.SMS_MY_CAMPAIGNS,
        isReady: true,
        enableInProd: true,
        viewRule: 'CAMPAIGNS',
    },
    {
        channel: 'WHATSAPP',
        label: 'WHATSAPP',
        icon: 'whatsapp',
        link: PRIVATE_ROUTES_ENUM.WHATSAPP,
        isReady: true,
        viewRule: 'CAMPAIGNS',
        subMenu: [
            {
                label: 'GET_ACCESS',
                link: PRIVATE_ROUTES_ENUM.WHATSAPP_GET_ACCESS,
                isReady: false,
                enableInProd: true,
                viewRule: 'SENDERS',
            },
            {
                label: 'WHATSAPP_TEMPLATES',
                link: PRIVATE_ROUTES_ENUM.WHATSAPP_MY_TEMPLATES,
                isReady: false,
                enableInProd: true,
                viewRule: 'TEMPLATES',
            },
            {
                label: 'WHATSAPP_MESSAGE',
                link: PRIVATE_ROUTES_ENUM.WHATSAPP_MY_CAMPAIGNS,
                isReady: false,
                enableInProd: true,
                viewRule: 'CAMPAIGNS',
            },
        ],
    },
    {
        channel: 'VIBER',
        label: 'VIBER',
        icon: 'viber',
        link: PRIVATE_ROUTES_ENUM.VIBER,
        isReady: true,
        viewRule: 'CAMPAIGNS',
        subMenu: [
            {
                label: 'GET_ACCESS',
                link: PRIVATE_ROUTES_ENUM.VIBER_GET_ACCESS,
                isReady: false,
                enableInProd: true,
                viewRule: 'SENDERS',
            },
            {
                label: 'VIBER_CAMPAIGN',
                link: PRIVATE_ROUTES_ENUM.VIBER_MY_CAMPAIGNS,
                isReady: false,
                enableInProd: true,
                viewRule: 'CAMPAIGNS',
            },
        ],
    },
];

const menuItems: IMenuList[] = [
    {
        label: 'ADD_CHANNEL',
        icon: 'plus',
        link: PRIVATE_ROUTES_ENUM.ADD_CHANNEL,
        isReady: true,
        withSpace: true,
        enableInProd: true,
        viewRule: 'CHANNELS',
    },
    {
        label: 'SENDERS',
        icon: 'id_card',
        link: PRIVATE_ROUTES_ENUM.SENDERS,
        isReady: true,
        viewRule: 'SENDERS',
        subMenu: [
            {
                label: 'SENDER_IDS',
                link: PRIVATE_ROUTES_ENUM.MY_SENDERS,
                isReady: true,
                enableInProd: true,
                viewRule: 'SENDERS',
            },
            {
                label: 'NUMBERS',
                link: PRIVATE_ROUTES_ENUM.MY_NUMBERS,
                isReady: true,
                enableInProd: true,
                viewRule: 'SENDERS',
            },
            {
                label: 'SHORT_CODES',
                link: PRIVATE_ROUTES_ENUM.MY_SHORT_CODES,
                isReady: true,
                enableInProd: true,
                viewRule: 'SENDERS',
            },
        ],
        enableInProd: true,
    },
    {
        label: 'MESSAGES',
        icon: 'chat_alt',
        link: PRIVATE_ROUTES_ENUM.LIBRARY,
        isReady: true,
        enableInProd: true,
        viewRule: 'TEMPLATES',
    },
    {
        label: 'CONTACTS',
        icon: 'group',
        link: PRIVATE_ROUTES_ENUM.CONTACTS,
        isReady: true,
        viewRule: 'AUDIENCES',
        subMenu: [
            {
                label: 'AUDIENCES',
                link: PRIVATE_ROUTES_ENUM.AUDIENCE,
                isReady: true,
                enableInProd: true,
                viewRule: 'AUDIENCES',
            },
            {
                label: 'STOP_LIST',
                link: PRIVATE_ROUTES_ENUM.STOP_LIST,
                isReady: true,
                enableInProd: true,
                viewRule: 'SENDERS',
            },
        ],
        enableInProd: true,
    },
    {
        label: 'INBOX',
        icon: 'chat',
        link: PRIVATE_ROUTES_ENUM.INBOX,
        isReady: true,
        viewRule: 'CONVERSATIONS',
        subMenu: [
            {
                label: 'INBOX_ASSIGNED_TO_ME',
                link: PRIVATE_ROUTES_ENUM.INBOX_ASSIGNED_TO_ME,
                isReady: true,
                enableInProd: true,
                viewRule: 'MESSAGES',
            },
            {
                label: 'INBOX_UNASSIGNED',
                link: PRIVATE_ROUTES_ENUM.INBOX_UNASSIGNED,
                isReady: true,
                enableInProd: true,
                viewRule: 'CONVERSATIONS',
            },
            {
                label: 'INBOX_ASSIGNED_TO_OTHERS',
                link: PRIVATE_ROUTES_ENUM.INBOX_ASSIGNED_TO_OTHERS,
                isReady: true,
                enableInProd: true,
                viewRule: 'CONVERSATIONS',
            },
        ],
        enableInProd: true,
    },
    {
        label: 'INSIGHTS',
        icon: 'bar_chart',
        link: PRIVATE_ROUTES_ENUM.INSIGHTS,
        isReady: true,
        enableInProd: true,
        viewRule: 'MESSAGES',
    },
    {
        label: 'CARRIER_LOOKUP',
        icon: 'carrier-lookup',
        link: PRIVATE_ROUTES_ENUM.CARRIER_LOOKUP,
        isReady: true,
        enableInProd: true,
        viewRule: 'SHORT_URLS',
    },
    {
        label: 'LINK_MANAGEMENT',
        icon: 'link_02',
        link: PRIVATE_ROUTES_ENUM.LINK_MANAGEMENT,
        isReady: true,
        enableInProd: true,
        viewRule: 'SHORT_URLS',
    },
    {
        label: 'APIS',
        icon: 'window_terminal',
        link: PRIVATE_ROUTES_ENUM.API_KEYS,
        isReady: true,
        enableInProd: true,
        viewRule: 'KEYS',
    },
];

const footerMenuItems: IMenuList[] = [
    {
        label: 'DOCUMENTATION',
        imgPath: docImg,
        link: 'https://developers.dexatel.com/',
        isReady: true,
        enableInProd: true,
        viewRule: 'CAMPAIGNS',
    },
    {
        label: 'USER_MANUAL',
        icon: 'bulb',
        link: 'https://help.dexatel.com/',
        isReady: true,
        enableInProd: true,
        viewRule: 'CAMPAIGNS',
    },
];

export { channels, menuItems, footerMenuItems };
