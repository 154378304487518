import React, { FC, memo } from 'react';
import { Tag } from 'antd';

import { IDxTagProps } from 'types/components';
import DxIcon from '../Icon/Icon';

import 'assets/scss/dxTag.scss';
import { DxTooltip } from '../index';

const DxTag: FC<IDxTagProps> = ({
    children,
    appearance,
    onClose,
    closable,
    disabled,
    tooltipMessage,
    tooltipPlacement,
    ...restProps
}) => {
    if (disabled && tooltipMessage) {
        return (
            <DxTooltip title={tooltipMessage} message={true} placement={tooltipPlacement}>
                <Tag
                    className={`dx-tag ${appearance}`}
                    closable={closable}
                    {...(closable ? { closeIcon: <DxIcon type="close_small" onClick={onClose} /> } : {})}
                    {...restProps}
                >
                    {children}
                </Tag>
            </DxTooltip>
        );
    }
    return (
        <Tag
            className={`dx-tag ${appearance}`}
            closable={closable}
            {...(closable ? { closeIcon: <DxIcon type="close_small" onClick={onClose} /> } : {})}
            {...restProps}
        >
            {children}
        </Tag>
    );
};

export default memo(DxTag);
