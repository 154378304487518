import React, { FC, useEffect, useState } from 'react';

import { DxButton, DxIcon, DxInputSearch, DxShowNotification, DxTable, DxTooltip } from '../../../components';
import { includedKeys } from '../../WhatsApp/Templates/constants';
import { ChannelInterface, DxWrapperModal } from '../../../components/common';
import { useAPIQuery, useDebounce, usePagination, useRequest } from '../../../hooks';
import { ResponsesHttpService, TemplatesHttpService } from '../../../services/http';
import useQuery from '../../../hooks/useQuery';
import { IConversationItem } from '../types';
import { IErrors } from '../../../types/validation';

interface IMessageTemplates {
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    channel: string;
    senderCode: string;
    selectedConversation: IConversationItem;
}
export const MessagesTemplates: FC<IMessageTemplates> = ({
    isModalVisible,
    setIsModalVisible,
    channel,
    senderCode,
    selectedConversation,
}) => {
    const [templateList, setTemplateList] = useState<any[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [search, setSearch] = useState('');
    const { updateQuery } = useQuery();
    const { doGetRequest, doPostRequest } = useRequest();
    const query = useAPIQuery();
    const [pagination, setPagination] = useState({
        number: 1,
        total: 1,
        size: 1,
    });
    const paginationProps = usePagination(pagination);

    const [templateData, setTemplateData] = useState<any>({});

    /**
     * Get templates data
     */
    useEffect(() => {
        if (isModalVisible) {
            const queryArr: string[] = [];
            queryArr.push(query || '?');
            queryArr.push('page_size=10');
            queryArr.push(`filter[channel]=${channel}`);
            queryArr.push(`filter[status]=COMPLETED`);
            queryArr.push(`filter[name]=${search}`);
            if (channel === 'whatsapp') {
                queryArr.push(`filter[sender_code]=${senderCode}`);
            }
            const requestFn = TemplatesHttpService.query(queryArr.join('&'));
            setLoading(true);
            doGetRequest(requestFn.request, {
                successCallback: (response) => {
                    setLoading(false);
                    setTemplateList(response.data);
                    if (response?.pagination) {
                        setPagination(response?.pagination);
                    }
                },
                errorCallback: () => {
                    setLoading(false);
                },
            });
            return () => {
                requestFn.cancel();
            };
        }
    }, [isModalVisible, search, query]);

    useEffect(() => {
        setTemplateList(null);
        setTemplateData({});
        setSearch('');
        updateQuery('', true);
    }, [isModalVisible]);

    const onSearch = useDebounce((e) => {
        setSearch(e.target.value);
    }, 700);

    const onSubmit = () => {
        if (templateData) {
            setSending(true);
            doPostRequest(ResponsesHttpService.createMessage().request, {
                requestBody: {
                    data: {
                        from: selectedConversation.sender_name,
                        to: [selectedConversation.number],
                        template: templateData.id,
                        channel,
                    },
                },
                successCallback: () => {
                    setSending(false);
                    setIsModalVisible(false);
                },
                errorCallback: (data) => {
                    const { errors } = data as IErrors;
                    errors?.map((error) => {
                        DxShowNotification('error', error?.message);
                    });
                    setSending(false);
                },
            });
        }
    };

    const onRowClick = (selectedItem: any) => ({
        onClick: () => {
            setTemplateList((prev) => {
                if (prev) {
                    return prev.map((item: any) => {
                        if (selectedItem.id === item.id) {
                            return { ...item, selectedRow: true };
                        }
                        return { ...item, selectedRow: false };
                    });
                }
                return prev;
            });
            setTemplateData(selectedItem);
        },
    });

    return (
        <DxWrapperModal
            footer={
                <div className="btn-wrapper">
                    <DxButton appearance={'default'} onClick={() => setIsModalVisible(false)}>
                        Cancel
                    </DxButton>

                    <DxButton disabled={!templateData.id} loading={sending} appearance={'primary'} onClick={onSubmit}>
                        <div>
                            <DxIcon type="send" size="sm" /> Reply
                        </div>
                    </DxButton>
                </div>
            }
            title={'Reply from Template'}
            visible={isModalVisible}
            className="templates-modal"
            onCancel={() => setIsModalVisible(false)}
        >
            <div>
                <div className="templates-table">
                    <div className={'search'}>
                        <DxInputSearch onChange={onSearch} />
                    </div>

                    {templateList ? (
                        <DxTable
                            emptyMessage={
                                search
                                    ? `You have not created any template with the searched name`
                                    : `You have not created any ${channel} Templates yet.`
                            }
                            columnsOrder={['name', 'text', 'create_date']}
                            pagination={paginationProps}
                            onRow={onRowClick}
                            includedKeys={includedKeys}
                            dataSource={templateList}
                            loading={loading}
                            keyRenderers={{
                                text: (text: string) => {
                                    return (
                                        <div className={'long-text-renderer'}>
                                            <DxTooltip title={text} message={true} placement="bottom">
                                                <span>{text}</span>
                                            </DxTooltip>
                                        </div>
                                    );
                                },
                            }}
                            rowKey={'id'}
                        />
                    ) : (
                        <DxTable dataSource={[]} emptyMessage={''} />
                    )}
                </div>
            </div>
            <div className="templates-interface">
                <ChannelInterface
                    text={templateData?.text}
                    channel={channel}
                    type={templateData?.type}
                    link={templateData?.url}
                    documentName={templateData?.filename}
                    btntext={templateData?.button_text}
                    btnType={templateData?.button_type}
                    media={templateData?.media_id ? { media_id: templateData.media_id, fetchMe: true } : null}
                />
            </div>
        </DxWrapperModal>
    );
};
