import React, { FC, memo } from 'react';
import { Upload } from 'antd';

import { Avatar, DxIcon } from 'components';

import 'assets/scss/avatarUpload.scss';

const AvatarUpload: FC<{ type: 'account' | 'user' }> = ({ type }) => (
    <div className="avatar-upload">
        <Avatar type={type} withUploader={true} />

        {/*<Upload className="avatar-upload-file">*/}
        {/*    <DxIcon type="photo" size="sm" />*/}
        {/*</Upload>*/}
    </div>
);

export default memo(AvatarUpload);
