import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { IDxFormItemProps } from 'types/components';

import 'assets/scss/dxFormItem.scss';

const DxFormItem: FC<IDxFormItemProps> = ({ additionalBox, withLeftSpace, children, ...rest }) => (
    <div
        className={classNames('dx-form-item', {
            'with-left-space': withLeftSpace === true,
            'additional-box': additionalBox === true,
        })}
        {...rest}
    >
        {children}
    </div>
);

export default memo(DxFormItem);
