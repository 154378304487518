import React, { memo } from 'react';
import { Tooltip } from 'antd';

import { IDxTooltipProps } from 'types/components';
import { backgroundColor } from './config';

import 'assets/scss/dxTooltip.scss';

const DxTooltip: React.FC<IDxTooltipProps> = ({ children, message, customClassName, ...restProps }) => (
    <Tooltip
        overlayClassName={`dx-tooltip ${message ? 'message' : ''} ${customClassName || ''} `}
        color={backgroundColor}
        {...restProps}
    >
        {children}
    </Tooltip>
);

export default memo(DxTooltip);
