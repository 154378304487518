import React, { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { Radio } from 'antd';
import { isNil } from 'lodash';

import { IDxRadioGroupProps } from 'types/components';
import { DxIcon, DxTooltip } from 'components/common';
import { ITranslationKey } from 'types/localization';
import { useLocalization } from 'hooks';
import 'assets/scss/dxRadioGroup.scss';

interface ITooltipInfo {
    tooltipInfo?: keyof ITranslationKey;
    customClassName?: string;
}

const TooltipInfo: FC<ITooltipInfo> = ({ tooltipInfo, customClassName }) => {
    const { t } = useLocalization();
    if (isNil(tooltipInfo)) return null;

    return (
        <DxTooltip customClassName={customClassName} title={t(tooltipInfo)}>
            <div className="dx-icon-outer">
                <DxIcon type="info_circle" />
            </div>
        </DxTooltip>
    );
};

const renderFunction =
    ({ options, direction, ...restProps }: any) =>
    ({ field }: any) =>
        (
            <Radio.Group
                className={`dx-radio-group ${direction === 'horizontal' ? 'horizontal' : ''} `}
                {...field}
                {...restProps}
            >
                {options.map((option: any, index: any) => (
                    <Radio key={index} className="dx-radio" {...option}>
                        {option.label}
                        <TooltipInfo customClassName={option.value} tooltipInfo={option.tooltipInfo} />
                    </Radio>
                ))}
            </Radio.Group>
        );

const DxRadioGroup: FC<IDxRadioGroupProps> = ({ fieldName = '', options, direction = 'vertical', ...restProps }) => (
    <Controller name={fieldName} render={renderFunction({ options, direction, ...restProps })} />
);

export default memo(DxRadioGroup);
