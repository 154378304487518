import React, { FC, memo } from 'react';
import { Switch, SwitchProps } from 'antd';

import 'assets/scss/dxSwitch.scss';
import { DxTooltip } from '../index';

interface DxSwitchProps extends SwitchProps {
    tooltipMessage?: string;
}

const DxSwitcher: FC<DxSwitchProps> = ({ disabled, tooltipMessage, ...restProps }) => {
    if (disabled && tooltipMessage) {
        return (
            <DxTooltip title={tooltipMessage} message={true}>
                <span style={{ cursor: 'not-allowed' }}>
                    <div style={{ pointerEvents: 'none' }} className="dx-switch">
                        <Switch disabled={disabled} {...restProps} />
                    </div>
                </span>
            </DxTooltip>
        );
    }
    return (
        <div className="dx-switch">
            <Switch disabled={disabled} {...restProps} />
        </div>
    );
};

export default memo(DxSwitcher);
