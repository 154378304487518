import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Linkify from 'react-linkify';

import { IChannelInterface } from 'types/components';
import { useMount, useRequest } from 'hooks';
import { IAccountState, IRootReducer } from '../../types/reducers';

import phoneDetails from 'assets/images/whatsapp/phone-details.svg';
import backBtnViber from 'assets/images/channel-interface/viber-back-btn.svg';
import backBtnWhatsapp from 'assets/images/channel-interface/whatsapp-back-btn.svg';
import infoIcon from 'assets/images/channel-interface/viber-info-icon.svg';
import verifiedIcon from 'assets/images/channel-interface/verified-icon.svg';
import smsUser from 'assets/images/channel-interface/sms-user.svg';
import 'assets/scss/channelInterface.scss';
import { DxLoading } from '../common';
import { MediaHttpService } from '../../services/http';
import { ICancelableRequest } from '../../types/services/http.config';
import Avatar from '../common/Avatar';
import { ViberMessageContent } from './ViberMessageContent';
import { WhatsAppMessageContent } from './WhatsAppMessageContent';

const ChannelInterface: FC<IChannelInterface> = ({
    text,
    channel,
    sender,
    type,
    link,
    documentName,
    btntext,
    media,
    btnType,
    customCallback,
}) => {
    const { companyName: companyNameRedux } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    const { doGetRequest } = useRequest();
    /**
     * Media service
     */
    const mediaService = useRef({
        getSingleMedia: (id: string) => MediaHttpService.getSingleMedia(id),
    }).current;
    const [awsURL, setAwsURL] = useState<string>('');
    const buttonType = useMemo(() => {
        return btnType?.toUpperCase() || '';
    }, [btnType]);
    /**
     * If we have MediaFile uploaded we have to fetch it first to get a URL
     */
    useEffect(() => {
        let requestFn: ICancelableRequest | undefined;
        setAwsURL('');
        if (media?.fetchMe && !media.ready) {
            requestFn = mediaService.getSingleMedia(media.media_id);
            doGetRequest(requestFn.request, {
                successCallback: (response) => {
                    setAwsURL(response.type === 'video' ? response.thumbnail_url : response.get_url);
                    if (customCallback) {
                        customCallback(response);
                    }
                },
                errorCallback: (error) => {
                    console.log('error', error);
                },
            });
        }
        return () => {
            if (requestFn) {
                requestFn.cancel();
            }
        };
    }, [media]);
    const getTime = () => {
        const date = new Date();
        const zeroSetter = (time: string | number) => {
            if (time < 10) {
                time = '0' + time.toString();
            }
            return time;
        };

        return zeroSetter(date.getHours()) + ':' + zeroSetter(date.getMinutes());
    };
    const [time, setTime] = useState(getTime);

    useMount(() => {
        setInterval(() => {
            setTime(getTime);
        }, 1000);
    });

    const [companyName, setCompanyName] = useState<string>('');

    useEffect(() => {
        setCompanyName(companyNameRedux);
    }, [sender]);

    return (
        <div
            className={`channel-interface ${
                channel === 'sms' ? 'sms' : channel === 'viber' ? 'viber-msg' : 'whatsapp-msg'
            } `}
        >
            <div className="channel-interface-inner">
                <div className="channel-interface-top">
                    <div className="channel-interface-details">
                        <p className="channel-interface-time">{time}</p>
                        <img src={phoneDetails} alt="Phone details" />
                    </div>
                </div>
                {channel === 'viber' && (
                    <>
                        <div className="channel-interface-details">
                            <div className="channel-interface-details-left">
                                <img src={backBtnViber} alt="Back" />
                                <div className="channel-interface-company">
                                    <span className="channel-interface-company-name">
                                        {sender ? sender : companyName}
                                    </span>
                                </div>
                                <img src={infoIcon} alt="Info icon" />
                            </div>
                        </div>
                        <ViberMessageContent
                            text={text}
                            type={type}
                            link={link}
                            media={media}
                            documentName={documentName}
                            awsURL={awsURL}
                            btntext={btntext}
                            time={time}
                            withAvatar={true}
                        />
                    </>
                )}
                {channel === 'whatsapp' && (
                    <>
                        <div className="channel-interface-details">
                            <div className="channel-interface-details-left">
                                <img src={backBtnWhatsapp} alt="Back" />
                                <div className="channel-interface-name">
                                    <Avatar type="user" withUploader={true} />
                                </div>
                                <div className="channel-interface-company">
                                    <span className="channel-interface-company-name">
                                        {sender ? sender : companyName}
                                    </span>
                                </div>
                                <img src={verifiedIcon} alt="Verified icon" />
                            </div>
                        </div>
                        <WhatsAppMessageContent
                            text={text}
                            type={type}
                            link={link}
                            media={media}
                            documentName={documentName}
                            awsURL={awsURL}
                            btntext={btntext}
                            btnType={buttonType}
                            time={time}
                        />
                    </>
                )}
                {channel === 'sms' && (
                    <>
                        <div className="channel-interface-details">
                            <div className="channel-interface-details-left">
                                <img src={backBtnWhatsapp} alt="Back" />
                                <div className="channel-interface-company">
                                    <img width={42} height={42} src={smsUser} alt="User" />
                                    <span className="channel-interface-company-name">
                                        {sender ? sender : companyName}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`channel-interface-content`}>
                            <div className="channel-interface-today">Today {time}</div>
                            <div className="channel-interface-msg-box">
                                {text && (
                                    <div className="channel-interface-message">
                                        <div className="channel-interface-text">
                                            <Linkify>{text}</Linkify>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export const ChannelImage = ({ type = 'external', link, uploading, mediaReady, onDownload, downloading }: any) => {
    const [fileLoaded, setFileLoaded] = useState(false);
    useEffect(() => {
        setFileLoaded(false);
    }, [link]);
    const handleFileLoaded = (e: any) => {
        if (mediaReady) {
            setFileLoaded(true);
        }
    };

    if (type === 'external') {
        return (
            <div
                onClick={() => {
                    if (onDownload) {
                        onDownload();
                    }
                }}
                className={`${onDownload && !downloading ? 'downloadable' : ''} ${downloading ? 'downloading' : ''}`}
            >
                {downloading && <DxLoading />}
                <img alt={'template-image'} src={link} width="200" height="120" />
            </div>
        );
    }
    return (
        <div className={`channel-interface-img-outer ${!fileLoaded && uploading ? 'with-overlay' : ''}`}>
            {!fileLoaded && uploading && <DxLoading />}
            <img alt={'template-image'} onLoad={handleFileLoaded} src={link} width="200" height="120" />
        </div>
    );
};

export const ChannelVideo = ({ type = 'external', link, uploading, mediaReady, onDownload, downloading }: any) => {
    if (type === 'external') {
        return (
            <div
                onClick={() => {
                    if (onDownload) {
                        onDownload();
                    }
                }}
                className={`${onDownload && !downloading ? 'downloadable' : ''} ${downloading ? 'downloading' : ''}`}
            >
                {downloading && <DxLoading />}

                <video
                    src={
                        link.includes('www.youtube.com')
                            ? link
                                  .replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
                                  .split('&')[0]
                            : link
                    }
                />
            </div>
        );
    }
    return (
        <div className={`channel-interface-video-box ${uploading ? 'with-overlay' : ''}`}>
            <img alt={'template-video'} src={link} width="200" height="120" />
            {uploading && (
                <div className="channel-interface-video-info">Video thumbnail will be available after saving</div>
            )}
        </div>
    );
};

export default memo(ChannelInterface, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});
