import { PRIVATE_ROUTES_VALUES, PUBLIC_ROUTES_VALUES } from '../../constants/routes';

export const getRouteForEdit = (route: string) => {
    return route.substring(route.indexOf(':'), -1);
};

const oneOfEditRoutes = [
    '/senders/request-short-code/',
    '/library/edit/',
    '/library/viber-edit/',
    '/contacts/rename-audience/',
    '/contacts/add-contact/',
    '/apis/edit/',
    '/settings/users/',
];

export const isKnownRoute = (route: string, privateRoute: boolean): boolean => {
    if (privateRoute) {
        for (const editRoute of oneOfEditRoutes) {
            if (route.includes(editRoute)) {
                return true;
            }
        }
        return PRIVATE_ROUTES_VALUES.includes(route);
    } else {
        return route.includes('/reset-password/') || PUBLIC_ROUTES_VALUES.includes(route);
    }
};
