import React, { memo, FC } from 'react';

import { useLocalization } from 'hooks';

import { DxCard, OrderedList } from 'components/index';
import { ChannelAdvantages } from 'pages/Dashboard/config';

const MainAdvantages: FC<any> = ({ channel }) => {
    const { t } = useLocalization();

    const advantages = ChannelAdvantages[channel];

    return (
        <DxCard withShadow={true}>
            <h2 className="homepage-card-title">
                {t(advantages.title)} <strong>{t(advantages.channel)}</strong>
            </h2>
            <OrderedList data={advantages.items} />
        </DxCard>
    );
};

export default memo(MainAdvantages);
