import React, { FC, memo } from 'react';
import { Input } from 'antd';

import { ISearchInputProps } from 'types/components/input';
import { DxIcon } from '../index';

import 'assets/scss/dxInput.scss';

interface IInputSearch extends ISearchInputProps {
    customPlaceholder?: string;
}

const InputSearch: FC<IInputSearch> = ({ value, onChange, customPlaceholder = 'Search' }) => (
    <Input
        suffix={<DxIcon type={'search'} size="sm" />}
        className={'dx-search-input'}
        placeholder={customPlaceholder}
        onChange={onChange}
        value={value}
        allowClear={true}
    />
);

export default memo(InputSearch);
