import React, { FC, memo, useCallback } from 'react';
import { Steps } from 'antd';

import { useLocalization } from 'hooks';
import { IStepProps } from 'types/components';

import 'assets/scss/dxSteps.scss';

const { Step } = Steps;

const DxSteps: FC<IStepProps> = ({ list, translate = true, handleStepClick, ...rest }) => {
    const { t } = useLocalization();

    const onStepClick = useCallback(
        (step: number) => {
            if (handleStepClick) {
                handleStepClick(step);
            }
        },
        [handleStepClick],
    );

    return (
        <div className="dx-steps">
            <Steps {...rest}>
                {list.map(({ title, icon }, index) => (
                    <Step title={translate ? t(title) : title} key={index} onStepClick={onStepClick} />
                ))}
            </Steps>
        </div>
    );
};

export default memo(DxSteps);
