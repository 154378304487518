export const getImageSizes = (file: File | null, callback: (width: number, height: number) => void): void => {
    if (!file) {
        return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const img = document.createElement('img');
        if (reader?.result && typeof reader.result === 'string') {
            img.src = reader.result;
            img.onload = () => {
                callback(img.width, img.height);
            };
        }
    };
    reader.onerror = (error) => {
        // tslint:disable-next-line:no-console
        console.log('Error: ', error);
    };
};
