import { ICancelableRequest } from '../../types/services/http.config';
import { GetawayAPIService, getCancelableRequest, putCancelableRequest } from './http.config';
import { TID } from '../../types/services/apiKey';

const getBlacklistedCountries = (): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/country_blacklist`,
    });
};

const updateBlacklistedCountries = (id: TID): ICancelableRequest => {
    return putCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/country_blacklist/${id}`,
    });
};

const methods = { getBlacklistedCountries, updateBlacklistedCountries };

export default methods;
