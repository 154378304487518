import { GetawayAPIService, postCancelableRequest } from './http.config';
import { ICancelableRequest } from 'types/services/http.config';

const MessagesHttpService = {
    smsTrial: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/messages`,
        });
    },
};

export default MessagesHttpService;
