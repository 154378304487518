const BTN_APPEARANCE = {
    PRIMARY: 'primary',
    DEFAULT: 'default',
    DANGER: 'danger',
    SUCCESS: 'success',
    TEXT: 'text',
    LINK: 'link',
    SECONDARY: 'secondary',
};

const BTN_SIZE = {
    SMALL: 'small',
    MIDDLE: 'middle',
    LARGE: 'large',
};

export { BTN_APPEARANCE, BTN_SIZE };
