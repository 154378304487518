import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import HEADERS_BY_ROUTE from 'constants/routeHeader';
import { useLocalization } from 'hooks';

function useHeaderMessage() {
    const { pathname } = useLocation();
    const { t } = useLocalization();

    return useMemo(() => {
        const key = HEADERS_BY_ROUTE[pathname] || HEADERS_BY_ROUTE.default;
        return t(key);
    }, [pathname, t]);
}

export default useHeaderMessage;
