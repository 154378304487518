import Linkify from 'react-linkify';
import docPlaceholder from '../../assets/images/channel-interface/doc-placeholder.png';
import PlaceholderImage from '../../assets/images/media/image-placeholder.png';
import PlaceholderVideo from '../../assets/images/media/video-placeholder.png';
import { DxButton, DxLoading } from '../common';
import React, { FC } from 'react';
import { ChannelImage, ChannelVideo } from './ChannelInterface';
import Avatar from '../common/Avatar';

interface IViberMessageContent {
    text?: string;
    type?: string;
    link?: string;
    media?: any;
    documentName?: string;
    awsURL?: string;
    btntext?: string;
    time?: string;
    withAvatar?: boolean;
    onDownload?: () => void;
    downloading?: boolean;
}

export const ViberMessageContent: FC<IViberMessageContent> = ({
    text,
    type,
    link,
    media,
    documentName,
    awsURL,
    btntext,
    time,
    withAvatar,
    onDownload,
    downloading,
}) => {
    return (
        <div className={`channel-interface-content viber ${type === 'document' && media ? 'document' : ''}`}>
            {time && <div className="channel-interface-today">Today</div>}
            <div className="channel-interface-msg-box">
                {withAvatar && (
                    <div className="channel-interface-name">
                        <Avatar type="user" withUploader={true} />
                    </div>
                )}
                <div className="channel-interface-message">
                    {text && (
                        <div className="channel-interface-text">
                            <Linkify>{text}</Linkify>
                        </div>
                    )}
                    {/*When link is used*/}
                    {type === 'image' && link && (
                        <ChannelImage downloading={downloading} link={link} onDownload={onDownload} />
                    )}
                    {type === 'video' && link && (
                        <ChannelVideo downloading={downloading} link={link} onDownload={onDownload} />
                    )}
                    {type === 'document' && (media || link) && (
                        <>
                            <div
                                onClick={() => {
                                    if (onDownload) {
                                        onDownload();
                                    }
                                }}
                                className={`${onDownload && !downloading ? 'downloadable' : ''} ${
                                    downloading ? 'downloading' : ''
                                }`}
                            >
                                {downloading && <DxLoading />}
                                <img src={docPlaceholder} alt="Document placeholder" />
                            </div>

                            {documentName && <div className="channel-interface-doc-name">{documentName}</div>}
                            <a
                                className="channel-interface-doc-link"
                                href={link || media?.get_url || awsURL}
                                target={'_blank'}
                            >
                                Open
                            </a>
                        </>
                    )}

                    {/*When file is provided*/}
                    {type === 'image' && media && (
                        <ChannelImage
                            type={'internal'}
                            link={media?.get_url || awsURL || PlaceholderImage}
                            uploading={!!media.file}
                            mediaReady={!!(media?.get_url || awsURL)}
                        />
                    )}
                    {type === 'video' && media && (
                        <ChannelVideo
                            type={'internal'}
                            link={media?.thumbnail_url || awsURL || PlaceholderVideo}
                            uploading={!!media.file}
                            mediaReady={!!(media?.get_url || awsURL)}
                        />
                    )}

                    {btntext && (
                        <DxButton className={`cta-button viber`} appearance={'primary'}>
                            {btntext}
                        </DxButton>
                    )}
                    {time && <p className="channel-interface-time">{time}</p>}
                </div>
            </div>
        </div>
    );
};
