import React, { ReactNode } from 'react';
import { notification } from 'antd';

import { INotificationProps } from 'types/components/notification';
import { DxIcon } from 'components/common';
import { getNotificationData } from './utils';

import 'assets/scss/dxNotification.scss';

const DxShowNotification = (
    type: INotificationProps,
    message: ReactNode,
    description?: string | ReactNode,
    topSpace: number = 80,
) => {
    const [customClassName, iconType] = getNotificationData(type);

    return notification[type]({
        className: `dx-notification ${customClassName}`,
        message,
        description,
        top: topSpace,
        icon: <DxIcon size="md" type={iconType} />,
        closeIcon: <DxIcon size="md" type="close_small" />,
    });
};

export { DxShowNotification };
