import React, { FC, memo, useMemo } from 'react';

import { DxAlert, DxLink } from 'components/common';
import { IGetAccess } from 'types/components';
import { useLocalization } from 'hooks';
import 'assets/scss/accessBox.scss';
import { useSelector } from 'react-redux';
import { IAccountState, IRootReducer } from '../../../types/reducers';
import { makePermissionRequiredMessage } from '../../../services/utils/helper';

const GetAccessView: FC<IGetAccess> = ({
    disabled,
    title,
    description,
    steps,
    subtitle,
    imgSrc,
    to,
    addDescription,
    isAccountNew,
}) => {
    const { t } = useLocalization();
    /**
     * Permissions set
     */
    const { permissions } = useSelector<IRootReducer, IAccountState>((state) => state.account);

    const canAdd = useMemo(() => {
        return permissions.SENDERS?.canAdd;
    }, [permissions]);

    return (
        <div className="access-box">
            <div className="access-box-content">
                {(disabled || !canAdd) && (
                    <div className="access-box-warning">
                        <DxAlert
                            appearance="warning"
                            showIcon={true}
                            message={!canAdd ? 'You cannot request Sender ID' : isAccountNew && t('GET_ACCESS_WARNING')}
                        />
                    </div>
                )}

                <h1 className="access-box-title">{title}</h1>
                <p className="access-box-desc main-desc">{description}</p>
                {addDescription && <p className="access-box-desc">{addDescription}</p>}
                <h2 className="access-box-sub-title">{subtitle}</h2>
                <ul className="access-box-list">
                    {steps.map((item: string, index: number) => (
                        <li className="access-box-list-item" key={index}>
                            {item}
                        </li>
                    ))}
                </ul>
                <DxLink
                    tooltipMessage={makePermissionRequiredMessage('Add', 'SENDERS')}
                    disabled={disabled || !canAdd}
                    appearance="primary"
                    to={to}
                >
                    Start Now
                </DxLink>
            </div>
            <img className="access-box-img" src={imgSrc} alt={title} width={180} />
        </div>
    );
};

export default memo(GetAccessView);
