import { ICancelableRequest } from '../../types/services/http.config';
import {
    deleteCancelableRequest,
    ExternalAPIService,
    GetawayAPIService,
    getCancelableRequest,
    postCancelableRequest,
    putCancelableRequest,
} from './http.config';

const getAWSUploadURL = (): ICancelableRequest => {
    return postCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/media`,
    });
};

const uploadToAWS = (awsURL: string, contentType: string): ICancelableRequest => {
    ExternalAPIService.defaults.baseURL = awsURL;
    return putCancelableRequest({
        requestService: ExternalAPIService,
        endpointPath: () => '',
        headers: {
            'Content-Type': contentType,
        },
    });
};

const deleteFromAWS = (awsURL: string): ICancelableRequest => {
    ExternalAPIService.defaults.baseURL = awsURL;
    return deleteCancelableRequest({
        requestService: ExternalAPIService,
        endpointPath: () => '',
    });
};

const getSingleMedia = (id: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/media/${id}`,
    });
};

const methods = { getAWSUploadURL, uploadToAWS, deleteFromAWS, getSingleMedia };

export default methods;
