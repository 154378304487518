import { DxShowNotification } from '../../components';

function ErrorCallback(rpsLimit = false) {
    const topPadding = localStorage.getItem('accessToken') ? 80 : 24;
    rpsLimit
        ? DxShowNotification('error', 'Too many requests', null, topPadding)
        : DxShowNotification('error', 'Something went wrong', null, topPadding);
}

export default ErrorCallback;
