import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DxFileUpload, DxIcon, DxInput, DxLoading } from '../common';
import { Col } from 'antd';
import { MediaHttpService } from '../../services/http';
import { useRequest } from '../../hooks';
import { usePrevious } from '../../hooks/usePrevious';
import { useSelector } from 'react-redux';
import { IAccountState, IRootReducer } from '../../types/reducers';
import PlaceholderImage from 'assets/images/media/image-placeholder.png';
import PlaceholderVideo from 'assets/images/media/video-placeholder.png';
import PlaceholderDocument from 'assets/images/media/document-placeholder.svg';

export const TemplateMedia = ({
    disabled,
    setValue,
    type,
    file,
    bucketDirectory,
    channel,
    error,
    clearErrors,
    setFileUploading,
}: any) => {
    const { doPostRequest, doPutRequest } = useRequest();
    const prevType = usePrevious(type);
    /**
     * Permissions set
     */
    const { permissions } = useSelector<IRootReducer, IAccountState>((state) => state.account);

    const canAdd = useMemo(() => {
        return permissions.MEDIA?.canAdd;
    }, [permissions]);
    /**
     * Media service
     */
    const media = useRef({
        getUploadURL: MediaHttpService.getAWSUploadURL(),
        uploadToAWS: (url: string, contentType: string) => MediaHttpService.uploadToAWS(url, contentType),
        deleteFromAWS: (url: string) => MediaHttpService.deleteFromAWS(url),
    }).current;

    const fileValidation = useMemo(() => {
        switch (type) {
            case 'image':
                return channel === 'viber' ? '.bmp, .gif, .jpg, .jpeg, .png, .svg, .webp' : '.jpg, .jpeg, .png';
            case 'video':
                return channel === 'viber' ? '.3gpp, .m4v, .mov, .mp4' : '.mp4, .3gp';
            case 'document':
                return channel === 'viber'
                    ? '.txt, .doc, .docx, .dot, .dotx, .rtf, .odt, .odf, .fodt, .info, .pdf, .xps, .pdax, .eps,.xls, .xlsx, .xltx, .xlsm, .csv, .ods, .fods'
                    : '.pdf';
        }
    }, [type]);

    const filePlaceholder = useMemo(() => {
        switch (type) {
            case 'image':
                return 'https://example.com/example.jpg';
            case 'video':
                return 'https://example.com/example.mp4';
            case 'document':
                return 'https://example.com/example.pdf';
        }
    }, [type]);

    /**
     * Cancel ongoing uploads
     */
    const abortController = useRef<any>(null);
    useEffect(() => {
        if (prevType && prevType !== type) {
            removeFile();
        }
    }, [type]);

    const removeFile = (clearFile?: boolean) => {
        if (abortController.current) {
            setFileUploading(false);
            abortController.current.cancel();
            abortController.current = null;
        }
        if (clearFile) {
            setValue('media.file', undefined, { shouldValidate: true });
        }
    };

    useEffect(() => {
        /**
         * Uploading case
         */
        if (file?.file && !file.ready && !file.uploading) {
            file.uploading = true;
            setFileUploading(true);
            doPostRequest(media.getUploadURL.request, {
                requestBody: {
                    data: {
                        file_name: file.file_name,
                        type,
                        content_type: file.file?.type,
                        directory: bucketDirectory,
                    },
                },
                successCallback: (response) => {
                    abortController.current = media.uploadToAWS(response.put_url, file.file?.type);
                    doPutRequest(abortController.current.request, {
                        requestBody: file?.file,
                        successCallback: () => {
                            setFileUploading(false);
                            setValue(
                                'media.file',
                                {
                                    ...file,
                                    get_url: response.get_url,
                                    media_id: response.id,
                                    thumbnail_url: response?.thumbnail_url,
                                    ready: true,
                                },
                                { shouldValidate: true },
                            );
                        },
                    });
                },
                errorCallback: () => {
                    setFileUploading(false);
                },
            });
        }
    }, [file]);

    if (type === 'text') {
        return <></>;
    }

    return (
        <>
            <div className={'template-media-box'}>
                <p className={'template-media-label'}>File</p>
                {file ? (
                    <div className={'template-media-file-box'}>
                        <div className={'template-media-left'}>
                            <div
                                className={`template-media-file-img ${
                                    type !== 'document' && (file?.get_url || file.thumbnail_url)
                                        ? 'background-black'
                                        : ''
                                } ${type !== 'document' ? 'with-border' : ''}`}
                            >
                                {type === 'image' && (
                                    <TemplateMediaPreview
                                        type={'image'}
                                        link={file?.get_url || PlaceholderImage}
                                        mediaReady={!!file?.get_url}
                                        uploading={!!file?.file}
                                    />
                                )}
                                {type === 'video' && (
                                    <TemplateMediaPreview
                                        type={'video'}
                                        link={file?.thumbnail_url || PlaceholderVideo}
                                        mediaReady={!!file?.get_url}
                                        uploading={!!file?.file}
                                    />
                                )}
                                {type === 'document' && (
                                    <TemplateMediaPreview
                                        type={'document'}
                                        link={PlaceholderDocument}
                                        mediaReady={!!file?.get_url}
                                        uploading={!!file?.file}
                                    />
                                )}
                            </div>
                            <p className={'template-media-name'}>{file.file_name || 'File...'}</p>
                        </div>
                        <div className={`template-media-right ${(disabled || !canAdd) && 'disabled'}`}>
                            <DxFileUpload
                                setValidFile={() => {}}
                                simpleButton={true}
                                desc={'Replace'}
                                setFile={() => {}}
                                customCallback={(file: any) => {
                                    if (file) {
                                        removeFile(true);
                                        clearErrors('media');
                                        setValue('media.file', { file, file_name: file?.name || type });
                                    }
                                }}
                                skipRegExCheck={true}
                                accept={fileValidation}
                                maxSize={200_000_000}
                            />
                            <div onClick={() => removeFile(true)} className={'remove-file'}>
                                <DxIcon type={'trash_full'} size={'md'} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Col span={24} className={`${(disabled || !canAdd) && 'disabled'}`}>
                        <DxFileUpload
                            setValidFile={() => {}}
                            setFile={() => {}}
                            customCallback={(file: any) => {
                                if (file) {
                                    removeFile();
                                    clearErrors('media');
                                    setValue('media.file', { file, file_name: file?.name || type });
                                }
                            }}
                            skipRegExCheck={true}
                            accept={fileValidation}
                            desc={'Click or drag file to upload'}
                            horizontal={true}
                            fileType={`(${fileValidation}) max. 200MB`}
                            maxSize={200_000_000}
                        />
                    </Col>
                )}
                <div className="divider">
                    <p className="divider-txt">or</p>
                </div>
                <Col span={24}>
                    <DxInput
                        readOnly={!!file}
                        placeholder={filePlaceholder}
                        label={'Enter file URL'}
                        fieldName="media.url"
                        onChange={(e) => {
                            clearErrors('media');
                            setValue('media.url', e.target.value, { shouldValidate: true });
                        }}
                        allowClear={true}
                    />
                </Col>
            </div>
            {error?.message && <span className={'template-media-error'}>{error.message}</span>}
        </>
    );
};

const TemplateMediaPreview = ({ type, link, uploading, mediaReady }: any) => {
    const [fileLoaded, setFileLoaded] = useState(false);
    useEffect(() => {
        setFileLoaded(false);
    }, [link]);
    const handleFileLoaded = (e: any) => {
        if (mediaReady) {
            setFileLoaded(true);
        }
    };

    if (type === 'image') {
        return (
            <div className={`template-media-img-box ${!fileLoaded && uploading ? 'with-overlay' : ''}`}>
                {!fileLoaded && uploading && <DxLoading />}
                <img onLoad={handleFileLoaded} src={link} alt={'template-image'} />
            </div>
        );
    }
    if (type === 'video') {
        return (
            <div className={`template-media-img-box ${!mediaReady && uploading ? 'with-overlay' : ''}`}>
                {!mediaReady && uploading && <DxLoading />}
                <img src={link} alt={'template-video'} />
            </div>
        );
    }
    return (
        <div className={`template-media-img-box ${!mediaReady && uploading ? 'with-overlay' : ''}`}>
            {!mediaReady && uploading && <DxLoading />}
            <img src={link} alt={'template-doc'} />
        </div>
    );
};
