import React, { FC, memo } from 'react';
import { DatePicker, DatePickerProps } from 'antd';

import 'assets/scss/dxDatepicker.scss';
import { DxIcon } from 'components';

const DxDatePicker: FC<DatePickerProps> = ({ onChange, ...rest }) => {
    // function disabledDate(current: any | undefined) {
    //     // Can not select days before today and today
    //     return current <= moment().endOf('day');
    // }

    return (
        <div className="dx-datepicker-box basic">
            <DatePicker
                clearIcon={<DxIcon size="sm" type={'close_small'} />}
                suffixIcon={<DxIcon size="sm" type={'calendar'} />}
                onChange={onChange}
                dropdownClassName="dx-datepicker"
                {...rest}
            />
        </div>
    );
};
export default memo(DxDatePicker);
