import routes from './routes';
import { IHeaderKeys } from 'types/localization';

type IHeaderMessage = {
    [key: string]: keyof IHeaderKeys;
};

const HEADERS_BY_ROUTE: IHeaderMessage = {
    [routes.DASHBOARD]: 'HEADER_DASHBOARD',
    [routes.WELCOME_DASHBOARD]: 'HEADER_DASHBOARD',
    [routes.SMS_MY_CAMPAIGNS]: 'HEADER_SMS',
    [routes.WHATSAPP_GET_ACCESS]: 'HEADER_WHATSAPP',
    [routes.WHATSAPP_MY_SENDERS]: 'HEADER_WHATSAPP',
    [routes.WHATSAPP_MY_CAMPAIGNS]: 'HEADER_WHATSAPP',
    [routes.WHATSAPP_MY_TEMPLATES]: 'HEADER_WHATSAPP',
    [routes.VIBER]: 'HEADER_VIBER',
    [routes.VIBER_GET_ACCESS]: 'HEADER_VIBER',
    [routes.VIBER_MY_SENDERS]: 'HEADER_VIBER',
    [routes.VIBER_MY_CAMPAIGNS]: 'HEADER_VIBER',
    [routes.INSIGHTS]: 'HEADER_INSIGHTS',
    [routes.SECURITY]: 'HEADER_SECURITY',
    [routes.PAYMENTS]: 'HEADER_BILLING',
    [routes.RATES]: 'HEADER_BILLING',
    [routes.LIBRARY]: 'HEADER_LIBRARY',
    [routes.PROFILE]: 'HEADER_PROFILE',
    [routes.API_KEYS]: 'HEADER_APIS',
    [routes.ALERTS]: 'HEADER_ALERTS',
    [routes.SMS_LIMITS]: 'HEADER_ALERTS',
    [routes.MY_SENDERS]: 'HEADER_SENDERS',
    [routes.MY_NUMBERS]: 'HEADER_SENDERS',
    [routes.MY_SHORT_CODES]: 'HEADER_SENDERS',
    [routes.AUDIENCE]: 'HEADER_CONTACTS',
    [routes.STOP_LIST]: 'HEADER_CONTACTS',
    [routes.RESPONSES]: 'HEADER_RESPONSES',
    [routes.INVOICES]: 'HEADER_BILLING',
    [routes.AUTO_TOP_UP]: 'HEADER_BILLING',
    [routes.LINK_MANAGEMENT]: 'HEADER_LINK_MANAGEMENT',
    [routes.SUBACCOUNTS]: 'HEADER_SUBACCOUNTS',
    [routes.USERS]: 'HEADER_USERS',
    [routes.MY_USER]: 'HEADER_USER',
    [routes.COUNTRY_BLACKLIST]: 'HEADER_COUNTRY_BLACKLIST',
    [routes.INBOX_ASSIGNED_TO_ME]: 'HEADER_INBOX',
    [routes.INBOX_ASSIGNED_TO_OTHERS]: 'HEADER_INBOX',
    [routes.INBOX_UNASSIGNED]: 'HEADER_INBOX',
    [routes.CARRIER_LOOKUP]: 'HEADER_CARRIER_LOOKUP',
    [routes.SINGLE_LOOKUP]: 'HEADER_CARRIER_LOOKUP',
    [routes.BULK_LOOKUP]: 'HEADER_CARRIER_LOOKUP',
    [routes.LOOKUP_HISTORY]: 'HEADER_CARRIER_LOOKUP',
    default: 'HEADER_DEFAULT',
};

export default HEADERS_BY_ROUTE;
