import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';

import {
    DxButton,
    DxDropdown,
    DxForm,
    DxIcon,
    DxInput,
    DxRadioGroup,
    DxShowNotification,
    DxTextarea,
} from '../../../components';
import { ChannelInterface, CounterBox, DxWrapperModal } from '../../../components/common';
import { useLocalization, useRequest } from '../../../hooks';
import { TemplateMedia } from '../../../components/Library/TemplateMedia';
import { useForm } from 'react-hook-form';
import { IViberInputs, IWhatsappInputs } from '../../../types/pages/templates';
import { formProps } from '../../Viber/ViberCampaign/CreateCampaign/steps/ViberTemplate/validationScenarios';
import { formProps as formPropsWhatsApp, WHATS_APP_QUICK_REPLY } from './whatsApp.validation';
import { TEMPLATE_SYMBOL_COUNT, WHATS_APP_BUTTON_ACTION_TYPE } from '../../../constants/common';
import { TemplateButton } from '../../../components/Library/TemplateButton';
import { IConversationItem } from '../types';
import { ResponsesHttpService } from '../../../services/http';
import { IErrors } from '../../../types/validation';
import { capitalize } from 'lodash';
import 'assets/scss/templateComponents.scss';

interface IMessageMedia {
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    type: string;
    channel: string;
    selectedConversation: IConversationItem;
}
export const MessagesMedia: FC<IMessageMedia> = ({
    isModalVisible,
    setIsModalVisible,
    type,
    channel,
    selectedConversation,
}) => {
    const { t } = useLocalization();
    const { doPostRequest } = useRequest();
    const [sending, setSending] = useState(false);
    const [tagCounts, setTagCounts] = useState<number>(0);
    const methods = useForm<IViberInputs | IWhatsappInputs>(channel === 'viber' ? formProps : formPropsWhatsApp);
    const {
        setValue,
        getValues,
        handleSubmit,
        watch,
        trigger,
        clearErrors,
        reset,
        formState: { errors },
    } = methods;

    useEffect(() => {
        setValue('media.type', type);
        setValue('name', 'dummy_value');
    });

    const submitForm = () => {
        trigger().then((valid) => {
            if (valid) {
                onSubmit(getValues());
            }
        });
    };

    const onSubmit = (values: IViberInputs) => {
        setSending(true);
        const additionalData: any = {};
        if (values.button_text) {
            additionalData.button = {
                text: values.button_text,
                value: values.button_value,
            };
        }
        if (type === 'document') {
            additionalData.file_name = values.media.filename;
        }
        if (values.text && (type !== 'document' || channel === 'whatsapp')) {
            additionalData.text = values.text;
        }
        if (type !== 'text') {
            if (values.media.file?.media_id) {
                additionalData.media_id = values.media.file.media_id;
            } else {
                additionalData.url = values.media.url;
            }
        }
        doPostRequest(ResponsesHttpService.createMessage().request, {
            requestBody: {
                data: {
                    from: selectedConversation.sender_name,
                    to: [selectedConversation.number],
                    channel,
                    type,
                    ...additionalData,
                },
            },
            successCallback: () => {
                setSending(false);
                closeModalClearValues();
            },
            errorCallback: (data) => {
                const { errors } = data as IErrors;
                errors?.map((error) => {
                    DxShowNotification('error', error?.message);
                });
                setSending(false);
            },
        });
    };

    const closeModalClearValues = () => {
        reset();
        setIsModalVisible(false);
    };

    /**
     * Watchers for UI
     */
    const messageText = watch('text');
    const docName = watch('media.filename');
    const url = watch('media.url');
    const buttonText = watch('button_text');
    const buttonEnabled = watch('button_enabled');
    const file = watch('media.file');
    const buttonType = watch('button_type');
    const buttonActionType = watch('button_action_type');
    const btnType = buttonType === 'quick_reply' ? 'quick_reply' : buttonActionType;
    const setMediaFromInterface = (response: any) => {
        setValue(
            'media.file',
            {
                get_url: response.get_url,
                media_id: response.id,
                file_name: response.file_name,
                thumbnail_url: response?.thumbnail_url,
                ready: true,
            },
            { shouldValidate: true },
        );
    };

    useEffect(() => {
        if (type === 'image' && channel === 'viber') {
            if (messageText) {
                setValue('button_enabled', true);
            } else {
                setValue('button_enabled', false);
                setValue('button_text', '');
                setValue('button_value', '');
            }
        }
        if (channel === 'whatsapp') {
            if (messageText) {
                setValue('button_enabled', true);
            } else {
                setValue('button_enabled', false);
                setValue('button_text', '');
                setValue('button_value', '');
            }
        }
    }, [messageText]);

    /**
     * Cursor for message
     */
    const cursorPos = useRef({ start: 0, end: 0 });
    const [focusPos, setFocusPos] = useState(0);
    const onTextAreaBlur = (e: any) => {
        const { selectionStart, selectionEnd } = e.target;
        cursorPos.current = { start: selectionStart, end: selectionEnd };
    };

    const onButtonToggle = (checked: boolean) => {
        if (channel === 'viber') {
            setValue('button_enabled', checked);
            setValue('button_text', '');
            setValue('button_value', '');
        } else {
            setValue('button_enabled', checked);
            setValue('button_type', 'quick_reply');
            setValue('button_action_type', 'phone_call');
            setValue('button_text', '');
            setValue('button_value', '');
        }
    };

    return (
        <DxWrapperModal
            footer={
                <div className="btn-wrapper">
                    <DxButton appearance={'default'} onClick={closeModalClearValues}>
                        Cancel
                    </DxButton>
                    <DxButton loading={sending} appearance={'primary'} onClick={submitForm}>
                        <div>
                            <DxIcon type="send" size="sm" /> Reply
                        </div>
                    </DxButton>
                </div>
            }
            title={`Attach ${capitalize(type)}`}
            visible={isModalVisible}
            className="media-modal"
            onCancel={closeModalClearValues}
        >
            <div className="media-modal-content">
                <div className="media-modal-left">
                    <DxForm methods={methods} onFinish={handleSubmit(onSubmit)}>
                        <Row gutter={[0, 16]}>
                            {type === 'document' && (
                                <Col span={24}>
                                    <DxInput
                                        placeholder="Add document name"
                                        label={'Document Name'}
                                        fieldName="media.filename"
                                    />
                                </Col>
                            )}
                            <Col span={24}>
                                <TemplateMedia
                                    disabled={!!url}
                                    setValue={setValue}
                                    type={type}
                                    file={file}
                                    bucketDirectory={'temp'}
                                    channel={channel}
                                    error={errors.media}
                                    clearErrors={clearErrors}
                                    setFileUploading={setSending}
                                />
                            </Col>

                            {!(type === 'document' && channel === 'viber') && (
                                <Col span={24}>
                                    <CounterBox withTags={true}>
                                        <p className="counter-box-title">{t('MESSAGE')}</p>
                                        <DxTextarea
                                            placeholder={'Here is your message'}
                                            cursorPos={focusPos}
                                            fieldName="text"
                                            showCounter={true}
                                            onTextAreaBlur={onTextAreaBlur}
                                            maxLength={TEMPLATE_SYMBOL_COUNT - tagCounts}
                                            maxLengthForShow={TEMPLATE_SYMBOL_COUNT}
                                            setTagCounts={setTagCounts}
                                        />
                                    </CounterBox>
                                </Col>
                            )}
                            {(type === 'image' || type === 'text') && channel === 'viber' && (
                                <Col span={24}>
                                    <TemplateButton
                                        checked={buttonEnabled}
                                        type={type}
                                        onChange={onButtonToggle}
                                        channel={'viber'}
                                    >
                                        <Row gutter={[0, 16]}>
                                            <Col span={24}>
                                                <DxInput
                                                    label={'Text'}
                                                    placeholder={'Add text'}
                                                    fieldName="button_text"
                                                />
                                            </Col>

                                            <Col span={24}>
                                                <DxInput
                                                    label={'Action URL'}
                                                    placeholder={'Paste Action URL'}
                                                    fieldName="button_value"
                                                />
                                            </Col>
                                        </Row>
                                    </TemplateButton>
                                </Col>
                            )}
                            {channel === 'whatsapp' && (
                                <TemplateButton
                                    checked={buttonEnabled}
                                    type={type}
                                    onChange={onButtonToggle}
                                    channel={'whatsapp'}
                                    freeText={true}
                                >
                                    <Row gutter={[0, 16]}>
                                        <Col span={24}>
                                            <DxRadioGroup
                                                value={buttonType}
                                                onChange={(e) => {
                                                    setValue('button_type', e.target.value);
                                                }}
                                                direction="horizontal"
                                                fieldName="button_type"
                                                options={WHATS_APP_QUICK_REPLY}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <DxInput
                                                label={'Button Text'}
                                                placeholder={'Add button text'}
                                                fieldName="button_text"
                                            />
                                        </Col>
                                    </Row>
                                </TemplateButton>
                            )}
                        </Row>
                    </DxForm>
                </div>
                <div className="media-modal-right">
                    <ChannelInterface
                        btnType={btnType}
                        btntext={buttonText ? buttonText : ''}
                        text={messageText}
                        type={type}
                        channel={channel}
                        link={url}
                        documentName={docName}
                        media={file}
                        customCallback={setMediaFromInterface}
                    />
                </div>
            </div>
        </DxWrapperModal>
    );
};
