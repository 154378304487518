import momentTimezone from 'moment-timezone';
import moment from 'moment';

import { DateFormat } from 'constants/common';
import { ITimezone } from 'types/global';

export function toUTC(date: string, timezone: string | null, format = DateFormat.default) {
    if (!timezone) {
        timezone = '';
    }
    return momentTimezone.tz(date, timezone).utc().format(format);
}

export function fromUTC(date: string, timezone: string | null, format = DateFormat.default) {
    const utcCutoff = momentTimezone.utc(date, 'YYYYMMDD HH:mm:ss');

    if (!timezone) {
        return utcCutoff.format(format);
    }

    const displayCutoff = utcCutoff.clone().tz(timezone);

    return displayCutoff.format(format);
}

export function getTimezones() {
    const momentTimezones = momentTimezone.tz.names();
    const today = toUTC(moment().format('YYYY-MM-DD HH:mm:ss'), momentTimezone.tz.guess());

    const timeZones: any[] = [];
    momentTimezones.filter((timezone) => {
        if (
            !timezone.startsWith('Africa') &&
            !timezone.startsWith('America') &&
            !timezone.startsWith('Antarctica') &&
            !timezone.startsWith('Arctic') &&
            !timezone.startsWith('Asia') &&
            !timezone.startsWith('Atlantic') &&
            !timezone.startsWith('Australia') &&
            !timezone.startsWith('Europe') &&
            !timezone.startsWith('Indian') &&
            !timezone.startsWith('Pacific') &&
            !timezone.startsWith('UTC')
        ) {
            return false;
        }

        const tz = momentTimezone.tz(today, timezone).format('Z');
        const offset = momentTimezone.tz(today, timezone).utcOffset();
        timeZones.push({
            timezone,
            name: `${timezone} (UTC ${tz})`,
            offset,
        });
    });

    timeZones.sort((a, b) => (a.offset < b.offset ? 1 : -1));

    const formattedTimezones: ITimezone[] = [];
    timeZones.map(({ timezone: value, name: label }) => {
        formattedTimezones.push({
            value,
            label,
        });
    });

    return formattedTimezones;
}
