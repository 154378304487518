import React, { FC, memo, useState } from 'react';
import Dragger from 'antd/es/upload/Dragger';

import { IDxFileUploadProps } from 'types/components';
import { checkFileSize } from 'services/utils/helper';
import { useLocalization } from 'hooks';
import { DxButton, DxIcon } from 'components/common';
import 'assets/scss/dxFileUpload.scss';
import DefaultMessages from './errorMessages';
import { FILE_UPLOAD_MAX_SIZE } from 'constants/common';
import Loading from './loading';
import { getImageSizes } from 'services/utils/uploadFile';
import { Upload } from 'antd';

const commonUploadProps = {
    multiple: false,
    showUploadList: false,
    openFileDialogOnClick: true,
};

const DxFileUpload: FC<IDxFileUploadProps> = ({
    setValidFile,
    loading,
    setFile,
    title,
    desc,
    fileType,
    additionalDesc,
    accept,
    sampleFile,
    imgSizes,
    maxSize,
    customCallback,
    skipRegExCheck,
    simpleButton,
    horizontal,
    ...restProps
}) => {
    const [currentFile, setCurrentFile] = useState<any>(null);
    const [failed, setFailed] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const uploadProps = {
        ...restProps,
        ...commonUploadProps,
        accept,
        customRequest: (d: any) => {
            setFile(d);
            setCurrentFile(d);
        },
        beforeUpload: (file: any) => {
            setValidFile(true);
            setFailed(false);
            const checkedSize = checkFileSize(file.size, maxSize || FILE_UPLOAD_MAX_SIZE);

            if (!checkedSize) {
                setValidFile(false);
                setCurrentFile(null);
                setFile(null);
                setFailed(true);
                setErrorMessage(
                    t(DefaultMessages.sizeError, {
                        maxSize: (maxSize || FILE_UPLOAD_MAX_SIZE) / 1000000,
                        fileSize: (file.size / 1000000).toFixed(2),
                    }),
                );
                return;
            }
            if (!skipRegExCheck) {
                const regex = new RegExp(
                    `(${accept
                        .split(',')
                        .map((elem: string) => elem)
                        .toString()
                        .replaceAll(',', '|')})$`,
                );
                if (!regex.test(file.type)) {
                    setValidFile(false);
                    setFailed(true);
                    setFile(null);
                    setErrorMessage(
                        t(
                            accept.split(',').length === 1
                                ? DefaultMessages.extensionErrorSingular
                                : DefaultMessages.extensionError,
                            {
                                fileExtension: file.type,
                                extension: fileType,
                            },
                        ),
                    );
                    return;
                }
            }

            getImageSizes(file, (width, height) => {
                if (imgSizes && width !== imgSizes.width && height !== imgSizes.height) {
                    setValidFile(false);
                    setFailed(true);
                    setFile(null);
                    setErrorMessage(
                        t(DefaultMessages.dimensionError, {
                            allowedSize: desc,
                        }),
                    );
                }
            });

            if (customCallback) {
                customCallback(file);
            }
        },
    };

    const { t } = useLocalization();

    if (loading) {
        return (
            <div className="dx-upload-loading">
                <Loading />
            </div>
        );
    }

    if (simpleButton) {
        return (
            <Upload {...uploadProps}>
                <DxButton appearance={'default'} radius={'sharped'}>
                    {desc}
                </DxButton>
            </Upload>
        );
    }

    return (
        <div className={`dx-upload ${horizontal ? 'dx-upload-horizontal' : ''}`}>
            <div className="dx-upload-item">
                {title && <h2 className="dx-upload-title">{title}</h2>}
                <Dragger {...uploadProps} className={`${currentFile?.file || !failed ? 'uploaded' : ''}`}>
                    {!failed &&
                        (currentFile?.file ? (
                            <div className="dx-upload-file-box">
                                <DxIcon type="circle_check_outline" appearance="success" size="md" />
                                <div className="dx-upload-name">{currentFile?.file?.name}</div>
                            </div>
                        ) : (
                            <div>
                                {horizontal ? (
                                    <div className={'dx-upload-file-title-box'}>
                                        <DxIcon type="file_blank_outline" size="md" />
                                        <p className="dx-upload-txt">{desc}</p>
                                    </div>
                                ) : (
                                    <>
                                        <DxIcon type="file_new" size="md" />
                                        <p className="dx-upload-txt">{desc}</p>
                                    </>
                                )}

                                <p className="dx-upload-file-type">{fileType}</p>
                                <p className="dx-upload-file-desc">{additionalDesc}</p>
                            </div>
                        ))}

                    {failed && (
                        <>
                            <DxIcon type="off_outline_close" appearance="danger" size="md" />
                            <p className="dx-upload-txt">{errorMessage}</p>
                        </>
                    )}
                </Dragger>
                {sampleFile && (
                    <div className="dx-upload-sample-outer">
                        <a className="dx-upload-sample-file" href={sampleFile} download={true}>
                            <DxIcon size="sm" type="download" /> {t('DOWNLOAD_THE_SAMPLE')}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DxFileUpload);
