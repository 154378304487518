import { useRef, useLayoutEffect, useMemo } from 'react';
import { IMessagesByDate } from '../pages/Inbox/Messages/types';

export const useKeepScrollPosition = (messages: IMessagesByDate[]) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const previousScrollPosition = useRef(0);

    useMemo(() => {
        if (containerRef?.current) {
            const container = containerRef?.current;
            previousScrollPosition.current = container?.scrollHeight - container?.scrollTop;
        }
    }, [messages]);

    useLayoutEffect(() => {
        if (containerRef?.current) {
            const container = containerRef?.current || {};
            container.scrollTop = container?.scrollHeight - previousScrollPosition.current;
        }
    }, [messages]);

    return {
        containerRef,
    };
};
