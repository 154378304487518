import React, { FC, memo, useCallback } from 'react';
import { Tabs } from 'antd';
import classNames from 'classnames';

import { ITabsProps } from 'types/components';

import 'assets/scss/dxTab.scss';
import { useSelector } from 'react-redux';
import { IAccountState, IRootReducer } from '../../../types/reducers';
import { PERMISSION_KEYS } from '../../../services/utils/helper';
import { PRIVATE_ROUTES_ENUM } from '../../../constants/routes';

const { TabPane } = Tabs;

const DxTab: FC<ITabsProps> = ({ appearance = 'default', withNavigation = false, list, callback, ...restProps }) => {
    const { permissions } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    const hasViewPermission = useCallback(
        (viewRule: PERMISSION_KEYS, url: string) => {
            if (viewRule === 'USERS' && url === PRIVATE_ROUTES_ENUM.USERS) {
                return permissions[viewRule]!.canAdd;
            }
            if (viewRule) {
                return permissions[viewRule]!.canView;
            }
            return true;
        },
        [permissions],
    );
    return (
        <Tabs
            className={classNames('dx-tab', {
                'box-view': appearance === 'box',
                default: appearance === 'default',
                'images-view': appearance === 'images-view',
            })}
            /*className={`dx-tab ${appearance === 'box' ? 'box-view' : ''} `}*/ onChange={callback}
            {...restProps}
        >
            {list.map(({ disabled, title, url, content, viewRule, className }, index) => (
                <TabPane
                    disabled={disabled || !hasViewPermission(viewRule as PERMISSION_KEYS, url || '')}
                    tab={title}
                    key={withNavigation ? url : index}
                    className={className}
                >
                    {content}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default memo(DxTab);
