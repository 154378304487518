import { useMemo } from 'react';
import { pickBy } from 'lodash';

import { useQuery } from './index';

function useFilters(filterKeys: string[]) {
    const { urlProps } = useQuery();
    const filters = useMemo(() => {
        const _filters = filterKeys.reduce((prev, current) => ({ ...prev, [current]: urlProps[current] }), {});
        const cleanFilters = pickBy(_filters);
        const { countries, sender_ids, statuses, user_statuses, campaign_ids, positions, type, wa_categories } =
            cleanFilters as {
                countries: string | undefined;
                sender_ids: string | undefined;
                statuses: string | undefined;
                user_statuses: string | undefined;
                campaign_ids: string | undefined;
                positions: string | undefined;
                type: string | undefined;
                wa_categories: string | undefined;
            };
        if (countries) {
            cleanFilters.countries = countries?.split(',');
        }
        if (sender_ids) {
            cleanFilters.sender_ids = sender_ids?.split(',');
        }
        if (statuses) {
            cleanFilters.statuses = statuses?.split(',');
        }
        if (user_statuses) {
            cleanFilters.user_statuses = user_statuses?.split(',');
        }
        if (campaign_ids) {
            cleanFilters.campaign_ids = campaign_ids?.split(',');
        }
        if (positions) {
            cleanFilters.positions = positions?.split(',');
        }
        if (type) {
            cleanFilters.type = type?.split(',');
        }
        if (wa_categories) {
            cleanFilters.wa_categories = wa_categories?.split(',');
        }
        return cleanFilters;
    }, [filterKeys, urlProps]);

    return {
        filters,
    };
}

export default useFilters;
