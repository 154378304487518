import { has, isFunction, noop } from 'lodash';
import { errorCallback } from 'services/http';
import { IParams } from 'types/hooks/useRequest';
import { AuthAction } from '../actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DxShowNotification } from '../components';

const useRequest = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const doRequest = (requestFn: Function, params: IParams, args: any[]) => {
        if (!isFunction(requestFn)) {
            console.error(`${requestFn} should be a function`);
        } else {
            const { successCallback = noop, catchCallback = noop, errorCallback: customErrorHandler } = params;

            return new Promise((resolve, reject) => {
                requestFn
                    .apply(null, args)
                    .then(
                        (response: any) => {
                            const { data, pagination, ...rest } = response;
                            successCallback(
                                has(response, 'data')
                                    ? has(response, 'pagination')
                                        ? { data, pagination, ...rest }
                                        : data
                                    : response,
                            );

                            resolve(response);
                        },
                        (error: any) => {
                            const errData = error?.response?.data;
                            const errorsArray = errData?.errors;
                            if (
                                Array.isArray(errorsArray) &&
                                errorsArray[0].status === 403 &&
                                errorsArray[0].code === '1135'
                            ) {
                                dispatch(AuthAction.doLogout(''));
                                navigate('/');
                                DxShowNotification(
                                    'error',
                                    'Your user is temporarily blocked, please contact customer.support@dexatel.com for more information.',
                                    null,
                                    24,
                                );
                                return;
                            }

                            if (!axios.isCancel(error)) {
                                if (typeof customErrorHandler === 'function') {
                                    if (error?.status === 429) {
                                        DxShowNotification(
                                            'error',
                                            'Too many requests',
                                            null,
                                            localStorage.getItem('accessToken') ? 80 : 24,
                                        );
                                    }
                                    customErrorHandler(errData || error, error?.status === 429);
                                } else {
                                    errorCallback(error?.status === 429);
                                }
                            }
                        },
                    )
                    .catch((error: any) => {
                        catchCallback(error);
                    });
            });
        }
    };

    const doGetRequest = (requestFn: Function, params: IParams) => {
        const { queryString = {} } = params;
        const args = [queryString];
        return doRequest(requestFn, params, args);
    };

    const doPostRequest = (requestFn: Function, params: IParams) => {
        const { requestBody, queryString = {} } = params;
        const args = [requestBody, queryString];
        return doRequest(requestFn, params, args);
    };

    const doPutRequest = (requestFn: Function, params: IParams) => {
        const { requestBody, queryString = {} } = params;
        const args = [requestBody, queryString];
        return doRequest(requestFn, params, args);
    };

    const doDeleteRequest = (requestFn: Function, params: IParams) => {
        const { queryString = {} } = params;
        const args = [queryString];
        return doRequest(requestFn, params, args);
    };

    return { doGetRequest, doPostRequest, doPutRequest, doDeleteRequest };
};

export default useRequest;
