import l from './localizationLabels';
import { IStatusAppearance } from 'types/components/variables';
import { IPaginationOptions } from '../types/hooks/usePagination';
import { IChannel, IIndustry } from '../types/global';

const DateFormat = {
    display: 'DD-MM-YYYY HH:mm',
    paymentUpload: 'dd-MM-yyyy HH:mm',
    api: 'dd/mm/yyyy',
    default: 'yyyy-MM-DD HH:mm:ss',
    onlyDate: 'DD-MM-yyyy',
};

interface IStringKeyValue {
    [key: string]: string;
}

export interface IStatusModel {
    [key: string]: IStatusAppearance;
}

const Currency = {
    USD: 'USD',
    EUR: 'EUR',
    AMD: 'AMD',
    RUB: 'RUB',
    AED: 'AED',
};

const CurrencySymbol: IStringKeyValue = {
    USD: '$',
    EUR: '€',
    AMD: '֏',
    RUB: '₽',
    AED: 'د.إ',
};

const PaymentTypes: IStringKeyValue = {
    PAYPAL: 'Paypal',
    STRIPE: 'Credit Card',
    TOPUP: 'Auto Top Up',
    STRIPE_SAVED: 'Credit Card',
    BONUS: 'Bonus',
    BANK_TRANSFER: 'Bank Transfer',
    FREE_TEST: 'Free Test',
    COMPENSATION_MANUAL: 'Compensation',
};

const PaymentStatuses: IStringKeyValue = {
    PAID: 'Paid',
    FROZEN: 'In Review',
    REFUNDED: 'Refund',
    PARTIALLY_REFUNDED: 'Partial Refund',
};

const SenderStatuses: IStringKeyValue = {
    pending: 'Pending',
    available: 'Available',
    rejected: 'Rejected',
};

const PaymentStatusesAppearance: IStatusModel = {
    PAID: 'success',
    FROZEN: 'warning',
    REFUNDED: 'info',
    PARTIALLY_REFUNDED: 'info',
};

const WhatsappTemplatesStatusesAppearance: IStatusModel = {
    COMPLETED: 'success',
    CANCELED: 'error',
    IN_PROCESS: 'info',
    DELETED: 'error',
};

const WhatsappTemplatesStatuses: IStringKeyValue = {
    COMPLETED: 'Available',
    CANCELED: 'Rejected',
    IN_PROCESS: 'Pending',
    DELETED: 'Deleted',
};

const InsightsStatusesAppearance: IStatusModel = {
    undelivered: 'warning',
    blocked: 'error',
    delivered: 'success',
    answered: 'success',
    read: 'success',
    pending: 'info',
    failed: 'error',
};

const SendersStatusesAppearance: IStatusModel = {
    pending: 'warning',
    approved: 'info',
    rejected: 'error',
    available: 'success',
    canceled: 'error',
};

const CampaignStatusesAppearance: IStatusModel = {
    in_complete: 'error',
    in_progress: 'info',
    scheduled: 'warning',
    failed: 'error',
    completed: 'success',
    canceled: 'error',
    error: 'error',
};

const BULK_LOOKUP_STATUSES_APPEARANCE: IStatusModel = {
    IN_PROGRESS: 'info',
    COMPLETED: 'success',
    PENDING: 'info',
};

const PaginationDefaultOptions: IPaginationOptions = {
    pageSizeOptions: ['10', '20', '50', '100'],
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSize: 10,
    current: 1,
    total: 0,
};

const CHANNELS = {
    SMS: 'SMS' as IChannel,
    VIBER: 'VIBER' as IChannel,
    WHATSAPP: 'WHATSAPP' as IChannel,
};

const CHANNEL_LABELS = {
    [CHANNELS.SMS]: 'SMS',
    [CHANNELS.VIBER]: 'Viber',
    [CHANNELS.WHATSAPP]: 'Whatsapp',
};

const SENDER_TYPES = {
    TOLL_FREE: 'Toll-Free Number',
    LOCAL: 'Local Number',
    MOBILE: 'Mobile Numbers',
    SHORT_CODE: 'Short Code',
    SENDER_ID: 'Sender Id',
};

const INDUSTRIES = {
    AUTOMOTIVE: 'AUTOMOTIVE' as IIndustry,
    ECOMMERCE: 'ECOMMERCE' as IIndustry,
    EDUCATION: 'EDUCATION' as IIndustry,
    FINANCIAL_SERVICES: 'FINANCIAL_SERVICES' as IIndustry,
    HEALTHCARE: 'HEALTHCARE' as IIndustry,
    LEGAL: 'LEGAL' as IIndustry,
    REAL_ESTATE: 'REAL_ESTATE' as IIndustry,
    RETAIL: 'RETAIL' as IIndustry,
    OTHER: 'OTHER' as IIndustry,
};

const INDUSTRY_IDS = {
    [INDUSTRIES.AUTOMOTIVE]: 1,
    [INDUSTRIES.ECOMMERCE]: 2,
    [INDUSTRIES.HEALTHCARE]: 3,
    [INDUSTRIES.EDUCATION]: 4,
    [INDUSTRIES.FINANCIAL_SERVICES]: 5,
    [INDUSTRIES.LEGAL]: 6,
    [INDUSTRIES.REAL_ESTATE]: 7,
    [INDUSTRIES.RETAIL]: 8,
    [INDUSTRIES.OTHER]: 9,
};

const USE_CASES = {
    MARKETING: 1,
    VERIFICATION: 2,
    REMINDERS: 3,
    ALERTS: 4,
};

const USE_CASES_VIBER = {
    PROMOTIONAL: 5,
    TRANSACTIONAL: 6,
};

const FILE_UPLOAD_MAX_SIZE = 5000000;
const FILE_UPLOAD_MAX_SIZE_CONTACTS = 5000000;
const CSV_ROW_MAX_COUNT = 100000;
const TEMPLATE_SYMBOL_COUNT = 1000;

const MESSAGE_VARIABLES = ['{first_name}', '{last_name}', '{custom1}', '{custom2}', '{sms_opt_out}'];

const WHATS_APP_TEMPLATES = [
    { value: 'text', label: 'Text' },
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' },
    { value: 'document', label: 'Document' },
];

const VIBER_TEMPLATES = [
    { value: 'text', label: 'None' },
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' },
    { value: 'document', label: 'Document' },
];

const WHATS_APP_BUTTON_ACTION_TYPE = [
    { value: 'phone_call', label: 'Phone Call' },
    { value: 'link', label: 'Visit Website' },
];

const US_BASED_COUNTRIES = ['ir', 'iq', 'il', 'jo', 'kw', 'om', 'ps', 'qa', 'sa', 'sy', 'tr', 'ae', 'ye', 'lb'];

const MIN_AMOUNT_FOR_ADMIN = 0.5;
const MIN_AMOUNT_DEFAULT = 15;
const MIN_AMOUNT_AMD_DEFAULT = 6000;
const MAX_AMOUNT_DEFAULT = 5000;
const MAX_AMOUNT_AMD_DEFAULT = 2000000;
const MAX_AMOUNT_TOP_UP = 2000;
const MAX_AMOUNT_TOP_UP_AMD = 800000;

const COMPANY_STATUSES = {
    UNVERIFIED: 'UNVERIFIED',
    FORM_SUBMITTED: 'FORM_SUBMITTED',
    VERIFIED: 'VERIFIED',
    BLOCKED: 'BLOCKED',
};

const USER_STATUSES = {
    NEW: 'NEW',
    EMAIL_VERIFIED: 'EMAIL_VERIFIED',
    PHONE_VERIFIED: 'PHONE_VERIFIED',
    BLOCKED: 'BLOCKED',
};

// Todo change the prices
const CARRIER_LOOKUP_PRICES: IStringKeyValue = {
    USD: '0.02',
    AMD: '7.50',
    EUR: '0.02',
};

export {
    l,
    DateFormat,
    Currency,
    CurrencySymbol,
    PaymentTypes,
    PaymentStatuses,
    SenderStatuses,
    PaginationDefaultOptions,
    PaymentStatusesAppearance,
    InsightsStatusesAppearance,
    SendersStatusesAppearance,
    CampaignStatusesAppearance,
    CHANNELS,
    CHANNEL_LABELS,
    INDUSTRIES,
    INDUSTRY_IDS,
    SENDER_TYPES,
    FILE_UPLOAD_MAX_SIZE,
    TEMPLATE_SYMBOL_COUNT,
    MESSAGE_VARIABLES,
    USE_CASES,
    USE_CASES_VIBER,
    FILE_UPLOAD_MAX_SIZE_CONTACTS,
    CSV_ROW_MAX_COUNT,
    WHATS_APP_TEMPLATES,
    MIN_AMOUNT_DEFAULT,
    MIN_AMOUNT_AMD_DEFAULT,
    MAX_AMOUNT_DEFAULT,
    MAX_AMOUNT_AMD_DEFAULT,
    MIN_AMOUNT_FOR_ADMIN,
    WhatsappTemplatesStatuses,
    WhatsappTemplatesStatusesAppearance,
    WHATS_APP_BUTTON_ACTION_TYPE,
    VIBER_TEMPLATES,
    US_BASED_COUNTRIES,
    MAX_AMOUNT_TOP_UP,
    MAX_AMOUNT_TOP_UP_AMD,
    COMPANY_STATUSES,
    USER_STATUSES,
    CARRIER_LOOKUP_PRICES,
    BULK_LOOKUP_STATUSES_APPEARANCE,
};
