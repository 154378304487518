import { IPendingAudiencesState } from '../types/reducers/pendingAudiences';
import { TActionCreator } from '../types/actions/types';
import { PendingAudiencesActionTypes } from '../actions/types';

const initialState = {
    data: [],
};

export default function contactsReducer(state: IPendingAudiencesState = initialState, action: TActionCreator) {
    switch (action.type) {
        case PendingAudiencesActionTypes.INIT:
            return {
                data: action.payload,
            };
        case PendingAudiencesActionTypes.ADD:
            return {
                data: [...state.data, action.payload],
            };
        case PendingAudiencesActionTypes.REMOVE:
            return {
                data: state.data.filter((item) => item.audience_id !== action.payload.id),
            };
        default:
            return state;
    }
}
