export const includedKeys = ['name', 'wa_category', 'wa_sender_code', 'text', 'status', 'create_date'];

export const actionColumn = {
    key: 'x',
    title: '',
    dataIndex: '',
    width: '10%',
};

export const WA_TEMPLATE_CATEGORIES = {
    MARKETING: 'Marketing',
    UTILITY: 'Utility',
    AUTHENTICATION: 'Authentication',
};

export const WA_OPTIONS = Object.entries(WA_TEMPLATE_CATEGORIES).map(([key, label]) => {
    return { value: key, label: label as string };
});

export const actionAttribute = 'data-action-id';
