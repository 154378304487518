import React, { memo, FC } from 'react';

import { useLocalization } from 'hooks';

import 'assets/scss/useCases.scss';
import { DxCard, DxLink, DxTable } from 'components/index';
import { getCountryNameByCode } from 'services/utils/helper';
import { AvailableSendersOptions } from 'pages/Dashboard/config';
import { isNil } from 'lodash';
import { IAvailableSenders } from 'types/components';
import { SENDER_TYPES, US_BASED_COUNTRIES } from 'constants/common';
import { PRIVATE_ROUTES_ENUM } from '../../../constants/routes';

const AvailableSenders: FC<IAvailableSenders> = ({ country }) => {
    const { t } = useLocalization();

    let selectedAvailableSenders = AvailableSendersOptions.ALL;

    // @ts-ignore
    if (!isNil(AvailableSendersOptions[country.toLowerCase()])) {
        // @ts-ignore
        selectedAvailableSenders = AvailableSendersOptions[country.toLowerCase()];
    }

    const countries: string[] = ['US', 'CA'];

    const source: any[] = [];

    if (US_BASED_COUNTRIES.includes(country?.toLowerCase())) {
        selectedAvailableSenders = [
            {
                type: SENDER_TYPES.SENDER_ID,
                example: 'E.x. Redwood',
                buttonLabel: 'Request',
                buttonLink: PRIVATE_ROUTES_ENUM.REQUEST_SENDER_ID,
            },
        ];
    }

    for (const item of selectedAvailableSenders) {
        const elem = {
            type: item.type,
            example: item.example,
            empty: (
                <div className="dx-right-aligned-cell">
                    {item.type === SENDER_TYPES.SHORT_CODE && !countries.includes(country) ? (
                        <DxLink
                            external={true}
                            appearance="default"
                            size="small"
                            to="mailto:customer.support@dexatel.com"
                        >
                            {item.buttonLabel}
                        </DxLink>
                    ) : (
                        <DxLink size="small" to={item.buttonLink} appearance="default">
                            {item.buttonLabel}
                        </DxLink>
                    )}
                </div>
            ),
        };

        source.push(elem);
    }

    return (
        <DxCard withShadow={true}>
            <h2 className="homepage-card-title">
                {t('AVAILABLE_SENDERS')} <strong>{getCountryNameByCode(country.toLowerCase())}</strong>
            </h2>
            <DxTable rowKey={'type'} highlighted={true} pagination={false} dataSource={source} />
        </DxCard>
    );
};

export default memo(AvailableSenders);
