import { GetawayAPIService, getCancelableRequest } from './http.config';

import { ICancelableRequest } from 'types/services/http.config';
import { TID } from 'types/services/template';

const getInvoices = (query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/invoices${query}`,
    });
};

const getCurrentInvoiceLink = (id: TID): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/invoices/${id}?exportPdf=true`,
    });
};

const methods = {
    getInvoices,
    getCurrentInvoiceLink,
};

export default methods;
