import twoWaySms from 'assets/images/homepage/two-way-sms.svg';
import unicode from 'assets/images/homepage/unicode.svg';
import deliveryReceipts from 'assets/images/homepage/delivery-receipts.svg';
import { IUseCasesList } from 'types/components/useCases';
import { ICapabilitiesList, IComplianceList, IGuideList } from 'types/components';
import { CHANNELS, INDUSTRIES, SENDER_TYPES } from 'constants/common';
import { ITranslationKey } from 'types/localization';
import { IChannel } from 'types/global';

export const HUBSPOT_MEETING_LINK = process.env.REACT_APP_HUBSPOT_MEETING_LINK || '';

type IRecommendedChannels = {
    [key in string]: {
        [key in string]: IChannel[];
    };
};

type IUseCasesGlobal = {
    [index in string]: {
        [index in string]: IUseCasesList[];
    };
};

type IUseCasesRegulationsGlobal = {
    [index in string]: IUseCasesList[];
};

const UseCases: IUseCasesGlobal = {
    [CHANNELS.SMS]: {
        [INDUSTRIES.AUTOMOTIVE]: [
            {
                title: 'APPOINTMENT_REMINDERS',
                desc: 'APPOINTMENT_REMINDERS_DESCRIPTION',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'REPAIR_UPDATES',
                desc: 'REPAIR_UPDATES_DESCRIPTION',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'NEW_VEHICLES_BROADCASTING',
                desc: 'NEW_VEHICLES_BROADCASTING_DESCRIPTION',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.ECOMMERCE]: [
            {
                title: 'Wish List Reminders',
                desc: 'Trigger customers to return and proceed with a purchase.',
                tags: [
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Customer Reviews',
                desc: 'Find out the level of your products and services satisfaction by asking for reviews.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Discounts',
                desc: 'Increase sales and loyalty by sending custom discounts.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.EDUCATION]: [
            {
                title: 'Upcoming Deadline Reminders',
                desc: 'Involve extra reminders for students to make sure everything will be on time.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Upcoming Meeting Reminders',
                desc: 'Inform parents about the upcoming meeting with teachers through SMS.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Upcoming Event Reminders',
                desc: 'For maximum attendance of parents and students, remind them about the event.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.FINANCIAL_SERVICES]: [
            {
                title: 'Payment Reminders',
                desc: 'Let your clients receive reminders on their payment deadlines instantly.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Transaction Confirmations',
                desc: 'Send OTP messages to verify user identity for every single transaction.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Low Balance Alerts',
                desc: 'Send alerts to users in case of a low balance.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.HEALTHCARE]: [
            {
                title: 'Appointment Reminders',
                desc: 'Send fast appointment reminders to both patients and doctors instantly.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Payment Alerts',
                desc: 'Collect payments by sending simple reminders.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Medical Report Notifications',
                desc: "Send test results directly to the patient's phone.",
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.LEGAL]: [
            {
                title: 'Appointment Reminders',
                desc: 'Send appointment reminders to your clients for proper time management.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Payment Reminders',
                desc: 'Inform your clients about the fee for services provided via SMS.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Customer Reviews',
                desc: 'Ask your client to review your services via SMS.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.REAL_ESTATE]: [
            {
                title: 'Open House Reminders',
                desc: 'Advertise the open house of a specific property you want to sell.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'New House Promotions',
                desc: 'Capture high-quality images of the property and send them via Viber.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Rent Payment Reminders',
                desc: 'Remind your customers about rent payments via SMS.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        [INDUSTRIES.RETAIL]: [
            {
                title: 'Special Offers',
                desc: 'Send discounts and offers via SMS with assurance of high quality customer care.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'New Product Promotions',
                desc: 'SMS is a quick way to update your customer about the arrival of new products.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'SMS Contests',
                desc: 'Provide your customers with a chance to win free products by running SMS contests.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
        ],
        ALL: [
            {
                title: 'Appointment Reminders',
                desc: 'Reduce no-shows with a simple appointment reminder.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                ],
            },
            {
                title: 'OTP Messages',
                desc: 'Send OTP messages to verify user identity for important actions.',
                tags: [
                    {
                        tag: 'PROGRAMMABLE',
                        appearance: 'success',
                    },
                ],
            },
            {
                title: 'Customer Reviews',
                desc: 'Ask your client to review your services via SMS.',
                tags: [
                    {
                        tag: 'CAMPAIGN',
                        appearance: 'info',
                    },
                ],
            },
        ],
    },
    [CHANNELS.WHATSAPP]: {
        ALL: [
            {
                title: 'Personalized Suggestions',
                desc: 'Send custom triggered product or service recommendations.',
                tags: [],
            },
            {
                title: 'Two-Way Conversations',
                desc: 'Provide real time customer support through one on one communication.',
                tags: [],
            },
            {
                title: 'Abandoned Cart Recovery',
                desc: 'Significantly increase conversion rate by sending abandoned cart reminders.',
                tags: [],
            },
        ],
    },
    [CHANNELS.VIBER]: {
        ALL: [
            {
                title: 'Personalized Suggestions',
                desc: 'Send custom triggered product or service recommendations.',
                tags: [],
            },
            {
                title: 'Two-Way Conversations',
                desc: 'Provide real time customer support through one on one communication.',
                tags: [],
            },
            {
                title: 'Abandoned Cart Recovery',
                desc: 'Significantly increase conversion rate by sending abandoned cart reminders.',
                tags: [],
            },
        ],
    },
};

interface IAvailableSendersList {
    type: string;
    example: string;
    buttonLabel: string;
    buttonLink: string;
}

interface IAvailableSendersOptions {
    [key: string]: IAvailableSendersList[];
}

const AvailableSendersOptions: IAvailableSendersOptions = {
    us: [
        {
            type: SENDER_TYPES.TOLL_FREE,
            example: 'E.x. +1 833 352 1933',
            buttonLabel: 'Buy',
            buttonLink: 'senders/buy-number',
        },
        {
            type: SENDER_TYPES.LOCAL,
            example: 'E.x. +1 364 999 8175',
            buttonLabel: 'Buy',
            buttonLink: 'senders/buy-number',
        },
        {
            type: SENDER_TYPES.SHORT_CODE,
            example: 'E.x. 67794',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-short-code',
        },
    ],
    ca: [
        {
            type: SENDER_TYPES.TOLL_FREE,
            example: 'E.x. + 1 889 878 7874',
            buttonLabel: 'Buy',
            buttonLink: 'senders/buy-number',
        },
        {
            type: SENDER_TYPES.LOCAL,
            example: 'E.x.  +1 289 768 4876',
            buttonLabel: 'Buy',
            buttonLink: 'senders/buy-number',
        },
        {
            type: SENDER_TYPES.SHORT_CODE,
            example: 'E.x. 78764',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-short-code',
        },
    ],
    gb: [
        {
            type: SENDER_TYPES.MOBILE,
            example: 'E.x. +44 20 4520 5266',
            buttonLabel: 'Buy',
            buttonLink: 'senders/buy-number',
        },
        {
            type: SENDER_TYPES.SENDER_ID,
            example: 'E.x. Redwood',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-sender-id',
        },
        {
            type: SENDER_TYPES.SHORT_CODE,
            example: 'E.x. 67794',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-short-code',
        },
    ],
    au: [
        {
            type: SENDER_TYPES.MOBILE,
            example: 'E.x. +61 2 4067 5871',
            buttonLabel: 'Buy',
            buttonLink: 'senders/buy-number',
        },
        {
            type: SENDER_TYPES.SENDER_ID,
            example: 'E.x. Redwood',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-sender-id',
        },
        {
            type: SENDER_TYPES.SHORT_CODE,
            example: 'E.x. 67794',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-short-code',
        },
    ],
    ALL: [
        {
            type: SENDER_TYPES.SENDER_ID,
            example: 'E.x. Redwood',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-sender-id',
        },
        {
            type: SENDER_TYPES.SHORT_CODE,
            example: 'E.x 78764',
            buttonLabel: 'Request',
            buttonLink: 'senders/request-short-code',
        },
    ],
};

type TTranslationKey = keyof ITranslationKey;
type IChannelAdvantages = {
    [index in string]: {
        title: keyof ITranslationKey;
        channel: keyof ITranslationKey;
        items: TTranslationKey[];
    };
};

const ChannelAdvantages: IChannelAdvantages = {
    [CHANNELS.WHATSAPP]: {
        title: 'ADVANTAGES_TITLE',
        channel: 'WHATSAPP_BUSINESS',
        items: ['WHATSAPP_ADVANTAGES_ITEM1', 'WHATSAPP_ADVANTAGES_ITEM2', 'WHATSAPP_ADVANTAGES_ITEM3'],
    },
    [CHANNELS.VIBER]: {
        title: 'ADVANTAGES_TITLE',
        channel: 'VIBER_BUSINESS',
        items: ['VIBER_ADVANTAGES_ITEM1', 'VIBER_ADVANTAGES_ITEM2', 'VIBER_ADVANTAGES_ITEM3'],
    },
};

const RecommendedChannels: IRecommendedChannels = {
    [CHANNELS.SMS]: {
        us: [CHANNELS.WHATSAPP],
        ca: [CHANNELS.WHATSAPP],
        gb: [CHANNELS.WHATSAPP],
        au: [CHANNELS.WHATSAPP],
        ALL: [CHANNELS.WHATSAPP, CHANNELS.VIBER],
    },
    [CHANNELS.WHATSAPP]: {
        us: [CHANNELS.SMS],
        ca: [CHANNELS.SMS],
        gb: [CHANNELS.SMS],
        au: [CHANNELS.SMS],
        ALL: [CHANNELS.SMS, CHANNELS.VIBER],
    },
    [CHANNELS.VIBER]: {
        us: [CHANNELS.SMS],
        ca: [CHANNELS.SMS],
        gb: [CHANNELS.SMS],
        au: [CHANNELS.SMS],
        ALL: [CHANNELS.SMS, CHANNELS.WHATSAPP],
    },
};

const HelpfulGuides = {
    [CHANNELS.SMS]: [
        {
            title: 'How to Start with SMS Campaign',
            path: 'https://help.dexatel.com/how-to-get-started-with-dexatel',
        },
        {
            title: 'How to Start SMS API Integration',
            path: 'https://help.dexatel.com/how-to-start-api-integration',
        },
    ],
    [CHANNELS.WHATSAPP]: [
        {
            title: 'What They are and How to Start with WhatsApp Templates',
            path: 'https://help.dexatel.com/how-to-get-started-with-dexatel',
        },
        {
            title: 'How to Start with WhatsApp Live One on One Conversations',
            path: 'https://help.dexatel.com/how-to-get-started-with-dexatel',
        },
    ],
    [CHANNELS.VIBER]: [
        {
            title: 'How to Start with Viber Campaign',
            path: 'https://help.dexatel.com/how-to-get-started-with-dexatel',
        },
        {
            title: 'How to Start with Viber Live One on One Conversations',
            path: 'https://help.dexatel.com/how-to-get-started-with-dexatel',
        },
    ],
};

export const capabilitiesList: ICapabilitiesList = {
    us: [
        {
            title: 'TWO_WAY_SMS',
            imgPath: twoWaySms,
        },
        {
            title: 'DELIVERY_RECEIPTS',
            imgPath: deliveryReceipts,
        },
        {
            title: 'UNICODE_RECEIPTS',
            imgPath: unicode,
        },
    ],
    ca: [
        {
            title: 'TWO_WAY_SMS',
            imgPath: twoWaySms,
        },
        {
            title: 'DELIVERY_RECEIPTS',
            imgPath: deliveryReceipts,
        },
        {
            title: 'UNICODE_RECEIPTS',
            imgPath: unicode,
        },
    ],
    gb: [
        {
            title: 'TWO_WAY_SMS',
            imgPath: twoWaySms,
        },
        {
            title: 'DELIVERY_RECEIPTS',
            imgPath: deliveryReceipts,
        },
        {
            title: 'UNICODE_RECEIPTS',
            imgPath: unicode,
        },
    ],
    au: [
        {
            title: 'TWO_WAY_SMS',
            imgPath: twoWaySms,
        },
        {
            title: 'DELIVERY_RECEIPTS',
            imgPath: deliveryReceipts,
        },
        {
            title: 'UNICODE_RECEIPTS',
            imgPath: unicode,
        },
    ],
    ALL: [
        {
            title: 'TWO_WAY_SMS_BY_COUNTRY',
            imgPath: twoWaySms,
        },
        {
            title: 'DELIVERY_RECEIPTS_BY_COUNTRY',
            imgPath: deliveryReceipts,
        },
    ],
};

export const complianceList: IComplianceList = {
    us: ['TCPA', 'PSD2', 'PDPA'],
    ca: ['CASL', 'HIPAA', 'PSD2', 'GDPR', 'PDPA'],
    gb: ['GDPR', 'HIPAA', 'PSD2', 'PDPA'],
    au: ['HIPAA', 'PSD2', 'PDPA'],
    ALL: ['TCPA', 'HIPAA', 'PSD2', 'GDPR', 'APA', 'PDPA'],
};

export const regulationsList: IUseCasesRegulationsGlobal = {
    us: [
        {
            title: 'CLEAR_OPT_IN_CONSENT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
        {
            title: 'CLEAR_OPT_OUT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'REQUIRED',
                    appearance: 'error',
                },
            ],
        },
        {
            title: 'ONLY_COMMUNICATE_DURING_DAYTIME',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
    ],
    ca: [
        {
            title: 'CLEAR_OPT_IN_CONSENT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
        {
            title: 'CLEAR_OPT_OUT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'REQUIRED',
                    appearance: 'error',
                },
            ],
        },
        {
            title: 'ONLY_COMMUNICATE_DURING_DAYTIME',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
    ],
    gb: [
        {
            title: 'CLEAR_OPT_IN_CONSENT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
        {
            title: 'CLEAR_OPT_OUT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
        {
            title: 'ONLY_COMMUNICATE_DURING_DAYTIME',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
    ],
    au: [
        {
            title: 'CLEAR_OPT_IN_CONSENT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
        {
            title: 'CLEAR_OPT_OUT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
        {
            title: 'ONLY_COMMUNICATE_DURING_DAYTIME',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'RECOMMENDED',
                    appearance: 'info',
                },
            ],
        },
    ],
    ALL: [
        {
            title: 'CLEAR_OPT_IN_CONSENT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'BY_COUNTRY',
                    appearance: 'success',
                },
            ],
        },
        {
            title: 'CLEAR_OPT_OUT',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'BY_COUNTRY',
                    appearance: 'success',
                },
            ],
        },
        {
            title: 'ONLY_COMMUNICATE_DURING_DAYTIME',
            tags: [
                {
                    tag: 'PROMOTIONAL',
                    appearance: 'additional',
                },
                {
                    tag: 'BY_COUNTRY',
                    appearance: 'success',
                },
            ],
        },
    ],
};

export const smsUseCasesList: IUseCasesList[] = [
    {
        title: 'APPOINTMENT_REMINDERS',
        desc: 'APPOINTMENT_REMINDERS_DESCRIPTION',
        tags: [
            {
                tag: 'CAMPAIGN',
                appearance: 'info',
            },
            {
                tag: 'PROGRAMMABLE',
                appearance: 'success',
            },
        ],
    },
    {
        title: 'REPAIR_UPDATES',
        desc: 'REPAIR_UPDATES_DESCRIPTION',
        tags: [
            {
                tag: 'CAMPAIGN',
                appearance: 'info',
            },
            {
                tag: 'PROGRAMMABLE',
                appearance: 'success',
            },
        ],
    },
    {
        title: 'NEW_VEHICLES_BROADCASTING',
        desc: 'NEW_VEHICLES_BROADCASTING_DESCRIPTION',
        tags: [
            {
                tag: 'CAMPAIGN',
                appearance: 'info',
            },
            {
                tag: 'PROGRAMMABLE',
                appearance: 'success',
            },
        ],
    },
];

export const guideList: IGuideList[] = [
    {
        title: 'HOW_TO_START_SMS_CAMPAIGN',
        path: 'https://help.dexatel.com/how-to-get-started-with-dexatel',
    },
    {
        title: 'HOW_TO_START_SMS_CONVERSATIONS',
        path: 'https://help.dexatel.com/how-to-start-api-integration',
    },
];

export { UseCases, AvailableSendersOptions, ChannelAdvantages, RecommendedChannels, HelpfulGuides };
