import { IErrorKeys } from 'types/localization';

type IErrorInfo = {
    description?: keyof IErrorKeys;
    message: keyof IErrorKeys;
};

export const ErrorByStatus: { [key: string]: IErrorInfo } = {
    '1000': {
        message: 'ERROR_MESSAGE_1000',
        description: 'ERROR_DESCRIPTION_1000',
    },
    '1001': {
        message: 'ERROR_MESSAGE_1001',
        description: 'ERROR_DESCRIPTION_1001',
    },
};
export const ErrorByMethod: { [key: string]: IErrorInfo } = {
    get: {
        message: 'ERROR_MESSAGE_GET_FAILED',
        description: 'ERROR_DESCRIPTION_GET_FAILED',
    },
    put: {
        message: 'ERROR_MESSAGE_UPDATE_FAILED',
        description: 'ERROR_DESCRIPTION_UPDATE_FAILED',
    },
    post: {
        message: 'ERROR_MESSAGE_CREATE_FAILED',
        description: 'ERROR_DESCRIPTION_CREATE_FAILED',
    },
    delete: {
        message: 'ERROR_MESSAGE_DELETE_FAILED',
        description: 'ERROR_DESCRIPTION_DELETE_FAILED',
    },
};

export const NoData = {
    common: 'NO_DATA',
};
