export const mimeTypes: { [key: string]: string } = {
    'text/html': '.html',
    'text/css': '.css',
    'text/csv': '.csv',
    'text/xml': '.xml',
    'image/gif': '.gif',
    'image/jpeg': '.jpg',
    'text/plain': '.txt',
    'image/png': '.png',
    'image/vnd.wap.wbmp': '.wbmp',
    'image/x-icon': '.ico',
    'image/x-ms-bmp': '.bmp',
    'image/svg+xml': '.svg',
    'image/webp': '.webp',
    'application/msword': '.doc',
    'application/pdf': '.pdf',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.ms-powerpoint': '.ppt',
    'application/zip': '.zip',
    'application/octet-stream': '.bin',
    'audio/mpeg': '.mp3',
    'audio/ogg': '.ogg',
    'video/3gpp': '.3gp',
    'video/mpeg': '.mpeg',
    'video/quicktime': '.mov',
    'video/x-ms-wmv': '.wmv',
    'video/mp4': '.mp4',
};

export const CHANNELS = [
    { value: 'sms', label: 'SMS' },
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'viber', label: 'Viber' },
];
