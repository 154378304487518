import {
    IHeaderKeys,
    ICommonKeys,
    ITableKeys,
    IErrorKeys,
    IIndustryKeys,
    IUserDashboardKeys,
    IInboxKeys,
} from 'types/localization';

// @ts-ignore
export const COMMON_EN: ICommonKeys = {
    FILE_IMPORT_SUCCESS: 'File Imported Successfully!',
    CONTACT_IMPORT_SUCCESS: 'Created contacts!',
    COMPANY_DETAILS: 'Company Details',
    CONTENT_VERIFICATION: 'Content Verification',
    VERIFICATION_CODE_NOT_VALID: 'Verification code not valid',
    VERIFICATION_CODE_LIMIT: 'The limit for the requests exceeded the allowed count',
    LOG_IN: 'Log In',
    LOG_IN_LOWERCASE: 'Sign in',
    CREATE_ACCOUNT: 'Create a Free Account',
    FORGOT_PASSWORD: 'Forgot Password',
    REMEMBER_ME: 'Remember me',
    PASSWORD: 'Password',
    SIGN_IN_WITH_GOOGLE: 'Sign In with Google',
    SIGN_UP_WITH_GOOGLE: 'Sign Up with Google',
    USER_NOT_AUTHORIZED: 'Wrong email or password',
    ACCOUNT_NOT_FOUND: 'Wrong email or password',
    TEMPORARY_BLOCKED: 'Your account is temporarily blocked due to many failed login attempts.',
    ACCOUNT_BLOCKED:
        'Your account is temporarily blocked, please contact customer.support@dexatel.com for more information.',
    RESET_PASSWORD: 'Reset Password',
    RESET_PASSWORD_DESCRIPTION: 'Write your email and we will send you instructions to reset your password.',
    CONGRATS: 'Congrats!',
    RESET_PASSWORD_MODAL_TEXT: 'An email has been sent with instructions on password reset.',
    FILL_NEW_PASSWORD: 'Fill new password',
    NEW_PASSWORD_DESCRIPTION: 'Type the new password that you want to use for',
    RE_TYPE_NEW_PASSWORD: 'Re-type New Password',
    SUBMIT: 'Submit',
    VERIFICATION_CODE_NOT_FOUND: 'Verification code not found',
    REPEAT_PASSWORD: 'Repeat Password',
    ACCOUNT_EXISTS: 'Account with the specified email address exists',
    ACCOUNT_INVALID: 'This email is not valid',
    PASSWORD_INVALID:
        'The password should be at least 8 character long and contain at least 1 digit and one uppercase letter',
    CURRENT_PASSWORD_INVALID: 'Incorrect current password',
    ACCOUNT_VERIFICATION_STEP_NUMBER: 'Phone Verification',
    PHONE_VERIFICATION: 'Phone Number Verification',
    DOMAIN_NOT_VALID: 'The domain is not supported',

    INVALID_RECIPIENT_NUMBER: 'Invalid recipient number(s)',

    DEXATEL_DASHBOARD: 'Dexatel Dashboard',
    GETTING_STARTED: 'Getting started',
    HELLO_WORLD: 'Hello world',
    TERMS_AND_CONDITIONS_AGREE: 'By signing up I agree to the Terms & Conditions, and Policy & Privacy of Dexatel.',
    API_KEYS_PAGE_TITLE: 'API Keys',
    API_KEYS_PAGE_SUB_TITLE:
        'The API key is a unique identifier that authenticates requests associated with your account. You must have at least one API key to access all our REST API methods.',
    KEYS_PAGE_DESCRIPTION:
        'Using this REST API, you can send and receive messages, track the delivery of sent messages, retrieve and modify message history and more.',
    KEYS_PAGE_CONFIRM_DELETE: 'Are you sure you want to delete the API key?',
    KEYS_PAGE_DELETE_DESCRIPTION:
        'You need at least 1 API key to access all REST API methods and deleting it will impact integrations.',
    CREATE_KEY: 'Create a Key',
    GET_A_NEW_KEY: 'Get a New Key',
    KEY_NAME: 'Key Name',
    EDIT_KEY: 'Edit Key',
    STATUS: 'Status',
    STATUSES: 'Statuses',
    USER_STATUSES: 'User statuses',
    KEY: 'Key',
    TYPE: 'Type',
    NAME: 'Name',
    SAVE: 'Save',
    EDIT: 'Edit',
    SEND: 'Send',
    APPLY: 'Apply',
    ADD_FILTER: 'Add Filter',
    DELETE: 'Delete',
    CREATE: 'Create',
    CANCEL: 'Cancel',
    ADD: 'Add',
    FILTERS: 'Filters',
    CONFIRM: 'Confirm',
    DESCRIPTION: 'Description',
    TRY_IT_NOW: 'Try it now',
    DOCUMENTATION: 'API Documentation',
    USER_MANUAL: 'Knowledgebase',
    ADD_CHANNEL: 'Add channel',
    CHANNELS: 'Channels',
    SELECT_CHANNEL: 'Select a channel to get started',
    SELECT_CHANNEL_INFO:
        "Note: You can choose only one channel to start with. Don't worry, you can add as many channels as you want from your dashboard later. ",
    SMS: 'SMS',
    VIBER: 'Viber',
    WHATSAPP: 'WhatsApp',
    MESSAGES: 'Message Library',
    AUDIENCES: 'Audiences',
    STOP_LIST: 'Stop List',
    STOP_LIST_DESCRIPTION:
        'Here you can view and add contacts that are unsubscribed from your campaign for the specific Sender.',
    STOP_LIST_BTN: 'Add Contact',
    CONTACTS: 'Contacts',
    INSIGHTS: 'Insights',
    LINK_MANAGEMENT: 'Link Management',
    INSIGHTS_DESC:
        'In this section you can measure your business communication results and filter information to get deeper insights.',
    CHOOSE_CHANNEL: 'Choose channel',
    CHOOSE_COUNTRY: 'Choose countries',
    CHOOSE_CAMPAIGN: 'Choose campaigns',
    CAMPAIGNS: 'Campaigns',
    METHOD: 'Method',
    CURRENCY: 'Currency',
    SELECT_COUNTRY: 'Select country',
    CHOOSE_SENDER: 'Choose sender',
    CHOOSE_STATUS: 'Choose statuses',
    CHOOSE_TYPE: 'Choose type',
    APIS: 'APIs',
    RESPONSES: 'Responses',
    GET_ACCESS: 'Get Access',
    WHATSAPP_TEMPLATES: 'WhatsApp Templates',
    WHATSAPP_MESSAGE: 'WhatsApp Campaign',
    NUMBERS: 'Numbers',
    SHORT_CODES: 'Short Codes',
    VIBER_CAMPAIGN: 'Viber Campaign',
    DOWNLOAD: 'Download',
    // HEADER
    PRICING: 'Pricing',
    BALANCE: 'Balance',
    ADD_FUNDS: 'Add funds',
    FUNDS_ADDED: 'Funds added successfully!',
    SETTINGS: 'Settings',
    BILLING: 'Billing',
    LOG_OUT: 'Log out',
    SUBACCOUNTS: 'Subaccounts',
    // ACCOUNT
    PROFILE: 'Profile',
    ALERTS: 'Alerts',
    SECURITY: 'Security',
    // Security page
    SECURITY_PAGE_TITLE: 'Security',
    EMAIL: 'Email',
    CURRENT_PASSWORD: 'Current Password',
    NEW_PASSWORD: 'New Password',
    REPEAT_NEW_PASSWORD: 'Repeat New Password',
    CONNECT_MULTIPLE_ACCOUNTS: 'You can add Google account to login using it.',
    CONNECT: 'Connect',
    DISCONNECT: 'Disconnect',
    RESET: 'Reset',
    SEND_RECOVERY_LINK: 'Send Recovery Link',
    UPDATE: 'Update',
    COMPANY_NAME: 'Company Name',
    P_COMPANY_NAME: 'E.g. Redwood',
    COMPANY_ADDRESS: 'Company Address',
    P_COMPANY_ADDRESS: 'E.g. 795 E  Willow Ave. Los Angeles CA 85705',
    INDUSTRY: 'Industry',
    COMPANY_SIZE: 'Company Size',
    CHANNEL: 'Channel',
    VAT_NUMBER: 'VAT Number',
    PHONE_NUMBER: 'Phone Number',
    COUNTRY: 'Country',
    COUNTRIES: 'Countries',
    TIME: 'Time',
    TIMEZONE: 'Timezone',
    TEMPLATES_PAGE_TITLE: 'My Templates',
    TEMPLATES_PAGE_SUB_TITLE: 'Here you can create messaging samples to be easily used in SMS and Viber campaigns.',
    W_TEMPLATES_PAGE_SUB_TITLE:
        'WhatsApp templates are specific messages that businesses use to send out notifications or customer care messages to people that have opted in. WhatsApp templates are pre-approved by WhatsApp.',
    CREATE_TEMPLATE: 'Create Template',
    BUTTONS: 'Buttons',
    CREATE_TEMPLATE_NOTIFICATION: 'Template is saved',
    EDIT_TEMPLATE: 'Edit Template',
    EDIT_TEMPLATE_NOTIFICATION: 'Changes are saved',
    DELETE_TEMPLATE_MODAL_TITLE: 'Are you sure you want to delete the template(s)?',
    DELETE_TEMPLATE_MODAL_DESCRIPTION:
        'Once deleted you will not be able to use those templates for your message campaigns.',
    DELETE_TEMPLATE_NOTIFICATION: 'Template is deleted',
    OPT_OUT: 'Opt Out',
    MESSAGE: 'Message',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    DATE: 'Date',
    TIMESTAMP: 'Timestamp',
    TEMPLATE_NAME_UNIQUE: 'There is already a template with this name.',
    TEMPLATE_NAME_INVALID: 'Name is invalid',
    DISCONNECT_GOOGLE_MODAL_TITLE: 'Are you sure you want to disconnect Google  Account?',
    DISCONNECT_GOOGLE_MODAL_DESCRIPTION: 'You will no longer be able to log in with your Google account.',
    PAYMENT_REFERENCE: 'Payment Reference',
    ADD_PAYMENT_METHOD: 'Add New Card',
    PAYMENTS_METHODS_DESCRIPTION: 'Pass along a reference so you can keep track of your payments',
    SAVED_CARDS: 'Saved Cards',
    DELETE_CARD_TITLE: 'Are you sure you want to delete the saved Credit Card data?',
    DELETE_CARD_DESC: 'Removing Credit Card may affect your billing.',
    AUTO_TOP_UP_SWITCH: 'Auto-Top Up',
    TOP_UP_THRESHOLD: 'Top Up Threshold',
    TOP_UP_AMOUNT: 'Top Up Amount',
    TOP_UP_THRESHOLD_TOOLTIP:
        'Your account will automatically recharged once your balance  falls below mentioned threshold',
    TOP_UP_AMOUNT_TOOLTIP:
        'Your account will automatically recharged with mentioned amount  once your balance falls below threshold',
    EMPTY_CARD_LIST_TITLE: 'There are no cards in this section.',
    EMPTY_CARD_LIST_DESC: 'You can add a new card by clicking on Add New Card button.',
    SAVE_PAYMENT_DETAILS: 'Save my payment details for future purchases.',
    PAT_WITH_NEW_CARD: 'Pay With New Card',
    GO_BACK: 'Go Back',
    AMOUNT: 'Amount',
    PAYMENT_METHOD: 'Payment Method',
    // Senders Start
    SENDERS: 'Senders',
    SENDER_ID: 'Sender ID',
    SENDER_IDS: 'Sender IDs',
    UPDATE_COMPANY_DETAILS: 'Update Company Details',
    MY_SENDERS_PAGE_SUB_TITLE:
        'The Sender ID will be displayed to your customers at the top of their screen and is used to identify who sent the message.',
    REQUEST_SENDER_ID: 'Request Sender ID',
    MY_SENDER_IDS: 'My Sender IDs',
    MY_SENDERS_PAGE_CONFIRM_DELETE: 'Are you sure you want to delete this sender ID?',
    CARD_DELETE: 'Are you sure you want to delete the card?',
    MY_SENDERS_PAGE_CONFIRM_DELETE_DESCRIPTION:
        "The sender ID won't be associated with your account and you will no longer use it.",
    MY_SENDERS_PAGE_CONFIRM_DELETE_NUMBER: 'Are you sure you want to delete the Number?',
    MY_SENDERS_PAGE_CONFIRM_DELETE_NUMBER_DESCRIPTION:
        'This Number will be disassociated from your account and you will no longer be able to use it as a Sender.',
    MY_SENDERS_PAGE_CONFIRM_DELETE_CODE: 'Are you sure you want to delete the Short Code?',
    MY_SENDERS_PAGE_CONFIRM_DELETE_CODE_DESCRIPTION:
        'This Short Code will be disassociated from your account and you will no longer be able to use it as a Sender.',
    MY_NUMBERS_PAGE_SUB_TITLE:
        'Numbers are displayed at the top of your customer screen and they can send a reply message to these numbers.',
    MY_NUMBERS: 'My Numbers',
    BUY_NUMBER: 'Buy a Number',
    MY_NUMBERS_PAGE_CONFIRM_DELETE: 'Are you sure you want to delete the Sender?',
    MY_SHORT_CODES_PAGE_SUB_TITLE:
        'Short Codes are displayed at the top of your customer screen and they can send a reply message to these short codes.',
    REQUEST_SHORT_CODE: 'Request Short Code',
    MY_SHORT_CODES: 'My Short Codes',
    MY_SHORT_CODES_PAGE_CONFIRM_DELETE: 'Are you sure you want to delete the Sender?',
    YOUR_SHORT_CODE: 'Your Short Code',
    COMPANY_INFORMATION: 'Company Information',
    USAGE_INFORMATION: 'Usage Information',
    COMPLIANCE_KEYWORD_RESPONSES: 'Compliance Keyword Responses',
    USER_SIGN_UP: 'User Sign Up',
    EXAMPLE_MESSAGES: 'Example Messages',
    REQUIRED_ATTACHMENTS: 'Required Attachments for the USA',
    SENDER_NAME_TYPE: 'Sender Name Types',
    RANDOM_SHORT_CODE_INFO: 'It will be selected randomly and you cannot see in advance',
    VANITY_SHORT_CODE_INFO: 'You can spell out preferable short code',
    SHORT_CODE_TYPE_TITLE: 'Would you like a vanity short code or a random short code?',
    WHAT_SHORT_CODE_DO_YOU_WANT: 'What short code do you want?',
    ADD_SHORT_CODE: 'Add shortcode',
    SHORT_CODE_PAYMENT_FREQUENCY: 'How frequently do you want to pay for the short code',
    SAVE_AND_NEXT: 'Save and Next',
    WEBSITE: 'Website',
    P_WEBSITE: 'E.g. https://redwood.com',
    POINT_OF_CONTACT_NAME: 'Point of Contact Name',
    P_POINT_OF_CONTACT_NAME: 'E.g. Patricia Williams',
    CONTACT_EMAIL_ADDRESS: 'Contact Email Address',
    CONTACT_PHONE_NUMBER: 'Contact Phone Number',
    P_CONTACT_EMAIL_ADDRESS: 'E.g. partica@redwood.com',
    P_CONTACT_PHONE_NUMBER: 'E.g. +1-202-555-0157',
    SMS_CAMPAIGN_BRAND_WEBSITE: 'SMS Campaign Brand Website',
    P_SMS_CAMPAIGN_BRAND_WEBSITE: 'E.g. https://redwood.com',
    SMS_CAMPAIGN_CUSTOMER_CARE: 'SMS Campaign Customer Care Information',
    P_SMS_CAMPAIGN_CUSTOMER_CARE: 'E.g. +1-202-555-0157',
    P_SENDERS: 'E.g. Redwood',
    SMS_CAMPAIGN_FREQUENCY: 'SMS campaign frequency',
    SMS_CAMPAIGN_MESSAGE_FREQUENCY: 'Users will receive',
    SMS_CAMPAIGN_MESSAGE_FREQUENCY_INTERVAL: 'message per',
    HELP: 'Help',
    P_HELP: 'E.g. More help at info@dexatel.com, 800-555-5555.',
    STOP: 'Stop',
    P_STOP: 'E.g. You are unsubscribed from all messages.',
    KEYWORD_RESPONSE_INFO:
        'Short Codes must always respond to HELP and STOP, regardless of whether the phone number sending the keywords is actually subscribed to your campaign or not.',
    SIGN_UP_OPTIONS: 'Sign Up Options for Users',
    WEB_FORM_URL: 'Web Form Url',
    KEYWORD_URL: 'Keyword Url',
    CONFIRMATION_MESSAGE: 'Message sent to users to confirm their signup to your SMS campaign',
    P_CONFIRMATION_MESSAGE:
        'E.g. This message must contain company name, expected message frequency, and STOP and HELP keywords.',
    EXAMPLE: 'Example',
    P_EXAMPLE_1:
        'E.g. This is a reminder from Redwood Realtors about your appointment on 7th June 2021 at 3 pm. If you want to postpone it, please contact us at +1-202-555-0157.',
    P_EXAMPLE_2:
        'Hi Patricia, this is to remind you about the open house today for a property in Willow Avenue from 3 pm to 4 pm. I am looking forward to welcoming you! – Christopher Brown, Realtor.',
    P_EXAMPLE_3:
        'Hello dear Christopher, your rent due date has passed; kindly pay your rent of $400 for this month, i.e., June.',
    REQUIRED_ATTACHMENTS_INFO:
        'Carriers require a Letter of Authorization (LOA) to be provided and signed by a representative from the brand that will be appearing in your SMS campaign.',
    SUBMIT_APPLICATION: 'Submit Application',
    COMPANY_ADDRESS_REQUIREMENT: 'This must be the real operational presence of the company.',
    SENDER_ID_REQUEST_TITLE: 'Thank you for requesting a Sender ID.',
    SENDER_ID_REQUEST_DESCRIPTION:
        'It may take some time to process the application. We will notify you of any updates via email.',
    OK: 'OK',
    SENDER_ID_IS_REMOVED: 'The Sender ID is removed.',
    NUMBER_IS_DELETED: 'The Number is deleted',
    SETUP_FEE: 'Setup fee',
    MONTHLY_FEE: 'Monthly fee',
    TOTAL_FOR_ORDER: 'Total for this order',
    FIND_NUMBER: 'Find New Number',
    SELECT_TYPE: 'Select Type',
    SEARCH: 'Search',
    NUMBERS_EMPTY_TITLE: 'You have not created any Number yet.',
    NUMBERS_EMPTY_DESCRIPTION: 'You have not created any Number yet.',
    NUMBERS_NOT_FOUND_TITLE: 'No numbers found.',
    NUMBERS_NOT_FOUND_DESCRIPTION: 'No numbers found.',
    SHORT_CODE_DELETED: 'The Short Code is removed.',
    // End Senders
    // Homepage
    HELLO: 'Hello',
    WELCOME_TEXT:
        "We're happy to have you onboard to learn and enjoy how everything works. Simply book a meeting with Gohar, our customer success specialist, or just explore this specially personalized page.",
    BOOK_MEETING: 'Book a Meeting',
    MOST_BENEFICIAL_USE_CASES: 'The Most Beneficial Use Cases for',
    THE_MAIN_ADVANTAGES: 'The Main Advantages',
    APPOINTMENT_REMINDERS: 'Appointment Reminders',
    APPOINTMENT_REMINDERS_DESCRIPTION: 'Reduce no-shows with a simple appointment reminder.',
    CAMPAIGN: 'Campaign',
    PROGRAMMABLE: 'Programmable',
    REPAIR_UPDATES: 'Repair Updates',
    REPAIR_UPDATES_DESCRIPTION: 'Facilitate your customers’ lives with simple repair update notifications.',
    NEW_VEHICLES_BROADCASTING: 'New Vehicles Broadcasting',
    NEW_VEHICLES_BROADCASTING_DESCRIPTION: 'Present advantages to your customers by inviting them to test-drives.',
    AVAILABLE_SENDERS: 'Available Senders for',
    CAPABILITIES_AND_REGULATIONS: 'Capabilities and Regulations for ',
    CAPABILITIES: 'Capabilities',
    TWO_WAY_SMS: 'Two-Way SMS',
    TWO_WAY_SMS_BY_COUNTRY: 'Two-Way SMS',
    DELIVERY_RECEIPTS: 'Delivery Receipts',
    DELIVERY_RECEIPTS_BY_COUNTRY: 'Delivery Receipts',
    UNICODE_RECEIPTS: 'Unicode Supported',
    CLEAR_OPT_IN_CONSENT: 'Clear Opt-In Consent',
    PROMOTIONAL: 'Promotional',
    RECOMMENDED: 'Recommended',
    BY_COUNTRY: 'By Country',
    CLEAR_OPT_OUT: 'Clear Opt-Out',
    REQUIRED: 'Required',
    ONLY_COMMUNICATE_DURING_DAYTIME: 'Only Communicate During Daytime',
    LEARN_MORE: 'Learn More',
    BUY_NOW: 'Buy Now',
    REQUEST: 'Request',
    OTHER_RECOMMENDED_CHANNELS: 'Other Recommended Channels for',
    HELPFUL_GUIDES: 'Helpful Guides',
    HOW_TO_START_SMS_CAMPAIGN: 'How to Start with SMS Campaign',
    HOW_TO_START_SMS_CONVERSATIONS: 'How to Start SMS API Integration',
    READ_NOW: 'Read Now',
    TOLL_FREE_NUMBER: 'Toll-Free Number',
    LOCAL_NUMBER: 'Local Number',
    SHORT_CODE: 'Short Code',
    COMPLIANCES: 'Compliances',
    REGULATIONS: 'Regulations',
    TCPA: 'TCPA',
    PSD2: 'PSD2',
    PDPA: 'PDPA',
    CASL: 'CASL (equivalent of TCPA)',
    HIPAA: 'HIPAA',
    GDPR: 'GDPR',
    APA: 'APA',
    WHATSAPP_ADVANTAGES_TITLE: 'The Main Advantages of WhatsApp Business',
    ADVANTAGES_TITLE: 'The Main Advantages of',
    WHATSAPP_BUSINESS: 'WhatsApp Business',
    WHATSAPP_ADVANTAGES_ITEM1: "It is your customers' favorite app",
    WHATSAPP_ADVANTAGES_ITEM2: 'End-to-end encryption',
    WHATSAPP_ADVANTAGES_ITEM3: '‘Delivered’ and ‘Read’ message receipts',
    VIBER_BUSINESS: 'Viber Business',
    VIBER_ADVANTAGES_ITEM1: 'Send your message without limits',
    VIBER_ADVANTAGES_ITEM2: 'Increase your campaigns conversion rates',
    VIBER_ADVANTAGES_ITEM3: '‘Delivered’ and ‘Read’ message receipts',
    GET_ACCESS_TO: 'Get Access to',
    // End Homepage
    ACCOUNT_UPDATE_INFO: 'Account details updated successfully!',
    PAYMENTS_PAGE_TITLE: 'Payment Confirmations',
    PAYMENTS_PAGE_SUB_TITLE: 'Here you can view payment history and download receipts.',
    INVOICES_PAGE_TITLE: 'Invoices',
    INVOICES_PAGE_SUB_TITLE: 'Here you can view and download your invoices.',
    // Alerts page start
    ALERTS_UPDATE_INFO: 'Alerts details updated successfully!',
    // Alerts page end
    // Add funds page
    ADD_FUNDS_THANK_YOU_TITLE: 'Payment Successful!',
    ADD_FUNDS_THANK_YOU_DESC: 'You can review your payment details in Payment Confirmation page.',
    ADD_FUNDS_THANK_YOU_LINK: 'Go to See',
    ADD_FUNDS_FAILED_TITLE: 'Payment Failed',
    ADD_FUNDS_FAILED_DESC: 'Your Payment is rejected. Please try again.',
    ADD_FUNDS_FAILED_LINK: 'Try again',
    // End Add funds page
    // Start Contacts page
    MY_AUDIENCES: 'My Audiences',
    MY_AUDIENCES_PAGE_SUB_TITLE: 'Here you can create audiences to easily send them campaigns.',
    MY_AUDIENCES_WARNING: 'Give me a message',
    // End Contacts page
    TRY: 'Try',
    COMING_SOON: 'Coming Soon',
    TRY_IT_NOW_TITLE: ' Try Our Services',
    TRY_IT_NOW_DESCRIPTION:
        'Try out our services by sending a message to your own verified number. You are able to send only one test SMS by spending one Credit, two more you can spend on testing WhatsApp and Viber.',
    TRY_IT_NOW_SMS_DESCRIPTION: 'Send and receive SMS from all over the world within a seconds.',
    TRY_IT_NOW_WHATSAPP_DESCRIPTION: 'Try out the WhatsApp service by sending a message to your account.',
    TRY_IT_NOW_VIBER_DESCRIPTION: 'Try out the Viber service by sending a message to your account.',
    TRY_IT_NOW_RCS_DESCRIPTION: 'Send instant messages with the new service of Google.',
    TRY_IT_NOW_INSTAGRAM_DESCRIPTION: 'Contact us to try out Instagram business messaging.',
    TRY_IT_NOW_MESSENGER_DESCRIPTION: 'Contact us to try out Facebook business messaging.',
    TRY_IT_NOW_VOICE_DESCRIPTION: 'Verify identities and authenticate users with our flash call service.',
    TRY_IT_NOW_TELEGRAM_DESCRIPTION: 'Contact us to try out Telegram business messaging.',
    VOICE: 'Voice',
    RCS: 'RCS',
    INSTAGRAM_BUSINESS: 'Instagram Business',
    TELEGRAM: 'Telegram',
    FACEBOOK_MESSENGER: 'Facebook Messenger',
    SMS_SEND_SUCCESS: 'Your message has been successfully sent!',
    SEND_TEST_SMS: 'Send Test SMS',
    SELECT_SENDER: 'Select Sender',
    SEND_MESSAGE_WARNING_UNVERIFIED:
        'You cannot send a sms, until your account is verified. Once your account gets approved you will be notified via email.',
    SEND_MESSAGE_WARNING_SMS_COUNT:
        'You can not use “Dexatel” Sender ID anymore. Please register a new Sender ID to be able to send SMS.',
    TEST_SEND_HELPER_MESSAGE: 'This is a test message so you can send it only to your verified number.',
    RECIPIENTS: 'Recipients',
    E_G: 'E.g.',
    ADD_CONTACT: 'Add Contact',
    UPDATE_CONTACT: 'Edit Contact',
    IMPORT_FILE: 'Import File',
    CLICK_OR_DRAG_UPLOAD: 'Click or drag file to this area to upload',
    UPLOAD_FILE: 'Upload file',
    DOWNLOAD_THE_SAMPLE: 'Download the Sample',
    IMPORT: 'Import',
    AUDIENCE_NAME: 'Audience Name',
    NAME_AUDIENCE_CHOOSE_CHANNEL: 'Name the Audience and Choose Channel',
    CONTINUE: 'Continue',
    ADD_SINGLE_CONTACT: 'Add Single Contact',
    ADD_SINGLE_CONTACT_DESCRIPTION: 'Add a single contact and set up variables.',
    CHOOSE_METHOD: 'Choose Method',
    CONTACTS_IMPORT_FILE_DESCRIPTION:
        'For a large number of contacts it’s most comfortable to use a CSV or EXCEL file import (max. 100,000 rows).',
    MAX_ROWS_COUNT: 'Max. {{count}} rows',
    CREATE_AUDIENCE: 'Create Audience',
    CHOOSE_CHANNEL_CAP: 'Choose Channel',
    RENAME_AUDIENCE: 'Rename Audience',
    EDIT_AUDIENCE_NAME: 'Edit Audience Name',
    EDIT_AUDIENCE: 'Edit Audience',
    DELETE_AUDIENCE: 'Are you sure you want to delete the audience?',
    DELETE_AUDIENCE_CONFIRM: 'Confirming the deletion of an audience will remove it permanently from your account.',
    DELETE_CONTACT: 'Are you sure you want to delete this contact?',
    DELETE_CONTACT_CONFIRM: 'Deleting this contact will permanently erase their information from our system.',
    DELETE_CONTACT_STOP_LIST: 'Are you sure you want to delete this contact from the stop list?',
    DELETE_CONTACT_DESC_STOP_LIST:
        'Removing the contact from the stop list will allow them to receive messages from this sender again.',
    MY_CAMPAIGNS: 'My Campaigns',
    MY_CAMPAIGNS_PAGE_SUB_TITLE:
        'By creating campaigns you are able to send marketing messages, time-sensitive alerts, and engage your audience with direct communication.',
    CREATE_CAMPAIGN: 'Create a Campaign',
    CAMPAIGN_NAME: 'Campaign Name',
    SENDER_AND_RECIPIENT: 'Sender & Recipient',
    VERIFY_MESSAGE: 'Verify Message',
    VIBER_GET_ACCESS_TITLE: 'Get Started With Viber Business Messages',
    VIBER_GET_ACCESS_DESC: 'In order to get started with Viber Business Messages you have to register your brand.',
    VIBER_GET_ACCESS_ADD_DESC: 'The registration process will take up to 5 business days.',
    VIBER_GET_ACCESS_SUB_TITLE: 'Complete the steps below:',
    VIBER_BUSINESS_DETAILS: 'Business Details',
    VIBER_ACCOUNT_DETAILS: 'Viber Account Details',
    VIBER_DOCUMENTS: 'Documents',
    CONTACT_US: 'Contact Us',
    // WHATSAPP
    WHATSAPP_GET_ACCESS_TITLE: 'Get Started With WhatsApp Business API',
    WHATSAPP_GET_ACCESS_DESC: 'In order to get started with WhatsApp Business API you have to register your brand.',
    WHATSAPP_BUSINESS_DETAILS: 'Business Details',
    WHATSAPP_ACCOUNT_DETAILS: 'WhatsApp Account Details',
    WHATSAPP_PROFILE_CONTENT: 'Business Profile Content',
    WHATSAPP_GET_ACCESS_SUB_TITLE: 'Complete the steps below:',
    W_SENDERS_TITLE: 'WhatsApp Business Accounts',
    W_SENDERS_INFO: 'Your Business Name will be displayed to customers while sending messages.',
    LINK_MANAGEMENT_INFO: 'Here you can shorten URLs and follow the clicks on it.',
    LINK_MANAGEMENT_TITLE: 'URL Shortener',
    LINK_ADD_NEW: 'Add New Link',
    W_REQUEST_NEW: 'Request New',
    BALANCE_ALERTS: 'Balance Alerts',
    BALANCE_ALERTS_TOOLTIP:
        'You can set to receive a notification email as soon as your balance drops below your preferred amount.',
    CAMPAIGN_NAME_EXISTS_ERROR: 'The campaign with this name already exists',
    INBOUND: 'Inbound',
    OUTBOUND: 'Outbound',
    CHOOSE_METHOD_DESC: 'Your file need to include headers as in the sample for the successful import.',
    OPTION: 'option',
    OPTIONS: 'options',
    MAX_ROW_COUNT_ERROR: 'Your file contains more than {{count}} numbers.',
    AUTHORISATION_LETTER: 'Authorisation letter',
    SWEEPSTAKES_AND_CONTEST_FORMS: 'Sweepstakes and Contest Forms',
    SUBACCOUNTS_PAGE_TITLE: 'Subaccount',
    SUBACCOUNTS_PAGE_SUB_TITLE: 'This is the admin page for subaccounts. Add, remove or customize subaccounts here.',
    SUBACCOUNTS_CREATE_NEW: 'Create New',
    SUBACCOUNTS_CREATE_NEW_WARNING: 'Create New Subaccount',
    KEY_NAME_PLACEHOLDER: 'E.g Primary Key',
    KEY_IS_CREATED: 'Api Key is created.',
    KEY_CHANGES_ARE_SAVED: 'Api Key changes are saved.',
    KEY_IS_DELETED: 'Api Key is deleted',
    DISABLE_CREATE_KEY:
        'You cannot create an API Key until your account is verified. Once your account gets approved you will be notified via email.',
    ADDED: 'Added',
    TEMPLATE_SEARCH_EMPTY_STATE: 'You have not a template with this name',
    TEMPLATE_DEFAULT_EMPTY_STATE: 'You have not created any Message Templates yet.',
    TEMPLATE_DEFAULT_EMPTY_DESC: 'You can create one by clicking on the Create Template button.',
    GET_ACCESS_WARNING:
        'Your account needs to get approved before you register Sender ID. You will be notified via email as soon as you get approved',
    ENTER_VERIFICATION_CODE: 'Please enter the verification code we sent to {{number}}',
    ENTER_CODE: 'Enter the verification code',
    ENTER_VERIFICATION_CODE_TXT: 'A text message with your verification code has been sent to ',
    INVITATION_TEXT_SUB_USER: 'You have been invited by your email address ',
    YOUR_INDUSTRY: 'Your Industry',
    INVOICES_EMPTY_TEXT: 'You have no invoices yet.',
    INVOICES: 'Invoices',
    INVOICES_DESCRIPTION: 'Here you can view and download your invoices.',
    INVOICES_ERROR_MESSAGE: 'Unable to download the invoice. Please try latter',
    RATES_TITLE: 'SMS Rates',
    RATES_DESCRIPTION:
        'Welcome to the SMS rates! View and manage your SMS rates directly from this section. Easily access detailed rate information tailored to your account, and export the data into an Excel file for comprehensive analysis or reporting by clicking the Export rates button. Keep control over your messaging expenses with just a few clicks.',
    TEMPLATE: 'Template',
    TEMPLATE_NAME: 'Template Name',
    MULTIMEDIA_FILE: 'Multimedia File',
    ADD_IMAGE_LINK: 'Add image link',
    ADD_VIDEO_LINK: 'Add video link',
    ADD_DOCUMENT_LINK: 'Add document link',
    CREATE_SMS_TEMPLATE: 'Create SMS Template',
    CREATE_VIBER_TEMPLATE: 'Create Viber Template',
    OTHER: 'Other',
    MESSAGE_TEXT: 'Message text',
    ANALYTICS: 'Analytics',
    COMPANIES: 'Companies',
    PAYMENT: 'Payment',
    FINANCE: 'Finance',
    NUMBER: 'Number',
    ADD_NEW_SENDER: 'Add New Sender',
    NEW_SENDER: 'New Sender',
    ADMIN_NEW_SENDER_DESC: 'Create new sender for each company.',
    SENDER: 'Sender',
    SELECT_BY_NAME: 'Select by name',
    SUMMARY: 'Summary',
    REVIEW_AND_VERIFY: 'Review and Verify Message',
    NOT_ENOUGH_BALANCE: 'You do not have enough balance for this campaign',
    SENDER_NAME: 'Sender Name',
    NUMBER_OF_RECIPIENTS: 'Number of recipients',
    ESTIMATED_COST: 'Estimated Cost',
    CHARGED: 'Charged',
    RESULTS: 'Results',
    TRUSTED: 'Trusted',
    VERIFY_ACCOUNT_TEXT: 'Verify your account to start free trial',
    POSITION: 'Position',
    POSITIONS: 'Positions',
    INVITE: 'Invite',
    CUSTOM_1: 'Custom 1',
    CUSTOM_2: 'Custom 2',
    BLACKLIST_NEW: 'Edit Blacklisted Countries',
    USER_STATUS: 'User Status',
    WA_CATEGORY: 'WhatsApp Category',
    TOOLS: 'Tools',
    CARRIER_LOOKUP: 'Carrier Lookup',
    BULK_LOOKUP_TITLE: 'Bulk Lookup',
    BULK_LOOKUP_DESCRIPTION:
        'Easily check the status and details of multiple numbers at once using our Bulk Number Lookup tool. To start a new bulk lookup, click the "New Lookup" button and follow the instructions.',
    NEW_LOOKUP: 'New Lookup',
    SINGLE_LOOKUPS_HISTORY_TITLE: 'Single Lookups History',
    SINGLE_LOOKUPS_HISTORY_DESCRIPTION:
        'Review the details of your previous single number lookups. Easily access and track your lookup history for quick reference.',
    SINGLE_LOOKUP_DELETE: 'Are you sure you want to delete this record?',
    SINGLE_LOOKUP_IS_DELETED: 'The lookup record was successfully deleted.',
};

export const INDUSTRIES_EN: IIndustryKeys = {
    AUTOMOTIVE: 'Automotive',
    ECOMMERCE: 'eCommerce',
    EDUCATION: 'Education',
    FINANCIAL_SERVICES: 'Financial Services',
    HEALTHCARE: 'Healthcare',
    LEGAL: 'Legal',
    REAL_ESTATE: 'Real Estate',
    RETAIL: 'Retail',
    HOSPITALITY: 'Hospitality',
    FOOD_BEVERAGE: 'Food & Beverage',
};

export const HEADERS_EN: IHeaderKeys = {
    HEADER_DEFAULT: '',
    HEADER_DASHBOARD: 'Welcome',
    HEADER_SMS: 'SMS',
    HEADER_WHATSAPP: 'WhatsApp',
    HEADER_VIBER: 'Viber',
    HEADER_SENDERS: 'Senders',
    HEADER_LIBRARY: 'Library',
    HEADER_CONTACTS: 'Contacts',
    HEADER_INSIGHTS: 'Insights',
    HEADER_LINK_MANAGEMENT: 'Link Management',
    HEADER_APIS: 'APIs',
    HEADER_RESPONSES: 'Responses',
    HEADER_USER: 'Settings',
    HEADER_INBOX: 'Inbox',
    HEADER_PROFILE: 'Settings',
    HEADER_ALERTS: 'Settings',
    HEADER_USERS: 'Settings',
    HEADER_SECURITY: 'Settings',
    HEADER_BILLING: 'Billing',
    HEADER_SUBACCOUNTS: 'Subaccount',
    HEADER_COUNTRY_BLACKLIST: 'Settings',
    HEADER_CARRIER_LOOKUP: 'Carrier Lookup',
};

export const TABLE_KEYS_EN: ITableKeys = {
    TABLE_KEY_EXAMPLE: 'Example',
    TABLE_KEY_VALUE: 'Value',
    TABLE_KEY_DISPLAY_NAME: 'Key Name',
    TABLE_KEY_CREATE_DATE: 'Created Date',
    TABLE_KEY_DATE_ADDED: 'Created Date',
    TABLE_KEY_CREATED: 'Created Date',
    TABLE_KEY_ID: 'Sender ID',
    TABLE_KEY_STATUS: COMMON_EN.STATUS,
    TABLE_KEY_TYPE: COMMON_EN.TYPE,
    TABLE_KEY_PAYMENT_TYPE: COMMON_EN.TYPE,
    TABLE_KEY_UPDATE_DATE: COMMON_EN.DATE,
    TABLE_KEY_CODE: 'Code',
    TABLE_KEY_NUMBER: 'Number',
    TABLE_KEY_SETUP_COST: 'Setup Fee',
    TABLE_KEY_MONTHLY_COST: 'Monthly Fee',
    TABLE_KEY_EMPTY: '',
    TABLE_KEY_SUM: 'Amount',
    TABLE_KEY_DATE: 'Date',
    TABLE_KEY_CHANNEL: 'Channel',
    TABLE_KEY_TEXT: 'Message',
    TABLE_KEY_SENDER: 'Sender',
    TABLE_KEY_SEGMENTS: 'Segments',
    TABLE_KEY_TO: 'Phone',
    TABLE_KEY_SOURCE: 'Type',
    TABLE_KEY_NAME: 'Name',
    TABLE_KEY_TEMPLATE_NAME: 'Template Name',
    TABLE_KEY_FIRST_NAME: 'First Name',
    TABLE_KEY_LAST_NAME: 'Last Name',
    TABLE_KEY_SENDER_ID: 'Sender ID',
    TABLE_KEY_COUNTRY: 'Country',
    TABLE_KEY_RECEIVED_DATE: 'Received Date',
    TABLE_KEY_CAMPAIGN_NAME: 'Campaign Name',
    TABLE_KEY_SCHEDULE: 'Scheduled Date',
    TABLE_KEY_KEY: 'API Key',
    TABLE_KEY_PERIOD_START: 'Period',
    TABLE_KEY_AMOUNT: 'Amount',
    TABLE_KEY_NET_AMOUNT: 'Net Amount',
    TABLE_KEY_AMOUNT_VAT: 'Amount (inc. VAT)',
    TABLE_KEY_EMAIL: 'Email',
    TABLE_KEY_EMAIL_ADDRESS: 'Email Address',
    TABLE_KEY_COMPANY_STATUS: 'Company Status',
    TABLE_KEY_USER_STATUS: 'User Status',
    TABLE_KEY_CREATED_TIME: 'Created Time',
    TABLE_KEY_CREATED_DATE: 'Created Date',
    TABLE_KEY_STATISTIC_PERIOD: 'Statistics Period',
    TABLE_KEY_COMPANY_NAME: 'Company Name',
    TABLE_KEY_SENT: 'Outgoing',
    TABLE_KEY_RECEIVED: 'Incoming',
    TABLE_KEY_DELIVERED: 'Delivered',
    TABLE_KEY_READ: 'Read',
    TABLE_KEY_CHARGED: 'Charged',
    TABLE_KEY_FAILED: 'Failed',
    TABLE_KEY_DLR: 'DLR (%)',
    TABLE_KEY_SELLING_PRICE: 'Selling Price',
    TABLE_KEY_BUYING_PRICE: 'Buying Price',
    TABLE_KEY_COMPANY: 'Company Name',
    TABLE_KEY_COMPANY_ID: 'Company ID',
    TABLE_KEY_BALANCE: 'Balance',
    TABLE_KEY_REVENUE: 'Revenue',
    TABLE_KEY_COST: 'Cost',
    TABLE_KEY_MARGIN: 'Margin',
    TABLE_KEY_MARGIN_PERCENT: 'Margin (%)',
    TABLE_KEY_TOTAL: 'Total',
    TABLE_KEY_POSITION: 'Position',
    TABLE_KEY_METHOD: 'Method',
    TABLE_KEY_COMMENT: 'Comment',
    TABLE_KEY_SHORT_CODE: 'Short Code',
    TABLE_KEY_PRIORITY: 'Source',
    TABLE_KEY_VERIFICATION_STATUS: 'Code Status',
    TABLE_KEY_WA_CATEGORY: 'WhatsApp Category',
    // SMS-Limits
    TABLE_KEY_LIMIT_TYPE: 'Limit Type',
    TABLE_KEY_COUNTRY_LIST: 'Countries',
    TABLE_KEY_LIMITS: 'Limits',
    TABLE_KEY_INTERVAL: 'Interval',
    TABLE_KEY_BLOCKING: 'Block Traffic',
    TABLE_KEY_ENABLED: 'Enabled',
    TABLE_KEY_FULL_NAME: 'Full Name',
    TABLE_KEY_UPDATED: 'Created',
    TABLE_KEY_MCCMNC: 'MCCMNC',
    TABLE_KEY_NETWORK: 'Network',
    TABLE_KEY_PRICE: 'Price',
    TABLE_KEY_MSISDN: 'Number',
    TABLE_KEY_VALID: 'Status',
    TABLE_KEY_OPERATOR: 'Phone Company',
    TABLE_KEY_REPORTED_DATE: 'Date of Report',
    TABLE_KEY_LINE: 'Line Type',
    TABLE_KEY_PORTED: 'Ported',
    TABLE_KEY_COUNTRY_NAME: 'Country',
    TABLE_KEY_FILE_NAME: 'File Name',
    TABLE_KEY_CHECKED: 'Checked',
    TABLE_KEY_SKIPPED: 'Skipped',
};

export const ERROR_MESSAGES_EN: IErrorKeys = {
    ERROR_DEFAULT: 'Something went wrong!',
    ERROR_DESCRIPTION_1000: 'Custom error description for 1000 status code',
    ERROR_MESSAGE_1000: 'Custom error for 1000 status code',
    ERROR_DESCRIPTION_1001: 'Custom error description for 1000 status code',
    ERROR_MESSAGE_1001: 'Custom error for 1000 status code',
    ERROR_MESSAGE_1002: 'You can not create an account. Please contact customer.support@dexatel.com',
    ERROR_MESSAGE_1125: 'Verification code is not valid',
    ERROR_MESSAGE_1137:
        'You do not have a permission to create an account. Please contact customer.support@dexatel.com in case of questions.',
    ERROR_MESSAGE_1303: 'Audience name is missing ',
    ERROR_MESSAGE_1304: 'The name "audience-N" can not be used, try another name',
    ERROR_MESSAGE_1305: 'Audience name is too long',
    ERROR_MESSAGE_1309: 'An audience with this name already exists',
    ERROR_MESSAGE_1311: '“Number” heading is missed. Your file can not be imported.',
    ERROR_MESSAGE_1312: 'Invalid delimiter',
    ERROR_MESSAGE_1314: 'You have exceeded the maximum tags number.',
    ERROR_MESSAGE_1315: 'Maximum number of contacts exceeded.',
    ERROR_MESSAGE_1310: 'Limit Exceed',
    ERROR_MESSAGE_1129: 'There is already an account with the specified company name',
    ERROR_MESSAGE_1133: 'There is already a user with the specified phone number',
    ERROR_MESSAGE_1441: 'Sender ID already exists on this channel',
    ERROR_MESSAGE_1405: 'Invalid Sender ID',
    ERROR_MESSAGE_1445: 'Business Name in English is invalid',
    ERROR_MESSAGE_1446: 'There is already a registered business with this name',
    ERROR_MESSAGE_1447: 'Business Name is invalid',
    ERROR_MESSAGE_1448: 'Business Address is invalid',
    ERROR_MESSAGE_1449: 'Business Description is invalid',
    ERROR_MESSAGE_1450: 'Business Website is invalid',
    ERROR_MESSAGE_1451: 'Business Phone is invalid',
    ERROR_MESSAGE_1452: 'Business Registration Country is invalid',
    ERROR_MESSAGE_1453: 'Targeted countries list can not be empty',
    ERROR_MESSAGE_1460: 'Parent ASP Name is invalid',
    ERROR_MESSAGE_1461: 'Business Website Additional is invalid',
    ERROR_MESSAGE_1462: 'Business Email is invalid',
    ERROR_MESSAGE_1463: 'Industry is invalid',
    ERROR_MESSAGE_1464: 'Targeted locations is invalid',
    ERROR_MESSAGE_1504: 'Invalid Message Sender',
    ERROR_MESSAGE_1523: 'Invalid recipient number(s)',
    ERROR_MESSAGE_1525: 'You have reached your test message limit',
    ERROR_MESSAGE_1507: 'Maximum 10  comma-separated recipients',
    ERROR_MESSAGE_1530: 'Blocked number',
    ERROR_MESSAGE_1705: 'Field can not be empty',
    ERROR_MESSAGE_1801: 'You failed to pass phone verification for this phone number. Please try later.',
    ERROR_MESSAGE_1802: 'Another profile exists with this phone number.',
    ERROR_MESSAGE_1262: 'Name is up to 45 characters.',
    ERROR_MESSAGE_1003: 'The contact is already blocked for the {{sender}}',
    ERROR_MESSAGE_1443: 'Invalid phone number:  {{number}}',
    ERROR_MESSAGE_7909: 'Your balance is not enough.',
    ERROR_MESSAGE_7910: 'The phone number is invalid.',
    ERROR_MESSAGE_7912: 'The CSV file contains wrong data.',
    ERROR_MESSAGE_UNKNOWN: 'Something went wrong, please try again later',
    ERROR_DESCRIPTION_DELETE_FAILED: 'Something went wrong',
    ERROR_DESCRIPTION_CREATE_FAILED: 'Something went wrong',
    ERROR_DESCRIPTION_UPDATE_FAILED: 'Something went wrong',
    ERROR_DESCRIPTION_GET_FAILED: 'Something went wrong',
    ERROR_MESSAGE_CREATE_FAILED: 'Unable to create',
    ERROR_MESSAGE_DELETE_FAILED: 'Unable to delete',
    ERROR_MESSAGE_UPDATE_FAILED: 'Unable to update',
    ERROR_MESSAGE_GET_FAILED: 'Unable to get',
    ERROR_MESSAGE_FILE_DIMENSION: 'Allowed size is {{allowedSize}}',
    ERROR_MESSAGE_FILE_SIZE: 'The file is too large ({{fileSize}} MB). Allowed maximum size is {{maxSize}} MB.',
    ERROR_MESSAGE_FILE_EXTENSION:
        'The extension of the file is invalid {{fileExtension}} . Allowed extensions are {{extension}}.',
    ERROR_MESSAGE_FILE_EXTENSION_SINGULAR:
        'The extension of the file is invalid {{fileExtension}} . Allowed extension is {{extension}}.',
    NO_DATA: 'There is currently no data',
};

const USER_DASHBOARD_EN: IUserDashboardKeys = {
    USERS_CREATE_NEW: 'Add New User',
    USER_DETAILS: 'User Details',
    USER_PERMISSIONS_TABLE: 'Permission Table',
    USER_DETAILS_DESCRIPTION: 'Create new users with varying degrees of permission.',
    USER_PERMISSIONS_TABLE_HEADING: 'Users Permissions Table',
    USER_PERMISSIONS_TABLE_DESCRIPTION: 'Create new users with varying degrees of permissions.',
    USER_DELETE: 'Delete User',
    USER_CREATE_SUCCESS: 'An invitation has been successfully sent.',
    USER_DELETE_SUCCESS: 'User is deleted successfully.',
    USER_EDIT_SUCCESS: 'User is edited successfully.',
    USER_UPDATE_SELF: 'User details updated successfully!',
};

const INBOX_EN: IInboxKeys = {
    INBOX: 'Inbox',
    INBOX_ASSIGNED_TO_ME: 'Assigned to Me',
    INBOX_ASSIGNED_TO_OTHERS: 'Other',
    INBOX_UNASSIGNED: 'Unassigned',
};

const translations = {
    ...COMMON_EN,
    ...HEADERS_EN,
    ...TABLE_KEYS_EN,
    ...ERROR_MESSAGES_EN,
    ...INDUSTRIES_EN,
    ...USER_DASHBOARD_EN,
    ...INBOX_EN,
};

export default translations;
