import React, { FC, memo } from 'react';
import { Button } from 'antd';

import { IButtonProps } from 'types/components';
import 'assets/scss/dxAppendButton.scss';
import { DxIcon } from 'components';

const DxAppendButton: FC<IButtonProps> = ({ children, customClassName, ...restProps }) => {
    return (
        <div className={`dx-append-button ${customClassName || ''}`}>
            <Button {...restProps}>
                <span className="dx-append-button-content">{children}</span>
                <DxIcon type="plus_circle" size="sm" />
            </Button>
        </div>
    );
};

export default memo(DxAppendButton);
