import axios from 'axios';
import momentTimezone from 'moment-timezone';

import { IMessageItem, IMessagesByDate } from './Messages/types';
import {
    IAssignmentItem,
    IBadges,
    IConversations,
    ILastRead,
    IMessages,
    IUnreadConversations,
    IUnreadConversationsByDate,
    IUtils,
} from '../../types/reducers/inbox';
import { SSE_EVENT_TYPES } from '../../hooks/useSSEEvents';
import { mimeTypes } from './constants';
import { IConversationItem } from './types';
import { DxShowNotification } from '../../components';
import { initialState } from '../../reducers/inbox.reducer';

export const dummyMessageGroup = {
    conversation_id: '',
    assignee_id: '',
    assignee_name: '',
    status: '',
    type: '',
    from: '',
    to: '',
    text: '',
    url: '',
    file_name: '',
    media_type: '',
    media_id: '',
    thumbnail_url: '',
    button_type: '',
    button_text: '',
    button_value: '',
    user_name: '',
    timestamp: 0,
    assignee_initials: '',
};

const getMediaTypeFromUrl = (url: string) => {
    if (/\.(jpg|jpeg|png|bmp|gif|svg|webp)$/i.test(url)) {
        return 'image';
    } else if (/\.(3gpp|m4v|mov|mp4)$/i.test(url)) {
        return 'video';
    } else if (
        /\.(txt|doc|docx|dot|dotx|rtf|odt|odf|fodt|info|pdf|xps|pdax|eps|xls|xlsx|xltx|xlsm|csv|ods|fods)$/i.test(url)
    ) {
        return 'document';
    }
    return '';
};

export const downloadInboxFile = async (url: string, filename: string, setDownloading: (state: string) => void) => {
    try {
        const result = await axios({ url, method: 'GET', responseType: 'blob' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([result.data]));
        if (!mimeTypes[result.headers['content-type']]) {
            throw new Error('Unsupported file format');
        }
        link.download = filename ? filename : 'Media_File' + mimeTypes[result.headers['content-type']];
        link.click();
        setDownloading('');
    } catch (error) {
        setDownloading('');
        window.open(url);
    }
};

export const findLastReadMessageId = (
    data: IMessageItem[],
    lastReadTimestamp: number,
    unreadCount: number,
): { lastReadId: string; unreadCount: number } => {
    for (const message of data) {
        if (message.timestamp > lastReadTimestamp && message.type === 'MO') {
            if (--unreadCount === 0) {
                return { lastReadId: message.id, unreadCount };
            }
        }
    }
    return { lastReadId: '', unreadCount };
};

const chatFormat = 'DD MMMM';
const timeFormat = 'DD-MM-YYYY HH:mm';

export const getStickyText = (timestamp: number, timezone: string, precise: boolean): string => {
    let messageTimeWithTimezone = momentTimezone.utc(timestamp);
    if (timezone) {
        messageTimeWithTimezone = messageTimeWithTimezone.clone().tz(timezone);
    }
    const nowWithTimezone = momentTimezone.utc(new Date());
    if (messageTimeWithTimezone.format(chatFormat) === nowWithTimezone.format(chatFormat)) {
        return 'Today';
    } else {
        const yesterday = new Date();
        let yesterdayWithTimezone = momentTimezone.utc(yesterday.setDate(yesterday.getDate() - 1));
        if (timezone) {
            yesterdayWithTimezone = yesterdayWithTimezone.clone().tz(timezone);
        }
        if (messageTimeWithTimezone.format(chatFormat) === yesterdayWithTimezone.format(chatFormat)) {
            return 'Yesterday';
        } else {
            return precise ? messageTimeWithTimezone.format(chatFormat) : 'Older';
        }
    }
};
export const groupMessagesByDate = (
    data: IMessageItem[],
    timezone: string,
    initialData: IMessagesByDate[],
    fromEvent = false,
): IMessagesByDate[] => {
    return data.reduce((result, message) => {
        /**
         * Add initials
         */
        if (message.user_name || message.assignee_name) {
            const userName = message.user_name?.split('');
            const assigneeName = message.assignee_name?.split(' ');
            if (userName?.length) {
                message.assignee_initials =
                    userName.length > 1
                        ? userName[0][0].toUpperCase() + userName[1][0].toUpperCase()
                        : userName[0][0]?.toUpperCase();
            } else if (assigneeName?.length) {
                message.assignee_initials =
                    assigneeName.length > 1
                        ? assigneeName[0][0].toUpperCase() + assigneeName[1][0].toUpperCase()
                        : assigneeName[0][0]?.toUpperCase();
            }
        }
        if (message.url && !message.media_type) {
            message.media_type = getMediaTypeFromUrl(message.url);
        }
        const stickyText = getStickyText(message.timestamp, timezone, true);
        let startOfDayTimestamp = momentTimezone.utc(message.timestamp);
        if (timezone) {
            startOfDayTimestamp = startOfDayTimestamp.clone().tz(timezone);
        }
        const dayGroup = result.find((group) => group.stickyText === stickyText);
        if (dayGroup) {
            fromEvent ? dayGroup.messages.unshift(message) : dayGroup.messages.push(message);
        } else {
            fromEvent
                ? result.unshift({
                      stickyText,
                      messages: [message],
                      startOfDayTimestamp: startOfDayTimestamp.startOf('day').unix() * 1000,
                  })
                : result.push({
                      stickyText,
                      messages: [message],
                      startOfDayTimestamp: startOfDayTimestamp.startOf('day').unix() * 1000,
                  });
        }
        return result;
    }, initialData);
};

export const groupConversationsByDate = (data: IConversationItem[], timezone: string) => {
    return data.reduce((result, conversation) => {
        const stickyText = getStickyText(conversation.timestamp, timezone, false);
        const dayGroup = result.find((group) => group.stickyText === stickyText);
        if (dayGroup) {
            dayGroup.conversations.push(conversation);
        } else {
            result.push({ stickyText, conversations: [conversation] });
        }
        return result;
    }, [] as IUnreadConversationsByDate[]);
};

export const timestampToDate = (timestamp: number, timezone: string, format = timeFormat) => {
    let messageTimeWithTimezone = momentTimezone.utc(timestamp);
    if (timezone) {
        messageTimeWithTimezone = messageTimeWithTimezone.clone().tz(timezone);
    }
    return messageTimeWithTimezone.format(format);
};

export const handleConversationEvent = (
    oldBadges: IBadges,
    oldConversations: IConversations,
    utils: IUtils,
    event: any,
): { badges: IBadges; conversations: IConversations; messagesActive?: boolean } => {
    const eventConversationId: string = event.conversation.id;
    const updateState = event.state;
    /**
     * Check if event matched filtering on front end side
     */
    let passesFiltering = false;
    if (
        event.state === 'DELETED' ||
        ((oldConversations.filters.senderId === 'All Senders' ||
            event.conversation.sender_id === oldConversations.filters.senderId) &&
            event.conversation.number.startsWith(oldConversations.filters.search) &&
            (oldConversations.filters.channels.includes(event.conversation.channel.toLowerCase()) ||
                oldConversations.filters.channels.length === 0))
    ) {
        passesFiltering = true;
    }
    if (updateState === 'NEW') {
        // NEW CONVERSATION
        // 1) MENU
        const newBadges = { ...oldBadges };
        const eventBadgeCount = event.badgeCount;
        const updatePlace = event.conversation.assignee_id;
        if (!updatePlace) {
            newBadges.none[eventConversationId] = eventBadgeCount;
        } else if (updatePlace === utils.userId) {
            newBadges.self[eventConversationId] = eventBadgeCount;
        } else {
            newBadges.others[eventConversationId] = eventBadgeCount;
        }
        const openedChatType = oldConversations.assignee;
        if (openedChatType && passesFiltering) {
            let newConversationsData = oldConversations.data;
            newConversationsData = newConversationsData.filter((conversation) => {
                return conversation.id !== eventConversationId;
            });
            if (openedChatType === 'self' && updatePlace === utils.userId) {
                newConversationsData.unshift({ ...event.conversation, unread_count: eventBadgeCount });
            } else if (openedChatType === 'others' && updatePlace !== utils.userId) {
                newConversationsData.unshift({ ...event.conversation, unread_count: eventBadgeCount });
            } else if (openedChatType === 'none' && !updatePlace) {
                newConversationsData.unshift({ ...event.conversation, unread_count: eventBadgeCount });
            }
            return { badges: newBadges, conversations: { ...oldConversations, data: newConversationsData } };
        } else {
            return { badges: newBadges, conversations: oldConversations };
        }
    } else if (updateState === 'UPDATED_READ_TIMESTAMP') {
        // UPDATE TIMESTAMP
        // 1) MENU
        const updatePlace = event.conversation.assignee_id;
        const newBadges = { ...oldBadges };
        if (!updatePlace) {
            newBadges.none[eventConversationId] = 0;
        } else if (updatePlace === utils.userId) {
            newBadges.self[eventConversationId] = 0;
        } else {
            newBadges.others[eventConversationId] = 0;
        }
        const openedChatType = oldConversations.assignee;
        if (openedChatType && passesFiltering) {
            // 2) Conversations
            const newConversationsData = oldConversations.data.map((conversation) => {
                if (conversation.id === eventConversationId) {
                    return { ...event.conversation, unread_count: 0 };
                }
                return conversation;
            });
            return { badges: newBadges, conversations: { ...oldConversations, data: newConversationsData } };
        } else {
            return { badges: newBadges, conversations: oldConversations };
        }
    } else if (updateState === 'UPDATED_ASSIGNEE') {
        // UPDATE ASSIGNEE
        const eventBadgeCount = event.badgeCount;
        const updatePlace = event.conversation.assignee_id;
        const oldPlace = event.previousAssignee;
        const newBadges = { ...oldBadges };
        const openedChatType = oldConversations.assignee;
        // 1) MENU
        if (!updatePlace) {
            if (oldPlace === utils.userId) {
                delete newBadges.self[eventConversationId];
            } else {
                delete newBadges.others[eventConversationId];
            }
            newBadges.none[eventConversationId] = utils.canView.none ? eventBadgeCount : 0;
        } else {
            if (updatePlace === utils.userId) {
                if (oldPlace) {
                    delete newBadges.others[eventConversationId];
                }
                newBadges.self[eventConversationId] = utils.canView.self ? eventBadgeCount : 0;
            } else {
                if (oldPlace === utils.userId) {
                    delete newBadges.self[eventConversationId];
                }
                newBadges.others[eventConversationId] = utils.canView.others ? eventBadgeCount : 0;
            }
            if (!oldPlace) {
                delete newBadges.none[eventConversationId];
            }
        }
        // 2) Conversations
        if (openedChatType && passesFiltering) {
            let newConversationsData = oldConversations.data;
            newConversationsData = newConversationsData.filter((conversation) => {
                return conversation.id !== eventConversationId;
            });
            if (openedChatType === 'self') {
                if (updatePlace === utils.userId) {
                    newConversationsData.unshift({ ...event.conversation, unread_count: eventBadgeCount });
                }
            } else if (openedChatType === 'others') {
                if (updatePlace && updatePlace !== utils.userId) {
                    newConversationsData.unshift({ ...event.conversation, unread_count: eventBadgeCount });
                }
            } else if (openedChatType === 'none' && !updatePlace) {
                newConversationsData.unshift({ ...event.conversation, unread_count: eventBadgeCount });
            }
            return { badges: newBadges, conversations: { ...oldConversations, data: newConversationsData } };
        } else {
            return { badges: newBadges, conversations: oldConversations };
        }
    } else if (updateState === 'DELETED') {
        const updatePlace = event.conversation.assignee_id;
        const newBadges = { ...oldBadges };
        // 1) MENU
        if (!updatePlace) {
            delete newBadges.none[eventConversationId];
        } else if (updatePlace === utils.userId) {
            delete newBadges.self[eventConversationId];
        } else {
            delete newBadges.others[eventConversationId];
        }
        // 2) Conversations
        let newConversationsData = oldConversations.data;
        newConversationsData = newConversationsData.filter((conversation) => {
            return conversation.id !== eventConversationId;
        });
        return {
            badges: newBadges,
            conversations: { ...oldConversations, data: newConversationsData },
        };
    } else {
        // MESSAGE
        const updatePlace = event.conversation.assignee_id;
        const newBadges = { ...oldBadges };
        const openedChatType = oldConversations.assignee;
        if (event.conversation.type === 'MO') {
            if (!updatePlace) {
                newBadges.none[eventConversationId] += 1;
            } else if (updatePlace === utils.userId) {
                newBadges.self[eventConversationId] += 1;
            } else {
                newBadges.others[eventConversationId] += 1;
            }
        }
        if (openedChatType && passesFiltering) {
            let newConversationsData = [...oldConversations.data];
            const foundConversation = newConversationsData.find(
                (conversation) => conversation.id === eventConversationId,
            );
            if (openedChatType === 'none' && !updatePlace) {
                if (foundConversation) {
                    newConversationsData = newConversationsData.map((conversation) => {
                        if (conversation.id !== eventConversationId) {
                            return conversation;
                        }
                        return {
                            ...event.conversation,
                            unread_count: newBadges.none[eventConversationId],
                        };
                    });
                    newConversationsData.sort((a, b) => b.timestamp - a.timestamp);
                } else {
                    newConversationsData.unshift({
                        ...event.conversation,
                        unread_count: newBadges.none[eventConversationId],
                    });
                }
            } else if (openedChatType === 'self' && updatePlace === utils.userId) {
                if (foundConversation) {
                    newConversationsData = newConversationsData.map((conversation) => {
                        if (conversation.id !== eventConversationId) {
                            return conversation;
                        }
                        return {
                            ...event.conversation,
                            unread_count: newBadges.self[eventConversationId],
                        };
                    });
                    newConversationsData.sort((a, b) => b.timestamp - a.timestamp);
                } else {
                    newConversationsData.unshift({
                        ...event.conversation,
                        unread_count: newBadges.self[eventConversationId],
                    });
                }
            } else if (openedChatType === 'others' && updatePlace && updatePlace !== utils.userId) {
                if (foundConversation) {
                    newConversationsData = newConversationsData.map((conversation) => {
                        if (conversation.id !== eventConversationId) {
                            return conversation;
                        }
                        return {
                            ...event.conversation,
                            unread_count: newBadges.others[eventConversationId],
                        };
                    });
                    newConversationsData.sort((a, b) => b.timestamp - a.timestamp);
                } else {
                    newConversationsData.unshift({
                        ...event.conversation,
                        unread_count: newBadges.others[eventConversationId],
                    });
                }
            }
            return { badges: newBadges, conversations: { ...oldConversations, data: newConversationsData } };
        } else {
            return { badges: newBadges, conversations: oldConversations };
        }
    }
};

export const handleMessageEvent = (
    oldMessages: IMessages,
    selectedConversation: IConversationItem | null,
    lastRead: ILastRead,
    timezone: string,
    event: any,
): { messages: IMessages; lastRead: ILastRead; selectedConversation: IConversationItem | null } => {
    if (!selectedConversation) {
        return { messages: oldMessages, lastRead, selectedConversation };
    }
    if (selectedConversation && selectedConversation.id !== event.message.conversation_id) {
        return { messages: oldMessages, lastRead, selectedConversation };
    }
    let newSelectedConversation = selectedConversation;
    /**
     * Update Unread count
     */
    if (event.message.type === 'MO') {
        newSelectedConversation = { ...selectedConversation, unread_count: selectedConversation.unread_count + 1 };
    }
    /**
     * Last read logic
     * 1) IF 'MT' received set lastRead: {true, ''}, it means that we don't have any unread messages
     * 2) IF 'MO' received check lastRead 2.1) if true and '' set {true, 'id'}, 2.2) if false keep it as it is
     */
    const newLastRead = { ...lastRead };
    if (event.message.type === 'MT') {
        newLastRead.found = true;
        newLastRead.id = '';
    } else {
        if (lastRead.found && !newLastRead.id) {
            newLastRead.found = true;
            newLastRead.id = event.message.id;
        }
    }
    const newMessageAdded = groupMessagesByDate([event.message], timezone, oldMessages.data, true);
    return {
        messages: {
            ...oldMessages,
            data: newMessageAdded,
            isActiveConversation: event.isActiveConversation,
            isViberLimitActive: event.isViberLimitActive,
            allRead: '',
        },
        lastRead: newLastRead,
        selectedConversation: newSelectedConversation,
    };
};

export const handleStatusEvent = (
    oldMessages: IMessages,
    selectedConversationId: string | undefined,
    event: any,
): { messages: IMessages } => {
    if (!selectedConversationId) {
        return { messages: oldMessages };
    }
    if (selectedConversationId && selectedConversationId !== event.conversation_id) {
        return { messages: oldMessages };
    }
    oldMessages.data.forEach((messageGroup) => {
        messageGroup.messages.forEach((message) => {
            if (message.id === event.message_id) {
                message.status = event.status;
            }
        });
    });
    return { messages: { ...oldMessages } };
};

export const handleUnreadConversationEvent = (
    oldUnreadConversations: IUnreadConversations,
    badges: IBadges,
    selfId: string,
    event: any,
): { unreadConversations: IUnreadConversations } => {
    if (!oldUnreadConversations.isOpen) {
        return { unreadConversations: oldUnreadConversations };
    }
    /**
     * Badges are already updated just take em
     */
    const eventConversationId: string = event.conversation.id;
    const updatePlace = event.conversation.assignee_id;
    let updatedBadgeCount;
    if (!updatePlace) {
        updatedBadgeCount = badges.none[eventConversationId];
    } else if (updatePlace === selfId) {
        updatedBadgeCount = badges.self[eventConversationId];
    } else {
        updatedBadgeCount = badges.others[eventConversationId];
    }
    let newUnreadConversationsData = [...oldUnreadConversations.data];
    newUnreadConversationsData = newUnreadConversationsData.filter((conversation) => {
        return conversation.id !== eventConversationId;
    });
    if (updatedBadgeCount) {
        newUnreadConversationsData.unshift({ ...event.conversation, unread_count: updatedBadgeCount });
    }
    return { unreadConversations: { ...oldUnreadConversations, data: newUnreadConversationsData } };
};

/**
 * Inject assignment history into messages
 */
export const injectAssignmentHistory = (
    messagesGroup: IMessages,
    assignments: IAssignmentItem[],
    timezone: string,
): IMessages => {
    const messagesData = messagesGroup.data;
    let finalIndex = assignments.length;
    for (let i = assignments.length - 1; i >= 0; --i) {
        const assignmentStickyText = getStickyText(assignments[i].timestamp, timezone, true);
        const groupExist = messagesData.find((message) => {
            return message.stickyText === assignmentStickyText;
        });
        /**
         * If assignment should be added into existing group
         */
        if (groupExist) {
            --finalIndex;
            groupExist.messages.push({
                ...dummyMessageGroup,
                id: assignments[i].id,
                timestamp: assignments[i].timestamp,
                assignmentsData: assignments[i],
            });
            groupExist.messages.sort((a, b) => b.timestamp - a.timestamp);
        } else {
            // TODO SHAHE
            // /**
            //  * Rest is too old break will try later
            //  */
            // if (assignments[i].timestamp < messagesData[messagesData.length - 1].startOfDayTimestamp) {
            //     break;
            // }
            /**
             * Create new group
             */
            for (let j = 0; j < messagesData.length; ++j) {
                if (assignments[i].timestamp > messagesData[j].startOfDayTimestamp) {
                    let startOfDayTimestamp = momentTimezone.utc(assignments[i].timestamp);
                    if (timezone) {
                        startOfDayTimestamp = startOfDayTimestamp.clone().tz(timezone);
                    }
                    messagesData.splice(j, 0, {
                        startOfDayTimestamp: startOfDayTimestamp.startOf('day').unix() * 1000,
                        stickyText: assignmentStickyText,
                        messages: [
                            {
                                ...dummyMessageGroup,
                                id: assignments[i].id,
                                timestamp: assignments[i].timestamp,
                                assignmentsData: assignments[i],
                            },
                        ],
                    });
                    --finalIndex;
                    break;
                }
            }
        }
    }
    assignments.length = finalIndex;
    return messagesGroup;
};

const modifyLastMessageAssignee = (
    messages: IMessages,
    selectedConversation: IConversationItem,
    timezone: string,
    event: any,
): IMessagesByDate[] => {
    const updatedMessagesGroup = [...messages.data];
    const newAssignmentData: IAssignmentItem = {
        id: event.conversation.timestamp,
        conversation_id: event.conversation.id,
        from: selectedConversation?.assignee_name || '',
        seen_by_assignee: false,
        timestamp: event.conversation.timestamp,
        to: event.conversation.assignee_name,
    };
    const assignmentStickyText = getStickyText(event.conversation.timestamp, timezone, true);
    if (assignmentStickyText === updatedMessagesGroup[0].stickyText) {
        updatedMessagesGroup[0].messages.unshift({
            ...dummyMessageGroup,
            id: newAssignmentData.id,
            timestamp: newAssignmentData.timestamp,
            assignmentsData: newAssignmentData,
        });
    } else {
        let startOfDayTimestamp = momentTimezone.utc(newAssignmentData.timestamp);
        if (timezone) {
            startOfDayTimestamp = startOfDayTimestamp.clone().tz(timezone);
        }
        updatedMessagesGroup.unshift({
            startOfDayTimestamp: startOfDayTimestamp.startOf('day').unix() * 1000,
            stickyText: 'Today',
            messages: [
                {
                    ...dummyMessageGroup,
                    id: newAssignmentData.id,
                    timestamp: newAssignmentData.timestamp,
                    assignmentsData: newAssignmentData,
                },
            ],
        });
    }
    return updatedMessagesGroup;
};

/**
 * Conversation event which changes messages
 */
export const handleMessageEventInConversation = (
    selectedConversation: IConversationItem | null,
    messages: IMessages,
    modalSelectedConversation: IConversationItem | null,
    modalMessages: IMessages,
    { userId, canView, timezone }: IUtils,
    event: any,
): {
    selectedConversation: IConversationItem | null;
    messages: IMessages;
    modalSelectedConversation: IConversationItem | null;
    modalMessages: IMessages;
} => {
    const eventConversationId = event.conversation.id;
    if (event.state === 'DELETED') {
        /**
         * Deleted conversation (Hide)
         */
        if (event.eventTriggerUserId !== userId) {
            if (selectedConversation && eventConversationId === selectedConversation.id) {
                selectedConversation = { ...selectedConversation, showPopUp: 'DELETED' };
            }
            if (modalSelectedConversation && eventConversationId === modalSelectedConversation.id) {
                modalSelectedConversation = { ...modalSelectedConversation, showPopUp: 'DELETED' };
            }
            if (
                eventConversationId !== selectedConversation?.id &&
                eventConversationId !== modalSelectedConversation?.id
            ) {
                DxShowNotification('warning', 'One of conversations is currently unavailable.');
            }
        } else {
            if (selectedConversation && eventConversationId === selectedConversation.id) {
                selectedConversation = null;
                messages = initialState.messages;
            }
            if (modalSelectedConversation && eventConversationId === modalSelectedConversation.id) {
                modalSelectedConversation = null;
                modalMessages = initialState.modalMessages;
            }
        }
        return {
            selectedConversation,
            messages,
            modalSelectedConversation,
            modalMessages,
        };
    }
    if (event.state === 'UPDATED_ASSIGNEE') {
        /**
         * Conversation assignee updated
         */
        const conversationNewAssigneeKey = event.conversation.assignee_id
            ? event.conversation.assignee_id === userId
                ? 'self'
                : 'others'
            : 'none';
        if (!canView[conversationNewAssigneeKey]) {
            /**
             * If conversation is not opened just check for notification and return
             */
            if (
                eventConversationId !== selectedConversation?.id &&
                eventConversationId !== modalSelectedConversation?.id
            ) {
                if (event.previousAssignee === userId) {
                    DxShowNotification('warning', "You've been unassigned from a conversation.");
                } else {
                    DxShowNotification('warning', 'One of conversations is currently unavailable.');
                }
                return {
                    selectedConversation,
                    messages,
                    modalSelectedConversation,
                    modalMessages,
                };
            }
            /**
             * If conversation is opened show pop up Inbox
             */
            if (selectedConversation && eventConversationId === selectedConversation.id) {
                selectedConversation = {
                    ...selectedConversation,
                    showPopUp: event.previousAssignee === userId ? 'UPDATED_ASSIGNEE' : 'DELETED',
                };
            }
            /**
             * If conversation is opened show pop up Modal
             */
            if (modalSelectedConversation && eventConversationId === modalSelectedConversation.id) {
                modalSelectedConversation = {
                    ...modalSelectedConversation,
                    showPopUp: event.previousAssignee === userId ? 'UPDATED_ASSIGNEE' : 'DELETED',
                };
            }
        } else {
            if (selectedConversation && eventConversationId === selectedConversation.id) {
                messages = {
                    ...messages,
                    data: modifyLastMessageAssignee(messages, selectedConversation, timezone, event),
                };
                selectedConversation = {
                    ...selectedConversation,
                    assignee_name: event.conversation.assignee_name || '',
                };
            }
            if (modalSelectedConversation && eventConversationId === modalSelectedConversation.id) {
                modalMessages = {
                    ...modalMessages,
                    data: modifyLastMessageAssignee(modalMessages, modalSelectedConversation, timezone, event),
                };
                modalSelectedConversation = {
                    ...modalSelectedConversation,
                    assignee_name: event.conversation.assignee_name || '',
                };
            }
        }
    }
    if (event.state === 'UPDATED_READ_TIMESTAMP') {
        if (selectedConversation && eventConversationId === selectedConversation.id) {
            selectedConversation = { ...selectedConversation, unread_count: 0 };
        }
        if (modalSelectedConversation && eventConversationId === modalSelectedConversation.id) {
            modalSelectedConversation = { ...modalSelectedConversation, unread_count: 0 };
        }
        return {
            selectedConversation,
            messages,
            modalSelectedConversation,
            modalMessages,
        };
    }
    return {
        selectedConversation,
        messages,
        modalSelectedConversation,
        modalMessages,
    };
};

/**
 * This function will use all event handlers combined in order
 */
export const clearEventsQueue = (
    // General
    events: any,
    badges: IBadges,
    utils: IUtils,
    // Inbox
    conversations: IConversations,
    selectedConversation: IConversationItem | null,
    messages: IMessages,
    lastRead: ILastRead,
    // Modal
    unreadConversations: IUnreadConversations,
    modalSelectedConversation: IConversationItem | null,
    modalMessages: IMessages,
    modalLastRead: ILastRead,
): any => {
    if (window.PUSH_EVENTS_IN_QUEUE !== 0 || events.length === 0) {
        return {
            badges,
            conversations,
            selectedConversation,
            messages,
            lastRead,
            unreadConversations,
            modalSelectedConversation,
            modalMessages,
            modalLastRead,
        };
    }
    for (const singleEvent of events) {
        switch (singleEvent.event_type) {
            case SSE_EVENT_TYPES.CONVERSATION:
                const conversationData1 = handleConversationEvent(badges, conversations, utils, singleEvent);
                badges = conversationData1.badges;
                conversations = conversationData1.conversations;
                const unreadData1 = handleUnreadConversationEvent(
                    unreadConversations,
                    badges,
                    utils.userId,
                    singleEvent,
                );
                unreadConversations = unreadData1.unreadConversations;
                const messagesData1 = handleMessageEventInConversation(
                    selectedConversation,
                    messages,
                    modalSelectedConversation,
                    modalMessages,
                    utils,
                    singleEvent,
                );
                messages = messagesData1.messages;
                selectedConversation = messagesData1.selectedConversation;
                modalMessages = messagesData1.modalMessages;
                modalSelectedConversation = messagesData1.modalSelectedConversation;
                break;
            case SSE_EVENT_TYPES.MESSAGE:
                const conversationData2 = handleConversationEvent(badges, conversations, utils, singleEvent);
                badges = conversationData2.badges;
                conversations = conversationData2.conversations;
                const unreadData2 = handleUnreadConversationEvent(
                    unreadConversations,
                    badges,
                    utils.userId,
                    singleEvent,
                );
                unreadConversations = unreadData2.unreadConversations;
                const messagesData2 = handleMessageEvent(
                    messages,
                    selectedConversation,
                    lastRead,
                    utils.timezone,
                    singleEvent,
                );
                const modalMessageData2 = handleMessageEvent(
                    modalMessages,
                    modalSelectedConversation,
                    modalLastRead,
                    utils.timezone,
                    singleEvent,
                );
                messages = messagesData2.messages;
                lastRead = messagesData2.lastRead;
                selectedConversation = messagesData2.selectedConversation;
                modalMessages = modalMessageData2.messages;
                modalLastRead = modalMessageData2.lastRead;
                modalSelectedConversation = modalMessageData2.selectedConversation;
                break;
            case SSE_EVENT_TYPES.MESSAGE_STATUS:
                const statusData = handleStatusEvent(messages, selectedConversation?.id, singleEvent);
                const modalStatusData = handleStatusEvent(modalMessages, modalSelectedConversation?.id, singleEvent);
                messages = statusData.messages;
                modalMessages = modalStatusData.messages;
                break;
        }
    }
    return {
        badges,
        conversations,
        selectedConversation,
        messages,
        lastRead,
        unreadConversations,
        modalSelectedConversation,
        modalMessages,
        modalLastRead,
    };
};
