import React, { FC, memo } from 'react';
import { Popover, PopoverProps } from 'antd';

import 'assets/scss/dxPopover.scss';

const DxPopover: FC<PopoverProps> = ({ children, placement = 'bottomRight', ...restProps }) => (
    <Popover className="dx-popover" placement={placement} trigger="click" {...restProps}>
        {children}
    </Popover>
);

export default memo(DxPopover);
