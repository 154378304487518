import { ReactNode } from 'react';
import { ALERT_TYPES } from './constants';
import { ICON_TYPES } from '../Icon';

const getIconType = (appearance: string, description: ReactNode) => {
    const { INFO, SUCCESS, WARNING, ERROR } = ALERT_TYPES;
    const {
        INFO_CIRCLE,
        INFO_CIRCLE_OUTLINE,
        CIRCLE_CHECK,
        CIRCLE_CHECK_OUTLINE,
        ERROR: ERROR_ICON,
        ERROR_OUTLINE,
        OFF_CLOSE,
        OFF_CLOSE_OUTLINE,
    } = ICON_TYPES;

    switch (appearance) {
        case INFO:
            return description ? INFO_CIRCLE_OUTLINE : INFO_CIRCLE;
        case SUCCESS:
            return description ? CIRCLE_CHECK_OUTLINE : CIRCLE_CHECK;
        case WARNING:
            return description ? ERROR_OUTLINE : ERROR_ICON;
        case ERROR:
            return description ? OFF_CLOSE_OUTLINE : OFF_CLOSE;
        default:
            return INFO_CIRCLE;
    }
};

export { getIconType };
