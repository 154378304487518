import React, { FC, memo } from 'react';
import { TimePicker } from 'antd';

import { DxIcon } from 'components/common';

import 'assets/scss/dxTimepicker.scss';
import { ITimePickerProps } from 'types/components';

const DxTimepicker: FC<ITimePickerProps> = ({
    label,
    use12Hours = true,
    format = 'h:mm a',
    allowClear = false,
    ...props
}) => (
    <>
        {label && <span className="dx-timepicker-label">{label}</span>}

        <TimePicker
            className="dx-timepicker"
            popupClassName="dx-timepicker-content"
            use12Hours={use12Hours}
            suffixIcon={<DxIcon size="md" type="clock" />}
            format={format}
            allowClear={allowClear}
            {...props}
        />
    </>
);

export default memo(DxTimepicker);
