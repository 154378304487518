import React, { FC, memo } from 'react';
import { isNil } from 'lodash';

import { IAvatar } from 'types/components';
import { DxIcon } from 'components/common';

import 'assets/scss/avatar.scss';
import { getAvatarLetters } from 'services/utils/helper';

const Avatar: FC<IAvatar> = ({ img, withUploader, type }) => {
    const avatar = getAvatarLetters(type);

    return (
        <div className="avatar">
            <div className="avatar-inner">
                {isNil(img) ? (
                    <div className="avatar-name-box">{avatar ? avatar : <DxIcon type="user" size="md" />}</div>
                ) : (
                    <div className="avatar-img-box">
                        <img src={img} alt="Profile pic" width={44} height={44} />
                    </div>
                )}
            </div>
            {isNil(withUploader) && <DxIcon size="md" type="chevron_down" />}
        </div>
    );
};

export default memo(Avatar);
