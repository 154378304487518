import React, { FC, memo } from 'react';
import { isNil } from 'lodash';
import { Controller } from 'react-hook-form';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { DxIcon, DxTooltip, InputHint } from 'components/common';
import { IDxInputPasswordProps } from 'types/components';

import 'assets/scss/dxInput.scss';

const renderFunction =
    ({ label, tooltipPlacement, helperText, tooltipInfo, autoComplete, ...restProps }: any) =>
    ({ field, fieldState: { error } }: any) => {
        const controledId = uuidv4();
        return (
            <div>
                {!isNil(label) && (
                    <label htmlFor={controledId} className="dx-label">
                        {label}
                        {!isNil(tooltipInfo) && (
                            <DxTooltip placement={tooltipPlacement} title={tooltipInfo}>
                                <div className="dx-icon-outer">
                                    <DxIcon type="info_circle" />
                                </div>
                            </DxTooltip>
                        )}
                    </label>
                )}
                <Input.Password
                    {...field}
                    {...(!isNil(label) ? { id: controledId } : {})}
                    className={`dx-password-input ${!isNil(error) ? 'error' : ''}`}
                    autoComplete={autoComplete || 'on'}
                    {...restProps}
                />
                <InputHint error={error} helperText={helperText} />
            </div>
        );
    };

const DxInputPassword: FC<IDxInputPasswordProps> = ({
    fieldName = '',
    label,
    tooltipPlacement,
    helperText,
    tooltipInfo,
    autoComplete,
    ...restProps
}) => {
    return (
        <Controller
            name={fieldName}
            render={renderFunction({ label, tooltipPlacement, helperText, tooltipInfo, autoComplete, ...restProps })}
        />
    );
};

export default memo(DxInputPassword);
