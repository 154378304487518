import { DxSwitcher } from '../common';

export const TemplateButton = ({ children, checked, type, onChange, channel, freeText }: any) => {
    return (
        <div className={'template-button-box'}>
            <div className={'template-button-title-box'}>
                <p className={'template-button-title'}>Button</p>
                <DxSwitcher
                    disabled={(type === 'image' && channel === 'viber') || (freeText && channel === 'whatsapp')}
                    checked={checked}
                    onChange={onChange}
                />
            </div>
            {checked && <div className={'template-button-expanded'}>{children}</div>}
        </div>
    );
};
