import { BalanceActionTypes as Types } from 'actions/types';
// Import Interfaces
import { IBalanceState } from 'types/reducers';
// Import Types
import { TActionCreator } from 'types/actions/types';
import { getBalanceLabel } from '../services/utils/helper';

const initialState = {
    balance: 0,
    currency: 'USD',
    label: '',
    credit_limit: 0,
    balance_frozen: 0,
    credit_limit_label: '',
    frozen_label: '',
    configuration: {},
};

const BalanceReducer = (state: IBalanceState = initialState, action: TActionCreator) => {
    switch (action.type) {
        case Types.BALANCE:
            const currency = action.payload.currency || state.currency;
            action.payload.label = getBalanceLabel(action.payload.balance, currency);
            action.payload.credit_limit_label = getBalanceLabel(
                action.payload.configuration?.credit_limit || 0,
                currency,
            );
            action.payload.frozen_label = getBalanceLabel(action.payload.pending_balance, currency);
            action.payload.balance_frozen = action.payload.pending_balance;
            action.payload.credit_limit = action.payload.configuration?.credit_limit || 0;
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default BalanceReducer;
