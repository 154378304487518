import React, { FC, memo } from 'react';

import { IComplianceProps } from 'types/components';
import { useLocalization } from 'hooks';

import 'assets/scss/complianceList.scss';
import { complianceList } from 'pages/Dashboard/config';
import { isNil } from 'lodash';
import {US_BASED_COUNTRIES} from "constants/common";

const ComplianceList: FC<IComplianceProps> = ({ country }) => {
    const { t } = useLocalization();
    const selectedCountry = US_BASED_COUNTRIES.includes(country?.toLowerCase()) ? 'us' : country?.toLowerCase();

    const selectedCountryInfo = isNil(complianceList[selectedCountry])
        ? complianceList['ALL']
        : complianceList[selectedCountry];

    return (
        <ul className="compliance-list">
            {selectedCountryInfo.map((item, index) => (
                <li className="compliance-list-item" key={index}>
                    {t(item)}
                </li>
            ))}
        </ul>
    );
};

export default memo(ComplianceList);
