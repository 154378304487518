import React, { FC, memo } from 'react';
import { IEmptyState } from 'types/components';
import 'assets/scss/emptyState.scss';

const EmptyState: FC<IEmptyState> = ({ title, description, image }) => {
    return (
        <div className="empty-state">
            <img className="empty-state-img" width={80} height={80} src={image} alt={title} />
            <p className="empty-state-title">{title}</p>
            {description && <p className="empty-state-description">{description}</p>}
        </div>
    );
};

export default memo(EmptyState);
