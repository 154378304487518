import { AuthActionTypes as Types } from 'actions/types';
// Import Interfaces
import { IAuthState } from 'types/reducers';
// Import Types
import { TActionCreator } from 'types/actions/types';

const initialState: IAuthState = {
    isLoggedIn: null,
    accessToken: '',
    ready: false,
    isAdmin: window.location.hostname.startsWith('admin.') || window.location.hostname.startsWith('admin-'),
    step: 'code',
    firstLogin: false,
};

const AuthReducer = (state: IAuthState = initialState, action: TActionCreator) => {
    switch (action.type) {
        case Types.DO_LOGIN:
            state.isLoggedIn = true;
            state.ready = true;
            state.firstLogin = action.payload || false;
            return Object.assign({}, state, {});
        case Types.DO_LOGOUT:
            state.isLoggedIn = false;
            state.ready = true;
            state.prevRoute = action.payload;
            if (action.payload) {
                const now = new Date();
                const item = {
                    value: action.payload,
                    expiry: now.getTime() + 24 * 60 * 60 * 1000,
                };
                localStorage.setItem('action:returnUrl', JSON.stringify(item));
            }
            return Object.assign({}, state, {});
        case Types.REMOVE_FIRST_LOGIN:
            state.firstLogin = false;
            return Object.assign({}, state, {});
        case Types.DO_IFRAME_LOGIN:
            state.ready = true;
            state.isLoggedIn = false;
            return Object.assign({}, state, {});
        case Types.SET_PHONE_STEP:
            state.step = action.payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};

export default AuthReducer;
