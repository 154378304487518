import { AccountActionTypes as Types, AuthActionTypes } from 'actions/types';
import jwt_decode from 'jwt-decode';
// Import Interfaces
import { IAccountState } from 'types/reducers';
// Import Types
import { mapSnakeToCamelCase, mapPermissionsToObj } from 'services/utils/helper';
import { TActionCreator } from 'types/actions/types';
import { COMPANY_STATUSES } from '../constants/common';
import { InsightsPreferences } from '../constants/preferences';

const initialState = {
    verified: true,
    phoneNumber: '',
    channels: [],
    companyName: '',
    countryCode: '',
    currency: '',
    fullName: '',
    roles: [],
    timezone: '',
    username: '',
    senderFree: false,
    testSms: 0,
    industryId: 9,
    useCase: [1],
    status: '',
    role: '',
    companyAddress: '',
    companySize: '',
    accountType: '',
    permissions: {},
    vatNumber: '',
    userId: '',
    interests: [],
    taxRate: 0,
    preference: {
        insights: {},
    },
};

const AccountReducer = (state: IAccountState = initialState, action: TActionCreator) => {
    switch (action.type) {
        case Types.SET_ACCOUNT_INFO:
            const token = localStorage.getItem('accessToken');
            const decodedToken: { impersonated: boolean; parent_id: string } = jwt_decode(token!);
            const data = action.payload;
            if (!data.country_code) {
                data.country_code = 'US';
            }

            if (!data.user_country && !state.userCountry) {
                data.user_country = data.country_code || 'US';
            }

            if (!data.tax_rate && !state.taxRate) {
                data.tax_rate = data.tax_rate || 0;
            }

            if (!data.industry_id) {
                data.industry_id = 9;
            }

            if (!data.interests) {
                data.interests = [];
            }

            if (data.permissions) {
                if (decodedToken.impersonated) {
                    data.permissions = mapPermissionsToObj({
                        ACCOUNTS: 15,
                        ALERTS: 15,
                        AUDIENCES: 15,
                        CAMPAIGNS: 15,
                        CHANNELS: 15,
                        INVOICES: 15,
                        KEYS: 0,
                        MESSAGES: 15,
                        NUMBERS: 15,
                        PAYMENTS: 15,
                        SENDERS: 15,
                        SHORT_URLS: 15,
                        TEMPLATES: 15,
                        USERS: 15,
                        WEBHOOKS: 15,
                        MEDIA: 3,
                        CONVERSATIONS: 15,
                        SMS_LIMITS: 15,
                    });
                } else {
                    data.permissions = mapPermissionsToObj(data.permissions);
                }
            }

            if (data.preference) {
                data.preference = JSON.parse(data.preference);
            } else {
                data.preference = {
                    insights: InsightsPreferences,
                };
            }

            return {
                ...state,
                ...mapSnakeToCamelCase(data),
                verified: data.status ? data.status === COMPANY_STATUSES.VERIFIED : state.verified,
            };
        case Types.UPDATE_INFO:
            return {
                ...state,
                ...mapSnakeToCamelCase(action.payload),
            };
        case Types.SET_ACCOUNT_SENDERS:
            return {
                ...state,
                senders: action.payload,
            };
        case Types.UPDATE_PREFERENCES:
            return {
                ...state,
                preference: {
                    ...state.preference,
                    ...action.payload,
                },
            };
        case AuthActionTypes.DO_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default AccountReducer;
