import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../../../store';
import { useDebounce, useRequest } from '../../../hooks';
import { InboxHttpService, MediaHttpService } from '../../../services/http';
import { downloadInboxFile, findLastReadMessageId, groupMessagesByDate, timestampToDate } from '../helpers';
import conversationImg from 'assets/images/empty-states/conversation-chat.svg';
import 'assets/scss/messages.scss';
/**
 * Types
 */
import { IConversationItem } from '../types';
import { IInboxState, ILastRead } from '../../../types/reducers/inbox';
import { IAccountState, IRootReducer } from '../../../types/reducers';
import { useKeepScrollPosition } from '../../../hooks/useKeepScrollPosition';
import { InboxActionTypes } from '../../../actions/types';
import { ICancelableRequest } from '../../../types/services/http.config';
import { IMessageItem, IMessagesByDate } from './types';
import { capitalize } from 'lodash';
import { DxIcon, DxTooltip, DxLoading, DxShowNotification } from '../../../components';
import { ConditionalWrapper } from '../../../services/utils/conditionalWrapper';
import { ViberMessageContent } from '../../../components/ChannelInterface/ViberMessageContent';
import { WhatsAppMessageContent } from '../../../components/ChannelInterface/WhatsAppMessageContent';

interface IMessagesMain {
    selectedConversation: IConversationItem;
    isModal: boolean;
}
export const MessagesMain: FC<IMessagesMain> = ({ selectedConversation, isModal = false }) => {
    const { doGetRequest, doPutRequest } = useRequest();
    /**
     * Data in Redux
     */
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const {
        messages: inboxMessages,
        lastRead: inboxLastRead,
        modalMessages,
        modalLastRead,
        utils,
    } = useSelector<IRootReducer, IInboxState>((state) => state.inbox);
    const messages = useMemo(() => {
        return isModal ? modalMessages : inboxMessages;
    }, [inboxMessages, modalMessages]);
    const lastRead = useMemo(() => {
        return isModal ? modalLastRead : inboxLastRead;
    }, [inboxMessages, modalMessages]);
    /**
     * Clear Messages data
     */
    useEffect(() => {
        return () => {
            isModal
                ? dispatch({ type: InboxActionTypes.CLEAR_MODAL_MESSAGES })
                : dispatch({ type: InboxActionTypes.CLEAR_MESSAGES });
        };
    }, []);
    /**
     * Last Read logic
     */
    const lastReadTimestamp = useRef(selectedConversation.last_read_timestamp || 0);
    const unreadCount = useRef(selectedConversation.unread_count || 0);
    const { containerRef } = useKeepScrollPosition(messages.data);

    useEffect(() => {
        let requestFn: ICancelableRequest | undefined;
        let assignmentsRequestFn: ICancelableRequest | undefined;
        const inboxState = store.getState().inbox as IInboxState;
        const selectedConversationRealTime = isModal
            ? inboxState.modalSelectedConversation?.id
            : inboxState.selectedConversation?.id;
        /**
         * Data passed from selector is not updated correctly
         * added additional check with store state
         */
        if (selectedConversation.id === selectedConversationRealTime) {
            requestFn = InboxHttpService.getConversationMessages(selectedConversation.id);
            window.PUSH_EVENTS_IN_QUEUE |= 2;
            setLoading(true);
            doGetRequest(requestFn.request, {
                successCallback: async (response) => {
                    try {
                        assignmentsRequestFn = InboxHttpService.getConversationAssignments(selectedConversation.id);
                        const assignments = await assignmentsRequestFn.request();
                        isModal
                            ? dispatch({
                                  type: InboxActionTypes.SET_MODAL_ASSIGNMENT_HISTORY,
                                  payload: assignments.data,
                              })
                            : dispatch({ type: InboxActionTypes.SET_ASSIGNMENT_HISTORY, payload: assignments.data });
                    } catch (error) {
                        // TODO error handling
                    }
                    const { data, pagination: resPagination, isActiveConversation, isViberLimitActive } = response;
                    let lastReadUpdate: ILastRead;
                    if (unreadCount.current > 0) {
                        const { lastReadId: newLastReadId, unreadCount: newUnreadCount } = findLastReadMessageId(
                            data,
                            lastReadTimestamp.current,
                            unreadCount.current,
                        );
                        lastReadUpdate = { found: !!newLastReadId, id: newLastReadId };
                        unreadCount.current = newUnreadCount;
                    } else {
                        lastReadUpdate = { found: true, id: '' };
                    }
                    lastMessageSnapshot.current = data[0];
                    const messagesGroupedByDate = groupMessagesByDate(data, utils.timezone, []);
                    window.PUSH_EVENTS_IN_QUEUE &= 5;
                    isModal
                        ? dispatch({
                              type: InboxActionTypes.SET_MODAL_MESSAGES,
                              payload: {
                                  data: messagesGroupedByDate,
                                  pagination: resPagination,
                                  lastRead: lastReadUpdate,
                                  isActiveConversation,
                                  isViberLimitActive,
                              },
                          })
                        : dispatch({
                              type: InboxActionTypes.SET_MESSAGES,
                              payload: {
                                  data: messagesGroupedByDate,
                                  pagination: resPagination,
                                  lastRead: lastReadUpdate,
                                  isActiveConversation,
                                  isViberLimitActive,
                              },
                          });
                    setLoading(false);
                },
                errorCallback: (error, rpsLimit) => {
                    if (!rpsLimit) {
                        DxShowNotification('error', 'Something went wrong');
                    }
                    window.PUSH_EVENTS_IN_QUEUE &= 5;
                    dispatch({ type: InboxActionTypes.CLEAR_QUEUE });
                    setLoading(false);
                },
            });
        }
        return () => {
            requestFn?.cancel();
            assignmentsRequestFn?.cancel();
        };
    }, [selectedConversation.id]);
    /**
     * Observer for Messages
     */
    const messagesTopObserver = useRef<IntersectionObserver>();
    const previousTop = useRef<number>(0);
    const fetchingOlder = useRef(false);
    useEffect(() => {
        let requestFn: ICancelableRequest | undefined;
        const handleObserver = (entries: IntersectionObserverEntry[]) => {
            const inboxState = store.getState().inbox as IInboxState;
            const nextPageRealTime = isModal
                ? inboxState.modalMessages.pagination.links?.next
                : inboxState.messages.pagination.links?.next;
            entries.forEach((entry) => {
                if (entry.isIntersecting && messages.data.length > 0 && nextPageRealTime && !fetchingOlder.current) {
                    const url = new URL(nextPageRealTime);
                    const searchParams = '?' + url.searchParams;
                    const messagesContainer = document.getElementsByClassName('messages-container')[0];
                    fetchingOlder.current = true;
                    requestFn = InboxHttpService.getConversationMessagesByToken(selectedConversation.id, searchParams);
                    window.PUSH_EVENTS_IN_QUEUE |= 2;
                    setLoading(true);
                    doGetRequest(requestFn.request, {
                        successCallback: async (response) => {
                            previousTop.current = messagesContainer.scrollHeight;
                            const {
                                data,
                                pagination: resPagination,
                                isActiveConversation,
                                isViberLimitActive,
                            } = response;
                            let lastReadUpdate: ILastRead = lastRead;
                            if (unreadCount.current > 0) {
                                const { lastReadId: newLastReadId, unreadCount: newUnreadCount } =
                                    findLastReadMessageId(data, lastReadTimestamp.current, unreadCount.current);
                                lastReadUpdate = { found: !!newLastReadId, id: newLastReadId };
                                unreadCount.current = newUnreadCount;
                            }
                            const messagesGroupedByDate = groupMessagesByDate(data, utils.timezone, [...messages.data]);
                            window.PUSH_EVENTS_IN_QUEUE &= 5;
                            isModal
                                ? dispatch({
                                      type: InboxActionTypes.SET_MODAL_MESSAGES,
                                      payload: {
                                          data: messagesGroupedByDate,
                                          pagination: resPagination,
                                          lastRead: lastReadUpdate,
                                          isActiveConversation,
                                          isViberLimitActive,
                                      },
                                  })
                                : dispatch({
                                      type: InboxActionTypes.SET_MESSAGES,
                                      payload: {
                                          data: messagesGroupedByDate,
                                          pagination: resPagination,
                                          lastRead: lastReadUpdate,
                                          isActiveConversation,
                                          isViberLimitActive,
                                      },
                                  });
                            setLoading(false);
                            fetchingOlder.current = false;
                        },
                        errorCallback: (error, rpsLimit) => {
                            if (!rpsLimit) {
                                DxShowNotification('error', 'Something went wrong');
                            }
                            window.PUSH_EVENTS_IN_QUEUE &= 5;
                            dispatch({ type: InboxActionTypes.CLEAR_QUEUE });
                            fetchingOlder.current = false;
                            setLoading(false);
                        },
                    });
                }
            });
        };
        messagesTopObserver.current = new IntersectionObserver(handleObserver, {
            root: document,
        });
        const element = document.getElementsByClassName(
            isModal ? 'messages-modal-top-trigger' : 'messages-top-trigger',
        )[0];
        messagesTopObserver.current!.observe(element!);
        return () => {
            requestFn?.cancel();
            messagesTopObserver.current?.disconnect();
        };
    }, [selectedConversation.id, messages]);

    const messagesBottomObserver = useRef<IntersectionObserver>();
    useEffect(() => {
        const inboxState = store.getState().inbox as IInboxState;
        const messagesLengthRealTime = isModal ? inboxState.modalMessages.data.length : inboxState.messages.data.length;
        if (messagesLengthRealTime > 0) {
            const handleObserver = (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && messages.allRead !== selectedConversation.id) {
                        doPutRequest(InboxHttpService.updateConversation(selectedConversation.id).request, {
                            requestBody: {
                                data: {
                                    last_read_timestamp: Date.now(),
                                },
                            },
                            successCallback: () => {
                                setShowScrollToBottom(false);
                                isModal
                                    ? dispatch({
                                          type: InboxActionTypes.SET_MODAL_MESSAGES_READ,
                                          payload: selectedConversation.id,
                                      })
                                    : dispatch({
                                          type: InboxActionTypes.SET_MESSAGES_READ,
                                          payload: selectedConversation.id,
                                      });
                            },
                            errorCallback: (error, rpsLimit) => {
                                if (!rpsLimit) {
                                    DxShowNotification('error', 'Something went wrong');
                                }
                            },
                        });
                    }
                });
            };
            messagesBottomObserver.current = new IntersectionObserver(handleObserver, {
                root: document,
            });
            const element = document.getElementsByClassName(
                isModal ? 'messages-modal-bottom-trigger' : 'messages-bottom-trigger',
            )[0];
            messagesBottomObserver.current!.observe(element!);
        }
        return () => messagesBottomObserver.current?.disconnect();
    }, [messages]);

    /**
     * Keep snapshot of the last message
     */
    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
        }
    };
    const lastMessageSnapshot = useRef<IMessageItem | null>(messages.data[0]?.messages[0]);
    useEffect(() => {
        if (lastMessageSnapshot.current && messages.data.length > 0) {
            /**
             * Check if new message appeared and is it MT or MO
             */
            const currentLastMessage = messages.data[0].messages[0];
            if (lastMessageSnapshot.current?.id !== currentLastMessage.id) {
                lastMessageSnapshot.current = currentLastMessage;
                if (containerRef.current && currentLastMessage.type) {
                    if (currentLastMessage.type === 'MT') {
                        scrollToBottom();
                    } else {
                        setShowScrollToBottom(true);
                    }
                }
            }
        }
    }, [messages]);
    /**
     * Handle scroll to add / remove scroll to bottom thing.
     */
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const handleScroll = useDebounce((e) => {
        if (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop > 100) {
            setShowScrollToBottom(true);
        } else {
            setShowScrollToBottom(false);
        }
    }, 100);

    return (
        <div ref={containerRef} className={`messages-container`} onScroll={handleScroll}>
            {isModal ? <div className={'messages-modal-top-trigger'} /> : <div className={'messages-top-trigger'} />}
            {loading && messages.data.length > 0 && (
                <div className={'messages-loading'}>
                    <DxLoading />
                </div>
            )}
            <div className={`messages-inner-box ${messages.data.length === 0 ? 'loading' : ''}`}>
                {messages.data.length === 0 ? (
                    <DxLoading />
                ) : (
                    <>
                        {messages.data.map((messageGroup) => {
                            return (
                                <MessageItem
                                    key={messageGroup.stickyText}
                                    messageGroup={messageGroup}
                                    phoneNumber={selectedConversation?.number}
                                    lastReadId={lastRead.id}
                                    channel={selectedConversation.channel}
                                />
                            );
                        })}
                        {!messages.pagination.links?.next && (
                            <div className={'messages-box-end'}>
                                <img src={conversationImg} alt={'start-of-conversation'} width={48} height={48} />
                                This is the beginning of the conversation
                            </div>
                        )}
                    </>
                )}
            </div>
            {isModal ? (
                <div className={'messages-modal-bottom-trigger'} />
            ) : (
                <div className={'messages-bottom-trigger'} />
            )}
            <div onClick={scrollToBottom} className={`messages-notification ${showScrollToBottom ? 'visible' : ''} `}>
                <DxIcon size={'md-2'} type={'chevron_down'} appearance={'primary'} />
                {selectedConversation.unread_count > 0 && (
                    <div className={'messages-notification-count'}>{selectedConversation.unread_count}</div>
                )}
            </div>
        </div>
    );
};

const MessageItem: FC<{
    messageGroup: IMessagesByDate;
    phoneNumber: string | undefined;
    lastReadId: string;
    channel: string;
}> = ({ messageGroup, phoneNumber, lastReadId, channel }) => {
    const [downloading, setDownloading] = useState('');
    const { doGetRequest } = useRequest();
    const { utils } = useSelector<IRootReducer, IInboxState>((state) => state.inbox);

    const downloadMediaFile = (message: IMessageItem) => {
        setDownloading(message.id);
        if (message.media_id) {
            doGetRequest(MediaHttpService.getSingleMedia(message.media_id).request, {
                successCallback: (res) => {
                    downloadInboxFile(res.get_url, res.file_name, setDownloading);
                },
                errorCallback: () => {
                    setDownloading('');
                },
            });
        } else {
            downloadInboxFile(message.url, message.file_name, setDownloading);
        }
    };
    const { companyName } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    const accountNameAndInitials = useMemo(() => {
        return { assignee_name: companyName, assignee_initials: companyName[0].toUpperCase() };
    }, [companyName]);

    return (
        <div className={'message-date-group'}>
            <div className={'sticky-date'}>
                <div>{messageGroup.stickyText}</div>
            </div>
            <div className={'messages-date-group-items'}>
                {messageGroup.messages.map((message) => {
                    return (
                        <React.Fragment key={message.id}>
                            {message.assignmentsData && (
                                <div className="message-assign-info" key={message.assignmentsData.timestamp}>
                                    <div className="message-assign-text">
                                        {message.assignmentsData.from ? (
                                            message.assignmentsData.to ? (
                                                <>
                                                    Conversation is reassigned from{' '}
                                                    <span>{message.assignmentsData.from}</span> to{' '}
                                                    <span>{message.assignmentsData.to}</span>
                                                </>
                                            ) : (
                                                <>
                                                    Conversation is unassigned from{' '}
                                                    <span>{message.assignmentsData.from}</span>
                                                </>
                                            )
                                        ) : (
                                            <>
                                                Conversation is assigned to <span>{message.assignmentsData.to}</span>
                                            </>
                                        )}
                                    </div>
                                    <div className="message-assign-date">
                                        {timestampToDate(message.assignmentsData.timestamp, utils.timezone, 'HH:mm')}
                                    </div>
                                </div>
                            )}
                            {message.conversation_id && (
                                <div
                                    key={message.id}
                                    className={`message-item ${message.type} ${
                                        message.status.toLowerCase() === 'failed' ? 'failed' : ''
                                    }`}
                                >
                                    <div className={'message-outer'}>
                                        {message.type === 'MT' ? (
                                            <div className={'message-account-box'}>
                                                <div className={'message-account-initials'}>
                                                    {message.assignee_initials ||
                                                        accountNameAndInitials.assignee_initials}
                                                </div>
                                                <div className={'message-account-name'}>
                                                    {message.user_name ||
                                                        message.assignee_name ||
                                                        accountNameAndInitials.assignee_name}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={'message-number'}>{phoneNumber}</div>
                                        )}
                                        <div className={'message-text'}>
                                            {message.media_id && !message.url && (
                                                <div className="message-file-box corrupted message-without-media-type">
                                                    <DxIcon type={'corrupted-outline'} />
                                                    <div className="message-file-txt">The file is corrupted</div>
                                                </div>
                                            )}
                                            {message.url && !(channel === 'WHATSAPP' && message.button_text) && (
                                                <>
                                                    {/*Message contains media but type is unknown*/}
                                                    {!message.media_type ? (
                                                        <div
                                                            className="message-without-media-type"
                                                            onClick={() => downloadMediaFile(message)}
                                                        >
                                                            <div
                                                                className={`message-file-box  ${
                                                                    downloading === message.id ? 'downloading' : ''
                                                                }`}
                                                            >
                                                                {downloading === message.id && <DxLoading />}
                                                                <DxIcon type={'document-download-filled'} />
                                                                <div className="message-file-txt">
                                                                    Click to download file
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {channel === 'VIBER' ? (
                                                                <div className="viber-msg">
                                                                    <ViberMessageContent
                                                                        documentName={message.file_name || ''}
                                                                        text={message.text}
                                                                        type={message.media_type}
                                                                        link={message.url}
                                                                        btntext={message.button_text}
                                                                        onDownload={() => downloadMediaFile(message)}
                                                                        downloading={downloading === message.id}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="whatsapp-msg">
                                                                    <WhatsAppMessageContent
                                                                        text={message.text}
                                                                        type={message.media_type}
                                                                        link={message.url}
                                                                        btntext={message.button_text}
                                                                        btnType={message.button_type}
                                                                        documentName={message.file_name || ''}
                                                                        onDownload={() => downloadMediaFile(message)}
                                                                        downloading={downloading === message.id}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {channel === 'WHATSAPP' && message.button_text && (
                                                <div className="whatsapp-msg">
                                                    <WhatsAppMessageContent
                                                        text={message.text}
                                                        type={message.media_type}
                                                        link={message.url}
                                                        btntext={message.button_text}
                                                        btnType={message.button_type}
                                                        documentName={message.file_name || ''}
                                                        onDownload={() => downloadMediaFile(message)}
                                                        downloading={downloading === message.id}
                                                    />
                                                </div>
                                            )}
                                            {(!message.media_type || message.media_type.toLowerCase() === 'text') &&
                                                !(channel === 'WHATSAPP' && message.button_text) && (
                                                    <div className="message-text-with-media">{message.text}</div>
                                                )}
                                        </div>
                                    </div>
                                    <div className={'message-info-box'}>
                                        {message.type === 'MT' ? (
                                            <>
                                                <div className={`message-status`}>
                                                    <ConditionalWrapper
                                                        condition={message.status.toLowerCase() === 'failed'}
                                                        wrapper={(children) => (
                                                            <DxTooltip
                                                                placement={'bottomLeft'}
                                                                title="Message cannot be sent. Issue may originate from user's end."
                                                                message={true}
                                                                customClassName={'without-arrow no-wrap'}
                                                            >
                                                                <DxIcon appearance="danger" type="error" size="sm" />

                                                                {children}
                                                            </DxTooltip>
                                                        )}
                                                    >
                                                        <>
                                                            {capitalize(message.status)}
                                                            <div className={'message-time'}>
                                                                {timestampToDate(
                                                                    message.timestamp,
                                                                    utils.timezone,
                                                                    'HH:mm',
                                                                )}
                                                            </div>
                                                        </>
                                                    </ConditionalWrapper>
                                                </div>
                                            </>
                                        ) : (
                                            <div className={'message-time'}>
                                                {timestampToDate(message.timestamp, utils.timezone, 'HH:mm')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {message.id === lastReadId && (
                                <div className={'unread-divider'}>
                                    <span>New messages</span>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};
