/* eslint-disable no-template-curly-in-string */
const layout = 'vertical';
const requiredMark = false;

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

export { layout, requiredMark, validateMessages };
