import { TActionCreator } from 'types/actions/types';
import { ISingleTemplate, ITemplateState } from 'types/reducers';
import { TemplateActionTypes } from 'actions/types';
import { PaginationDefaultOptions } from '../constants/common';

const initialState = {
    loading: true,
    templateList: [],
    pagination: {
        number: PaginationDefaultOptions.current,
        total: PaginationDefaultOptions.total,
        size: PaginationDefaultOptions.pageSize,
    },
};

export default function templatesReducer(state: ITemplateState = initialState, action: TActionCreator) {
    switch (action.type) {
        case TemplateActionTypes.INIT:
            return {
                loading: false,
                templateList: action.payload.data,
                pagination: action.payload.pagination,
            };
        case TemplateActionTypes.CREATE:
            return {
                ...state,
                templateList: [action.payload, ...state.templateList],
                pagination: {
                    ...state.pagination,
                    total: state.pagination.total + 1,
                },
            };
        case TemplateActionTypes.EDIT:
            return {
                ...state,
                templateList: state.templateList.map((template: ISingleTemplate) =>
                    template.id === action.payload.id ? action.payload : template,
                ),
            };
        case TemplateActionTypes.DELETE:
            return {
                ...state,
                templateList: state.templateList.filter((template: ISingleTemplate) => template.id !== action.payload),
                pagination: {
                    ...state.pagination,
                    total: state.pagination.total - 1,
                },
            };
        default:
            return state;
    }
}
