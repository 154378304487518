import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMode } from 'react-hook-form';

export const WHATS_APP_QUICK_REPLY = [
    {
        label: 'Quick Reply',
        value: 'quick_reply',
    },
];

const schema = yup.object({
    name: yup
        .string()
        .max(45)
        .required()
        .matches(/^[a-z0-9_]+$/, 'Only lowercase letters, numbers, and (_) are allowed')
        .label('Name'),
    text: yup.string().max(1000).label('Message'),
    media: yup
        .object()
        .shape(
            {
                type: yup.string().required(),
                filename: yup.string().when('type', {
                    is: 'document',
                    then: yup
                        .string()
                        .max(25)
                        .required()
                        .matches(/^[A-Za-z0-9_]+$/, 'Only letters, numbers, and (_) are allowed')
                        .label('Document Name'),
                }),
                file: yup.object().when(['type', 'url'], {
                    is: (checker: string, url: string) => checker === 'text' || url,
                    then: yup.object(),
                    otherwise: yup
                        .object()
                        .required()
                        .test('file', 'File is a required field', (value) => {
                            return value?.media_id;
                        }),
                }),
                url: yup
                    .string()
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker !== 'text' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(/^https/, `URL should start with 'https'`)
                            .max(1000)
                            .label('Link'),
                    })
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker === 'document' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(/\.(pdf)$/i, 'File extension can be only: .pdf. maximum size 200 MB ')
                            .label('Link'),
                    })
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker === 'video' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(/\.(3gpp|mp4)$/i, 'File extension can be only: .3gpp, .mp4.')
                            .label('Link'),
                    })
                    .when(['type', 'file'], {
                        is: (checker: string, file: any) => {
                            return checker === 'image' && !file?.media_id;
                        },
                        then: yup
                            .string()
                            .required()
                            .matches(
                                /\.(jpg|jpeg|png)$/i,
                                'File extension can be only: .jpg, .jpeg, .png. maximum size 24 MB ',
                            )
                            .label('Link'),
                    }),
            },
            [['url', 'file']],
        )
        .test('media', 'A file attachment is required', (value) => {
            return value.type !== 'text' ? !!value.file || !!value.url : true;
        }),
    button_enabled: yup.boolean(),
    button_type: yup.string(),
    button_action_type: yup.string(),
    button_text: yup.string().when('button_enabled', {
        is: (buttonEnabled: string) => buttonEnabled,
        then: yup.string().max(20).required().label('Button text'),
    }),
    button_value: yup.string().when('button_type', {
        is: (buttonType: string) => buttonType === 'call_to_action',
        then: yup
            .string()
            .required()
            .max(195)
            .label('Website URL')
            .when('button_action_type', {
                is: 'phone_call',
                then: yup
                    .string()
                    .required()
                    .min(4)
                    .max(17)
                    .matches(/^[0-9+]*$/, 'Phone number can contain only pluses and numbers.')
                    .label('Phone number'),
            }),
    }),
});

const formProps = {
    mode: 'onChange' as keyof ValidationMode,
    resolver: yupResolver(schema),
    revalidateMode: 'onChange',
    defaultValues: {
        text: '',
        media: {
            type: 'text',
        },
        button_type: 'quick_reply',
        button_action_type: 'phone_call',
        button_enabled: false,
    },
};

export { formProps };
