import docPlaceholderWhatsapp from '../../assets/images/channel-interface/whatsapp-doc-placeholder.png';
import { DxIcon } from '../common';
import PlaceholderImage from '../../assets/images/media/image-placeholder.png';
import PlaceholderVideo from '../../assets/images/media/video-placeholder.png';
import Linkify from 'react-linkify';
import React, { FC } from 'react';
import { ChannelImage, ChannelVideo } from './ChannelInterface';

interface IWhatsAppMessageContent {
    text?: string;
    type?: string;
    link?: string;
    media?: any;
    documentName?: string;
    awsURL?: string;
    btntext?: string;
    btnType?: string;
    time?: string;
    onDownload?: () => void;
    downloading?: boolean;
}

export const WhatsAppMessageContent: FC<IWhatsAppMessageContent> = ({
    text,
    type,
    link,
    media,
    documentName,
    awsURL,
    btntext,
    btnType,
    time,
    onDownload,
}) => {
    return (
        <div className={`channel-interface-content whatsapp ${type === 'document' && media ? 'document' : ''}`}>
            {time && <div className="channel-interface-today">Today</div>}
            <div className="channel-interface-msg-box">
                <div className="channel-interface-message">
                    {/*When link is used*/}
                    {type === 'image' && link && <ChannelImage link={link} onDownload={onDownload} />}
                    {type === 'video' && link && <ChannelVideo link={link} onDownload={onDownload} />}
                    {type === 'document' && (media || link) && (
                        <div className="channel-interface-wa-doc">
                            <img
                                onClick={() => {
                                    if (onDownload) {
                                        onDownload();
                                    }
                                }}
                                className={`${onDownload ? 'downloadable' : ''}`}
                                src={docPlaceholderWhatsapp}
                                alt="Document placeholder"
                            />
                            {documentName && (
                                <a
                                    className="channel-interface-whatsapp-doc"
                                    href={link || media?.get_url || awsURL}
                                    target={'_blank'}
                                >
                                    <DxIcon size="sm" type="file_blank_fill" /> {documentName}
                                </a>
                            )}
                        </div>
                    )}

                    {/*When file is provided*/}
                    {type === 'image' && media && (
                        <ChannelImage
                            type={'internal'}
                            link={media?.get_url || awsURL || PlaceholderImage}
                            uploading={!!media.file}
                            mediaReady={!!(media?.get_url || awsURL)}
                        />
                    )}
                    {type === 'video' && media && (
                        <ChannelVideo
                            type={'internal'}
                            link={media?.thumbnail_url || awsURL || PlaceholderVideo}
                            uploading={!!media.file}
                            mediaReady={!!(media?.get_url || awsURL)}
                        />
                    )}
                    {text && (
                        <div className="channel-interface-text">
                            <Linkify>{text}</Linkify>
                        </div>
                    )}
                    <p className="channel-interface-time">{time}</p>
                    {btntext && btnType !== 'quick_reply' && (
                        <div className="channel-interface-whatsapp-btn">
                            <DxIcon type={`${btnType === 'link' ? 'external_link' : 'phone_outline'}`} size="sm" />
                            <span>{btntext}</span>
                        </div>
                    )}
                </div>
            </div>
            {btnType === 'quick_reply' && btntext && <div className="channel-interface-reply-btn">{btntext}</div>}
        </div>
    );
};
