import { ICancelableRequest } from '../../types/services/http.config';
import { deleteCancelableRequest, GetawayAPIService, getCancelableRequest, postCancelableRequest } from './http.config';

const getSingleLookupHistory = (query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/number-lookups${query}`,
    });
};

const getSingleLookup = (query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/number-lookups/${query}`,
    });
};

const deleteSingleLookup = (id: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/number-lookups/${id}`,
    });
};

const createBulkLookup = (): ICancelableRequest => {
    return postCancelableRequest({
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        requestService: GetawayAPIService,
        endpointPath: () => `/bulk-lookups?type=HLR`,
    });
};

const getBulkLookups = (query: string): ICancelableRequest => {
    return getCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/bulk-lookups${query}`,
    });
};

const deleteBulkLookup = (id: string): ICancelableRequest => {
    return deleteCancelableRequest({
        requestService: GetawayAPIService,
        endpointPath: () => `/bulk-lookups/${id}`,
    });
};

const methods = {
    getSingleLookupHistory,
    getSingleLookup,
    deleteSingleLookup,
    createBulkLookup,
    getBulkLookups,
    deleteBulkLookup,
};

export default methods;
