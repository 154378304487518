import { GetawayAPIService, getCancelableRequest, postCancelableRequest, putCancelableRequest } from './http.config';
// Import Interfaces
import { ICancelableRequest } from 'types/services/http.config';
import { IUserCredentials, THash } from 'types/services/auth';

const AuthHttpService = {
    signIn: ({ username, password }: IUserCredentials): ICancelableRequest => {
        return getCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self/token',
            headers: {
                Authorization: 'Basic ' + Buffer.from(`${username}:${password}`).toString('base64'),
            },
        });
    },
    signInWithGoogle: (hash: THash): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users/self/token',
            headers: {
                'D-social-network-token': hash,
            },
        });
    },
    signUp: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/users',
            headers: {
                'D-Source': 'dashboard',
            },
        });
    },
    resendEmailVerification: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/email_verifications',
        });
    },
    emailVerification: (hash: THash): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/email_verifications/${hash}`,
        });
    },
    loginVerifications: (hash: THash): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/login_verifications/${hash}`,
        });
    },
    forgotPassword: (): ICancelableRequest => {
        return postCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => '/password_resets',
        });
    },
    resetPassword: (hash: THash): ICancelableRequest => {
        return putCancelableRequest({
            requestService: GetawayAPIService,
            endpointPath: () => `/password_resets/${hash}`,
        });
    },
};

export default AuthHttpService;
