import React, { memo, FC } from 'react';
import { Space } from 'antd';
import { isNil } from 'lodash';

import { useLocalization } from 'hooks';
import { DxTag } from 'components/common';
import { IUseCasesProps } from 'types/components';

import 'assets/scss/useCases.scss';
import { ITranslationKey } from 'types/localization';

const UseCases: FC<IUseCasesProps> = ({ list }) => {
    const { t } = useLocalization();

    return (
        <ul className="use-cases-list">
            {list?.map(({ title, desc, tags = [] }, index) => (
                <li className="use-cases-item" key={index}>
                    <p className="use-cases-title">{t(title as keyof ITranslationKey)}</p>
                    {!isNil(desc) && <p className="use-cases-desc">{t(desc as keyof ITranslationKey)}</p>}
                    <Space size={8} wrap={true}>
                        {tags.map(({ tag, appearance }, idx) => (
                            <DxTag key={idx} appearance={appearance}>
                                {t(tag as keyof ITranslationKey)}
                            </DxTag>
                        ))}
                    </Space>
                </li>
            ))}
        </ul>
    );
};

export default memo(UseCases);
