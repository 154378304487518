import { TActionCreator } from 'types/actions/types';
import { IResponsesState } from 'types/reducers';
import { ResponsesActionTypes } from 'actions/types';
import { PaginationDefaultOptions } from 'constants/common';

const initialState = {
    loading: true,
    list: [],
    pagination: {
        number: PaginationDefaultOptions.current,
        total: PaginationDefaultOptions.total,
        size: PaginationDefaultOptions.pageSize,
    },
};

export default function responsesReducer(state: IResponsesState = initialState, action: TActionCreator) {
    switch (action.type) {
        case ResponsesActionTypes.INIT:
            return {
                loading: false,
                list: action.payload.data,
                pagination: action.payload.pagination,
            };
        default:
            return state;
    }
}