import React, { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'antd';
import { isNil } from 'lodash';

import { IDxCheckboxProps } from 'types/components';
import { DxIcon, DxTooltip, InputHint } from 'components';

import 'assets/scss/dxCheckbox.scss';

interface ITooltipInfo {
    tooltipInfo?: string;
}

const TooltipInfo: FC<ITooltipInfo> = ({ tooltipInfo }) => {
    if (isNil(tooltipInfo)) return null;

    return (
        <DxTooltip title={tooltipInfo}>
            <span className="dx-icon-outer">
                <DxIcon type="info_circle" />
            </span>
        </DxTooltip>
    );
};

const renderFunction =
    ({ options, helperText, direction, ...restProps }: any) =>
    ({ field, fieldState: { error } }: any) =>
        (
            <>
                {options.length > 1 ? (
                    <Checkbox.Group className={`dx-checkbox ${direction}`} {...field} {...restProps}>
                        {options.map((option: any, index: any) => (
                            <Checkbox value={option.value} key={index}>
                                {option.label}
                                <TooltipInfo tooltipInfo={option.tooltipInfo} />
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                ) : (
                    <Checkbox
                        className={`dx-checkbox ${direction}`}
                        {...field}
                        {...restProps}
                        value={options[0].value}
                        defaultChecked={options[0].checked}
                    >
                        {options[0].label}
                        <TooltipInfo tooltipInfo={options[0].tooltipInfo} />
                    </Checkbox>
                )}
                <InputHint error={error} helperText={helperText} />
            </>
        );

const DxCheckbox: FC<IDxCheckboxProps> = ({
    fieldName = '',
    options,
    helperText,
    direction = 'vertical',
    ...restProps
}) => {
    return <Controller name={fieldName} render={renderFunction({ options, helperText, direction, ...restProps })} />;
};

export default memo(DxCheckbox);
