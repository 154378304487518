import {
    Channels,
    Countries,
    Industries,
    CompanySizes,
    Positions,
    Currencies,
    Timezones,
    PaymentTypes,
    PaymentMethods,
    PaymentStatus,
    PaymentTypesStatus,
} from './profileDetails';

const Types = [
    { label: 'Outbound', value: 'MT' },
    { label: 'Inbound', value: 'MO' },
];

const Statuses = [
    { label: 'Delivered', value: 'delivered' },
    { label: 'Sent', value: 'sent' },
    { label: 'Failed', value: 'failed' },
    { label: 'Enroute', value: 'enroute' },
    { label: 'Read', value: 'read' },
    { label: 'Unsubscribed', value: 'unsubscribed' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Expired', value: 'expired' },
    { label: 'Blocked', value: 'blocked' },
];
export {
    Channels,
    Countries,
    Industries,
    CompanySizes,
    Positions,
    Currencies,
    Timezones,
    Statuses,
    Types,
    PaymentTypes,
    PaymentMethods,
    PaymentStatus,
    PaymentTypesStatus,
};
