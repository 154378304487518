import { TActionCreator } from 'types/actions/types';
import { IAudiencesState } from 'types/reducers';
import { AudiencesActionTypes } from 'actions/types';
import { PaginationDefaultOptions } from 'constants/common';

const initialState = {
    loading: true,
    list: [],
    pagination: {
        number: PaginationDefaultOptions.current,
        total: PaginationDefaultOptions.total,
        size: PaginationDefaultOptions.pageSize,
    },
    finishedReFetch: undefined,
};

export default function audiencesReducer(state: IAudiencesState = initialState, action: TActionCreator) {
    switch (action.type) {
        case AudiencesActionTypes.INIT:
            return {
                ...state,
                loading: false,
                list: action.payload.data,
                pagination: action.payload.pagination,
            };
        case AudiencesActionTypes.RE_FETCH:
            return {
                ...state,
                finishedReFetch: action.payload,
            };
        default:
            return state;
    }
}
