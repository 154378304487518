import { TActionCreator } from '../types/actions/types';
import { SMSViolationTypes } from '../actions/types';

const initialState = {
    violation: {
        violationID: '',
        violated: false,
    },
};

export default function smsViolationReducer(state: any = initialState, action: TActionCreator) {
    switch (action.type) {
        case SMSViolationTypes.ADD_VIOLATION:
            return {
                violation: { violationID: action.payload.ruleId, violated: true },
            };
        case SMSViolationTypes.REMOVE_VIOLATION:
            return {
                violation: { violationID: action.payload.ruleId, violated: false },
            };
        default:
            return state;
    }
}
