import { useMemo } from 'react';
import { Carousel } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PUBLIC_ROUTES_ENUM } from '../../constants/routes';
import {
    AuthSliderCodeData,
    AuthSliderData,
    AuthSliderEmailData,
    AuthSliderEmailRegistrationData,
    AuthSliderPhoneData,
    AuthSliderResetData,
    AuthSliderSignUpData,
} from '../../pages/Auth/constants';
import { IAuthSliderItem } from 'types/components';
import { IRootReducer } from '../../types/reducers';
import 'assets/scss/authSlider.scss';

const AuthSlider = () => {
    const { pathname, state } = useLocation();
    const step = useSelector<IRootReducer, 'code' | 'phone'>((state) => state.auth.step);
    const data: IAuthSliderItem[] = useMemo(() => {
        let checkPath = pathname;
        if (pathname.includes('/reset-password')) {
            checkPath = PUBLIC_ROUTES_ENUM.RESET_PASSWORD;
        }
        switch (checkPath) {
            case PUBLIC_ROUTES_ENUM.SIGN_UP:
            case PUBLIC_ROUTES_ENUM.INVITATIONS:
            case PUBLIC_ROUTES_ENUM.ACCOUNT_VERIFICATION:
                return AuthSliderSignUpData;
            case PUBLIC_ROUTES_ENUM.PHONE_VERIFICATION:
                return step === 'code' ? AuthSliderCodeData : AuthSliderPhoneData;
            case PUBLIC_ROUTES_ENUM.EMAIL_VERIFICATION:
                return state?.type === 'password_recovery' ? AuthSliderEmailData : AuthSliderEmailRegistrationData;
            case PUBLIC_ROUTES_ENUM.FORGOT_PASSWORD:
            case PUBLIC_ROUTES_ENUM.RESET_PASSWORD:
                return AuthSliderResetData;
            default:
                return AuthSliderData;
        }
    }, [pathname, step]);

    return (
        <div className="auth-slider align-items-center p-24">
            <div className="d-flex flex-column align-items-center">
                <div className="font-size-24 mb-1 font-poppins-bold slider-title">{data[0].title}</div>
                <div className="font-size-24 font-poppins-regular mb-3 pb-3 slider-sub-title">{data[0].subTitle}</div>
            </div>
            <Carousel
                autoplay={true}
                className="carousel"
                dotPosition="bottom"
                variableWidth={true}
                dots={true}
                centerMode={true}
                slidesToShow={1}
                touchMove={true}
                draggable={true}
                pauseOnHover={true}
            >
                {data.map(({ title, subTitle, desc, imgSrc }) => (
                    <div key={title}>
                        {/*<div className="font-size-24 mb-1 font-poppins-bold">{title}</div>*/}
                        {/*<div className="font-size-24 font-poppins-regular mb-3 pb-3">{subTitle}</div>*/}
                        <div className="mb-4">
                            <img src={imgSrc} width={452} height={332} alt={title} />
                        </div>
                        <div className="font-size-16 px-5">{desc}</div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default AuthSlider;
