import React, { memo, FC, useRef, useEffect, useState } from 'react';
import { count, messageLength } from 'sms-counter-npm';
import { Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash';
import { Input } from 'antd';

import { DxIcon, DxTooltip, InputHint } from 'components';
import { IDxTextareaProps } from 'types/components';

import 'assets/scss/dxTextarea.scss';
import classNames from 'classnames';
import { customCharactersCount } from 'services/utils/helper';

const { TextArea } = Input;
// currently only vay to change config, is modify inside /node_modules/sms-counter-npm/dist/SmsCounter.js
messageLength.per_message = 500;

function setSelectionRange(input: any, selectionStart: any, selectionEnd: any) {
    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
        const range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
}

function setCaretToPos(input: any, pos: any) {
    setSelectionRange(input, pos, pos);
}

const renderFunction =
    ({
        label,
        formatter,
        ref,
        showCounter,
        showCounterWithTags,
        showSmsCount,
        tooltipPlacement,
        helperText,
        errorText,
        tooltipInfo,
        onTextAreaBlur,
        cursorPos,
        onKeyPress,
        hideError,
        ...restProps
    }: any) =>
    ({ field, fieldState: { error } }: any) => {
        const controlledId = uuidv4();

        const onBlurHandler = (e: any) => {
            if (onTextAreaBlur) {
                onTextAreaBlur(e);
            }
        };

        useEffect(() => {
            if (isNil(cursorPos)) {
                return;
            }
            const txtArea = document.querySelector('.ant-input-textarea textarea');
            setCaretToPos(txtArea, cursorPos);
        }, [cursorPos]);

        return (
            <div>
                <label htmlFor={controlledId} className="dx-label">
                    {label}
                    {!isNil(tooltipInfo) && (
                        <DxTooltip placement={tooltipPlacement} title={tooltipInfo}>
                            <div className="dx-icon-outer">
                                <DxIcon type="info_circle" />
                            </div>
                        </DxTooltip>
                    )}
                </label>
                <TextArea
                    {...(!isNil(label) ? { id: controlledId } : {})}
                    showCount={showCounter || showCounterWithTags ? { formatter } : false}
                    className={classNames('dx-textarea', {
                        'dx-textarea-counter': showCounter,
                        'dx-textarea-with-tags': showCounterWithTags,
                        error: !isNil(error),
                    })}
                    {...field}
                    {...restProps}
                    {...(onKeyPress ? { onKeyPress } : {})}
                    ref={ref}
                    onBlur={onBlurHandler}
                />
                {!hideError && <InputHint error={error} helperText={helperText} />}
            </div>
        );
    };

const DxTextarea: FC<IDxTextareaProps> = ({
    showCounter = false,
    showSmsCount,
    showCounterWithTags,
    fieldName = '',
    label,
    tooltipPlacement,
    helperText,
    errorText,
    tooltipInfo,
    onTextAreaBlur,
    cursorPos,
    simpleCounter,
    setTagCounts,
    maxLengthForShow,
    setTagValuesArray = false,
    ...restProps
}) => {
    const ref = useRef(null);
    const [_, setForce] = useState(false);

    useEffect(() => {
        setForce((prev) => !prev);
    }, [ref]);
    const formatter = (e: any) => {
        let customMessage = '';
        let message = '';

        if (ref.current) {
            const { resizableTextArea } = ref.current as { resizableTextArea: { textArea: HTMLTextAreaElement } };
            message = resizableTextArea.textArea.value;
            customMessage = simpleCounter ? message : customCharactersCount(message, setTagCounts);
        }
        const { messages } = count(customMessage);
        let counterInfo = `${customMessage.length}/${maxLengthForShow || e.maxLength}, SMS:${messages}`;
        if (!showSmsCount) {
            counterInfo = `${customMessage.length}/${maxLengthForShow || e.maxLength}`;
        }

        return counterInfo;
    };

    return (
        <Controller
            name={fieldName}
            render={renderFunction({
                tooltipPlacement,
                tooltipInfo,
                showCounter,
                showCounterWithTags,
                helperText,
                errorText,
                formatter,
                label,
                ref,
                onTextAreaBlur,
                cursorPos,
                simpleCounter,
                ...restProps,
            })}
        />
    );
};

export default memo(DxTextarea);
