import React, { FC, memo } from 'react';
import { channels, menuItems, footerMenuItems } from './config';
import { useLocation, matchRoutes } from 'react-router-dom';
import { privateRoutes } from 'routes/PrivateRoutes';
import { DxIcon } from 'components/common';
import LeftNavMenu from './LeftNavMenu';
import { useLocalization } from 'hooks';
import 'assets/scss/dxMenu.scss';

import { isNil } from 'lodash';
import LeftNavChannels from './LeftNavChannels';

const DxMenu: FC = () => {
    const { t } = useLocalization();
    const location = useLocation();
    const matches = matchRoutes(privateRoutes, location) || [];
    const defaultOpenKeys = matches.filter((item) => item.pathname !== '/').map((item) => item.pathname);

    return (
        <>
            <div className="scroller-block">
                <p className="dx-menu-title">{t('CHANNELS')}</p>
                <LeftNavChannels openItems={defaultOpenKeys} list={channels} />
                <LeftNavMenu openItems={defaultOpenKeys} list={menuItems} />
            </div>
            <div className="dx-menu-footer">
                {footerMenuItems.map(({ isReady, enableInProd, label, link, icon, imgPath }) =>
                    (isReady && ['dev', 'stage'].includes(process.env.REACT_APP_MODE as string)) ||
                    (enableInProd && process.env.REACT_APP_MODE === 'prod') ? (
                        <a className="dx-menu-link" href={link} target="_blank" key={label} rel="noreferrer">
                            <span className="dx-menu-link-inner">
                                {!isNil(imgPath) ? (
                                    <img src={imgPath} alt={t(label)} width={24} height={24} />
                                ) : (
                                    <DxIcon type={icon} />
                                )}
                                <span className="dx-menu-link-text">{t(label)}</span>
                            </span>
                            <DxIcon type="external_link" />
                        </a>
                    ) : (
                        <span className="dx-menu-link custom-disable" key={label}>
                            <span className="dx-menu-link-inner">
                                {!isNil(imgPath) ? (
                                    <img src={imgPath} alt={t(label)} width={24} height={24} />
                                ) : (
                                    <DxIcon type={icon} />
                                )}
                                <span className="dx-menu-link-text">{t(label)}</span>
                            </span>
                            <DxIcon type="external_link" />
                        </span>
                    ),
                )}
            </div>
        </>
    );
};

export default memo(DxMenu);
