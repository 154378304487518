import React, { FC, memo } from 'react';
import { Modal } from 'antd';
import { isNil } from 'lodash';

import { DxIcon, DxButton } from 'components/common';
import { IDxModalProps } from 'types/components';
import { overflowBackgroundColor } from './config';

import 'assets/scss/dxModal.scss';

const DxModal: FC<IDxModalProps> = ({
    visible = false,
    title,
    description,
    textAlignment = 'center',
    btnAlignment = 'center',
    icon,
    confirmText = 'Ok',
    onCancel,
    onOk,
    children,
    confirmIconType,
    footerCustomContent,
    separator = false,
    notConfirmButton = true,
    loading = false,
    className,
    ...restProps
}) => {
    return (
        <Modal
            className={`dx-modal ${className ? className : ''}`}
            closeIcon={
                <span onClick={onCancel}>
                    <DxIcon type="close_small" />
                </span>
            }
            title={separator && title}
            visible={visible}
            onCancel={onCancel}
            wrapClassName="dx-modal-wrap"
            maskStyle={{
                backgroundColor: overflowBackgroundColor,
                opacity: 0.6,
            }}
            footer={[
                <div key="modal" className={`dx-btn-outer ${btnAlignment}`}>
                    {footerCustomContent}
                    {notConfirmButton && (
                        <DxButton appearance="primary" key="submit" onClick={onOk} loading={loading}>
                            <DxIcon size={'sm'} type={confirmIconType} />
                            {confirmText}
                        </DxButton>
                    )}
                </div>,
            ]}
            {...restProps}
        >
            <div className={`dx-modal-content ${textAlignment}`}>
                {icon}
                {!isNil(title) && !separator && <p className="dx-modal-title">{title}</p>}
                {!isNil(description) && <p className="dx-modal-description">{description}</p>}
                {children}
            </div>
        </Modal>
    );
};

export default memo(DxModal);
