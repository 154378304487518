import { IAccountState, IRootReducer } from 'types/reducers';
import { FILE_UPLOAD_MAX_SIZE, MESSAGE_VARIABLES } from 'constants/common';
import { CurrencySymbol } from 'constants/common';
import { Countries } from 'constants/options';
import { isNil, capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { IChannel } from 'types/global';

export function getBalanceLabel(amount: number | string, currency: string) {
    return (CurrencySymbol[currency] || '$') + ' ' + parseFloat(amount as string).toFixed(2);
}

export function getCountryNameByCode(code: string | undefined) {
    if (isNil(code)) {
        return '';
    }
    const countries = Countries.filter((item) => item.value === code).map((filtered) => filtered.label);
    return isNil(countries) ? code : countries[0];
}

export function getAvatarLetters(type: 'account' | 'user') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { fullName, companyName } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    let letters = '';
    if (type === 'user') {
        if (!isNil(fullName)) {
            const splitFullName = fullName.split(' ');
            letters += splitFullName[0][0];
            letters += splitFullName[1] ? splitFullName[1][0] : '';
        }
    } else {
        if (!isNil(companyName)) {
            const companyNameWords = companyName.split(' ');
            letters += companyNameWords[0].charAt(0).toLocaleUpperCase();
            if (companyNameWords[1]) {
                letters += companyNameWords[1].charAt(0).toLocaleUpperCase();
            }
        }
    }
    return letters;
}

export function mapSnakeToCamelCase(model: any) {
    return Object.entries(model).reduce((x: any, [key, value]) => {
        const [prefix, suffix] = key.split('_');
        const newKey = suffix ? `${prefix}${capitalize(suffix)}` : prefix;
        return {
            ...x,
            [newKey]: value,
        };
    }, {});
}

export function getErrorStatus(err: any) {
    const errors = err.errors || err.response?.data?.errors || [];
    return errors[0]?.code || 'UNKNOWN';
}

export function checkFileSize(size: number, maxSize: number = FILE_UPLOAD_MAX_SIZE) {
    return size < maxSize;
}

export const isSelected = (channel: IChannel, reduxChannels: any = []) => {
    const channels = reduxChannels?.map((item: string) => item.toLowerCase());

    return channels.indexOf(channel.toLowerCase()) >= 0;
};

export const customCharactersCount = (
    message: string,
    setTagCounts: (n: number | { count: number; arrayOfAttributes: string[] }) => void = () => undefined,
    returnAttributesArray?: boolean,
) => {
    const regexObj = new RegExp(MESSAGE_VARIABLES.map((x) => x.replace(/[-\/\\^$*+?.()|[\]]/g, '\\$&')).join('|'), 'g');
    let currentMessage: any = message;
    let match;
    let count = 0;
    const arrayOfAttributes: string[] = [];
    while ((match = regexObj.exec(message))) {
        let replaceBy = '';
        switch (match[0]) {
            case '{sms_opt_out}':
                replaceBy = '____+____+____+____+____+__';
                break;
            case '{custom2}':
                replaceBy = '____+____+____+____+____+____+____+____+____+____+____+____+____+____+';
                break;
            default:
                replaceBy = '____+____+____+____+____+____+__';
        }
        currentMessage = currentMessage.replaceAll(match[0], replaceBy);
        const countOfLength = replaceBy.length;
        count += countOfLength - match[0].length;
        arrayOfAttributes.push(match[0]);
    }

    if (setTagCounts) {
        setTagCounts(count);
    }
    return returnAttributesArray ? { currentMessage, arrayOfAttributes } : currentMessage;
};

export const csvToArray = (str: any, delimiter = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const arr = rows.map((row: any) => {
        const values = row.split(delimiter);
        const el = headers.reduce((object: any, header: any, index: any) => {
            object[header] = values[index];
            return object;
        }, {});
        return el;
    });

    return arr;
};

export const getColor = (colorName: string) => {
    return getComputedStyle(document.documentElement).getPropertyValue(colorName);
};

export const swapKeysAndValues = (obj: object) => {
    const swapped = Object.entries(obj).map(([key, value]) => ({ [value]: key }));

    return Object.assign({}, ...swapped);
};

export const showNumberWithColon = (num: number) => {
    return num
        .toString()
        .split('.')[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export type PERMISSION_KEYS =
    | 'ACCOUNTS'
    | 'ALERTS'
    | 'AUDIENCES'
    | 'CAMPAIGNS'
    | 'CHANNELS'
    | 'INVOICES'
    | 'KEYS'
    | 'MESSAGES'
    | 'NUMBERS'
    | 'PAYMENTS'
    | 'SENDERS'
    | 'SHORT_URLS'
    | 'TEMPLATES'
    | 'USERS'
    | 'WEBHOOKS'
    | 'MEDIA'
    | 'CONVERSATIONS'
    | 'SMS_LIMITS';

interface IPermission {
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canAdd: boolean;
}

export type PERMISSIONS_OBJ = {
    [key in PERMISSION_KEYS]?: IPermission;
};

export type PERMISSIONS_CREATE_OBJ = {
    [key in PERMISSION_KEYS]: number;
};
export const mapPermissionsToObj = (permissions: PERMISSIONS_CREATE_OBJ) => {
    const permissionsObj: PERMISSIONS_OBJ = {};
    Object.entries(permissions).map(([permission, value]) => {
        permissionsObj[permission as PERMISSION_KEYS] = {
            canView: (value & 1) === 1,
            canEdit: (value & 4) === 4,
            canDelete: (value & 8) === 8,
            canAdd: (value & 2) === 2,
        };
    });
    return permissionsObj;
};

export const makePermissionRequiredMessage = (permissionMode: string, permission: PERMISSION_KEYS): string => {
    const arr = permission.toLowerCase().split('_');
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1, -1);
    }
    return `You need ${permissionMode} ${arr.join(' ')} permission`;
};
