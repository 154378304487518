import { ReactElement, LazyExoticComponent, Suspense } from 'react';

type Props = {
    view: LazyExoticComponent<any>;
    innerProps?: Record<string, any>;
    fallback?: ReactElement | null;
};

export default function LazyComponent(props: Props): ReactElement {
    const View = props.view;

    return (
        <Suspense fallback={props.fallback || null}>
            <View {...props.innerProps} />
        </Suspense>
    );
}
