import { BalanceActionTypes as Types } from './types';
// Import Interfaces
import { IBalanceState } from 'types/reducers';

const setInfo = (data: IBalanceState) => {
    return {
        type: Types.BALANCE,
        payload: data,
    };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    setInfo,
};
