import React, { memo } from 'react';
import { isNil } from 'lodash';
import { Progress } from 'antd';

import { IDxProgressProps } from 'types/components';

import 'assets/scss/dxProgress.scss';
import { DxTooltip } from '../index';

const DxProgress: React.FC<IDxProgressProps> = ({ title, percent, appearance, info, hidePercent, ...props }) => {
    const showTitle = !isNil(title);
    const showPercent = !isNil(percent) && props.showInfo;
    const showData = showTitle || showPercent;

    return (
        <div className="dx-outer">
            {showData && (
                <div className="dx-title-box">
                    {showTitle && <span className="dx-progress-title">{title}</span>}
                    {showPercent && <span className="dx-progress-percent">{percent}%</span>}
                </div>
            )}
            {info ? (
                <DxTooltip title={info}>
                    <Progress
                        className={`dx-progress ${appearance ? appearance : ''} ${
                            hidePercent ? 'dx-progress-hide-percent' : ''
                        }`}
                        percent={percent}
                        {...props}
                    />
                </DxTooltip>
            ) : (
                <Progress
                    className={`dx-progress ${appearance ? appearance : ''} ${
                        hidePercent ? 'dx-progress-hide-percent' : ''
                    }`}
                    percent={percent}
                    {...props}
                />
            )}
        </div>
    );
};

export default memo(DxProgress);
