import { AuthActionTypes as Types } from './types';

const doLogin = (firstLogin?: boolean) => {
    return {
        type: Types.DO_LOGIN,
        payload: firstLogin,
    };
};

const removeFirstLogin = () => {
    return {
        type: Types.REMOVE_FIRST_LOGIN,
    };
};

const doLogout = (prevUrl: string) => {
    localStorage.removeItem('accessTokenMain');
    return {
        type: Types.DO_LOGOUT,
        payload: prevUrl,
    };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    doLogin,
    doLogout,
    removeFirstLogin,
};
