import { TActionCreator } from 'types/actions/types';
import { ISMSCampaignsState } from 'types/reducers';
import { SMSCampaignActionTypes } from 'actions/types';
import { PaginationDefaultOptions } from 'constants/common';

const initialState = {
    loading: true,
    list: [],
    pagination: {
        number: PaginationDefaultOptions.current,
        total: PaginationDefaultOptions.total,
        size: PaginationDefaultOptions.pageSize,
    },
};

export default function sendersReducer(state: ISMSCampaignsState = initialState, action: TActionCreator) {
    switch (action.type) {
        case SMSCampaignActionTypes.INIT:
            return {
                loading: false,
                list: action.payload.data,
                pagination: action.payload.pagination,
            };
        case SMSCampaignActionTypes.DELETE:
            return {
                ...state,
                campaigns: state.list.filter((x) => x.value !== action.payload),
                pagination: {
                    ...state.pagination,
                    total: state.pagination.total - 1,
                },
            };
        case SMSCampaignActionTypes.UPDATE_LIST:
            return {
                ...state,
                list: action.payload,
            };
        default:
            return state;
    }
}
