import React, { FC, memo } from 'react';

import { useLocalization } from 'hooks';
import { IGuideListProps } from 'types/components';
import { DxCard, DxLink } from 'components';

import 'assets/scss/guide-list.scss';
import { HelpfulGuides } from '../../../pages/Dashboard/config';

const GuideList: FC<IGuideListProps> = ({ channel }) => {
    const { t } = useLocalization();

    const list = HelpfulGuides[channel];

    return (
        <DxCard withShadow={true}>
            <h2 className="homepage-card-title">{t('HELPFUL_GUIDES')}</h2>
            <ul className="guide-list">
                {list?.map(({ title, path }, index) => (
                    <li className="guide-list-item" key={index}>
                        <p className="guide-list-title">{title}</p>
                        <DxLink external={true} appearance="link" target="_target" to={path}>
                            {t('READ_NOW')} {`>`}
                        </DxLink>
                    </li>
                ))}
            </ul>
        </DxCard>
    );
};

export default memo(GuideList);
