import React, { FC, memo } from 'react';
import { DatePicker as AntDatePicker, TimeRangePickerProps, Space } from 'antd';

import 'assets/scss/dxDatepicker.scss';
import { DxIcon } from 'components/common';

const AntRangePicker = AntDatePicker.RangePicker;

const DxRangePicker: FC<any> = (rest) => (
    <div className="dx-datepicker-box">
        <Space direction="vertical" size={5}>
            <AntRangePicker suffixIcon={<DxIcon type={'calendar'} />} dropdownClassName="dx-datepicker" {...rest} />
        </Space>
    </div>
);

export default memo(DxRangePicker);
