import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import 'assets/scss/publicLayout.scss';

const PrivateBlankLayout = () => {
    const { pathname } = useLocation();
    return (
        <div className={`private-blank-layout ${pathname.includes('company/') && 'with-tab'}`}>
            {pathname.includes('company/') ? (
                <Outlet />
            ) : (
                <div className="container">
                    <Outlet />
                </div>
            )}
        </div>
    );
};

export default PrivateBlankLayout;
