import React, { FC, memo } from 'react';

import { ICapabilitiesProps } from 'types/components';
import { useLocalization } from 'hooks';

import 'assets/scss/capabilitiesList.scss';
import { capabilitiesList } from 'pages/Dashboard/config';
import { isNil } from 'lodash';
import { US_BASED_COUNTRIES } from 'constants/common';

const CapabilitiesList: FC<ICapabilitiesProps> = ({ country }) => {
    const { t } = useLocalization();

    const selectedCountry = US_BASED_COUNTRIES.includes(country?.toLowerCase()) ? 'us' : country?.toLowerCase();

    const selectedCountryInfo = isNil(capabilitiesList[selectedCountry])
        ? capabilitiesList['ALL']
        : capabilitiesList[selectedCountry];

    return (
        <ul className={`capabilities-list ${selectedCountryInfo.length < 3 ? 'capabilities-list-center' : ''} `}>
            {selectedCountryInfo.map(({ title, imgPath }, index) => (
                <li className="capabilities-list-item" key={index}>
                    <img className="capabilities-list-img" src={imgPath} alt="" />
                    <span className="capabilities-list-title">{t(title)}</span>
                </li>
            ))}
        </ul>
    );
};

export default memo(CapabilitiesList);
