import { CHANNEL_LABELS, CHANNELS, INDUSTRIES, INDUSTRY_IDS, USE_CASES_VIBER } from './common';
import { getTimezones } from '../services/utils/timezoneHelper';

const PaymentTypes = [
    { value: 'STRIPE', label: 'Stripe' },
    { value: 'PAYPAL', label: 'Paypal' },
    { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
    { value: 'BONUS', label: 'Bonus' },
    { value: 'FREE_TEST', label: 'Free Test' },
    { value: 'COMPENSATION_MANUAL', label: 'Compensation Manual' },
];
const PaymentTypesStatus = [
    { value: 'sent', label: 'Sent' },
    { value: 'received', label: 'Received' },
];
const PaymentMethods = [
    { value: 'paypal', label: 'PayPal' },
    { value: 'stripe', label: 'Stripe' },
    { value: 'bank_payment', label: 'Bank Payments' },
    { value: 'free_message', label: 'Free Messages' },
];
const PaymentStatus = [
    { value: 'pending', label: 'Pending' },
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'refunded', label: 'Refunded' },
];

const Channels = [
    { value: CHANNELS.SMS, label: CHANNEL_LABELS[CHANNELS.SMS] },
    { value: CHANNELS.VIBER, label: CHANNEL_LABELS[CHANNELS.VIBER] },
    { value: CHANNELS.WHATSAPP, label: CHANNEL_LABELS[CHANNELS.WHATSAPP] },
];

const Industries = [
    { value: INDUSTRY_IDS[INDUSTRIES.AUTOMOTIVE], label: 'Automotive' },
    { value: INDUSTRY_IDS[INDUSTRIES.ECOMMERCE], label: 'eCommerce' },
    { value: INDUSTRY_IDS[INDUSTRIES.HEALTHCARE], label: 'Healthcare' },
    { value: INDUSTRY_IDS[INDUSTRIES.EDUCATION], label: 'Education' },
    { value: INDUSTRY_IDS[INDUSTRIES.FINANCIAL_SERVICES], label: 'Financial Services' },
    { value: INDUSTRY_IDS[INDUSTRIES.LEGAL], label: 'Legal' },
    { value: INDUSTRY_IDS[INDUSTRIES.REAL_ESTATE], label: 'Real Estate' },
    { value: INDUSTRY_IDS[INDUSTRIES.RETAIL], label: 'Retail' },
    { value: 9, label: 'Other' },
];

const smsTemplatesByIndustry: any = {
    [INDUSTRY_IDS[INDUSTRIES.AUTOMOTIVE]]: [
        {
            title: 'Appointment Reminders',
            text: 'Hi {first_name}, we wanted to confirm your appointment on {custom2} at {custom1}. Please reply with YES to confirm.',
        },
        {
            title: 'Payment Alerts',
            text: 'Hello {first_name}, your next payment of $100 is due on Friday. Email us and mention your check or submit it online: bit.ly/p1owe1.',
        },
        {
            title: 'Repair Updates',
            text: 'Hello {first_name}, Dealer & Dealt here! It’s been months since you got your oil container refilled. Stop by to refill your container with our branded oil.',
        },
        {
            title: 'Restock Alerts',
            text: 'Hey {first_name}, Dealer & Dealt here! We see you’re interested in the new Dodge Challenger auto parts. Be the first to know about the restock: bit.ly/p2owe2.',
        },
        {
            title: 'Discounts And Coupons',
            text: 'Hey {first_name}. Are you interested in the latest Dodge? We have solid discounts for subscribers. Show this text and get $300 off your purchase.',
        },
        {
            title: 'Text Broadcasting',
            text: 'Hi {first_name}, good news. The new Dodge Challenger is here! Reply to this message and we’ll have you come for a quick test drive.',
        },
        {
            title: 'Maintenance Tips',
            text: 'Hi {first_name}, the first snow of the season is expected to come on {custom2}. Dealer & Dealt would like to remind you to switch to winter tires.',
        },
        {
            title: 'Customer Reviews',
            text: 'Dear {first_name}, thank you for choosing  Dealer & Dealt. Please provide us with your feedback so we improve our services: bit.ly/p3owe3. Thank you!',
        },
        {
            title: 'Customers Feedback',
            text: 'Hey {first_name}, please take a few minutes to fill out this survey about your last purchase: bit.ly/p3owe3. Text back DONE to get a test drive coupon.',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.ECOMMERCE]]: [
        {
            title: 'Abandoned Cart Reminders',
            text: 'Hi {first_name}, we noticed you didn’t complete your order on Shirtera. Reply with HELP if you need our assistance or complete your order at bit.ly/p4owe4.',
        },
        {
            title: 'One-Time Passcodes',
            text: 'Hi {first_name}, your one-time passcode is 7856. Valid for 10 minutes.',
        },
        {
            title: 'Order Updates',
            text: 'Dear {first_name}, order #7856 was confirmed on {custom2}, {custom1}. Please check the order status here: bit.ly/p2owe2.',
        },
        {
            title: 'Delivery Status Notifications',
            text: 'Dear {first_name}, we noticed the current status of your order is on hold. Sorry for the inconvenience. You’ll get an update regarding the delivery status shortly.',
        },
        {
            title: 'Discounts And Coupons',
            text: 'Hi {first_name}, congratulations! You are the winner of our latest contest. Use this code to proceed with your prize: 7856',
        },
        {
            title: 'New Products Recommendations',
            text: 'Hi {first_name}, the new Nike T-shirt, code 7856 is an awesome gift idea for the holidays. Find the full product description here: bit.ly/p3owe3.',
        },
        {
            title: 'Holiday Offers',
            text: "It’s Black Friday {first_name}, and you're our lucky customer. Buy the latest iPhone with the lowest prices within 8 hours, starting from {custom1}.",
        },
        {
            title: 'Customer Reviews',
            text: 'Hi {first_name}! How would you rate our product from 1 (very bad) to 10 (excellent)? Share your opinion by visiting here: bit.ly/p4owe4.',
        },
        {
            title: 'Customer Feedback',
            text: 'Hi {first_name}! We value your experience, so let us know whether your issue was solved by replying with YES/NO. For further queries, call: +1-202-555-0157.',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.HEALTHCARE]]: [
        {
            title: 'Appointment Reminders',
            text: 'Dear {first_name}, your appointment with Dr. {last_name} is scheduled for {custom2}, {custom1}. Make sure to arrive at Cordial Care on time. Stay safe and healthy!',
        },
        {
            title: 'Payment Alerts',
            text: 'Hi {first_name}, this is Cordial Care reminding you that you have a $1000 payment for a surgery. Wishing you a speedy recovery!',
        },
        {
            title: 'Test Result Notifications',
            text: 'Dear {first_name}, your lab test results are ready. Please log in to your account to view the results bit.ly/p1we1.',
        },
        {
            title: 'Aftercare Management Programs',
            text: "Hello Mr. {last_name}, here's everything prescribed by your doctor: 20 Ibuprofen pills taken twice a day for 10 days - every morning and evening after eating.",
        },
        {
            title: 'Patient Reviews',
            text: 'Dear {first_name}, thank you for choosing Cordial Care. Share your experience and provide your feedback: bit.ly/p3owe3. Stay healthy!',
        },
        {
            title: 'Patient Feedback',
            text: 'Hey {first_name}, Cordial Care would like your feedback on our service quality. Fill out a short satisfaction here: bit.ly/p2owe2',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.EDUCATION]]: [
        {
            title: 'Deadline Alerts',
            text: 'Dear {first_name}, your physics assignment is due tomorrow evening. Submit it on time to avoid deduction of marks.',
        },
        {
            title: 'Meeting Reminders',
            text: 'Dear {first_name}, this is Edgewood reminding you to attend the parent-teacher meeting on {custom2} at {custom1}.',
        },
        {
            title: 'Event Invitations',
            text: 'Dear {first_name}, join us at the Annual Sports Festival on {custom2} from {custom1}. Call the administration for further details: +1-202-555-0157.',
        },
        {
            title: 'Payment Alerts',
            text: "Dear {first_name}, your child's tuition fee is due tomorrow. For more details, call the administration office: +1-202-555-0157.",
        },
        {
            title: 'Internal Communication',
            text: 'Dear {first_name}, Miss {first_name} is unavailable to conduct the English class at {custom1}. Reply with YES with your name if you can substitute.',
        },
        {
            title: 'Emergency Alerts',
            text: 'Dear {first_name}, your child {first_name} is feeling unwell and is asking to be picked up.  Reply with YES to confirm or call us for another solution.',
        },
        {
            title: 'Class Cancellation Alerts',
            text: 'Dear {first_name}, Miss {first_name} is unwell. Philosophy class at {custom1} is canceled. Contact school administration for more details.',
        },
        {
            title: 'Exam Result Notifications',
            text: 'Hi {first_name}, this mid-term’s examination results are now available. Pick up your exam results from Mrs. {last_name} office between {custom1}.',
        },
        {
            title: 'Student Attendance Updates',
            text: "Dear {first_name}, {first_name} {last_name} didn't attend philosophy class today at {custom1}. Let us know if you’re aware of this and call the admin office :+1-202-555-0157.",
        },
        {
            title: 'Fundraising Campaign Reminders',
            text: 'Dear {first_name}, the annual fundraising campaign is live tomorrow! We expect your kind contribution to the fundraiser. Thank you!',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.FINANCIAL_SERVICES]]: [
        {
            title: 'Identity Verification',
            text: 'Dear {first_name}, your one-time password is 7856. Valid for 10 minutes.',
        },
        {
            title: 'Appointment Reminders',
            text: 'Hi {first_name}! This is a quick reminder about your meeting today with our insurance department at {custom1}, Willow Avenue.',
        },
        {
            title: 'Payments Alerts',
            text: 'Hi {first_name}, your next loan payment is due on {custom2}. Please make a payment of $100 before the due date or call +1-202-555-0157 for further inquiry.',
        },
        {
            title: 'Low-Balance Notifications',
            text: 'Dear {first_name}, your current balance is $0.06 and at risk of going overdrawn. Please call +1-202-555-0157 to clarify the issue.',
        },
        {
            title: 'Credit Card Updates',
            text: 'Dear {first_name}, your money is being processed. Please note that it would take up to 3 hours for funds to appear.',
        },
        {
            title: 'Customer Reviews',
            text: 'Dear {first_name}, please share your experience with us. Visit bit.ly/p1owe1 and provide feedback so we can improve our services. Thank you!',
        },
        {
            title: 'Customers Feedback',
            text: 'Dear {first_name}, please share your recent experience with our bank. Fill out this short survey to help us improve our services: bit.ly/p2owe2.',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.LEGAL]]: [
        {
            title: 'Appointment Reminders',
            text: 'Hi {first_name}, your appointment at the Downtown Courthouse is on {custom2}, {custom1}.',
        },
        {
            title: 'Payments Alerts',
            text: 'Hello {first_name}, your payment is due tomorrow. Kindly visit our office to proceed with the transaction. Thank you!',
        },
        {
            title: 'Customer Reviews',
            text: 'Hey {first_name}, it was a pleasure working with you! Please give a quick review of your experience on the website: bit.ly/p1owe1.',
        },
        {
            title: 'Customers Feedback',
            text: 'Hey {first_name}, how would you rate our services from 1 (very bad) to 10 (excellent)?',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.REAL_ESTATE]]: [
        {
            title: 'Appointment Reminders',
            text: 'Hi {first_name}, your appointment with Redwood Realtors is on {custom2}, {custom1}. If you want to postpone or cancel, call +1-202-555-0157.',
        },
        {
            title: 'Open House Invitations',
            text: 'Hi {first_name}, the Willow Avenue property is hosting an open house today from {custom1}. Looking forward to seeing you! – {first_name}{last_name}, Realtor.',
        },
        {
            title: 'Payment Alerts',
            text: "Hi {first_name}, your rent is due. Kindly pay $400 for this month's rent.",
        },
        {
            title: 'Property Promotion',
            text: 'Hi {first_name}, a 307-square yard property like your previous one is available for rent on Willow Avenue. Contact us for more details: +1-202-555-0157',
        },
        {
            title: 'New Listing Announcements',
            text: 'Hi {first_name}, we have new listings waiting for you. Reply with LISTING to view the updates.',
        },
        {
            title: 'Client Reviews',
            text: 'Hello {first_name}! We loved seeing you at the open house today. How would you rate our services from 1 (very bad) to 10 (excellent)?',
        },
        {
            title: 'Clients Feedback',
            text: 'Thank you for choosing Redwood Realtors, {first_name}. Please share your experience with us and fill out this short survey: bit.ly/p1owe1. Thank you!',
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.RETAIL]]: [
        {
            title: 'Special Offers',
            text: 'Hi {first_name}, thank you for being a loyal customer! Show this text in our stores and get 20% off your next purchase.',
        },
        {
            title: 'New Products Promotion',
            text: 'Hi {first_name}, we have a brand new Body Stackers Union Full-Zip Hoodie in stock. Get yours now before it sells out!',
        },
        {
            title: 'Discounts And Coupons',
            text: 'Hi {first_name}, 15% off in-store purchases this Friday only! Want to be the lucky one? Show this text in-store and get 15% off your purchase.',
        },
        {
            title: '“Thank You” Messages',
            text: 'Dear {first_name}, we hope you liked your new mug from The Full Cart. Thank you for supporting our business!',
        },
        {
            title: 'Text-to-Win Contests',
            text: 'Hey {first_name}! What is the United States’ favorite holiday destination? Text the correct answer to +1-202-555-0157 and win a pair of sunglasses!',
        },
        {
            title: 'Customer Reviews',
            text: 'Hi {first_name}, thank you for the lovely chat with us at The Full Cart!  How would you rate our services 1 of 5 (5 as excellent)',
        },
        {
            title: 'Customers Feedback',
            text: "Hey {first_name}, we value your opinion. Are you satisfied with The Full Cart's services? Reply with YES/NO. Have a nice day!",
        },
    ],
    [INDUSTRY_IDS[INDUSTRIES.OTHER]]: [
        {
            title: 'Appointment Reminders',
            text: 'Hi {first_name}, we wanted to confirm your appointment on {custom2} at {custom1}. Please reply with YES to confirm.',
        },
        {
            title: 'Payment Alerts',
            text: 'Hello {first_name}, your next payment of $100 is due on Friday. Email us and mention your check or submit it online: bit.ly/p1owe1.',
        },
        {
            title: 'Repair Updates',
            text: 'Hello {first_name}, Dealer & Dealt here! It’s been months since you got your oil container refilled. Stop by to refill your container with our branded oil.',
        },
        {
            title: 'Restock Alerts',
            text: 'Hey {first_name}, Dealer & Dealt here! We see you’re interested in the new Dodge Challenger auto parts. Be the first to know about the restock: bit.ly/p2owe2.',
        },
        {
            title: 'Discounts And Coupons',
            text: 'Hey {first_name}. Are you interested in the latest Dodge? We have solid discounts for subscribers. Show this text and get $300 off your purchase.',
        },
        {
            title: 'Maintenance Tips',
            text: 'Hi {first_name}, the first snow of the season is expected to come on {custom2}. Dealer & Dealt would like to remind you to switch to winter tires.',
        },
        {
            title: 'Customer Reviews',
            text: 'Dear {first_name}, thank you for choosing  Dealer & Dealt. Please provide us with your feedback so we improve our services: bit.ly/p3owe3. Thank you!',
        },
        {
            title: 'Customers Feedback',
            text: 'Hey {first_name}, please take a few minutes to fill out this survey about your last purchase: bit.ly/p3owe3. Text back DONE to get a test drive coupon.',
        },
        {
            title: 'Abandoned Cart Reminders',
            text: 'Hi {first_name}, we noticed you didn’t complete your order on Shirtera. Reply with HELP if you need our assistance or complete your order at bit.ly/p4owe4.',
        },
        {
            title: 'One-Time Passcodes',
            text: 'Hi {first_name}, your one-time passcode is 7856. Valid for 10 minutes.',
        },
        {
            title: 'Order Updates',
            text: 'Dear {first_name}, order #7856 was confirmed on {custom2}, {custom1}. Please check the order status here: bit.ly/p2owe2.',
        },
        {
            title: 'Delivery Status Notifications',
            text: 'Dear {first_name}, we noticed the current status of your order is on hold. Sorry for the inconvenience. You’ll get an update regarding the delivery status shortly.',
        },
        {
            title: 'Winner Announcement',
            text: 'Hi {first_name}, congratulations! You are the winner of our latest contest. Use this code to proceed with your prize: 7856',
        },
        {
            title: 'New Products Recommendations',
            text: 'Hi {first_name}, the new Nike T-shirt, code 7856 is an awesome gift idea for the holidays. Find the full product description here: bit.ly/p3owe3.',
        },
        {
            title: 'Holiday Offers',
            text: "It’s Black Friday {first_name}, and you're our lucky customer. Buy the latest iPhone with the lowest prices within 8 hours, starting from {custom1}.",
        },
        {
            title: 'Test Result Notifications',
            text: 'Dear {first_name}, your lab test results are ready. Please log in to your account to view the results bit.ly/p1we1.',
        },
        {
            title: 'Aftercare Management Programs',
            text: "Hello Mr. {last_name}, here's everything prescribed by your doctor: 20 Ibuprofen pills taken twice a day for 10 days - every morning and evening after eating.",
        },
        {
            title: 'Deadline Alerts',
            text: 'Dear {first_name}, your physics assignment is due tomorrow evening. Submit it on time to avoid deduction of marks.',
        },
        {
            title: 'Event Invitations',
            text: 'Dear {first_name}, join us at the Annual Sports Festival on {custom2} from {custom1}. Call the administration for further details: +1-202-555-0157.',
        },
        {
            title: 'Internal Communication',
            text: 'Dear {first_name}, Miss {first_name} is unavailable to conduct the English class at {custom1}. Reply with YES with your name if you can substitute.',
        },
        {
            title: 'Emergency Alerts',
            text: 'Dear {first_name}, your child {first_name} is feeling unwell and is asking to be picked up.  Reply with YES to confirm or call us for another solution.',
        },
        {
            title: 'Class Cancellation Alerts',
            text: 'Dear {first_name}, Miss {first_name} is unwell. Philosophy class at {custom1} is canceled. Contact school administration for more details.',
        },
        {
            title: 'Exam Result Notifications',
            text: 'Hi {first_name}, this mid-term’s examination results are now available. Pick up your exam results from Mrs. {last_name} office between {custom1}.',
        },
        {
            title: 'Student Attendance Updates',
            text: "Dear {first_name}, {first_name} {last_name} didn't attend philosophy class today at {custom1}. Let us know if you’re aware of this and call the admin office :+1-202-555-0157.",
        },
        {
            title: 'Fundraising Campaign Reminders',
            text: 'Dear {first_name}, the annual fundraising campaign is live tomorrow! We expect your kind contribution to the fundraiser. Thank you!',
        },
        {
            title: 'Identity Verification',
            text: 'Dear {first_name}, your one-time password is 7856. Valid for 10 minutes.',
        },
        {
            title: 'Low-Balance Notifications',
            text: 'Dear {first_name}, your current balance is $0.06 and at risk of going overdrawn. Please call +1-202-555-0157 to clarify the issue.',
        },
        {
            title: 'Credit Card Updates',
            text: 'Dear {first_name}, your money is being processed. Please note that it would take up to 3 hours for funds to appear.',
        },
        {
            title: 'Open House Invitations',
            text: 'Hi {first_name}, the Willow Avenue property is hosting an open house today from {custom1}. Looking forward to seeing you! – {first_name}{last_name}, Realtor.',
        },
        {
            title: 'Special Offers',
            text: 'Hi {first_name}, thank you for being a loyal customer! Show this text in our stores and get 20% off your next purchase.',
        },
        {
            title: '“Thank You” Messages',
            text: 'Dear {first_name}, we hope you liked your new mug from The Full Cart. Thank you for supporting our business!',
        },
        {
            title: 'Text-to-Win Contests',
            text: 'Hey {first_name}! What is the United States’ favorite holiday destination? Text the correct answer to +1-202-555-0157 and win a pair of sunglasses!',
        },
    ],
};

const viberTemplatesByIndustry: any = {
    [USE_CASES_VIBER.PROMOTIONAL]: [
        {
            title: 'Review Collection',
            text: 'Hey {first_name}, it was a pleasure working with you! Please provide a quick review of your experience on the website: bit.ly/p1we1.',
        },
        {
            title: 'New Collection Discounts',
            text: 'Hi {first_name}, use the Shirtera coupon on your next trip to get 15% off the new collection. Check out the products here: bit.ly/p1we1.',
        },
        {
            title: 'Product Recommendations',
            text: 'Hi {first_name}, the new Nike T-shirt, code 7856 is an awesome gift idea for the holidays. Find the full product description here: bit.ly/p3owe3.',
        },
        {
            title: 'Discounts by Season',
            text: 'Hi {first_name}, it’s getting hot out there! We’re giving an extra 20% off all summer items. Check out the hottest deals in-store and on our website at bit.ly/p1we1.',
        },
        {
            title: 'Discounts by Category',
            text: 'Hi {first_name}, run, run as fast as you can! We’re offering up to 30% off all running shoes and more. Race for your discount ASAP: now bit.ly/p1we1.',
        },
    ],
    [USE_CASES_VIBER.TRANSACTIONAL]: [
        {
            title: 'Reservation Confirmation',
            text: 'Hello {first_name}! Thank you for choosing Best Nest. We’re looking forward to your stay on {custom2}.',
        },
        {
            title: 'Reservation Reminders',
            text: 'Hi {first_name}, your reservation at Flavoroso is on{custom2} at {custom1}. To specify details, contact us: +1-202-555-0157',
        },
        {
            title: 'Appointment Reminder',
            text: 'Hi {first_name}, your appointment at the Downtown Courthouse is on{custom2}, {custom1}.',
        },
        {
            title: 'Rent Payment Reminders',
            text: "Hi {first_name}, your rent is due. Kindly pay $400 for this month's (June) rent.",
        },
        {
            title: 'Call Reminders',
            text: 'Hi {first_name}! Redwood Realtors is reminding you about your upcoming call tomorrow at{custom2}. To cancel or reschedule, follow this link: bit.ly/p4owe4.',
        },
        {
            title: 'Verification Code',
            text: 'Your verification code is {CODE}.',
        },
    ],
};

const CompanySizes = [
    { value: 1, label: '1-50' },
    { value: 2, label: '50-500' },
    { value: 3, label: '500 and more' },
];

const Positions = [
    { value: 1, label: 'Development' },
    { value: 2, label: 'Marketing' },
    { value: 3, label: 'IT & Operations' },
    { value: 4, label: 'Customer Support' },
    { value: 5, label: 'Billing' },
    { value: 6, label: 'Human Resources' },
    { value: 7, label: 'Other' },
];

const Currencies = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
];

const UseCases: {
    [key: string]: {
        [key: string]: any;
    };
} = {
    SMS: {
        list: [
            { value: 1, label: 'Marketing' },
            { value: 2, label: 'Verification' },
            { value: 3, label: 'Reminders' },
            { value: 4, label: 'Alerts' },
        ],
        maxLength: 1337,
        maxTagCount: 50,
    },
    VIBER: {
        list: [
            { value: 5, label: 'Promotional' },
            { value: 6, label: 'Transactional' },
        ],
        maxLength: 1000,
        maxTagCount: 10,
    },
    WHATSAPP: {
        list: [{ value: 7, label: 'Notification alerts' }],
        maxLength: 1024,
        maxTagCount: 50,
    },
};

const UseCasesCombined = [
    { value: 1, label: 'Marketing' },
    { value: 2, label: 'Verification' },
    { value: 3, label: 'Reminders' },
    { value: 4, label: 'Alerts' },
    { value: 5, label: 'Promotional' },
    { value: 6, label: 'Transactional' },
    { value: 7, label: 'Notification alerts' },
];

const Countries = [
    { value: 'af', label: 'Afghanistan' },
    { value: 'al', label: 'Albania' },
    { value: 'dz', label: 'Algeria' },
    { value: 'as', label: 'American Samoa' },
    { value: 'ad', label: 'Andorra' },
    { value: 'ao', label: 'Angola' },
    { value: 'ai', label: 'Anguilla' },
    { value: 'aq', label: 'Antarctica' },
    { value: 'ag', label: 'Antigua And Barbuda' },
    { value: 'ar', label: 'Argentina' },
    { value: 'am', label: 'Armenia' },
    { value: 'aw', label: 'Aruba' },
    { value: 'au', label: 'Australia' },
    { value: 'at', label: 'Austria' },
    { value: 'az', label: 'Azerbaijan' },
    { value: 'bs', label: 'Bahamas' },
    { value: 'bh', label: 'Bahrain' },
    { value: 'bd', label: 'Bangladesh' },
    { value: 'bb', label: 'Barbados' },
    { value: 'by', label: 'Belarus' },
    { value: 'be', label: 'Belgium' },
    { value: 'bz', label: 'Belize' },
    { value: 'bj', label: 'Benin' },
    { value: 'bm', label: 'Bermuda' },
    { value: 'bt', label: 'Bhutan' },
    { value: 'bo', label: 'Bolivia' },
    { value: 'ba', label: 'Bosnia And Herzegovina' },
    { value: 'bw', label: 'Botswana' },
    { value: 'bv', label: 'Bouvet Island' },
    { value: 'br', label: 'Brazil' },
    { value: 'io', label: 'British Indian Ocean Territory' },
    { value: 'bn', label: 'Brunei Darussalam' },
    { value: 'bg', label: 'Bulgaria' },
    { value: 'bf', label: 'Burkina Faso' },
    { value: 'bi', label: 'Burundi' },
    { value: 'kh', label: 'Cambodia' },
    { value: 'cm', label: 'Cameroon' },
    { value: 'ca', label: 'Canada' },
    { value: 'cv', label: 'Cape Verde' },
    { value: 'ky', label: 'Cayman Islands' },
    { value: 'cf', label: 'Central African Republic' },
    { value: 'td', label: 'Chad' },
    { value: 'cl', label: 'Chile' },
    { value: 'cn', label: 'China' },
    { value: 'cx', label: 'Christmas Island' },
    { value: 'cc', label: 'Cocos (keeling) Islands' },
    { value: 'co', label: 'Colombia' },
    { value: 'km', label: 'Comoros' },
    { value: 'cg', label: 'Congo' },
    { value: 'cd', label: 'Congo, The Democratic Republic Of The' },
    { value: 'ck', label: 'Cook Islands' },
    { value: 'cr', label: 'Costa Rica' },
    { value: 'ci', label: "Cote D'ivoire" },
    { value: 'hr', label: 'Croatia' },
    { value: 'cu', label: 'Cuba' },
    { value: 'cy', label: 'Cyprus' },
    { value: 'cz', label: 'Czech Republic' },
    { value: 'dk', label: 'Denmark' },
    { value: 'dj', label: 'Djibouti' },
    { value: 'dm', label: 'Dominica' },
    { value: 'do', label: 'Dominican Republic' },
    { value: 'tl', label: 'East Timor' },
    { value: 'ec', label: 'Ecuador' },
    { value: 'eg', label: 'Egypt' },
    { value: 'sv', label: 'El Salvador' },
    { value: 'gq', label: 'Equatorial Guinea' },
    { value: 'er', label: 'Eritrea' },
    { value: 'ee', label: 'Estonia' },
    { value: 'et', label: 'Ethiopia' },
    { value: 'fk', label: 'Falkland Islands (malvinas)' },
    { value: 'fo', label: 'Faroe Islands' },
    { value: 'fj', label: 'Fiji' },
    { value: 'fi', label: 'Finland' },
    { value: 'fr', label: 'France' },
    { value: 'gf', label: 'French Guiana' },
    { value: 'pf', label: 'French Polynesia' },
    { value: 'tf', label: 'French Southern Territories' },
    { value: 'ga', label: 'Gabon' },
    { value: 'gm', label: 'Gambia' },
    { value: 'ge', label: 'Georgia' },
    { value: 'de', label: 'Germany' },
    { value: 'gh', label: 'Ghana' },
    { value: 'gi', label: 'Gibraltar' },
    { value: 'gr', label: 'Greece' },
    { value: 'gl', label: 'Greenland' },
    { value: 'gd', label: 'Grenada' },
    { value: 'gp', label: 'Guadeloupe' },
    { value: 'gu', label: 'Guam' },
    { value: 'gt', label: 'Guatemala' },
    { value: 'gn', label: 'Guinea' },
    { value: 'gw', label: 'Guinea-bissau' },
    { value: 'gy', label: 'Guyana' },
    { value: 'ht', label: 'Haiti' },
    { value: 'hm', label: 'Heard Island And Mcdonald Islands' },
    { value: 'va', label: 'Holy See (vatican City State)' },
    { value: 'hn', label: 'Honduras' },
    { value: 'hk', label: 'Hong Kong' },
    { value: 'hu', label: 'Hungary' },
    { value: 'is', label: 'Iceland' },
    { value: 'in', label: 'India' },
    { value: 'id', label: 'Indonesia' },
    { value: 'ir', label: 'Iran, Islamic Republic Of' },
    { value: 'iq', label: 'Iraq' },
    { value: 'ie', label: 'Ireland' },
    { value: 'il', label: 'Israel' },
    { value: 'it', label: 'Italy' },
    { value: 'jm', label: 'Jamaica' },
    { value: 'jp', label: 'Japan' },
    { value: 'jo', label: 'Jordan' },
    { value: 'kz', label: 'Kazakstan' },
    { value: 'ke', label: 'Kenya' },
    { value: 'ki', label: 'Kiribati' },
    { value: 'kp', label: "Korea, Democratic People's Republic Of" },
    { value: 'kr', label: 'Korea, Republic Of' },
    { value: 'kv', label: 'Kosovo' },
    { value: 'kw', label: 'Kuwait' },
    { value: 'kg', label: 'Kyrgyzstan' },
    { value: 'la', label: "Lao People's Democratic Republic" },
    { value: 'lv', label: 'Latvia' },
    { value: 'lb', label: 'Lebanon' },
    { value: 'ls', label: 'Lesotho' },
    { value: 'lr', label: 'Liberia' },
    { value: 'ly', label: 'Libyan Arab Jamahiriya' },
    { value: 'li', label: 'Liechtenstein' },
    { value: 'lt', label: 'Lithuania' },
    { value: 'lu', label: 'Luxembourg' },
    { value: 'mo', label: 'Macau' },
    { value: 'mk', label: 'Macedonia, The Former Yugoslav Republic Of' },
    { value: 'mg', label: 'Madagascar' },
    { value: 'mw', label: 'Malawi' },
    { value: 'my', label: 'Malaysia' },
    { value: 'mv', label: 'Maldives' },
    { value: 'ml', label: 'Mali' },
    { value: 'mt', label: 'Malta' },
    { value: 'mh', label: 'Marshall Islands' },
    { value: 'mq', label: 'Martinique' },
    { value: 'mr', label: 'Mauritania' },
    { value: 'mu', label: 'Mauritius' },
    { value: 'yt', label: 'Mayotte' },
    { value: 'mx', label: 'Mexico' },
    { value: 'fm', label: 'Micronesia, Federated States Of' },
    { value: 'md', label: 'Moldova, Republic Of' },
    { value: 'mc', label: 'Monaco' },
    { value: 'mn', label: 'Mongolia' },
    { value: 'ms', label: 'Montserrat' },
    { value: 'me', label: 'Montenegro' },
    { value: 'ma', label: 'Morocco' },
    { value: 'mz', label: 'Mozambique' },
    { value: 'mm', label: 'Myanmar' },
    { value: 'na', label: 'Namibia' },
    { value: 'nr', label: 'Nauru' },
    { value: 'np', label: 'Nepal' },
    { value: 'nl', label: 'Netherlands' },
    { value: 'an', label: 'Netherlands Antilles' },
    { value: 'nc', label: 'New Caledonia' },
    { value: 'nz', label: 'New Zealand' },
    { value: 'ni', label: 'Nicaragua' },
    { value: 'ne', label: 'Niger' },
    { value: 'ng', label: 'Nigeria' },
    { value: 'nu', label: 'Niue' },
    { value: 'nf', label: 'Norfolk Island' },
    { value: 'mp', label: 'Northern Mariana Islands' },
    { value: 'no', label: 'Norway' },
    { value: 'om', label: 'Oman' },
    { value: 'pk', label: 'Pakistan' },
    { value: 'pw', label: 'Palau' },
    { value: 'ps', label: 'Palestinian Territory, Occupied' },
    { value: 'pa', label: 'Panama' },
    { value: 'pg', label: 'Papua New Guinea' },
    { value: 'py', label: 'Paraguay' },
    { value: 'pe', label: 'Peru' },
    { value: 'ph', label: 'Philippines' },
    { value: 'pn', label: 'Pitcairn' },
    { value: 'pl', label: 'Poland' },
    { value: 'pt', label: 'Portugal' },
    { value: 'pr', label: 'Puerto Rico' },
    { value: 'qa', label: 'Qatar' },
    { value: 're', label: 'Reunion' },
    { value: 'ro', label: 'Romania' },
    { value: 'ru', label: 'Russian Federation' },
    { value: 'rw', label: 'Rwanda' },
    { value: 'sh', label: 'Saint Helena' },
    { value: 'kn', label: 'Saint Kitts And Nevis' },
    { value: 'lc', label: 'Saint Lucia' },
    { value: 'pm', label: 'Saint Pierre And Miquelon' },
    { value: 'vc', label: 'Saint Vincent And The Grenadines' },
    { value: 'ws', label: 'Samoa' },
    { value: 'sm', label: 'San Marino' },
    { value: 'st', label: 'Sao Tome And Principe' },
    { value: 'sa', label: 'Saudi Arabia' },
    { value: 'sn', label: 'Senegal' },
    { value: 'rs', label: 'Serbia' },
    { value: 'sc', label: 'Seychelles' },
    { value: 'sl', label: 'Sierra Leone' },
    { value: 'sg', label: 'Singapore' },
    { value: 'sk', label: 'Slovakia' },
    { value: 'si', label: 'Slovenia' },
    { value: 'sb', label: 'Solomon Islands' },
    { value: 'so', label: 'Somalia' },
    { value: 'za', label: 'South Africa' },
    { value: 'gs', label: 'South Georgia And The South Sandwich Islands' },
    { value: 'es', label: 'Spain' },
    { value: 'lk', label: 'Sri Lanka' },
    { value: 'sd', label: 'Sudan' },
    { value: 'sr', label: 'Suriname' },
    { value: 'sj', label: 'Svalbard And Jan Mayen' },
    { value: 'sz', label: 'Swaziland' },
    { value: 'se', label: 'Sweden' },
    { value: 'ch', label: 'Switzerland' },
    { value: 'sy', label: 'Syrian Arab Republic' },
    { value: 'tw', label: 'Taiwan, Province Of China' },
    { value: 'tj', label: 'Tajikistan' },
    { value: 'tz', label: 'Tanzania, United Republic Of' },
    { value: 'th', label: 'Thailand' },
    { value: 'tg', label: 'Togo' },
    { value: 'tk', label: 'Tokelau' },
    { value: 'to', label: 'Tonga' },
    { value: 'tt', label: 'Trinidad And Tobago' },
    { value: 'tn', label: 'Tunisia' },
    { value: 'tr', label: 'Turkey' },
    { value: 'tm', label: 'Turkmenistan' },
    { value: 'tc', label: 'Turks And Caicos Islands' },
    { value: 'tv', label: 'Tuvalu' },
    { value: 'ug', label: 'Uganda' },
    { value: 'ua', label: 'Ukraine' },
    { value: 'ae', label: 'United Arab Emirates' },
    { value: 'gb', label: 'United Kingdom' },
    { value: 'us', label: 'United States' },
    { value: 'um', label: 'United States Minor Outlying Islands' },
    { value: 'uy', label: 'Uruguay' },
    { value: 'uz', label: 'Uzbekistan' },
    { value: 'vu', label: 'Vanuatu' },
    { value: 've', label: 'Venezuela' },
    { value: 'vn', label: 'Vietnam' },
    { value: 'vg', label: 'Virgin Islands, British' },
    { value: 'vi', label: 'Virgin Islands, U.s.' },
    { value: 'wf', label: 'Wallis And Futuna' },
    { value: 'eh', label: 'Western Sahara' },
    { value: 'ye', label: 'Yemen' },
    { value: 'zm', label: 'Zambia' },
    { value: 'zw', label: 'Zimbabwe' },
];

const CountriesWithVAT = [
    'at',
    'be',
    'bg',
    'hr',
    'cy',
    'cz',
    'dk',
    'ee',
    'fi',
    'fr',
    'de',
    'gr',
    'hu',
    'ie',
    'it',
    'lv',
    'lt',
    'lu',
    'mt',
    'nl',
    'pl',
    'pt',
    'ro',
    'sk',
    'si',
    'es',
    'se',
];

const Timezones = getTimezones();

export {
    Channels,
    Countries,
    Industries,
    CompanySizes,
    Positions,
    Currencies,
    Timezones,
    UseCases,
    UseCasesCombined,
    smsTemplatesByIndustry,
    viberTemplatesByIndustry,
    PaymentTypes,
    PaymentMethods,
    PaymentStatus,
    PaymentTypesStatus,
    CountriesWithVAT,
};
