import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IAccountState, IRootReducer } from 'types/reducers';
import { fromUTC } from 'services/utils/timezoneHelper';
import { DateFormat } from 'constants/common';

type IDateTimezone = {
    displayFormat?: string;
    value: string;
    showDateOnly?: boolean;
};

const DateTimezone: FC<IDateTimezone> = ({ value, showDateOnly }) => {
    const { timezone } = useSelector<IRootReducer, IAccountState>((state) => state.account);
    const format = showDateOnly ? DateFormat.onlyDate : DateFormat.display;
    const convertedDate = useMemo(() => {
        return fromUTC(value, timezone, format);
    }, [value, timezone]);

    return <>{convertedDate}</>;
};

export default memo(DateTimezone);
