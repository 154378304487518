import React, { FC, memo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import '../../../assets/scss/dxLoader.scss';
import { ILoading } from 'types/components/loading';

const Loading: FC<ILoading> = ({ size = 32 }) => {
    const loadingIcon = <LoadingOutlined style={{ fontSize: size }} spin={true} />;
    return <Spin className="dx-loading" indicator={loadingIcon} />;
};

export default memo(Loading);
