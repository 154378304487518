import React, { FC, memo, useMemo } from 'react';
import head from 'lodash/head';
import { Table } from 'antd';

import { IDataTableProps, ICreateColumnProps } from 'types/components/dataTable';
import { NoData } from 'constants/errorMessages';
import { createColumns } from './service';
import { useLocalization } from 'hooks';

import 'assets/scss/dxDatatable.scss';
import { EmptyState } from '../index';

import emptyDefaultImg from 'assets/images/empty-states/campaign.svg';

const DxDataTable: FC<IDataTableProps> = ({
    columnsOrder,
    includedKeys,
    excludedKeys,
    keyRenderers,
    customColumn,
    dataSource,
    keyFilters,
    pagination,
    highlighted,
    displayPattern,
    emptyDesc,
    emptyImg = emptyDefaultImg,
    emptyMessage = NoData.common,
    sticky,
    columnsWidth,
    onRow,
    scrollable,
    ...rest
}: IDataTableProps) => {
    const { t } = useLocalization();
    const columnProps = useMemo<ICreateColumnProps>(
        () => ({
            model: head(dataSource) || {},
            displayPattern,
            columnsOrder,
            keyRenderers,
            excludedKeys,
            includedKeys,
            keyFilters,
            t,
            columnsWidth,
            sticky,
            scrollable,
        }),
        [
            dataSource,
            excludedKeys,
            includedKeys,
            keyRenderers,
            keyFilters,
            columnsOrder,
            displayPattern,
            t,
            columnsWidth,
            sticky,
            scrollable,
        ],
    );
    let columns = useMemo(() => createColumns(columnProps), [columnProps]);
    if (customColumn) {
        if (Array.isArray(customColumn)) {
            columns = [...columns, ...customColumn];
        } else {
            columns = [...columns, customColumn];
        }
    }

    const locale = useMemo(
        () => ({ emptyText: <EmptyState title={t(emptyMessage)} description={emptyDesc} image={emptyImg} /> }),
        [emptyMessage, emptyImg, emptyDesc, t],
    );

    return (
        <Table
            className={`dx-datatable ${highlighted && 'dx-datatable-highlighted'} ${sticky && 'dx-datatable-sticky'} ${
                onRow ? 'dx-clickable' : ''
            } `}
            locale={rest.loading ? {} : locale}
            pagination={pagination}
            dataSource={dataSource}
            rowClassName={(record) =>
                `dx-row ${record.disabledRow ? 'dx-disabled-row' : ''} ${record.selectedRow ? 'dx-selected-row' : ''}`
            }
            columns={columns}
            onRow={onRow}
            {...(sticky || scrollable ? { scroll: { x: 'max-content' } } : {})}
            {...rest}
        />
    );
};

export default memo(DxDataTable);
