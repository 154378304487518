import React, { memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Form } from 'antd';

import { IDxFormProps } from 'types/components';
import { layout, requiredMark, validateMessages } from './config';

import 'assets/scss/dxForm.scss';

const DxForm: React.FC<IDxFormProps> = ({ methods, onFinish, ...restProps }) => (
    <FormProvider {...methods}>
        <Form
            className="dx-form"
            validateMessages={validateMessages}
            layout={layout}
            requiredMark={requiredMark}
            onFinish={onFinish}
            {...restProps}
        />
    </FormProvider>
);

export default memo(DxForm);
