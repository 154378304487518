import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { IDxLinkTypes } from 'types/components';

import 'assets/scss/dxButton.scss';
import { DxTooltip } from '../index';

const DxLink: FC<IDxLinkTypes> = ({
    children,
    appearance,
    disabled = false,
    radius = 'sharped',
    size,
    external = false,
    to,
    tooltipMessage,
    tooltipPlacement,
    ...restProps
}) => {
    if (disabled && tooltipMessage) {
        return (
            <DxTooltip message={true} placement={tooltipPlacement || 'left'} title={tooltipMessage}>
                <div className={classNames(`dx-link ${appearance} ${radius} ${size}`, { disabled })}>
                    {!external ? (
                        <Link to={to} {...restProps}>
                            {children}
                        </Link>
                    ) : (
                        <a href={to as string} {...restProps}>
                            {children}
                        </a>
                    )}
                </div>
            </DxTooltip>
        );
    }
    return (
        <div className={classNames(`dx-link ${appearance} ${radius} ${size}`, { disabled })}>
            {!external ? (
                <Link to={to} {...restProps}>
                    {children}
                </Link>
            ) : (
                <a href={to as string} {...restProps}>
                    {children}
                </a>
            )}
        </div>
    );
};

export default memo(DxLink);
